import { useCallback, useMemo } from 'react';
import { createStyles } from '@mantine/core';
import OverviewHeaderPanel from 'components/panels/OverviewHeaderPanel';
import { FilterArea } from 'components/filter-area/FilterArea';
import {
  AssetCategory,
  CustomTag,
  ModelOverviewInfoFragment,
  ModelType,
  SortDirection,
  useGetAllResourcesForOverviewPageQuery,
  useGetModelOverviewInformationQuery,
} from 'generated/graphql';
import ResourceOverviewCardLayout from 'pages/resource-overview-page/components/ResourceOverviewCardLayout/ResourceOverviewCardLayout';
import { Colors, Spacings } from '@whylabs/observatory-lib';
import { VIEW_TYPE } from 'types/navTags';
import { useNavLinkHandler } from 'hooks/usePageLinkHandler';
import useSort from 'hooks/useSort';
import { AllAccessors, ModelSortBy, SortByKeys, SortDirectionKeys, SortDirectionType } from 'hooks/useSort/types';
import { FilterKeys } from 'hooks/useFilterQueryString';
import { canManageDatasets } from 'utils/permissionUtils';
import { useUserContext } from 'hooks/useUserContext';
import { isItOverSubscriptionLimit } from 'utils/subscriptionUtils';
import { useDeepCompareMemo } from 'use-deep-compare';
import { DATASET_TYPES, getLabelForModelType, MODEL_TYPES, OTHER_TYPES, SecuredLLM } from 'utils/modelTypeUtils';
import { useSearchParams } from 'react-router-dom';
import { CheckBoxSection } from 'components/filter-area/utils';
import { useSuperGlobalDateRange } from 'components/super-date-picker/hooks/useSuperGlobalDateRange';
import { useFlags } from 'hooks/flags/useFlags';
import { LLM_SECURE_OVERALL, RESOURCE_TAGGING_FRONT_END } from 'constants/flags';
import { useSetHtmlTitle } from 'hooks/useSetHtmlTitle';
import UnexpectedErrorPage from 'pages/model-not-found/UnexpectedErrorPage';
import { useDebouncedValue } from '@mantine/hooks';
import { useResourceFilter } from 'components/resources-filter/useResourceFilter';
import { getCustomTagLabel } from 'components/tags/UserDefinedTags';
import DashboardLayout from './components/TableDashboard/DashboardLayout';
import { asLayoutTypeOrDefault, LayoutType, ResourceOverviewData } from './layoutHelpers';
import { RESOURCE_SORT_COLUMNS, SearchAndFilterCombo } from './components/ResourcesOverviewFilter/SearchAndFilterCombo';

const accessorsMapper = new Map<ModelSortBy, AllAccessors<ResourceOverviewData>>([
  ['Name', ['name']],
  ['Freshness', ['dataAvailability', 'latestTimestamp']],
  ['LatestAlert', ['latestAnomalyTimestamp']],
  ['AnomaliesInRange', ['totalAnomaliesInRange']],
  ['ResourceType', ['modelType']],
  ['CreationTime', ['creationTime']],
]);

const useContentAreaStyles = createStyles({
  panelRoot: {
    display: 'flex',
    flexBasis: Spacings.tabContentHeaderHeight,
  },
  panelCardRoot: {
    borderBottom: `1px solid ${Colors.brandSecondary200}`,
  },
});

export function ResourceOverviewPageContentArea(): JSX.Element {
  const { classes, cx } = useContentAreaStyles();
  const { flags } = useFlags();
  const { dateRange, loading: loadingDateRange } = useSuperGlobalDateRange();
  const { handleNavigation } = useNavLinkHandler();
  const [searchParams] = useSearchParams();
  const activeFilters = useMemo(() => searchParams.getAll(FilterKeys.modelFilter), [searchParams]);
  useSetHtmlTitle('Project dashboard');

  const RESOURCE_TYPE_FILTERS: CheckBoxSection[] = [
    {
      sectionLabel: 'Filter by model type',
      key: 'model_type_filters',
      items: MODEL_TYPES.flatMap((option) => {
        if (option === ModelType.Llm && flags[LLM_SECURE_OVERALL]) {
          return [option, SecuredLLM as ModelType];
        }
        return [option];
      }).map((d) => ({
        label: getLabelForModelType(d),
        value: d.toString(),
      })),
    },
    {
      sectionLabel: 'Filter by dataset type',
      key: 'dataset_type_filters',
      items: DATASET_TYPES.map((d) => ({
        label: getLabelForModelType(d),
        value: d.toString(),
      })),
    },
    {
      key: 'other_type_filters',
      items: OTHER_TYPES.map((d) => ({
        label: getLabelForModelType(d),
        value: d.toString(),
      })),
    },
  ];
  const searchTerm = searchParams.get(FilterKeys.searchString) ?? undefined;
  const normalizedTerm = searchTerm?.toLowerCase();
  const [debouncedSearchTerm] = useDebouncedValue(normalizedTerm, 250);

  const { renderFilter, activeFilters: newResourceFilters, addResourceTagToFilter } = useResourceFilter();
  const hasActiveResourceFilters = !!(
    newResourceFilters.resourceType?.length || newResourceFilters.resourceTags?.length
  );

  const { data: allResources, loading: loadingAllResourcesData } = useGetAllResourcesForOverviewPageQuery();
  const {
    data,
    loading: loadingFilteredResources,
    error,
    refetch,
  } = useGetModelOverviewInformationQuery({
    variables: {
      ...dateRange,
      ...(flags[RESOURCE_TAGGING_FRONT_END]
        ? { ...newResourceFilters, searchTerm: hasActiveResourceFilters ? '' : debouncedSearchTerm }
        : {}),
    },
    skip: loadingDateRange,
  });

  const resourcesLoading = loadingFilteredResources || loadingAllResourcesData;

  const resourceTagsInUse = useMemo(() => {
    const tags = new Map<string, CustomTag>([]);
    allResources?.models?.forEach(({ resourceTags }) =>
      resourceTags.forEach((customTag) => tags.set(getCustomTagLabel(customTag), customTag)),
    );
    return [...tags.values()];
  }, [allResources?.models]);

  const { getCurrentUser } = useUserContext();
  const user = getCurrentUser();
  const userCanManageDatasets = canManageDatasets(user);
  const isOverSubscriptionLimit = isItOverSubscriptionLimit({
    modelCount: allResources?.models?.length || 0,
    tier: user?.organization?.subscriptionTier,
  });

  const { sortDirection, sortBy, handleSort, setSort } = useSort<ModelSortBy>(
    SortByKeys.sortModelBy,
    SortDirectionKeys.sortModelDirection,
  );

  const modelsHaveBeenConfigured = useCallback(() => {
    if (allResources) {
      return allResources.models.some((model) => model.dataLineage?.oldestProfileTimestamp);
    }
    return false;
  }, [allResources]);

  const mapResources = (resourcesData?: ModelOverviewInfoFragment[]): ResourceOverviewData[] =>
    resourcesData?.map((r) => {
      const totalAnomaliesInRange =
        r.anomalyCounts?.timeseries?.reduce((acc, curr) => {
          const dayCount = curr.counts.reduce((dayTotal, current) => dayTotal + current.count, 0);
          return acc + dayCount;
        }, 0) ?? 0;
      return {
        ...r,
        totalAnomaliesInRange,
      };
    }) ?? [];

  const sortData = useCallback(
    (
      nextSortDirection: SortDirectionType,
      newSortBy: ModelSortBy = 'LatestAlert',
      usedData?: ResourceOverviewData[],
    ) => {
      const models = usedData || mapResources(data?.models);
      const accessors = accessorsMapper.get(newSortBy) ?? [];
      return handleSort<ResourceOverviewData>(models, nextSortDirection, newSortBy, accessors);
    },
    [data?.models, handleSort],
  );

  const filteredResources = useMemo(() => {
    const filterSecuredLLM = (model: ResourceOverviewData) =>
      model.tracesSummary?.hasTraces &&
      model.modelType === ModelType.Llm &&
      flags[LLM_SECURE_OVERALL] &&
      activeFilters.includes(SecuredLLM);
    const isFilterEmpty = activeFilters.length === 0;
    const resources = mapResources(data?.models);
    if (!resources || isFilterEmpty || flags[RESOURCE_TAGGING_FRONT_END]) {
      return sortData(sortDirection ?? SortDirection.Desc, sortBy, resources);
    }
    const sorted = resources.filter((model) => activeFilters.includes(model.modelType) || filterSecuredLLM(model));
    return sortData(sortDirection ?? SortDirection.Desc, sortBy, sorted);
  }, [activeFilters, data?.models, flags, sortBy, sortData, sortDirection]);

  const filteredData = (() => {
    if (!normalizedTerm || flags[RESOURCE_TAGGING_FRONT_END]) return filteredResources;

    return filteredResources?.filter(
      (searchModel) =>
        searchModel.name.toLowerCase().includes(normalizedTerm) ||
        searchModel.id.toLowerCase().includes(normalizedTerm),
    );
  })();

  function generateCardLayout() {
    return (
      <ResourceOverviewCardLayout
        searchTerm={searchParams.get(FilterKeys.searchString) ?? undefined}
        loading={loadingFilteredResources}
        error={error}
        sortByDirection={sortDirection}
        sortBy={sortBy}
        userCanManageDatasets={userCanManageDatasets}
        filteredData={filteredData}
        withTags={flags[RESOURCE_TAGGING_FRONT_END]}
        addResourceTagToFilter={addResourceTagToFilter}
      />
    );
  }

  function generateTableLayout() {
    return (
      <DashboardLayout
        searchTerm={searchParams.get(FilterKeys.searchString) ?? undefined}
        models={filteredResources}
        sortDirection={sortDirection}
        sortBy={sortBy}
        handleSort={setSort}
        loading={loadingFilteredResources}
        refetchData={refetch}
        setIsOpen={navigateToAddResource}
        userCanManageDatasets={userCanManageDatasets}
        withTags={flags[RESOURCE_TAGGING_FRONT_END]}
        addResourceTagToFilter={addResourceTagToFilter}
      />
    );
  }

  const layoutType = useDeepCompareMemo(() => {
    return asLayoutTypeOrDefault(searchParams.get(VIEW_TYPE));
  }, [searchParams]);

  function generateLayout(layout: LayoutType) {
    if (error) return <UnexpectedErrorPage />;

    return layout === 'card' ? generateCardLayout() : generateTableLayout();
  }

  const resourcesCount = useMemo(() => {
    const datasetsCount = filteredData.filter((r) => r.assetCategory === AssetCategory.Data)?.length ?? 0;
    const modelsCount = (filteredData.length ?? 0) - datasetsCount;
    return { modelsCount, datasetsCount };
  }, [filteredData]);

  return (
    <>
      <div className={cx(classes.panelRoot, layoutType === 'card' && classes.panelCardRoot)}>
        {flags[RESOURCE_TAGGING_FRONT_END] ? (
          <SearchAndFilterCombo
            filterComponent={renderFilter({ resourceTags: resourceTagsInUse, loading: loadingAllResourcesData })}
            hasActiveFilters={hasActiveResourceFilters}
            sortBy={sortBy ?? 'LatestAlert'}
            sortSetter={setSort}
            sortByDirection={sortDirection}
          />
        ) : (
          <FilterArea<ModelSortBy>
            titleText="Filter resources"
            placeholder="Filter by name or ID"
            tooltipContent="The list of all datasets and models which can be searched and filtered"
            checkboxFilterList={RESOURCE_TYPE_FILTERS}
            filterKey={FilterKeys.modelFilter}
            sortBy={sortBy}
            sortSetter={setSort}
            sortByDirection={sortDirection}
            sortFieldsList={RESOURCE_SORT_COLUMNS}
          />
        )}

        <OverviewHeaderPanel
          isOverSubscriptionLimit={isOverSubscriptionLimit}
          loading={resourcesLoading}
          error={error}
          modelsHaveBeenConfigured={modelsHaveBeenConfigured()}
          addResource={navigateToAddResource}
          userCanManageDatasets={userCanManageDatasets}
          datasetCount={resourcesCount.datasetsCount}
          modelsCount={resourcesCount.modelsCount}
          hasLayoutToggle
          hideDailyAnomalyGraph={hasActiveResourceFilters}
        />
      </div>
      <>{generateLayout(layoutType)}</>
    </>
  );

  function navigateToAddResource() {
    handleNavigation({ page: 'settings', settings: { path: 'model-management' } });
  }
}
