export const USER_PERMISSIONS_MANAGEMENT = 'userPermissionsManagement'; // Permanent flag - opt-in enterprise function
export const ACCOUNT_MANAGEMENT = 'accountManagement'; // Permanent flag - opt-in enterprise function

// Note: LLM_MONITORING_COVERAGE does not exist in LaunchDarkly, so it is always false
export const LLM_MONITORING_COVERAGE = 'llmMonitoringCoverageCard';

// Permanent flag - this turns on LLM Secure for customers
export const LLM_SECURE_OVERALL = 'llmSecureOverall';

// GE work
export const INDIVIDUAL_PROFILES = 'individualProfiles';
export const GE_HIDE_INSIGHTS = 'geHideInsights';

// new monitor preview
export const COMPREHENSIVE_PREVIEW = 'newMonitorComprehensivePreview';
export const MONITOR_BACKFILL = 'monitorBackfill';

// Monitor calibration graph in performance
export const MODEL_CALIBRATION_GRAPH = 'modelCalibrationGraph';

// Integration Library in get started
export const NETWORKED_INTEGRATION = 'networkedIntegration';

// Lineage link limiters
export const LIMIT_LINEAGE_LINKS = 'limitLineageLinks';

// Custom Dashboards
export const CUSTOM_DASHBOARD_IN_RESOURCES = 'customDashboardsInResources';

// Dashbird UI migration flags
export const SETTINGS_PAGE_DASHBIRD_UI = 'settingsPageDashbirdUi';

// UI-EXP part of resource tagging
export const RESOURCE_TAGGING_FRONT_END = 'resourceTaggingFrontEnd';
