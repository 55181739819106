import { ApolloError } from '@apollo/client';
import { adHocAtom, useAdHocExists } from 'atoms/adHocAtom';
import { FeatureWidget } from 'components/controls/widgets';
import FeatureControlWidget from 'components/controls/widgets/FeatureControlWidget';
import { HeaderEmptyFillWidget } from 'components/controls/widgets/HeaderEmptyFillWidget';
import SkeletonFeatureWidget from 'components/controls/widgets/SkeletonFeatureWidget';
import { useContext } from 'react';
import { TooltipSpacer } from 'strings/tooltips';
import { FeatureBasicData } from 'utils/createFeatureBasicData';
import { useFlags } from 'hooks/flags/useFlags';
import { MONITOR_BACKFILL } from 'constants/flags';
import { usePageTypeWithParams } from 'pages/page-types/usePageType';
import { useRecoilState } from 'recoil';
import { useFeatureWidgetStyles } from 'hooks/useFeatureWidgetStyles';
import { useNavLinkHandler } from 'hooks/usePageLinkHandler';
import { useDeepCompareMemo } from 'use-deep-compare';
import {
  useAdHocMonitorMutation,
  useGetBatchFrequencyQuery,
  useGetMonitorConfigQuery,
  FeatureDataSideTableFragment,
} from 'generated/graphql';

import { stringToSchema } from 'utils/schemaUtils';
import BatchFrequencyWidget from 'components/controls/widgets/BatchFrequencyWidget';
import ProfileLineageWidget from 'components/controls/widgets/ProfileLineageWidget';
import { WhyLabsText, WhyLabsTooltip, WhyLabsButton } from 'components/design-system';
import { useResourceText } from 'pages/model-page/hooks/useResourceText';
import { InputsTableTexts } from 'pages/model-page/components/InputsTable/InputsTableTexts';
import { upperCaseFirstLetterOnly } from 'utils/stringUtils';
import { AnalysisContext } from 'pages/shared/AnalysisContext';
import { Loader } from '@mantine/core';
import { isActiveBackfillJobStatus } from 'pages/shared/AnalysisPreview/utils';
import { nextMonitorRun } from './textUtils';
import { useFeatureHeaderPanelViewStyles } from './FeatureHeaderPanelViewCSS';
import { targetMatrixMember } from './FeatureHeaderPanelViewUtils';
import { canManageMonitors } from '../../../utils/permissionUtils';
import { useUserContext } from '../../../hooks/useUserContext';

const COMMON_TEXTS = {
  adHocButtonDisabled: 'Contact us to enable this feature',
  featureDetailPageBatchesInRangeTooltipContent: 'The total number of batches within the selected date range.',
  comparisonButton: 'Compare columns across segments',
};

const PAGE_TEXTS = {
  DATA: {
    ...COMMON_TEXTS,
    ...InputsTableTexts.DATA,
    adHocButton: 'Preview the expected results of the monitor run for the currently visible columns',
    adHocWidget: (
      <>
        Monitors run based on the batch frequency. For example, a daily frequency will result in one monitor run every
        24 hours at 00:00:00 UTC, and an hourly frequency will run at the top of each hour.
        <TooltipSpacer />
        More information on monitors and advanced configurations can be found at
        https://docs.whylabs.ai/docs/monitor-manager.
        <TooltipSpacer />
        Preview anomalies are temporary; they don&#39;t trigger notifications, and they don&#39;t affect anomalies
        generated by scheduled monitor runs.
      </>
    ),
    errorMessage: 'Column error',
    inferredFeatureType: 'Inferred column type',
    outputTitle: 'Output',
    featureDetailTooltipContent: 'The current column being displayed',
    featureTitle: 'Column',
  },
  MODEL: {
    ...COMMON_TEXTS,
    ...InputsTableTexts.MODEL,
    adHocButton: 'Preview the expected results of the monitor run for the currently visible columns',
    adHocWidget: (
      <>
        Monitors run based on the batch frequency. For example, a daily frequency will result in one run every 24 hours
        at 00:00:00 UTC, and an hourly frequency will run at the top of each hour.
        <TooltipSpacer />
        More information on monitors and advanced configurations can be found at
        https://docs.whylabs.ai/docs/monitor-manager.
        <TooltipSpacer />
        Preview anomalies are temporary; they don&#39;t trigger notifications, and they don&#39;t affect anomalies
        generated by scheduled monitor runs.
      </>
    ),
    errorMessage: 'Feature error',
    inferredFeatureType: 'Inferred feature type',
    outputTitle: 'Output',
    featureDetailTooltipContent: 'The current feature being displayed',
    featureTitle: 'Feature',
  },
  LLM: {
    ...COMMON_TEXTS,
    ...InputsTableTexts.LLM,
    adHocButton: 'Preview the expected results of the monitor run for the currently visible columns',
    adHocWidget: (
      <>
        Monitors run based on the batch frequency. For example, a daily frequency will result in one run every 24 hours
        at 00:00:00 UTC, and an hourly frequency will run at the top of each hour.
        <TooltipSpacer />
        More information on monitors and advanced configurations can be found at
        https://docs.whylabs.ai/docs/monitor-manager.
        <TooltipSpacer />
        Preview anomalies are temporary; they don&#39;t trigger notifications, and they don&#39;t affect anomalies
        generated by scheduled monitor runs.
      </>
    ),
    errorMessage: 'Metric error',
    inferredFeatureType: 'Inferred metric type',
    outputTitle: 'Metric',
    featureDetailTooltipContent: 'The current metric being displayed',
    featureTitle: 'Metric',
  },
};

interface FeatureHeaderPanelViewProps {
  readonly loading: boolean;
  readonly error: ApolloError | undefined;
  readonly featureBasicData: FeatureBasicData;
  readonly showOutputTitle?: boolean;
  featureSideTableData?: FeatureDataSideTableFragment[];
}

const SKELETON_WIDGET_COUNT = 6;
const FeatureHeaderPanelView: React.FC<FeatureHeaderPanelViewProps> = ({
  loading,
  error,
  featureBasicData,
  showOutputTitle = false,
}) => {
  const urlParams = usePageTypeWithParams();
  const displayOutputTitle = showOutputTitle || !!urlParams.outputName;
  const { handleNavigation } = useNavLinkHandler();
  const { resourceTexts, isDataTransform, isModelCategory } = useResourceText(PAGE_TEXTS);
  const [{ analysisPreview, activeBackfillJob }, analysisDispatch] = useContext(AnalysisContext);
  const { data: monitorData } = useGetMonitorConfigQuery({ variables: { modelId: urlParams.modelId } });
  const monitorSchema = stringToSchema(monitorData?.monitorConfig);

  const hasMonitors = useDeepCompareMemo(() => {
    if (monitorSchema) {
      const foundAnalyzer = monitorSchema.analyzers.find((a) => {
        if (a.disabled) {
          return false;
        }
        if (a.targetMatrix?.type === 'column') {
          if (
            targetMatrixMember(a.targetMatrix.include, featureBasicData) &&
            !targetMatrixMember(a.targetMatrix.exclude ?? [], featureBasicData)
          ) {
            return true;
          }
        }
        return false;
      });
      return foundAnalyzer !== undefined;
    }
    return undefined;
  }, [monitorSchema, featureBasicData]);

  const [adHocRecoilData, setAdHocRecoilData] = useRecoilState(adHocAtom);
  const [, { loading: mutationLoading }] = useAdHocMonitorMutation();
  const { data: basicData } = useGetBatchFrequencyQuery({ variables: { modelId: urlParams.modelId } });
  const batchFrequency = basicData?.model?.batchFrequency;

  const clearAdHocRecoilData = (err = false) => {
    setAdHocRecoilData({
      features: [],
      segment: undefined,
      model: undefined,
      runId: undefined,
      pageType: undefined,
      loading: false,
      error: err,
    });
  };

  const existingAdHoc = useAdHocExists();

  const adHocButtonPress = () => {
    if (!hasMonitors) {
      handleNavigation({ page: 'monitorManager', modelId: urlParams.modelId, monitorManager: { path: 'presets' } });
      return;
    }
    if (existingAdHoc && !flags[MONITOR_BACKFILL]) {
      // close preview and clear analysisPreview state
      analysisDispatch({ analysisPreview: { drawerOpened: false } });
      clearAdHocRecoilData();
      return;
    }
    analysisDispatch({ analysisPreview: { ...analysisPreview, drawerOpened: true } });
  };

  const { classes: styles, cx } = useFeatureHeaderPanelViewStyles();
  const { getCurrentUser } = useUserContext();
  const userCanManageMonitors = canManageMonitors(getCurrentUser());
  const { flags } = useFlags();
  const { classes: stylesFeature } = useFeatureWidgetStyles();
  const showError = error || (!featureBasicData.isValid && !featureBasicData.loading);

  const hasActiveBackfillJob =
    activeBackfillJob?.runId && (!activeBackfillJob?.status || isActiveBackfillJobStatus(activeBackfillJob.status));

  const nextRunText = (() => {
    if (hasActiveBackfillJob && flags[MONITOR_BACKFILL]) {
      return (
        <>
          <Loader size={18} />
          Backfill in progress
        </>
      );
    }
    if (hasMonitors) {
      return `In ${nextMonitorRun(batchFrequency)}`;
    }
    if (hasMonitors === false) {
      return `No monitors enabled`;
    }
    return `Loading monitor data`;
  })();

  const previewButtonText = (() => {
    if (adHocRecoilData.loading || mutationLoading) {
      return { label: 'Working...', tooltip: 'Analysis preview is running' };
    }

    if (hasActiveBackfillJob && flags[MONITOR_BACKFILL]) {
      return { label: 'See status', tooltip: 'Check status of the active backfill request' };
    }
    if (existingAdHoc && !adHocRecoilData.error) {
      return flags[MONITOR_BACKFILL]
        ? { label: 'Run monitor now', tooltip: 'Request an analysis backfill for the previewed monitor' }
        : { label: 'Close preview', tooltip: 'Exit preview mode' };
    }
    if (monitorSchema && !hasMonitors) {
      return { label: 'Set up monitors', tooltip: "You haven't created any monitors yet" };
    }
    return {
      label: 'Preview analysis',
      tooltip: resourceTexts.adHocButton.concat(flags[MONITOR_BACKFILL] ? ' and request a backfill' : ''),
    };
  })();

  const renderAdHocWidget = () => (
    <div className={styles.adHoc} id="preview-monitor-header">
      <FeatureControlWidget title="Next monitor run" tooltipContent={resourceTexts.adHocWidget}>
        <div className={styles.flexRow}>
          <WhyLabsText
            inherit
            className={cx(
              stylesFeature.heroText,
              stylesFeature.lengthRestricted,
              styles.comboWidgetText,
              styles.buttonFlex,
            )}
          >
            {nextRunText}
          </WhyLabsText>
        </div>
      </FeatureControlWidget>
      {userCanManageMonitors && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <WhyLabsTooltip label={previewButtonText.tooltip}>
            <WhyLabsButton
              variant="outline"
              color="gray"
              className={styles.previewButton}
              onClick={() => adHocButtonPress()}
              disabled={mutationLoading || adHocRecoilData.loading === true}
            >
              <div className={styles.buttonFlex}>
                {previewButtonText.label}
                {mutationLoading || adHocRecoilData.loading ? <Loader size={18} /> : null}
              </div>
            </WhyLabsButton>
          </WhyLabsTooltip>
        </div>
      )}
    </div>
  );

  const renderSimpleFeatureBasicWidget = () => (
    <FeatureWidget
      title={
        displayOutputTitle && (isModelCategory || isDataTransform)
          ? resourceTexts.outputTitle
          : resourceTexts.featureTitle
      }
      tooltipContent={resourceTexts.featureDetailTooltipContent}
      hero={featureBasicData.name}
    />
  );

  const renderWidgets = () => {
    if (loading || featureBasicData.loading) {
      return (
        <>
          {Array(SKELETON_WIDGET_COUNT)
            .fill(0)
            .map((_val, index) => {
              const rowKey = `feature-skeleton-widget-${index}`;
              return <SkeletonFeatureWidget key={rowKey} index={index} />;
            })}
          <HeaderEmptyFillWidget />
        </>
      );
    }

    if (showError) {
      return <FeatureWidget hero="Invalid Data" title={resourceTexts.errorMessage} />;
    }

    return (
      <>
        {renderSimpleFeatureBasicWidget()}
        {/* adhoc monitor needs to support MV3, before it makes sense to re-enable the widget */}
        {renderAdHocWidget()}
        <FeatureWidget
          title={resourceTexts.inferredFeatureType}
          tooltipContent={resourceTexts.infFeatureTypeTooltip}
          hero={upperCaseFirstLetterOnly(featureBasicData.inferredType)}
        />
        <FeatureWidget
          title="Inferred data types"
          tooltipContent={resourceTexts.infDataTypeTooltip}
          hero={featureBasicData.inferredDataType.toLowerCase()}
          capitalized
        />

        <BatchFrequencyWidget />

        <ProfileLineageWidget />

        <FeatureWidget
          title="Batches in range"
          tooltipContent={resourceTexts.featureDetailPageBatchesInRangeTooltipContent}
          hero={featureBasicData.pointsInSpan.toString()}
          stretchy
        />
      </>
    );
  };

  return (
    <div className={cx(styles.root, styles.bottomBorder)}>
      <div className={styles.widgetRow}>
        {renderWidgets()}
        {showError ? <HeaderEmptyFillWidget /> : null}
      </div>
    </div>
  );
};

export default FeatureHeaderPanelView;
