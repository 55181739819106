/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type AwsMarketplaceMetadata = {
  __typename?: 'AWSMarketplaceMetadata';
  awsMarketplaceProductCode: Scalars['String'];
  dimension: Scalars['String'];
  expirationTime: Scalars['Float'];
  expirationUpdateTime: Scalars['Float'];
};

export type AccessTokenManagement = {
  __typename?: 'AccessTokenManagement';
  /** Creates a new Access Token for accessing WhyLabs API */
  generate: AccessTokenMetadata;
  /** Revokes the specified Access Token, removing its access to WhyLabs API */
  revoke: AccessTokenMetadata;
};


export type AccessTokenManagementGenerateArgs = {
  name: Scalars['String'];
  expiresAt?: Maybe<Scalars['Float']>;
  scopes?: Maybe<Array<RequestableTokenScope>>;
};


export type AccessTokenManagementRevokeArgs = {
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type AccessTokenMetadata = {
  __typename?: 'AccessTokenMetadata';
  id: Scalars['String'];
  /** ID of the user that generated the key */
  userId: Scalars['String'];
  /** Human-readable token alias */
  name: Scalars['String'];
  createdAt: Scalars['Float'];
  /** Expiration timestamp. Null if the token never expires. */
  expiresAt?: Maybe<Scalars['Float']>;
  /** List of scopes the token was generated for */
  scopes: Array<Scalars['String']>;
  /** The actual Access Token value, only returned if the token was just created */
  secret?: Maybe<Scalars['String']>;
  /** If the token has been revoked, this will be True */
  isRevoked?: Maybe<Scalars['Boolean']>;
};

export enum ActionType {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Teams = 'TEAMS',
  PagerDuty = 'PAGER_DUTY',
  Webhook = 'WEBHOOK',
  Na = 'NA'
}

/** Information about the adhoc monitor job. */
export type AdHocMonitorJob = {
  __typename?: 'AdHocMonitorJob';
  /** ID of the monitor run. Should be used to filter for events related to that run. */
  runId: Scalars['String'];
  /** Number of events that were generated by the ad hoc run */
  numEvents: Scalars['Int'];
  /**
   * List of columns targeted by the ad hoc run based in the features param and analyzer targetMatrix if config is passed.
   * If no config is passed by client, we can have either nullish or the same features array from request params,
   * so null or undefined does NOT mean that the adhoc has no target.
   */
  columns?: Maybe<Array<Scalars['String']>>;
};

export type AdHocMonitorMutations = {
  __typename?: 'AdHocMonitorMutations';
  /**
   * Trigger an adhoc run of the monitor for the specified features.
   * Once this returns, the run is complete.
   */
  run: AdHocMonitorJob;
};


export type AdHocMonitorMutationsRunArgs = {
  datasetId: Scalars['String'];
  features: Array<Scalars['String']>;
  toTimestamp?: Maybe<Scalars['Float']>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  monitorConfig?: Maybe<Scalars['String']>;
  segments?: Maybe<Array<Array<SegmentTagFilter>>>;
};

export enum AlertCategory {
  Ingestion = 'Ingestion',
  DataQuality = 'DataQuality',
  DataDrift = 'DataDrift',
  Performance = 'Performance',
  Unknown = 'Unknown'
}

export type AlertCategoryCount = {
  __typename?: 'AlertCategoryCount';
  category: AlertCategory;
  count: Scalars['Float'];
  metric?: Maybe<AnalysisMetric>;
};

export type AlertCategoryCounts = {
  __typename?: 'AlertCategoryCounts';
  /** Total alerts within range, broken down by category */
  totals: Array<AlertCategoryCount>;
  /** Alerts by category and timestamp */
  timeseries: Array<GroupedAlertBatch>;
};

export type AlertCount = {
  __typename?: 'AlertCount';
  total: Scalars['Float'];
  nullFraction: Scalars['Float'];
  distribution: Scalars['Float'];
  uniqueness: Scalars['Float'];
  dataType: Scalars['Float'];
  thresholdBased: Scalars['Float'];
  unknown: Scalars['Float'];
};

export enum Algorithm {
  SeasonalArima = 'SeasonalARIMA',
  StaticThreshold = 'StaticThreshold',
  Unknown = 'Unknown'
}

export type AnalysisFilter = {
  /** If true, only analysis results that are anomalous will be returned */
  anomaliesOnly: Scalars['Boolean'];
  /** If true, failed analyses will be included even if not marked as anomalies. Defaults to false. */
  includeFailed?: Maybe<Scalars['Boolean']>;
  /** If true, unhelpful anomalies will be included. Defaults to true. */
  includeUnhelpful?: Maybe<Scalars['Boolean']>;
  datasetId?: Maybe<Scalars['String']>;
  /**
   * Segment tags to filter by.
   * NOTE: If this is NULL, then ALL analysis results will be returned, regardless of their tags.
   * If you want to fetch analysis results only for the 'overall' segment, specify an empty array here ([]).
   * Otherwise specify the tags to filter by.
   */
  segmentTags?: Maybe<Array<SegmentTagFilter>>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  toTimestamp?: Maybe<Scalars['Float']>;
  /** Target metrics */
  metrics?: Maybe<Array<AnalysisMetric>>;
  /** Target columns */
  columns?: Maybe<Array<Scalars['String']>>;
  /**
   * Target analyzer types
   * Corresponds to AlgorithmType enum in monitor config schema
   */
  analyzerTypes?: Maybe<Array<Scalars['String']>>;
  /**
   * Filter by Analyzer IDs
   * E.g. you want output from specific Analyzers
   */
  analyzerIDs?: Maybe<Array<Scalars['String']>>;
  /** Filter by Monitor IDs (will only fetch anomalies that triggered the monitor(s) */
  monitorIDs?: Maybe<Array<Scalars['String']>>;
  /**
   * Filter by specific Analysis IDs
   * E.g. you want specific anomalies where you already know their AnalysisIDs
   */
  analysisIDs?: Maybe<Array<Scalars['String']>>;
  /** Filter by run ID for adhoc monitor runs */
  adhocRunId?: Maybe<Scalars['String']>;
  /** Filter results to be for either individual or batch profiles */
  granularityInclusion?: Maybe<GranularityInclusion>;
};

export enum AnalysisMetric {
  /** Fields PROFILE_xxx, INPUT_COUNT, OUTPUT_COUNT, SHAPE_xxx, COLUMN_ROW_COUNT_SUM are not implemented */
  ClassificationAccuracy = 'CLASSIFICATION_ACCURACY',
  ClassificationRecall = 'CLASSIFICATION_RECALL',
  ClassificationFpr = 'CLASSIFICATION_FPR',
  ClassificationPrecision = 'CLASSIFICATION_PRECISION',
  ClassificationF1 = 'CLASSIFICATION_F1',
  ClassificationAuroc = 'CLASSIFICATION_AUROC',
  Histogram = 'HISTOGRAM',
  FrequentItems = 'FREQUENT_ITEMS',
  ProfileCount = 'PROFILE_COUNT',
  ProfileLastIngestionTime = 'PROFILE_LAST_INGESTION_TIME',
  ProfileFirstIngestionTime = 'PROFILE_FIRST_INGESTION_TIME',
  InputCount = 'INPUT_COUNT',
  OutputCount = 'OUTPUT_COUNT',
  RegressionMse = 'REGRESSION_MSE',
  RegressionMae = 'REGRESSION_MAE',
  RegressionRmse = 'REGRESSION_RMSE',
  Count = 'COUNT',
  Median = 'MEDIAN',
  Min = 'MIN',
  Max = 'MAX',
  Mean = 'MEAN',
  StdDev = 'STD_DEV',
  Variance = 'VARIANCE',
  UniqueUpper = 'UNIQUE_UPPER',
  UniqueUpperRatio = 'UNIQUE_UPPER_RATIO',
  UniqueEst = 'UNIQUE_EST',
  UniqueEstRatio = 'UNIQUE_EST_RATIO',
  UniqueLower = 'UNIQUE_LOWER',
  UniqueLowerRatio = 'UNIQUE_LOWER_RATIO',
  CountBool = 'COUNT_BOOL',
  CountBoolRatio = 'COUNT_BOOL_RATIO',
  CountIntegral = 'COUNT_INTEGRAL',
  CountIntegralRatio = 'COUNT_INTEGRAL_RATIO',
  CountFractional = 'COUNT_FRACTIONAL',
  CountFractionalRatio = 'COUNT_FRACTIONAL_RATIO',
  CountString = 'COUNT_STRING',
  CountStringRatio = 'COUNT_STRING_RATIO',
  CountNull = 'COUNT_NULL',
  CountNullRatio = 'COUNT_NULL_RATIO',
  InferredDataType = 'INFERRED_DATA_TYPE',
  Quantile_75 = 'QUANTILE_75',
  Quantile_25 = 'QUANTILE_25',
  Quantile_90 = 'QUANTILE_90',
  Quantile_99 = 'QUANTILE_99',
  Quantile_5 = 'QUANTILE_5',
  Quantile_95 = 'QUANTILE_95',
  ColumnRowCountSum = 'COLUMN_ROW_COUNT_SUM',
  ShapeColumnCount = 'SHAPE_COLUMN_COUNT',
  ShapeRowCount = 'SHAPE_ROW_COUNT',
  SecondsSinceLastUpload = 'SECONDS_SINCE_LAST_UPLOAD',
  MissingDatapoint = 'MISSING_DATAPOINT',
  PredictionCount = 'PREDICTION_COUNT',
  Unknown = 'UNKNOWN',
  Composed = 'COMPOSED'
}

/**
 * Result of an analyzer run.
 * Field definitions can also be found in the monitor code: https://gitlab.com/whylabs/core/whylabs-processing-core/-/blob/mainline/murmuration/src/main/java/ai/whylabs/core/structures/monitor/events/AnalyzerResult.java
 */
export type AnalysisResult = {
  __typename?: 'AnalysisResult';
  /**
   * UUID/primary key of the analysis result that resulted from a particular monitor run.
   * You will probably want to use 'analysisId', rather than 'id' in most cases.
   */
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  datasetId?: Maybe<Scalars['String']>;
  /**
   * Segment tags for which the analysis result was generated.
   * Empty tags = overall segment
   */
  tags: Array<SegmentTag>;
  /** ID of the monitor run that produced this analysis */
  runId?: Maybe<Scalars['String']>;
  /** When the analysis was produced by monitor */
  creationTimestamp?: Maybe<Scalars['Float']>;
  /** Timestamp of the profile that this analysis refers to */
  datasetTimestamp?: Maybe<Scalars['Float']>;
  /** What part of the dataset the analyzer was targeting */
  targetLevel?: Maybe<AnalysisTargetLevel>;
  /** Name of the target column, if applicable */
  column?: Maybe<Scalars['String']>;
  /**
   * Algorithm used to produce the analysis
   * Currently corresponds to DriftConfig[algorithm]  in monitor config schema
   */
  algorithm?: Maybe<Scalars['String']>;
  /**
   * Mode of the algorithm
   * Currently corresponds to ColumnListChangeConfig[mode] or DiffConfig[mode]  in monitor config schema
   */
  algorithmMode?: Maybe<Scalars['String']>;
  /**
   * UUID defining a particular analyzer running on a specific point in time. Similar to 'id', but stable across monitor backfills.
   * When backfilling/overwriting monitor data, this ID will be stable across multiple job runs (unlike the similar 'id' property)
   */
  analysisId?: Maybe<Scalars['String']>;
  /** Which analyzer produced the analysis */
  analyzerId?: Maybe<Scalars['String']>;
  /** Version of the analyzer config that produced this analysis */
  analyzerConfigVersion?: Maybe<Scalars['Int']>;
  /** Currently corresponds to AlgorithmType in monitor config schema */
  analyzerType?: Maybe<Scalars['String']>;
  /** What type of analyzer result was produced. Determines which fields/columns will be useful to explain the event. */
  analyzerResultType?: Maybe<Scalars['String']>;
  /**
   * Names of this analysis result's fields that help explain the anomaly.
   * Each value will be in `keyof AnalysisResult`.
   */
  explanationFields?: Maybe<Array<Scalars['String']>>;
  /** IDs of the monitors that were triggered by the anomaly */
  monitorIds?: Maybe<Array<Scalars['String']>>;
  /** monitorDisplayName */
  monitorDisplayName?: Maybe<Scalars['String']>;
  /** Whether the analysis is an anomaly */
  isAnomaly?: Maybe<Scalars['Boolean']>;
  /** Granularity at which the analysis was produced */
  granularity?: Maybe<Scalars['String']>;
  /** Metric targeted by the analysis */
  metric?: Maybe<AnalysisMetric>;
  /** Inferred category of the anomaly, based on its target metric */
  category?: Maybe<AlertCategory>;
  /** Weight of the target (importance), as registered in the entity schema */
  weight?: Maybe<Scalars['Float']>;
  /** Duration of the calculation */
  calculationRuntimeNano?: Maybe<Scalars['Float']>;
  /** For column_list analysis, how many columns were added */
  columnList_added?: Maybe<Scalars['Float']>;
  /** For column_list analysis, how many columns were removed */
  columnList_removed?: Maybe<Scalars['Float']>;
  /** Sample list of the added columns */
  columnList_addedSample?: Maybe<Array<Scalars['String']>>;
  /** Sample list of the removed columns */
  columnList_removedSample?: Maybe<Array<Scalars['String']>>;
  /** Describes how the column list analyzer was configured (e.g. on add/remove, on add, etc) */
  columnList_mode?: Maybe<Scalars['String']>;
  /** Expected value when performing an equality check */
  comparison_expected?: Maybe<Scalars['String']>;
  /** Observed value when performing an equality check */
  comparison_observed?: Maybe<Scalars['String']>;
  /** For diff analysis, what was the metric value */
  diff_metricValue?: Maybe<Scalars['Float']>;
  /** Diff mode for diff analyzers (DiffConfig[mode]) */
  diff_mode?: Maybe<Scalars['String']>;
  /** Threshold for the diff to trigger an anomaly */
  diff_threshold?: Maybe<Scalars['Float']>;
  /** For drift analysis, what was the metric value */
  drift_metricValue?: Maybe<Scalars['Float']>;
  /** ??? TODO */
  drift_minBatchSize?: Maybe<Scalars['Float']>;
  /** For drift analysis, what was the threshold */
  drift_threshold?: Maybe<Scalars['Float']>;
  /** When was the data last modified when this analysis was produced */
  mostRecentDatasetDatalakeWriteTs?: Maybe<Scalars['Float']>;
  /** Absolute lower threshold */
  threshold_absoluteLower?: Maybe<Scalars['Float']>;
  /** Absolute upper threshold */
  threshold_absoluteUpper?: Maybe<Scalars['Float']>;
  /** For threshold analysis, what was the baseline metric value */
  threshold_baselineMetricValue?: Maybe<Scalars['Float']>;
  /** For threshold analysis, what was the lower threshold that was used for the calculation */
  threshold_calculatedLower?: Maybe<Scalars['Float']>;
  /** For threshold analysis, what was the upper threshold that was used for the calculation */
  threshold_calculatedUpper?: Maybe<Scalars['Float']>;
  /** For threshold analysis, what was the metric value */
  threshold_metricValue?: Maybe<Scalars['Float']>;
  /** When doing stddev, the factor */
  threshold_factor?: Maybe<Scalars['Float']>;
  /** Minimum number of batches present for this calculation to run */
  threshold_minBatchSize?: Maybe<Scalars['Float']>;
  /** If there was a failure computing the analysis, this captures the failure code */
  failureType?: Maybe<Scalars['String']>;
  /** If there was a failure computing the analysis, provides additional context on why the analyzer run failed */
  failureExplanation?: Maybe<Scalars['String']>;
  /** Users can mark anomalies as false alarms */
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
  /** Frequent string comparison operator */
  frequentStringComparison_operator?: Maybe<Scalars['String']>;
  /** Mismatched strings found by frequent string comparison */
  frequentStringComparison_sample?: Maybe<Array<Scalars['String']>>;
  /** This is a composed analyzer (e.g. conjunction/disjunction) that combines the results of multiple child analyzers */
  parent?: Maybe<Scalars['Boolean']>;
  /** For a composed analyzer, identifies the child analyzers in the monitor config */
  childAnalyzerIds?: Maybe<Array<Scalars['String']>>;
  /** For a composed analyzer, identifies the AnalysisResults of the child analyzers */
  childAnalysisIds?: Maybe<Array<Scalars['String']>>;
  /** If the analyzer is run on individual profiles, this will contain zero or more traceIds associated with the profile */
  traceIds?: Maybe<Array<Scalars['String']>>;
  /** If the analyzer is run on individual profiles, this will be true */
  disableTargetRollup?: Maybe<Scalars['Boolean']>;
  /** The tags associated with the analyzer, e.g. indicating if it is a constraint */
  analyzerTags?: Maybe<Array<Scalars['String']>>;
};

export enum AnalysisTargetLevel {
  Dataset = 'DATASET',
  Column = 'COLUMN',
  Unknown = 'UNKNOWN'
}

export type AnalyzerAnomalyCount = {
  __typename?: 'AnalyzerAnomalyCount';
  /** Analyzer ID */
  analyzerId: Scalars['String'];
  /** Count of anomalies */
  anomalyCount: Scalars['Int'];
  /** Count of failed analyses */
  failedCount: Scalars['Int'];
  /** Count of all analysis results */
  resultCount: Scalars['Int'];
};

/** Count of analyzer runs */
export type AnalyzerRunCountResult = {
  __typename?: 'AnalyzerRunCountResult';
  orgId?: Maybe<Scalars['String']>;
  datasetId?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type AnalyzerRunFilter = {
  datasetId: Scalars['String'];
  /** Get runs that occurred on or after this timestamp. */
  fromTimestamp: Scalars['Float'];
  /** Get runs that occurred up to but not including this timestamp. Defaults to current time. */
  toTimestamp?: Maybe<Scalars['Float']>;
  /** Filter to a specific analyzerId. */
  analyzerId?: Maybe<Scalars['String']>;
  /** Filter to a specific monitorId. If an analyzerId is also specified, the analyzerId must be associated with the specified monitor. */
  monitorId?: Maybe<Scalars['String']>;
};

/** Result (e.g. errors, anomalies found) of the analyzer run */
export type AnalyzerRunResult = {
  __typename?: 'AnalyzerRunResult';
  orgId?: Maybe<Scalars['String']>;
  datasetId?: Maybe<Scalars['String']>;
  /** ID of the monitor run. Note this may cover multiple analyzer runs. */
  runId?: Maybe<Scalars['String']>;
  /** Timestamp for when the run started */
  runStarted?: Maybe<Scalars['Float']>;
  /** Timestamp for when the run completed */
  runCompleted?: Maybe<Scalars['Float']>;
  /** Which analyzer this analyzer run result is for. */
  analyzerId?: Maybe<Scalars['String']>;
  /** IDs of the monitors this analyzer is associated with. */
  monitorIds?: Maybe<Array<Scalars['String']>>;
  /** Number of anomalies found in this analyzer run */
  anomalyCount?: Maybe<Scalars['Int']>;
  /** Number of columns analyzed */
  columnCount?: Maybe<Scalars['Int']>;
  /** If there were any failures in analyzing one or more batches, this captures the failure codes. */
  failureTypes?: Maybe<Array<Scalars['String']>>;
};

export type AnomalyCount = {
  __typename?: 'AnomalyCount';
  /** Anomaly timestamp */
  timestamp: Scalars['Float'];
  /**
   * Count of anomalies
   * @deprecated Use anomalyCount
   */
  count: Scalars['Int'];
  /** Count of anomalies */
  anomalyCount: Scalars['Int'];
  /** Count of failed analyses */
  failedCount: Scalars['Int'];
  /** Count of all analysis results */
  resultCount: Scalars['Int'];
};

export enum AnomalyCountGroupBy {
  Metric = 'METRIC',
  Category = 'CATEGORY'
}

export type AnomalyFilter = {
  datasetId: Scalars['String'];
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  /**
   * Filter by Analyzer IDs
   * E.g. you want output from specific Analyzers
   */
  analyzerIDs?: Maybe<Array<Scalars['String']>>;
  /** Filter by Monitor IDs (will only fetch anomalies that triggered the monitor(s) */
  monitorIDs?: Maybe<Array<Scalars['String']>>;
  /** Filter by run ID for adhoc monitor runs */
  adhocRunId?: Maybe<Scalars['String']>;
};

export type AnomalyOptionalFilter = {
  /**
   * Filter by Analyzer IDs
   * E.g. you want output from specific Analyzers
   */
  analyzerIDs?: Maybe<Array<Scalars['String']>>;
  /** Filter by Monitor IDs (will only fetch anomalies that triggered the monitor(s) */
  monitorIDs?: Maybe<Array<Scalars['String']>>;
  /** Filter by run ID for adhoc monitor runs */
  adhocRunId?: Maybe<Scalars['String']>;
};

export type AnonymousSession = {
  __typename?: 'AnonymousSession';
  /** Whether the user is in an anonymous session */
  enabled: Scalars['Boolean'];
  /** Whether the anonymous session is valid (hasn't expired, exists, etc) */
  valid: Scalars['Boolean'];
};

export enum AssetCategory {
  Model = 'MODEL',
  Data = 'DATA',
  Llm = 'LLM'
}

export type AuditLog = {
  __typename?: 'AuditLog';
  timestamp: Scalars['Float'];
  userId: Scalars['String'];
  userName: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  datasetId: Scalars['String'];
  datasetName: Scalars['String'];
  segmentTags?: Maybe<Array<SegmentTag>>;
  feature?: Maybe<Scalars['String']>;
  resource: Scalars['String'];
  diff: Scalars['String'];
};

export type BackfillAnalyzersJob = {
  __typename?: 'BackfillAnalyzersJob';
  /** ID of the backfill job run. Should be used to get job status or cancel that job. */
  runId: Scalars['String'];
};

export type BackfillAnalyzersMutations = {
  __typename?: 'BackfillAnalyzersMutations';
  /**
   * Trigger a backfill job for a dataset within specified interval
   * It's an async data-service API, so the returned runID can be used to fetch the job status.
   * Will run a comprehensive backfill if no analyzerIds were specified in this call.
   */
  triggerBackfill: BackfillAnalyzersJob;
  /** Cancel a backfill job by runID */
  cancelJob: BackfillJobStatus;
};


export type BackfillAnalyzersMutationsTriggerBackfillArgs = {
  datasetId: Scalars['String'];
  analyzerIds?: Maybe<Array<Scalars['String']>>;
  toTimestamp: Scalars['Float'];
  fromTimestamp: Scalars['Float'];
};


export type BackfillAnalyzersMutationsCancelJobArgs = {
  runId: Scalars['String'];
};

export type BackfillJobInfo = {
  __typename?: 'BackfillJobInfo';
  status: BackfillJobStatus;
  progress: Scalars['Float'];
  monitorsList?: Maybe<Array<Scalars['String']>>;
  duration?: Maybe<DateRange>;
  columns: Scalars['Int'];
  segments: Scalars['Int'];
  runId: Scalars['String'];
  datasetId: Scalars['String'];
};

export enum BackfillJobStatus {
  Pending = 'Pending',
  Planning = 'Planning',
  Executing = 'Executing',
  Successful = 'Successful',
  WritingResults = 'WritingResults',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Unknown = 'Unknown'
}

export type BaseEventExplanation = {
  __typename?: 'BaseEventExplanation';
  /** Contains JSON body of the explanation that we couldn't parse, if any. */
  jsonBody?: Maybe<Scalars['String']>;
};

export type BatchMetadata = {
  __typename?: 'BatchMetadata';
  datasetId: Scalars['String'];
  tags: Array<SegmentTag>;
  timestamp: Scalars['Float'];
  /** endTimestamp is only available when getting batches by timestamp */
  endTimestamp?: Maybe<Scalars['Float']>;
  inputCount: Scalars['Float'];
  outputCount: Scalars['Float'];
  batchFrequency: TimePeriod;
  /**
   * Gets all the FeatureSketches associated with the given Batch.
   * May be horribly inefficient when the number of batches is large, as it looks up sketches by timestamp match rather than a time range at the moment.
   * Avoid queries like `model > batches(from: 123, to: 123) > sketches` until this flow is optimized. `model > batch(timestamp: 123) > sketches` is ok.
   * 
   * Set histogramSplitPoints to specify the desired histogram bin edges.
   * NOTE: min and max will be added automatically at query time, because they depend on the profile for which the histogram will be generated. These split points should not include min/max.
   * E.g., if you'd like to split data into bins with edges [min, 5, 10, max], set this argument to [5, 10]
   */
  sketches: FilteredFeatureSketches;
  /**
   * Returns model metrics associated with a particular batch of data.
   * Only applicable to ML models.
   */
  metrics: ModelMetrics;
};


export type BatchMetadataSketchesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<FeatureSketchFilter>;
  histogramSplitPoints?: Maybe<Array<Scalars['Float']>>;
  excludeEmpty?: Maybe<Scalars['Boolean']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CalibrationCurve = {
  __typename?: 'CalibrationCurve';
  values: Array<Array<Maybe<Scalars['Float']>>>;
};

export type ConfusionMatrix = {
  __typename?: 'ConfusionMatrix';
  labels: Array<Scalars['String']>;
  counts: Array<Array<Scalars['Float']>>;
  targetField?: Maybe<Scalars['String']>;
  predictionsField?: Maybe<Scalars['String']>;
  scoreField?: Maybe<Scalars['String']>;
};

export type CustomDashboard = {
  __typename?: 'CustomDashboard';
  displayName: Scalars['String'];
  id: Scalars['String'];
};

export type CustomMetric = {
  __typename?: 'CustomMetric';
  id: Scalars['String'];
  datasetGranularity: TimePeriod;
  datasetId: Scalars['String'];
  segmentTags: Array<SegmentTag>;
  /** Name of the metric */
  name: Scalars['String'];
  /** Numeric values of this metric, over time */
  numericValues?: Maybe<Array<MetricValue>>;
  numericValuesForBatch?: Maybe<Array<MetricValue>>;
  /** Distribution values of this metric, over time */
  distributionValues?: Maybe<Array<DistributionValue>>;
  distributionValuesForBatch?: Maybe<Array<DistributionValue>>;
  metadata?: Maybe<MetricSchema>;
};


export type CustomMetricNumericValuesArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
};


export type CustomMetricNumericValuesForBatchArgs = {
  timestamp: Scalars['Float'];
};


export type CustomMetricDistributionValuesArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
};


export type CustomMetricDistributionValuesForBatchArgs = {
  timestamp: Scalars['Float'];
};

export type CustomTag = {
  __typename?: 'CustomTag';
  key: Scalars['String'];
  value: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
};

export type CustomTagFilter = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type CustomWebhookAction = {
  __typename?: 'CustomWebhookAction';
  url: Scalars['String'];
  method: Scalars['String'];
  headers?: Maybe<Array<HeaderTuple>>;
  body?: Maybe<Scalars['String']>;
};

export type DashbirdError = {
  __typename?: 'DashbirdError';
  /** Error code */
  code: DashbirdErrorCode;
  /** Message that should be safe to show to users */
  safeErrorMsg: Scalars['String'];
  /** Parameter with error */
  parameter?: Maybe<Scalars['String']>;
};

/** Code used for error identification */
export enum DashbirdErrorCode {
  GenericError = 'GENERIC_ERROR',
  PaginationLimitExceeded = 'PAGINATION_LIMIT_EXCEEDED',
  InvalidTimeRange = 'INVALID_TIME_RANGE',
  InvalidOrganization = 'INVALID_ORGANIZATION',
  MonitorSchemaValidationError = 'MONITOR_SCHEMA_VALIDATION_ERROR',
  MonitorConfigValidationError = 'MONITOR_CONFIG_VALIDATION_ERROR',
  AdhocMonitorRunError = 'ADHOC_MONITOR_RUN_ERROR',
  BackfillAnalyzersError = 'BACKFILL_ANALYZERS_ERROR',
  DataQueryValidationError = 'DATA_QUERY_VALIDATION_ERROR',
  AuthorizationError = 'AUTHORIZATION_ERROR',
  ValidationError = 'VALIDATION_ERROR',
  IllegalArgument = 'ILLEGAL_ARGUMENT',
  ResourceAlreadyExists = 'RESOURCE_ALREADY_EXISTS',
  ResourceConstraint = 'RESOURCE_CONSTRAINT',
  ArgumentValue = 'ARGUMENT_VALUE',
  QueryTooGranular = 'QUERY_TOO_GRANULAR',
  InvalidRequest = 'INVALID_REQUEST'
}

export type DataAvailability = {
  __typename?: 'DataAvailability';
  /** Whether the dataset has any data */
  hasData?: Maybe<Scalars['Boolean']>;
  /** Timestamp of the oldest available batch of data for the dataset */
  oldestTimestamp?: Maybe<Scalars['Float']>;
  /** Timestamp of the latest (most recent) available batch of data for the dataset */
  latestTimestamp?: Maybe<Scalars['Float']>;
};

export type DataInvestigator = {
  __typename?: 'DataInvestigator';
  /** Rolls up metric values for a specific set of segments */
  getMetricRollupForSegments?: Maybe<Array<MetricRollupResult>>;
  /**
   * Get a metric result for each metric query for the full dataset. Each result contains the points for
   * a specific dataset, metric, and feature.
   */
  getMetricData?: Maybe<Array<MetricResult>>;
  /**
   * Rolls up metric values across all segments with the given key
   * @deprecated Use getMetricRollupForSegments instead and paginate through segments
   */
  getSegmentMetricDataRollup?: Maybe<Array<MetricRollupResult>>;
  /** Get information about the available metrics for all datasets of a given type (or the specified dataset, if applicable) */
  availableMetrics: Array<MetricSchema>;
  /** Get information about a specific metric, if supported. */
  metricInfo?: Maybe<MetricSchema>;
  /**
   * Get a metric result for each metric query for the full dataset. Each result contains the points for
   * a specific dataset, metric, and feature.
   */
  getMergedFeatureData?: Maybe<FeatureSketch>;
};


export type DataInvestigatorGetMetricRollupForSegmentsArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  segments: Array<Array<SegmentTagFilter>>;
  datasetId: Scalars['String'];
  metric: AnalysisMetric;
  column?: Maybe<Scalars['String']>;
};


export type DataInvestigatorGetMetricDataArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  granularity?: Maybe<TimePeriod>;
  segment?: Maybe<Array<SegmentTagFilter>>;
  queries: Array<MetricQuery>;
};


export type DataInvestigatorGetSegmentMetricDataRollupArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  segmentKey: Scalars['String'];
  queries: Array<MetricQuery>;
};


export type DataInvestigatorAvailableMetricsArgs = {
  datasetId?: Maybe<Scalars['String']>;
  modelType?: Maybe<ModelType>;
  metricTags?: Maybe<Array<Scalars['String']>>;
};


export type DataInvestigatorMetricInfoArgs = {
  name?: Maybe<AnalysisMetric>;
};


export type DataInvestigatorGetMergedFeatureDataArgs = {
  datasetId: Scalars['String'];
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  segment: Array<SegmentTagFilter>;
  column: Scalars['String'];
  splitpoints?: Maybe<Array<Scalars['Float']>>;
};

export type DataLineage = {
  __typename?: 'DataLineage';
  oldestProfileTimestamp?: Maybe<Scalars['Float']>;
  latestProfileTimestamp?: Maybe<Scalars['Float']>;
};

export type DataQualityEvent = {
  id: Scalars['String'];
  runId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  datasetId: Scalars['String'];
  /** If the event is an Alert, this flag determines whether it was designated as a False Alarm by a user. */
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
  /** @deprecated This field refers to datasetTimestamp. Please use either creationTimestamp or datasetTimestamp explicitly. */
  timestamp: Scalars['Float'];
  creationTimestamp: Scalars['Float'];
  datasetTimestamp: Scalars['Float'];
  /** @deprecated Field no longer supported */
  type: EventType;
  target: EventTargetType;
  category: AlertCategory;
  /** @deprecated Field no longer supported */
  archetype: EventArchetype;
  algorithm: Algorithm;
  /** @deprecated Segments have no name, use tags instead */
  segment?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTag>>;
  feature: Scalars['String'];
  metric: WhyLogsMetric;
  /** @deprecated Currently not a human-interpretable parameter, do not expose in UI */
  severity: Scalars['Float'];
  explanation: EventExplanation;
};

export type DataQualityEventBase = DataQualityEvent & {
  __typename?: 'DataQualityEventBase';
  id: Scalars['String'];
  runId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  datasetId: Scalars['String'];
  /** If the event is an Alert, this flag determines whether it was designated as a False Alarm by a user. */
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
  /** @deprecated Use datasetTimestamp or creationTimestamp instead */
  timestamp: Scalars['Float'];
  creationTimestamp: Scalars['Float'];
  datasetTimestamp: Scalars['Float'];
  type: EventType;
  target: EventTargetType;
  category: AlertCategory;
  archetype: EventArchetype;
  algorithm: Algorithm;
  segment?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTag>>;
  feature: Scalars['String'];
  metric: WhyLogsMetric;
  severity: Scalars['Float'];
  explanation: EventExplanation;
};

export type DataTypeEvent = DataQualityEvent & {
  __typename?: 'DataTypeEvent';
  id: Scalars['String'];
  runId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  datasetId: Scalars['String'];
  /** If the event is an Alert, this flag determines whether it was designated as a False Alarm by a user. */
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
  /** @deprecated Use datasetTimestamp or creationTimestamp instead */
  timestamp: Scalars['Float'];
  creationTimestamp: Scalars['Float'];
  datasetTimestamp: Scalars['Float'];
  type: EventType;
  target: EventTargetType;
  category: AlertCategory;
  archetype: EventArchetype;
  algorithm: Algorithm;
  segment?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTag>>;
  feature: Scalars['String'];
  metric: WhyLogsMetric;
  severity: Scalars['Float'];
  explanation: DataTypeEventExplanation;
};

export type DataTypeEventExplanation = {
  __typename?: 'DataTypeEventExplanation';
  value: FeatureType;
  previousValue: FeatureType;
  /** @deprecated Duplicate of event type, do not use */
  eventName: Scalars['String'];
  /** @deprecated Messages will not be populated on the backend going forward. Human-friendly alert descriptions can be generated and localized in the front end. */
  message: Scalars['String'];
};

export type Dataset = {
  /**
   * Returns analysis results, filtered by the specified filter and scoped to the specific dataset.
   * Note: supplied dataset/tags filters are ignored
   */
  analysisResults?: Maybe<Array<AnalysisResult>>;
  /**
   * Returns analysis results that are anomalous, filtered by the specified filter and scoped to the specific dataset.
   * Note: supplied dataset/tags and anomaliesOnly filters are ignored
   */
  anomalies?: Maybe<Array<AnalysisResult>>;
  /**
   * Alias for alertCountsV2
   * Returns aggregated anomaly counts over time and broken down by category, for the overall dataset.
   * Much more efficient than fetching anomaly objects directly, because aggregation happens in Druid.
   */
  anomalyCounts?: Maybe<AlertCategoryCounts>;
  id: Scalars['String'];
  datasetId: Scalars['String'];
  name: Scalars['String'];
  tags: Array<SegmentTag>;
  resourceTags: Array<CustomTag>;
  creationTime?: Maybe<Scalars['Float']>;
  modelType: ModelType;
  assetCategory?: Maybe<AssetCategory>;
  batchFrequency: TimePeriod;
  batch?: Maybe<BatchMetadata>;
  batches: Array<BatchMetadata>;
  batchDateRanges: Array<DateRange>;
  output?: Maybe<Feature>;
  outputs: Array<Feature>;
  filteredOutputs: FilteredFeatures;
  feature?: Maybe<Feature>;
  /** @deprecated This query returns ALL features in a dataset, which can be thousands. Please use 'filteredFeatures' query instead. */
  features: Array<Feature>;
  filteredFeatures: FilteredFeatures;
  /** @deprecated Use anomalyCounts instead - same query, but clearer naming. */
  alertCountsV2?: Maybe<AlertCategoryCounts>;
  /** @deprecated This query serves Monitor V2 data. Use 'anomalies' query instead */
  alerts: Array<DataQualityEvent>;
  /** @deprecated This query serves Monitor V2 data. Use 'latestAnomaly' query instead */
  latestAlert?: Maybe<DataQualityEvent>;
  /** @deprecated No longer supported. Use 'latestAnomalyTimestamp' instead */
  latestAnomaly?: Maybe<AnalysisResult>;
  /** Returns the timestamp of the latest anomaly, if one exists */
  latestAnomalyTimestamp?: Maybe<Scalars['Float']>;
  /** @deprecated This query serves Monitor V2 data. Use 'analysisResults' query instead */
  events: Array<DataQualityEvent>;
  /** Available reference profiles that have been uploaded for this dataset */
  referenceProfiles?: Maybe<Array<ReferenceProfile>>;
  /** Individual profiles that have been uploaded for this dataset */
  individualProfileList?: Maybe<Array<IndividualProfileItem>>;
  /** Individual profiles that have been uploaded for this dataset */
  individualProfiles?: Maybe<Array<IndividualProfile>>;
  /** Constraints list (analyzers json string config) for this dataset */
  constraintsList?: Maybe<Array<Scalars['String']>>;
  featureCounts?: Maybe<FeatureCounts>;
  /** Information about data availability for this dataset */
  dataAvailability?: Maybe<DataAvailability>;
  /** Date range of data lineage for this dataset with start and end timestamps fitting the batch bucket */
  dataLineage?: Maybe<DataLineage>;
  /** Information about entity schema for this dataset */
  entitySchema?: Maybe<EntitySchema>;
  /** Profile insights for this dataset */
  insights?: Maybe<Array<InsightEntry>>;
  datasetMetric?: Maybe<Metric>;
  datasetMetrics?: Maybe<Array<Metric>>;
  customMetrics?: Maybe<Array<CustomMetric>>;
};


export type DatasetAnalysisResultsArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type DatasetAnomaliesArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type DatasetAnomalyCountsArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type DatasetBatchArgs = {
  datasetTimestamp: Scalars['Float'];
};


export type DatasetBatchesArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  timestamps?: Maybe<Array<Scalars['Float']>>;
};


export type DatasetBatchDateRangesArgs = {
  timestamps: Array<Scalars['Float']>;
};


export type DatasetOutputArgs = {
  name: Scalars['String'];
};


export type DatasetFilteredOutputsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  sort?: Maybe<FilteredFeaturesSort>;
};


export type DatasetFeatureArgs = {
  name: Scalars['String'];
};


export type DatasetFeaturesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type DatasetFilteredFeaturesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  sort?: Maybe<FilteredFeaturesSort>;
};


export type DatasetAlertCountsV2Args = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type DatasetAlertsArgs = {
  filter: EventFilter;
};


export type DatasetLatestAnomalyArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type DatasetLatestAnomalyTimestampArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type DatasetEventsArgs = {
  filter: EventFilter;
};


export type DatasetReferenceProfilesArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  profileIds?: Maybe<Array<Scalars['String']>>;
};


export type DatasetIndividualProfileListArgs = {
  from: Scalars['Float'];
  to: Scalars['Float'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type DatasetIndividualProfilesArgs = {
  retrievalTokens: Array<Scalars['String']>;
};


export type DatasetInsightsArgs = {
  batchProfileTimestamp?: Maybe<Scalars['Float']>;
  referenceProfileId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
};


export type DatasetDatasetMetricArgs = {
  name: Scalars['String'];
  granularity?: Maybe<TimePeriod>;
};


export type DatasetDatasetMetricsArgs = {
  granularity?: Maybe<TimePeriod>;
};


export type DatasetCustomMetricsArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
  granularity?: Maybe<TimePeriod>;
};

export type DateRange = {
  __typename?: 'DateRange';
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
};

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export type DigestTemplateSettings = {
  __typename?: 'DigestTemplateSettings';
  /** Determines the content of digest notifications */
  enabledNotificationContent: Array<NotificationContentType>;
  /** Determines which alert types will be included in digest notifications */
  alertsToMonitor: Array<EventType>;
};

export type DistributionValue = {
  __typename?: 'DistributionValue';
  timestamp: Scalars['Float'];
  frequentItems?: Maybe<Array<FrequentItem>>;
  numberSummary?: Maybe<NumberSummary>;
  lastUploadTimestamp?: Maybe<Scalars['Float']>;
};

export type EmailAction = {
  __typename?: 'EmailAction';
  email: Scalars['String'];
};

export type EmailNotificationSettings = {
  __typename?: 'EmailNotificationSettings';
  /** Email address where notifications will be sent. */
  address?: Maybe<Scalars['String']>;
  general: GeneralNotificationSettings;
};

export type EmailVerificationManagement = {
  __typename?: 'EmailVerificationManagement';
  /** Resends Auth0 verification email for the user */
  resendVerificationEmail?: Maybe<Scalars['Boolean']>;
};

export type EntityColumnSchema = {
  __typename?: 'EntityColumnSchema';
  name: Scalars['String'];
  inferredType: FeatureType;
  isDiscrete: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type EntitySchema = {
  __typename?: 'EntitySchema';
  /** Schema for inputs */
  inputs?: Maybe<Array<EntityColumnSchema>>;
  /** Schema for outputs */
  outputs?: Maybe<Array<EntityColumnSchema>>;
  /** Schema for all columns */
  columns?: Maybe<Array<EntityColumnSchema>>;
  /** Whether the dataset has columns */
  hasColumns: Scalars['Boolean'];
  /** Whether the dataset has outputs */
  hasOutputs: Scalars['Boolean'];
  /** Whether the dataset has inputs */
  hasInputs: Scalars['Boolean'];
  /** Counts for input columns */
  inputCounts: EntitySchemaColumnCounts;
  /** Counts for output columns */
  outputCounts: EntitySchemaColumnCounts;
};

export type EntitySchemaColumnCounts = {
  __typename?: 'EntitySchemaColumnCounts';
  total: Scalars['Int'];
  discrete: Scalars['Int'];
  nonDiscrete: Scalars['Int'];
};

export enum EventArchetype {
  Threshold = 'Threshold',
  DataType = 'DataType',
  Unknown = 'Unknown'
}

export type EventExplanation = DataTypeEventExplanation | ThresholdEventExplanation | BaseEventExplanation;

export type EventFilter = {
  id?: Maybe<Scalars['String']>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  toTimestamp?: Maybe<Scalars['Float']>;
  /** Deprecated field, same effect as `datasetId` */
  dataset?: Maybe<Scalars['String']>;
  /** ID of the dataset to filter on */
  datasetId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  feature?: Maybe<Scalars['String']>;
  eventType?: Maybe<EventType>;
  eventArchetype?: Maybe<EventArchetype>;
  metric?: Maybe<WhyLogsMetric>;
  algorithm?: Maybe<Algorithm>;
  adhocRunId?: Maybe<Scalars['String']>;
  targetTypes?: Maybe<Array<EventTargetType>>;
};

export type EventParam = {
  __typename?: 'EventParam';
  value: Scalars['Float'];
  source: ValueSource;
};

/**
 * What entity the event is targeting
 * (i.e. what was the monitor monitoring when it produced the event)
 */
export enum EventTargetType {
  Dataset = 'Dataset',
  Feature = 'Feature',
  Unknown = 'Unknown'
}

export enum EventType {
  NullFraction = 'NullFraction',
  Distribution = 'Distribution',
  Uniqueness = 'Uniqueness',
  DataType = 'DataType',
  ThresholdBased = 'ThresholdBased',
  MissingRecentData = 'MissingRecentData',
  MissingRecentProfiles = 'MissingRecentProfiles',
  Unknown = 'Unknown'
}

export type Feature = {
  __typename?: 'Feature';
  /**
   * Returns analysis results, filtered by the specified filter and scoped to the specific dataset and feature.
   * Note: supplied dataset/tags and feature filters are ignored
   */
  analysisResults?: Maybe<Array<AnalysisResult>>;
  /**
   * Returns analysis results that are anomalous, filtered by the specified filter and scoped to the specific dataset and feature.
   * Note: supplied dataset/tags, anomaliesOnly, and feature filters are ignored
   */
  anomalies?: Maybe<Array<AnalysisResult>>;
  /** Alias for alertCountsV2 */
  anomalyCounts?: Maybe<AlertCategoryCounts>;
  /** Metadata about the feature's weight/importance. */
  weight?: Maybe<FeatureWeight>;
  id: Scalars['String'];
  /** @deprecated Use alertCountsV2 instead */
  alertCounts?: Maybe<AlertCount>;
  /** @deprecated Use anomalyCounts instead - same query, but clearer naming. */
  alertCountsV2?: Maybe<AlertCategoryCounts>;
  modelName: Scalars['String'];
  modelTimePeriod: TimePeriod;
  modelId: Scalars['String'];
  tags: Array<SegmentTag>;
  name: Scalars['String'];
  /**
   * Returns dataset profiles/sketches associated with the feature.
   * 
   * Set histogramSplitPoints to specify the desired histogram bin edges.
   * NOTE: min and max will be added automatically at query time, because they depend on the profile for which the histogram will be generated. These split points should not include min/max.
   * E.g., if you'd like to split data into bins with edges [min, 5, 10, max], set this argument to [5, 10]
   */
  sketches: Array<FeatureSketch>;
  /** @deprecated This query serves Monitor V2 data. Use 'anomalies' query instead */
  alerts: Array<DataQualityEvent>;
  /** @deprecated This query serves Monitor V2 data. Use 'latestAnomaly' query instead */
  latestAlert?: Maybe<DataQualityEvent>;
  /** @deprecated No longer supported. Use 'latestAnomalyTimestamp' instead */
  latestAnomaly?: Maybe<AnalysisResult>;
  /** Returns the timestamp of the latest anomaly, if one exists */
  latestAnomalyTimestamp?: Maybe<Scalars['Float']>;
  /** @deprecated This query serves Monitor V2 data. Use 'analysisResults' query instead */
  events: Array<DataQualityEvent>;
  /** @deprecated To get schema information for a feature, please use the new `schema` field. The baseline sketch may not return all fields specified on the FeatureSketch type. */
  baselineSketch?: Maybe<FeatureSketch>;
  /** Returns schema information for the feature (type, discreteness, etc) */
  schema?: Maybe<FeatureSchema>;
};


export type FeatureAnalysisResultsArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type FeatureAnomaliesArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type FeatureAnomalyCountsArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type FeatureAlertCountsArgs = {
  fromTimestamp?: Maybe<Scalars['Float']>;
  toTimestamp?: Maybe<Scalars['Float']>;
};


export type FeatureAlertCountsV2Args = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type FeatureSketchesArgs = {
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  histogramSplitPoints?: Maybe<Array<Scalars['Float']>>;
};


export type FeatureAlertsArgs = {
  filter: EventFilter;
};


export type FeatureLatestAnomalyArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type FeatureLatestAnomalyTimestampArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type FeatureEventsArgs = {
  filter: EventFilter;
};

export type FeatureCounts = {
  __typename?: 'FeatureCounts';
  discrete: Scalars['Int'];
  nonDiscrete: Scalars['Int'];
};

export type FeatureFilter = {
  substring?: Maybe<Scalars['String']>;
  substrings?: Maybe<Array<Scalars['String']>>;
  alertTypes?: Maybe<Array<EventType>>;
  anomalyCategories?: Maybe<Array<AlertCategory>>;
  includeDiscrete?: Maybe<Scalars['Boolean']>;
  includeNonDiscrete?: Maybe<Scalars['Boolean']>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  toTimestamp?: Maybe<Scalars['Float']>;
  excludeEmpty?: Maybe<Scalars['Boolean']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type FeatureSchema = {
  __typename?: 'FeatureSchema';
  inferredType: FeatureType;
  isDiscrete: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type FeatureSketch = {
  __typename?: 'FeatureSketch';
  id: Scalars['String'];
  featureName: Scalars['String'];
  /** @deprecated Confusing name - use datasetTimestamp instead */
  createdAt: Scalars['Float'];
  datasetTimestamp?: Maybe<Scalars['Float']>;
  lastUploadTimestamp?: Maybe<Scalars['Float']>;
  totalCount: Scalars['Float'];
  nullCount: Scalars['Float'];
  /** @deprecated No longer supported in whylogs v1 */
  trueCount: Scalars['Float'];
  nullRatio: Scalars['Float'];
  booleanCount: Scalars['Float'];
  integerCount: Scalars['Float'];
  fractionCount: Scalars['Float'];
  showAsDiscrete: Scalars['Boolean'];
  uniqueCount?: Maybe<UniqueCountSummary>;
  uniqueRatio?: Maybe<Scalars['Float']>;
  frequentItems: Array<FrequentItem>;
  schemaSummary?: Maybe<SchemaSummary>;
  numberSummary?: Maybe<NumberSummary>;
  stringSummary?: Maybe<StringSummary>;
};

export type FeatureSketchFilter = {
  substring?: Maybe<Scalars['String']>;
  substrings?: Maybe<Array<Scalars['String']>>;
  featureNames?: Maybe<Array<Scalars['String']>>;
  featureName?: Maybe<Scalars['String']>;
  includeDiscrete?: Maybe<Scalars['Boolean']>;
  includeNonDiscrete?: Maybe<Scalars['Boolean']>;
};

export enum FeatureSortBy {
  Name = 'Name',
  AbsoluteWeight = 'AbsoluteWeight',
  AlertCount = 'AlertCount',
  Weight = 'Weight',
  WeightRank = 'WeightRank'
}

export enum FeatureType {
  Unknown = 'UNKNOWN',
  Null = 'NULL',
  Fraction = 'FRACTION',
  Integer = 'INTEGER',
  Boolean = 'BOOLEAN',
  Text = 'TEXT'
}

export type FeatureWeight = {
  __typename?: 'FeatureWeight';
  /** Feature's rank by weight, if known. */
  rank?: Maybe<Scalars['Int']>;
  /** Current feature weight, if provided. */
  value?: Maybe<Scalars['Float']>;
};

export type Feedback = {
  /** Type of feedback being submitted */
  category: FeedbackCategory;
  /** Tags associated with this feedback (Usability, Content, Data accuracy, etc) */
  tags: Array<Scalars['String']>;
  /** Target of the feedback (name of the component, page, feature, etc) */
  component: Scalars['String'];
  /** Message to include with the feedback */
  message: Scalars['String'];
  /** Dataset ID, associated with this feedback (optional) */
  datasetId?: Maybe<Scalars['String']>;
  /** Feature name, associated with this feedback (optional) */
  featureName?: Maybe<Scalars['String']>;
  /** current URL when feedback was submitted (optional) */
  url?: Maybe<Scalars['String']>;
  /** ID used to find a log in logRocket and track the user interaction */
  trackID?: Maybe<Scalars['String']>;
};

export enum FeedbackCategory {
  Bug = 'Bug',
  Request = 'Request',
  General = 'General'
}

export type FilteredFeatureSketches = {
  __typename?: 'FilteredFeatureSketches';
  totalCount: Scalars['Float'];
  totalDiscrete: Scalars['Float'];
  totalNonDiscrete: Scalars['Float'];
  results: Array<FeatureSketch>;
};

export type FilteredFeatures = {
  __typename?: 'FilteredFeatures';
  results: Array<Feature>;
  totalCount: Scalars['Float'];
};

export type FilteredFeaturesSort = {
  by: FeatureSortBy;
  direction: SortDirection;
};

export type FloatDataPoint = {
  __typename?: 'FloatDataPoint';
  timestamp: Scalars['Float'];
  value: Scalars['Float'];
  lastUploadTimestamp?: Maybe<Scalars['Float']>;
};

export type FrequentItem = {
  __typename?: 'FrequentItem';
  value?: Maybe<Scalars['String']>;
  lower?: Maybe<Scalars['Float']>;
  estimate?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
};

export type GeneralNotificationSettings = {
  __typename?: 'GeneralNotificationSettings';
  /** ID of this integration */
  id: Scalars['String'];
  /** Whether the notification channel is enabled */
  enabled: Scalars['Boolean'];
  /** Scheduling mode */
  cadence: NotificationSchedulingCadence;
  /** Timezone for scheduled deliveries */
  timezone?: Maybe<Scalars['String']>;
  /** Delivery day of week */
  day?: Maybe<DayOfWeek>;
  /** UTC Delivery hour of day (0-23) */
  hour?: Maybe<Scalars['Int']>;
  /** UTC Delivery minute of day (0-59) */
  minute?: Maybe<Scalars['Int']>;
};

export type GenericNotificationAction = {
  __typename?: 'GenericNotificationAction';
  id: Scalars['String'];
  type: ActionType;
  enabled: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Float']>;
  payload?: Maybe<NotificationAction>;
  references?: Maybe<Array<Maybe<NotificationRelationshipItem>>>;
};

export type GlobalActionsFetching = {
  __typename?: 'GlobalActionsFetching';
  listGlobalActions?: Maybe<Array<GenericNotificationAction>>;
  findGlobalAction?: Maybe<GenericNotificationAction>;
};


export type GlobalActionsFetchingFindGlobalActionArgs = {
  id: Scalars['String'];
};

export type GlobalActionsManagement = {
  __typename?: 'GlobalActionsManagement';
  updateGlobalAction?: Maybe<Scalars['Boolean']>;
  createGlobalAction?: Maybe<Scalars['Boolean']>;
  enableGlobalAction?: Maybe<Scalars['Boolean']>;
  disableGlobalAction?: Maybe<Scalars['Boolean']>;
  deleteGlobalAction?: Maybe<Scalars['Boolean']>;
  testGlobalAction?: Maybe<Scalars['Boolean']>;
};


export type GlobalActionsManagementUpdateGlobalActionArgs = {
  id: Scalars['String'];
  type: ActionType;
  action: Scalars['String'];
};


export type GlobalActionsManagementCreateGlobalActionArgs = {
  id: Scalars['String'];
  type: ActionType;
  action: Scalars['String'];
};


export type GlobalActionsManagementEnableGlobalActionArgs = {
  id: Scalars['String'];
};


export type GlobalActionsManagementDisableGlobalActionArgs = {
  id: Scalars['String'];
};


export type GlobalActionsManagementDeleteGlobalActionArgs = {
  id: Scalars['String'];
};


export type GlobalActionsManagementTestGlobalActionArgs = {
  id: Scalars['String'];
};

export enum GranularityInclusion {
  IndividualOnly = 'INDIVIDUAL_ONLY',
  RollupOnly = 'ROLLUP_ONLY',
  Both = 'BOTH'
}

export type GroupedAlertBatch = {
  __typename?: 'GroupedAlertBatch';
  /** Alert timestamp */
  timestamp: Scalars['Float'];
  /** Counts of alerts by category */
  counts: Array<AlertCategoryCount>;
};

export type HeaderTuple = {
  __typename?: 'HeaderTuple';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Histogram = {
  __typename?: 'Histogram';
  bins: Array<Scalars['Float']>;
  counts: Array<Scalars['Float']>;
};

export enum HttpMethod {
  Get = 'Get',
  Post = 'Post',
  Put = 'Put',
  Patch = 'Patch',
  Delete = 'Delete'
}

export type IndividualProfile = {
  __typename?: 'IndividualProfile';
  /** Individual profile's unique WhyLabs ID */
  retrievalToken: Scalars['String'];
  /** Dataset ID with which it is associated */
  datasetId: Scalars['String'];
  /** Segment tags */
  tags?: Maybe<Array<SegmentTag>>;
  /** Feature sketches associated with the individual profile */
  sketches?: Maybe<FilteredFeatureSketches>;
};


export type IndividualProfileSketchesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<FeatureSketchFilter>;
  histogramSplitPoints?: Maybe<Array<Scalars['Float']>>;
};

export type IndividualProfileItem = {
  __typename?: 'IndividualProfileItem';
  /** Individual profile's trace ID */
  traceId?: Maybe<Scalars['String']>;
  /** Individual profile's unique WhyLabs ID */
  retrievalToken: Scalars['String'];
  /** Dataset ID with which it is associated */
  datasetId: Scalars['String'];
  /** Timestamp of the associated data */
  datasetTimestamp?: Maybe<Scalars['Float']>;
};

export type InsightEntry = {
  __typename?: 'InsightEntry';
  name: Scalars['String'];
  column: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  metrics: InsightMetricResult;
};

export type InsightMetricResult = {
  __typename?: 'InsightMetricResult';
  most_freq_estimate?: Maybe<Scalars['Int']>;
  most_freq_value?: Maybe<Scalars['String']>;
  mean?: Maybe<Scalars['Float']>;
  counts_total?: Maybe<Scalars['Int']>;
  counts_null?: Maybe<Scalars['Int']>;
  types_boolean?: Maybe<Scalars['Int']>;
  types_fractional?: Maybe<Scalars['Int']>;
  types_integral?: Maybe<Scalars['Int']>;
  types_tensor?: Maybe<Scalars['Int']>;
  types_object?: Maybe<Scalars['Int']>;
  min_value?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  uniqueness?: Maybe<Scalars['Float']>;
};

export type IntegrationCard = {
  __typename?: 'IntegrationCard';
  category?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  coming_soon?: Maybe<Scalars['Boolean']>;
};

export type InternalAdminInfo = {
  __typename?: 'InternalAdminInfo';
  /** Get a specific organization */
  organization?: Maybe<Organization>;
  /** List all available organizations in the current environment */
  organizations: Array<Organization>;
  /** List all models for a given organization */
  models: Array<Model>;
  /** List all memberships associated with the email address or user ID */
  memberships: Array<Member>;
  /** Check if a user's email has been verified in Auth0 */
  checkAuth0Verified: Scalars['Boolean'];
};


export type InternalAdminInfoOrganizationArgs = {
  orgId: Scalars['String'];
};


export type InternalAdminInfoOrganizationsArgs = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type InternalAdminInfoModelsArgs = {
  orgId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
};


export type InternalAdminInfoMembershipsArgs = {
  email: Scalars['String'];
};


export type InternalAdminInfoCheckAuth0VerifiedArgs = {
  email: Scalars['String'];
};

export type InternalGlobalManagement = {
  __typename?: 'InternalGlobalManagement';
  /** Updates the global maintenance/status banner message. */
  updateMaintenanceBanner: Scalars['Boolean'];
  /** Clears the global maintenance/status banner. */
  clearMaintenanceBanner: Scalars['Boolean'];
};


export type InternalGlobalManagementUpdateMaintenanceBannerArgs = {
  message: Scalars['String'];
};

export type InternalImpersonationManagement = {
  __typename?: 'InternalImpersonationManagement';
  /** Assume identity of the specified user within an org */
  impersonate?: Maybe<Scalars['Boolean']>;
};


export type InternalImpersonationManagementImpersonateArgs = {
  userId: Scalars['String'];
  durationMinutes: Scalars['Int'];
};

export type InternalMembershipManagement = {
  __typename?: 'InternalMembershipManagement';
  /** Removes all memberships for the specified email address, effectively kicking them out of all orgs that they currently belong to */
  clear: Scalars['Boolean'];
  /** Add a member to the target organization */
  add: Member;
  /** Update a membership to change a user's role in an organization */
  update: Member;
  /** Remove a member from the target organization */
  remove: Member;
  /** Change the default organization for the target user */
  setDefaultOrg: Scalars['Boolean'];
};


export type InternalMembershipManagementClearArgs = {
  email: Scalars['String'];
};


export type InternalMembershipManagementAddArgs = {
  orgId: Scalars['String'];
  email: Scalars['String'];
  role?: Maybe<MembershipRole>;
};


export type InternalMembershipManagementUpdateArgs = {
  orgId: Scalars['String'];
  email: Scalars['String'];
  role: MembershipRole;
};


export type InternalMembershipManagementRemoveArgs = {
  orgId: Scalars['String'];
  email: Scalars['String'];
};


export type InternalMembershipManagementSetDefaultOrgArgs = {
  orgId: Scalars['String'];
  email: Scalars['String'];
};

export type InternalModelManagement = {
  __typename?: 'InternalModelManagement';
  /** Create a new Model for the specified Organization with the specified name */
  create: Model;
};


export type InternalModelManagementCreateArgs = {
  orgId: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<ModelType>;
};

export type InternalOrganizationManagement = {
  __typename?: 'InternalOrganizationManagement';
  /** Create a new Organization with the specified properties. This Organization will automatically be assigned to the PAID tier. */
  create: Organization;
  /** Update an Organization. */
  organization: InternalOrganizationUpdate;
};


export type InternalOrganizationManagementCreateArgs = {
  name: Scalars['String'];
  emailDomains?: Maybe<Array<Scalars['String']>>;
};


export type InternalOrganizationManagementOrganizationArgs = {
  orgId: Scalars['String'];
};

export type InternalOrganizationUpdate = {
  __typename?: 'InternalOrganizationUpdate';
  orgId: Scalars['String'];
  /** Update the emailDomains for an Organization. */
  updateEmailDomains: Organization;
  /** Update the subscriptionTier for an Organization. */
  updateSubscriptionTier: Organization;
};


export type InternalOrganizationUpdateUpdateEmailDomainsArgs = {
  emailDomains: Array<Scalars['String']>;
};


export type InternalOrganizationUpdateUpdateSubscriptionTierArgs = {
  subscriptionTier: SubscriptionTier;
};

export enum JobStatus {
  InProgress = 'InProgress',
  Failed = 'Failed',
  Succeeded = 'Succeeded'
}

export type LlmTraces = {
  __typename?: 'LLMTraces';
  /** Returns if the model has traces, checking the last 10 years from now independently of passed range */
  hasTraces: Scalars['Boolean'];
  /** Returns the total of traces in range */
  totalCount: Scalars['Float'];
  /** Returns the total of policy violations in range */
  violationsCount: Scalars['Float'];
  /** Returns the total of blocked interactions in range */
  blockedInteractionsCount: Scalars['Float'];
  /** Last trace uploaded timestamp */
  latestTraceTimestamp?: Maybe<Scalars['Float']>;
};

export type LimitSpec = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<SortDirection>;
};

export type MsTeamsAction = {
  __typename?: 'MSTeamsAction';
  webhook: Scalars['String'];
};

export type MaintenanceBanner = {
  __typename?: 'MaintenanceBanner';
  /** Outage/maintenance message. Will be visible to ALL users on the platform. */
  message: Scalars['String'];
  /**
   * Email of the WhyLabs user that set the message.
   * Not available to regular users, only WhyLabs employees.
   */
  updatedBy: Scalars['String'];
  /** UTC datetime of the last update */
  updatedAt: Scalars['String'];
};

export type Masquerade = {
  __typename?: 'Masquerade';
  expiration?: Maybe<Scalars['String']>;
  isActiveNow: Scalars['Boolean'];
};

export type Member = {
  __typename?: 'Member';
  userId: Scalars['String'];
  email: Scalars['String'];
  orgId: Scalars['String'];
  role: MembershipRole;
};

export type MembershipManagement = {
  __typename?: 'MembershipManagement';
  /** Add a Member to the current Organization */
  add?: Maybe<Member>;
  /** Add multiple Members to the current Organization */
  bulkAdd?: Maybe<Array<Member>>;
  /** Update a Member from the current Organization */
  update?: Maybe<Member>;
  /** Remove a Member from the current Organization */
  remove?: Maybe<Member>;
  /** Set the default Organization for the current User */
  setDefaultOrganization?: Maybe<Scalars['Boolean']>;
  /** Update the email domains allowed for Members in the current Organization */
  updateEmailDomains?: Maybe<Scalars['Boolean']>;
};


export type MembershipManagementAddArgs = {
  email: Scalars['String'];
  role?: Maybe<MembershipRole>;
};


export type MembershipManagementBulkAddArgs = {
  emails: Array<Scalars['String']>;
  role?: Maybe<MembershipRole>;
};


export type MembershipManagementUpdateArgs = {
  email: Scalars['String'];
  role: MembershipRole;
};


export type MembershipManagementRemoveArgs = {
  email: Scalars['String'];
};


export type MembershipManagementSetDefaultOrganizationArgs = {
  orgId: Scalars['String'];
};


export type MembershipManagementUpdateEmailDomainsArgs = {
  emailDomains: Array<Scalars['String']>;
};

export enum MembershipRole {
  Admin = 'Admin',
  Member = 'Member',
  Viewer = 'Viewer',
  Unknown = 'Unknown'
}

/** Describes the nature of the relationship of the membership to the respective org */
export enum MembershipType {
  Demo = 'DEMO',
  Default = 'DEFAULT',
  Member = 'MEMBER'
}

export type Metric = {
  __typename?: 'Metric';
  id: Scalars['String'];
  datasetGranularity: TimePeriod;
  datasetId: Scalars['String'];
  segmentTags: Array<SegmentTag>;
  /** Name of the metric */
  name: Scalars['String'];
  /** Values of this metric, over time */
  values: Array<MetricValue>;
  metadata?: Maybe<MetricSchema>;
};


export type MetricValuesArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
};

export type MetricBounds = {
  __typename?: 'MetricBounds';
  /** Name of metric that is the upper bound, if any */
  upper?: Maybe<AnalysisMetric>;
  /** Name of metric that is the lower bound, if any */
  lower?: Maybe<AnalysisMetric>;
};

export enum MetricDataType {
  Float = 'FLOAT',
  Integer = 'INTEGER',
  Complex = 'COMPLEX'
}

export enum MetricDirection {
  /** The metric improves as its numeric value increases */
  ImproveUp = 'IMPROVE_UP',
  /** The metric improves as its numeric value decreases */
  ImproveDown = 'IMPROVE_DOWN'
}

export enum MetricKind {
  /** An amount can be summed/averaged over time. A rollup query will give the sum over the unit of granularity. */
  Amount = 'AMOUNT',
  /**
   * A rate (including ratios, percents) compares one amount to another. It cannot typically be summed/averaged over time.
   * A rollup query will perform the right calculation to give the rate over the unit of granularity.
   */
  Rate = 'RATE',
  /**
   * A distribution shows the distribution of values within a profile.
   * A rollup query will show the distribution over the unit of granularity.
   */
  Distribution = 'DISTRIBUTION'
}

export type MetricQuery = {
  datasetId: Scalars['String'];
  metric: AnalysisMetric;
  /** Feature to query. This should be omitted if the metric is a dataset metric. */
  feature?: Maybe<Scalars['String']>;
};

export type MetricQueryDefinition = {
  __typename?: 'MetricQueryDefinition';
  /** Whether to look at a specific column or the entire dataset */
  targetLevel: AnalysisTargetLevel;
  /** Name of the column to query, if data is not available at the dataset level */
  column?: Maybe<Scalars['String']>;
  /** Which WhyLabs metric to query */
  metric: AnalysisMetric;
  /** The label of the WhyLabs metric to query */
  metricLabel?: Maybe<Scalars['String']>;
};

export type MetricResult = {
  __typename?: 'MetricResult';
  datasetId: Scalars['String'];
  metric: AnalysisMetric;
  feature: Scalars['String'];
  /** The specific segment tags queried - none for the full dataset. */
  segment?: Maybe<Array<SegmentTag>>;
  segmentDescription?: Maybe<Scalars['String']>;
  points: Array<FloatDataPoint>;
};

export type MetricRollupResult = {
  __typename?: 'MetricRollupResult';
  datasetId: Scalars['String'];
  metric: AnalysisMetric;
  feature: Scalars['String'];
  segmentGroup: Scalars['String'];
  value: Scalars['Float'];
};

export type MetricSchema = {
  __typename?: 'MetricSchema';
  /** The name of the metric if it is a built-in metric. */
  name?: Maybe<AnalysisMetric>;
  /** The source of the metric. */
  source: MetricSource;
  /** A short label suitable for displaying in a UI. */
  label: Scalars['String'];
  /** The data type of the metric. */
  dataType: MetricDataType;
  /** Whether this metric is bounded between 0 and 1. */
  unitInterval?: Maybe<Scalars['Boolean']>;
  /** Whether this metric is a rate or amount. */
  metricKind?: Maybe<MetricKind>;
  /** Whether this metric improves up or down. Only applies to a subset of metrics such as performance. */
  metricDirection?: Maybe<MetricDirection>;
  /** Whether this metric is usually shown as a percent. */
  showAsPercent?: Maybe<Scalars['Boolean']>;
  /** The related bounding metrics, if any. */
  bounds?: Maybe<MetricBounds>;
  /** An explanation of how to construct the query for this metric */
  queryDefinition?: Maybe<MetricQueryDefinition>;
  /** User-assigned tags associated with this metric (particularly LLM metrics). */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Description of the metric (particularly LLM metrics). */
  description?: Maybe<Scalars['String']>;
};

export enum MetricSource {
  Whylabs = 'WHYLABS',
  Llm = 'LLM',
  UserDefined = 'USER_DEFINED'
}

/** Value of a single value metric at a given point in time */
export type MetricValue = {
  __typename?: 'MetricValue';
  timestamp: Scalars['Float'];
  value: Scalars['Float'];
  lastUploadTimestamp?: Maybe<Scalars['Float']>;
};

export type Model = Dataset & {
  __typename?: 'Model';
  /**
   * Returns analysis results, filtered by the specified filter and scoped to the specific dataset.
   * Note: supplied dataset/tags filters are ignored
   */
  analysisResults?: Maybe<Array<AnalysisResult>>;
  /**
   * Returns analysis results that are anomalous, filtered by the specified filter and scoped to the specific dataset.
   * Note: supplied dataset/tags and anomaliesOnly filters are ignored
   */
  anomalies?: Maybe<Array<AnalysisResult>>;
  /**
   * Alias for alertCountsV2
   * Returns aggregated anomaly counts over time and broken down by category.
   * Much more efficient than fetching anomaly objects directly, because aggregation happens in Druid.
   */
  anomalyCounts?: Maybe<AlertCategoryCounts>;
  /** Returns aggregated anomaly counts over time and broken down by category, for all segments and the overall dataset. */
  allAnomalyCounts?: Maybe<AlertCategoryCounts>;
  /**
   * Returns true if any weights have been uploaded for this dataset (in any segment).
   * @deprecated use model > weightMetadata > hasWeights instead
   */
  hasWeights?: Maybe<Scalars['Boolean']>;
  weightMetadata?: Maybe<ModelWeightMetadata>;
  id: Scalars['String'];
  datasetId: Scalars['String'];
  name: Scalars['String'];
  tags: Array<SegmentTag>;
  resourceTags: Array<CustomTag>;
  creationTime?: Maybe<Scalars['Float']>;
  modelType: ModelType;
  assetCategory?: Maybe<AssetCategory>;
  segment?: Maybe<Segment>;
  segments: Array<Segment>;
  output?: Maybe<Feature>;
  outputs: Array<Feature>;
  filteredOutputs: FilteredFeatures;
  feature?: Maybe<Feature>;
  /** @deprecated This query returns ALL features in a dataset, which can be thousands. Please use 'filteredFeatures' query instead. */
  features: Array<Feature>;
  filteredFeatures: FilteredFeatures;
  batch?: Maybe<BatchMetadata>;
  /**
   * Fetches the dataset-level metadata by time range or from the specified timestamps.
   * If the `timestamps` argument is passed, it will be prioritized over the `from/to` args.
   * We probably shouldn't pass more than a couple timestamps at a time. For large queries, use a time range :)
   */
  batches: Array<BatchMetadata>;
  batchDateRanges: Array<DateRange>;
  /** @deprecated This query serves Monitor V2 data. Use 'anomalies' query instead */
  alerts: Array<DataQualityEvent>;
  /** @deprecated Use anomalyCounts instead - same query, but clearer naming. */
  alertCountsV2?: Maybe<AlertCategoryCounts>;
  /** @deprecated This query serves Monitor V2 data. Use 'latestAnomaly' query instead */
  latestAlert?: Maybe<DataQualityEvent>;
  /** @deprecated No longer supported. Use 'latestAnomalyTimestamp' instead */
  latestAnomaly?: Maybe<AnalysisResult>;
  /** Returns the timestamp of the latest anomaly, if one exists */
  latestAnomalyTimestamp?: Maybe<Scalars['Float']>;
  /** @deprecated This query serves Monitor V2 data. Use 'analysisResults' query instead */
  events: Array<DataQualityEvent>;
  /** @deprecated These are Monitor V2 audit logs. These don't work with MV3. */
  monitorConfigAuditLogs: Array<AuditLog>;
  batchFrequency: TimePeriod;
  /** @deprecated Use entitySchema */
  totalFeatures: Scalars['Int'];
  totalSegments: Scalars['Int'];
  totalFilteredSegments: Scalars['Int'];
  /** @deprecated Use entitySchema */
  featureCounts?: Maybe<FeatureCounts>;
  /** Available reference profiles that have been uploaded for this dataset */
  referenceProfiles?: Maybe<Array<ReferenceProfile>>;
  /** Individual profiles that have been uploaded for this dataset */
  individualProfileList?: Maybe<Array<IndividualProfileItem>>;
  /** Individual profiles that have been uploaded for this dataset */
  individualProfiles?: Maybe<Array<IndividualProfile>>;
  /** Constraints list (analyzers json string config) for this dataset */
  constraintsList?: Maybe<Array<Scalars['String']>>;
  /** Information about data availability for this dataset */
  dataAvailability?: Maybe<DataAvailability>;
  /** Date range of data lineage for this dataset with start and end timestamps fitting the batch bucket */
  dataLineage?: Maybe<DataLineage>;
  /** Information about entity schema for this dataset */
  entitySchema?: Maybe<EntitySchema>;
  /** Profile insights for this model */
  insights?: Maybe<Array<InsightEntry>>;
  datasetMetric?: Maybe<Metric>;
  datasetMetrics?: Maybe<Array<Metric>>;
  customMetrics?: Maybe<Array<CustomMetric>>;
  /** Monitor coverage information for the given dataset */
  monitoredCategories?: Maybe<Array<AlertCategory>>;
  /** Data used in the LLM Secure summary card */
  tracesSummary?: Maybe<LlmTraces>;
};


export type ModelAnalysisResultsArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type ModelAnomaliesArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type ModelAnomalyCountsArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type ModelAllAnomalyCountsArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type ModelSegmentArgs = {
  tags?: Maybe<Array<SegmentTagFilter>>;
};


export type ModelSegmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<SegmentFilter>;
  sort?: Maybe<SegmentSort>;
};


export type ModelOutputArgs = {
  name: Scalars['String'];
};


export type ModelFilteredOutputsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  sort?: Maybe<FilteredFeaturesSort>;
};


export type ModelFeatureArgs = {
  name: Scalars['String'];
};


export type ModelFeaturesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type ModelFilteredFeaturesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  sort?: Maybe<FilteredFeaturesSort>;
};


export type ModelBatchArgs = {
  datasetTimestamp: Scalars['Float'];
};


export type ModelBatchesArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  timestamps?: Maybe<Array<Scalars['Float']>>;
};


export type ModelBatchDateRangesArgs = {
  timestamps: Array<Scalars['Float']>;
};


export type ModelAlertsArgs = {
  filter: EventFilter;
};


export type ModelAlertCountsV2Args = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type ModelLatestAnomalyArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type ModelLatestAnomalyTimestampArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type ModelEventsArgs = {
  filter: EventFilter;
};


export type ModelMonitorConfigAuditLogsArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
};


export type ModelTotalFeaturesArgs = {
  fromTimestamp?: Maybe<Scalars['Float']>;
  toTimestamp?: Maybe<Scalars['Float']>;
};


export type ModelTotalFilteredSegmentsArgs = {
  filter?: Maybe<SegmentFilter>;
};


export type ModelReferenceProfilesArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  profileIds?: Maybe<Array<Scalars['String']>>;
};


export type ModelIndividualProfileListArgs = {
  from: Scalars['Float'];
  to: Scalars['Float'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type ModelIndividualProfilesArgs = {
  retrievalTokens: Array<Scalars['String']>;
};


export type ModelInsightsArgs = {
  batchProfileTimestamp?: Maybe<Scalars['Float']>;
  referenceProfileId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
};


export type ModelDatasetMetricArgs = {
  name: Scalars['String'];
  granularity?: Maybe<TimePeriod>;
};


export type ModelDatasetMetricsArgs = {
  granularity?: Maybe<TimePeriod>;
};


export type ModelCustomMetricsArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
  granularity?: Maybe<TimePeriod>;
};


export type ModelTracesSummaryArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
};

export type ModelManagement = {
  __typename?: 'ModelManagement';
  /** Creates a new Model, under the user's organization */
  create: Model;
  /** Creates n amount of models */
  createBulk: Array<Model>;
  /** Updates an existing Model */
  update: Model;
  /** Updates a list of models */
  bulkUpdate: Array<Model>;
  /** Soft-deletes an existing Model, making it unreachable (can't list or get), but preserving its metadata */
  delete: Model;
};


export type ModelManagementCreateArgs = {
  name: Scalars['String'];
  type: ModelType;
  timePeriod?: Maybe<TimePeriod>;
};


export type ModelManagementCreateBulkArgs = {
  name: Scalars['String'];
  type: ModelType;
  quantity: Scalars['Int'];
  timePeriod?: Maybe<TimePeriod>;
};


export type ModelManagementUpdateArgs = {
  model: ModelUpdateParams;
};


export type ModelManagementBulkUpdateArgs = {
  models: Array<ModelUpdateParams>;
};


export type ModelManagementDeleteArgs = {
  id: Scalars['String'];
};

export type ModelMetrics = {
  __typename?: 'ModelMetrics';
  calibration?: Maybe<CalibrationCurve>;
  confusion?: Maybe<ConfusionMatrix>;
  fprTpr?: Maybe<Roc>;
  recallPrecision?: Maybe<Roc>;
};

export type ModelMetricsQueries = {
  __typename?: 'ModelMetricsQueries';
  rollup?: Maybe<Array<ModelMetricsRollup>>;
  rollupAll?: Maybe<ModelMetrics>;
};


export type ModelMetricsQueriesRollupArgs = {
  datasetId: Scalars['String'];
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  segment: Array<SegmentTagFilter>;
  granularity: TimePeriod;
};


export type ModelMetricsQueriesRollupAllArgs = {
  datasetId: Scalars['String'];
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  segment: Array<SegmentTagFilter>;
};

export type ModelMetricsRollup = {
  __typename?: 'ModelMetricsRollup';
  timestamp: Scalars['Float'];
  metrics?: Maybe<ModelMetrics>;
};

export enum ModelType {
  Classification = 'CLASSIFICATION',
  Regression = 'REGRESSION',
  Embeddings = 'EMBEDDINGS',
  Llm = 'LLM',
  Ranking = 'RANKING',
  ModelOther = 'MODEL_OTHER',
  DataSource = 'DATA_SOURCE',
  DataStream = 'DATA_STREAM',
  DataTransform = 'DATA_TRANSFORM',
  DataOther = 'DATA_OTHER',
  Unknown = 'UNKNOWN'
}

export type ModelUpdateParams = {
  id: Scalars['String'];
  name: Scalars['String'];
  type?: Maybe<ModelType>;
  timePeriod?: Maybe<TimePeriod>;
};

/** Information about the weights that were uploaded for the given model */
export type ModelWeightMetadata = {
  __typename?: 'ModelWeightMetadata';
  /** Returns true if any weights have been uploaded for this dataset (in any segment). */
  hasWeights: Scalars['Boolean'];
  /** Timestamp in UTC millis of when the weights were last updated */
  lastUpdatedAt?: Maybe<Scalars['Float']>;
};

export type MonitorCoverage = {
  __typename?: 'MonitorCoverage';
  category: AlertCategory;
  /** Percentage of datasets covered by this category of monitors */
  coverage: Scalars['Float'];
  /** List of datasets covered by this category of monitors */
  coveredDatasets: Array<Scalars['String']>;
};

export type MonitorSettingsManagement = {
  __typename?: 'MonitorSettingsManagement';
  /** Update monitor config settings document. */
  updateMonitorConfig?: Maybe<Scalars['Boolean']>;
  /** Merge update monitor config settings document with existing config. */
  patchMonitorConfig?: Maybe<Scalars['Boolean']>;
  /** Update a single analyzer in the config. */
  updateAnalyzer?: Maybe<Scalars['Boolean']>;
  /** Update a single monitor in the config. */
  updateMonitor?: Maybe<Scalars['Boolean']>;
  /** Deletes a single analyzer */
  deleteAnalyzer?: Maybe<Scalars['Boolean']>;
  /** Deletes a single monitor */
  deleteMonitor?: Maybe<Scalars['Boolean']>;
};


export type MonitorSettingsManagementUpdateMonitorConfigArgs = {
  datasetId: Scalars['String'];
  config: Scalars['String'];
};


export type MonitorSettingsManagementPatchMonitorConfigArgs = {
  datasetId: Scalars['String'];
  config: Scalars['String'];
};


export type MonitorSettingsManagementUpdateAnalyzerArgs = {
  datasetId: Scalars['String'];
  analyzerId: Scalars['String'];
  config: Scalars['String'];
};


export type MonitorSettingsManagementUpdateMonitorArgs = {
  datasetId: Scalars['String'];
  monitorId: Scalars['String'];
  config: Scalars['String'];
};


export type MonitorSettingsManagementDeleteAnalyzerArgs = {
  datasetId: Scalars['String'];
  analyzerId: Scalars['String'];
};


export type MonitorSettingsManagementDeleteMonitorArgs = {
  datasetId: Scalars['String'];
  monitorId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Operations related to managing WhyLabs API Access Tokens */
  accessToken: AccessTokenManagement;
  /**
   * Submit general feedback for a component/page/feature. Returns true if feedback was submitted successfully.
   * Feedback will go directly to WhyLabs email/Slack.
   */
  submitFeedback: Scalars['Boolean'];
  /**
   * Mark an Alert as a false alarm (false positive).
   * The number or ratio of false alarms for a given monitor will then be used to adjust the monitor settings to make it less noisy.
   */
  setFalseAlarm: Scalars['Boolean'];
  globalActions: GlobalActionsManagement;
  /** Administrative queries/tools. WhyLabs employees only. Do not use for operations initiated by customers. */
  admin?: Maybe<PlatformAdministration>;
  /** Tools related to impersonation */
  impersonation?: Maybe<InternalImpersonationManagement>;
  /**
   * Clear impersonation
   * Skipping permissions check is necessary here as the impersonated user may not have sufficient access rights to touch admin controls
   */
  clearImpersonation?: Maybe<Scalars['Boolean']>;
  /** Submit a form to reach out WhyLabs for enabling WhyLabs Secure. The message will be sent to a Slack channel via webhook */
  submitSecureForm: Scalars['Boolean'];
  /** Entrypoint for managing relationships between Organizations and Users */
  memberships?: Maybe<MembershipManagement>;
  models?: Maybe<ModelManagement>;
  /** Manage monitor config settings */
  monitorSettings?: Maybe<MonitorSettingsManagement>;
  /** Manage adhoc monitor runs */
  adHocMonitor?: Maybe<AdHocMonitorMutations>;
  /** Manage monitor backfill runs */
  backfillAnalyzers?: Maybe<BackfillAnalyzersMutations>;
  notificationManagement: NotificationManagement;
  /** Provisions an Organization with the specified org name and model name for the currently logged in user */
  provisionOrganization: Scalars['Boolean'];
  /** Claim a guest session for this organization. */
  claimSession: Scalars['Boolean'];
  /** Manage org custom tags */
  organizationCustomTags: OrgCustomTagsManagement;
  preferences?: Maybe<PreferencesManagement>;
  emailVerification?: Maybe<EmailVerificationManagement>;
};


export type MutationSubmitFeedbackArgs = {
  feedback: Feedback;
  submitAnonymously: Scalars['Boolean'];
};


export type MutationSetFalseAlarmArgs = {
  alertId: Scalars['String'];
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
};


export type MutationSubmitSecureFormArgs = {
  form: SecureForm;
};


export type MutationProvisionOrganizationArgs = {
  orgName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
};


export type MutationClaimSessionArgs = {
  sessionToken: Scalars['String'];
};

export type NotificationAction = SlackAction | EmailAction | PagerDutyAction | MsTeamsAction | CustomWebhookAction | UnknownAction;

export enum NotificationContentType {
  TotalAlertCount = 'TotalAlertCount',
  AlertsByTypeSummary = 'AlertsByTypeSummary',
  TopThreeMostAlertedFeatures = 'TopThreeMostAlertedFeatures'
}

export type NotificationManagement = {
  __typename?: 'NotificationManagement';
  updateSlackNotifications: SlackNotificationSettings;
  updateEmailNotifications: EmailNotificationSettings;
  updatePagerDutyNotifications: PagerDutyNotificationSettings;
  updateDigestTemplate: DigestTemplateSettings;
};


export type NotificationManagementUpdateSlackNotificationsArgs = {
  settings: NotificationSettingsUpdate;
  webhook?: Maybe<Scalars['String']>;
};


export type NotificationManagementUpdateEmailNotificationsArgs = {
  settings: NotificationSettingsUpdate;
  address?: Maybe<Scalars['String']>;
};


export type NotificationManagementUpdatePagerDutyNotificationsArgs = {
  settings: NotificationSettingsUpdate;
  integrationKey?: Maybe<Scalars['String']>;
};


export type NotificationManagementUpdateDigestTemplateArgs = {
  enabledNotificationContent: Array<NotificationContentType>;
  alertsToMonitor: Array<EventType>;
};

export type NotificationRelationshipItem = {
  __typename?: 'NotificationRelationshipItem';
  datasetId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  itemDisplayName?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationRelationshipType>;
};

export enum NotificationRelationshipType {
  Monitor = 'MONITOR'
}

export enum NotificationSchedulingCadence {
  Individual = 'Individual',
  Weekly = 'Weekly',
  Daily = 'Daily'
}

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  /** Where user will be redirected from links in Notifications */
  observatoryUrl?: Maybe<Scalars['String']>;
  slack?: Maybe<SlackNotificationSettings>;
  email?: Maybe<EmailNotificationSettings>;
  pagerDuty?: Maybe<PagerDutyNotificationSettings>;
  digest?: Maybe<DigestTemplateSettings>;
};

export type NotificationSettingsUpdate = {
  /** Whether the notification channel is enabled */
  enabled: Scalars['Boolean'];
  /** Scheduling mode */
  cadence: NotificationSchedulingCadence;
  /** Timezone for scheduled deliveries */
  timezone: Scalars['String'];
  /** Delivery day of week */
  day?: Maybe<DayOfWeek>;
  /** Delivery hour of day (0-23) */
  hour?: Maybe<Scalars['Int']>;
  /** Delivery minute of day (0-59) */
  minute?: Maybe<Scalars['Int']>;
};

export type NumberSummary = {
  __typename?: 'NumberSummary';
  histogram?: Maybe<Histogram>;
  count?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  mean?: Maybe<Scalars['Float']>;
  stddev?: Maybe<Scalars['Float']>;
  quantiles: Quantile;
  isDiscrete?: Maybe<Scalars['Boolean']>;
};

export type OrgCustomTagsManagement = {
  __typename?: 'OrgCustomTagsManagement';
  validateYamlConfigChanges: YamlTagsValidationResponse;
  updateYamlConfig: Scalars['Boolean'];
};


export type OrgCustomTagsManagementValidateYamlConfigChangesArgs = {
  yaml: Scalars['String'];
};


export type OrgCustomTagsManagementUpdateYamlConfigArgs = {
  yaml: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** Qualifies the relationship between the org and the user */
  membershipType?: Maybe<MembershipType>;
  /** Email domains associated with the organization (used to constrain allowed members) */
  emailDomains?: Maybe<Array<Scalars['String']>>;
  /** Organization's Subscription information */
  subscriptionTier?: Maybe<SubscriptionTier>;
  /** Metadata about the AWS marketplace subscription status if it exists. */
  awsMarketplaceMetadata?: Maybe<AwsMarketplaceMetadata>;
  /** Whether or not this org is subscribed through AWS marketplace, or was before it expired. */
  isAWSMarketplace: Scalars['Boolean'];
  /** Notification settings for the given Organization */
  notifications?: Maybe<NotificationSettings>;
  /** State related to the user's anonymous session, if any */
  anonymousSession?: Maybe<AnonymousSession>;
  /** A list of members within the organization */
  members?: Maybe<Array<Member>>;
  /** If true, the organization's memberships should only be managed through the account API (e.g. by external SCIM provisioner) */
  allowManagedMembershipUpdatesOnly?: Maybe<Scalars['Boolean']>;
  /** If not null, this organization is part of an account managed via the parent organization */
  parentOrgId?: Maybe<Scalars['String']>;
  /** The yaml configuration with all org custom tags */
  resourceTagsConfig?: Maybe<Scalars['String']>;
  /** List of all resource tags of the org */
  availableResourceTags?: Maybe<Array<CustomTag>>;
  /** List resource tags in use by resources */
  resourceTagsInUse?: Maybe<Array<CustomTag>>;
};

export type OrganizationMemberMetadata = {
  __typename?: 'OrganizationMemberMetadata';
  orgId: Scalars['String'];
  name: Scalars['String'];
  role: MembershipRole;
  membershipType?: Maybe<MembershipType>;
};

export type PagerDutyAction = {
  __typename?: 'PagerDutyAction';
  pagerDutyKey: Scalars['String'];
};

export type PagerDutyNotificationSettings = {
  __typename?: 'PagerDutyNotificationSettings';
  /** PagerDuty integration key to use for notifications (similar to an API key, available in user's PagerDuty account) */
  integrationKey?: Maybe<Scalars['String']>;
  general: GeneralNotificationSettings;
};

export enum Permission {
  ManageInternal = 'MANAGE_INTERNAL',
  ManageOrg = 'MANAGE_ORG',
  ManageDatasets = 'MANAGE_DATASETS',
  ManageMonitors = 'MANAGE_MONITORS',
  ManageActions = 'MANAGE_ACTIONS',
  ManageApiTokens = 'MANAGE_API_TOKENS',
  ManageDashboards = 'MANAGE_DASHBOARDS',
  ViewData = 'VIEW_DATA'
}

export type PlatformAdministration = {
  __typename?: 'PlatformAdministration';
  /** Manage organizations */
  organizations?: Maybe<InternalOrganizationManagement>;
  /** Update an organization */
  organization?: Maybe<InternalOrganizationUpdate>;
  /** Manage models */
  models?: Maybe<InternalModelManagement>;
  /** Manage memberships */
  memberships?: Maybe<InternalMembershipManagement>;
  /** Global platform settings */
  global?: Maybe<InternalGlobalManagement>;
};


export type PlatformAdministrationOrganizationArgs = {
  orgId: Scalars['String'];
};

export type PreferencesManagement = {
  __typename?: 'PreferencesManagement';
  update?: Maybe<Scalars['Boolean']>;
};


export type PreferencesManagementUpdateArgs = {
  newPreferences: UserPreferencesInput;
};

export type Quantile = {
  __typename?: 'Quantile';
  bins: Array<Scalars['Float']>;
  counts: Array<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  /** List all of the Access Tokens associated with the user's organization */
  accessTokens: Array<AccessTokenMetadata>;
  /**
   * Returns analysis results, filtered by the specified filter
   * Note: offset/limit are not supported. Use analysisResultsPaginated instead.
   */
  analysisResults?: Maybe<Array<AnalysisResult>>;
  /** Returns paginated analysis results. */
  paginatedAnalysisResults?: Maybe<Array<AnalysisResult>>;
  /**
   * Fetch a specific analysis result by analysisId.
   * Note: this should be the 'analysisId' field on analysis results, not the 'id' field.
   */
  analysisResult?: Maybe<AnalysisResult>;
  /** Returns the total count of anomalies by time period. Use timePeriod of ALL to get the sum of all anomalies in the time range. */
  anomalyCount?: Maybe<Array<AnomalyCount>>;
  /** Returns the total count of anomalies by analyzer ID. */
  anomalyCountByAnalyzer?: Maybe<Array<AnalyzerAnomalyCount>>;
  /** Returns the total count of anomalies within the segments of the given dataset and time range */
  segmentedAnomalyCount?: Maybe<Scalars['Float']>;
  /**
   * Returns aggregated anomaly counts over time and broken down by category.
   * Much more efficient than fetching anomaly objects directly, because aggregation happens in Druid.
   */
  anomalyCounts?: Maybe<AlertCategoryCounts>;
  /** Returns paginated results of the analyzer runs */
  analyzerRuns?: Maybe<Array<AnalyzerRunResult>>;
  runCount?: Maybe<AnalyzerRunCountResult>;
  customDashboards?: Maybe<Array<CustomDashboard>>;
  /** Queries provided for data investigation. */
  dataQueries?: Maybe<DataInvestigator>;
  modelMetrics?: Maybe<ModelMetricsQueries>;
  /** Returns the state of feature flags for the current user. */
  featureFlags?: Maybe<Array<FeatureFlag>>;
  globalActions: GlobalActionsFetching;
  integrationCards?: Maybe<Array<IntegrationCard>>;
  /** Administrative information about the state of the platform. WhyLabs employees only. Do not use for operations initiated by customers. */
  admin?: Maybe<InternalAdminInfo>;
  model?: Maybe<Model>;
  /**
   * Fetch the specified models/datasets/projects.
   * Fetches all models/datasets/projects if no dataset IDs argument was supplied.
   */
  models: Array<Model>;
  /** Fetch the maintenance banner. Used to display information about outages/maintenance, if any. */
  maintenanceBanner?: Maybe<MaintenanceBanner>;
  adhocRunStatus: JobStatus;
  backfillRunStatus: BackfillJobStatus;
  queryBackfillJobs: Array<BackfillJobInfo>;
  monitorConfig?: Maybe<Scalars['String']>;
  validateMonitorConfig?: Maybe<Scalars['Boolean']>;
  analyzer?: Maybe<Scalars['String']>;
  monitor?: Maybe<Scalars['String']>;
  columnMonitors: Array<Maybe<Scalars['String']>>;
  /** Monitor coverage information for all datasets in the organization */
  monitorCoverage?: Maybe<Array<MonitorCoverage>>;
  /**
   * Returns valid segment keys matching the provided search string, or all keys if no search string was specified.
   * If tags are specified, only matching segments will be considered when searching.
   * E.g. 'pur' -> ['purpose'], null -> ['purpose'], 'foo' -> []
   */
  searchSegmentKeys?: Maybe<Array<Scalars['String']>>;
  /**
   * Returns valid values for the specified segment key matching the provided search string, or all valid values if no search string was specified.
   * If tags are specified, only matching segments will be considered when searching.
   * E.g. 'purpose', 'car' -> ['car', 'credit_card'], null -> [...all results], 'foo' -> []
   */
  searchSegmentValues?: Maybe<Array<Scalars['String']>>;
  user: User;
};


export type QueryAccessTokensArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryAnalysisResultsArgs = {
  filter: AnalysisFilter;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortDirection: SortDirection;
};


export type QueryPaginatedAnalysisResultsArgs = {
  filter: AnalysisFilter;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sortDirection: SortDirection;
};


export type QueryAnalysisResultArgs = {
  analysisId: Scalars['String'];
};


export type QueryAnomalyCountArgs = {
  filter: AnomalyFilter;
  timePeriod?: Maybe<TimePeriod>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type QueryAnomalyCountByAnalyzerArgs = {
  datasetId: Scalars['String'];
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  analyzerIDs: Array<Scalars['String']>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type QuerySegmentedAnomalyCountArgs = {
  datasetId: Scalars['String'];
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type QueryAnomalyCountsArgs = {
  datasetId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type QueryAnalyzerRunsArgs = {
  filter: AnalyzerRunFilter;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sortDirection?: Maybe<SortDirection>;
};


export type QueryRunCountArgs = {
  filter: AnalyzerRunFilter;
};


export type QueryCustomDashboardsArgs = {
  usedOn: Scalars['String'];
};


export type QueryModelArgs = {
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
};


export type QueryModelsArgs = {
  datasetIDs?: Maybe<Array<Scalars['String']>>;
  resourceTags?: Maybe<Array<CustomTagFilter>>;
  resourceType?: Maybe<Array<ModelType>>;
  onlySecuredLLM?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryAdhocRunStatusArgs = {
  runId: Scalars['String'];
  numEvents: Scalars['Int'];
};


export type QueryBackfillRunStatusArgs = {
  runId: Scalars['String'];
};


export type QueryQueryBackfillJobsArgs = {
  datasetId?: Maybe<Scalars['String']>;
  onlyActive?: Maybe<Scalars['Boolean']>;
  runId?: Maybe<Scalars['String']>;
};


export type QueryMonitorConfigArgs = {
  datasetId: Scalars['String'];
};


export type QueryValidateMonitorConfigArgs = {
  datasetId: Scalars['String'];
  config: Scalars['String'];
};


export type QueryAnalyzerArgs = {
  datasetId: Scalars['String'];
  analyzerId: Scalars['String'];
};


export type QueryMonitorArgs = {
  datasetId: Scalars['String'];
  monitorId: Scalars['String'];
};


export type QueryColumnMonitorsArgs = {
  datasetId: Scalars['String'];
  columnId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
};


export type QuerySearchSegmentKeysArgs = {
  datasetId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  searchString?: Maybe<Scalars['String']>;
  limitSpec?: Maybe<LimitSpec>;
};


export type QuerySearchSegmentValuesArgs = {
  datasetId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  key: Scalars['String'];
  searchString?: Maybe<Scalars['String']>;
  limitSpec?: Maybe<LimitSpec>;
};

export type Roc = {
  __typename?: 'ROC';
  values: Array<Array<Scalars['Float']>>;
};

export type ReferenceProfile = {
  __typename?: 'ReferenceProfile';
  /** Reference profile's unique ID */
  id: Scalars['String'];
  /** Dataset ID with which it is associated */
  datasetId: Scalars['String'];
  /** Segment tags */
  tags?: Maybe<Array<SegmentTag>>;
  /** Human-readable name for the profile */
  alias: Scalars['String'];
  /** When the profile was uploaded */
  uploadTimestamp?: Maybe<Scalars['Float']>;
  /**
   * Timestamp of the associated data
   * Might be different from actual data timestamp due to these profiles being indexed a little differently in Druid
   */
  datasetTimestamp?: Maybe<Scalars['Float']>;
  /** Feature sketches associated with the reference profile */
  sketches?: Maybe<FilteredFeatureSketches>;
  /** Model metrics associated with the profile */
  metrics?: Maybe<ModelMetrics>;
};


export type ReferenceProfileSketchesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<FeatureSketchFilter>;
  histogramSplitPoints?: Maybe<Array<Scalars['Float']>>;
  excludeEmpty?: Maybe<Scalars['Boolean']>;
};

export enum RequestableTokenScope {
  User = 'USER',
  Admin = 'ADMIN',
  AccountAdmin = 'ACCOUNT_ADMIN'
}

export type SchemaSummary = {
  __typename?: 'SchemaSummary';
  inference?: Maybe<TypeInference>;
  typeCounts: Array<TypeSummary>;
};

export type SecureForm = {
  /** Dataset id */
  datasetId: Scalars['String'];
  /** Customer full name */
  fullName: Scalars['String'];
  /** Optional phone field */
  phone?: Maybe<Scalars['String']>;
  /** Optional contact email field */
  contactEmail?: Maybe<Scalars['String']>;
};

export type Segment = Dataset & {
  __typename?: 'Segment';
  /**
   * Returns analysis results, filtered by the specified filter and scoped to the specific dataset.
   * Note: supplied dataset/tags filters are ignored
   */
  analysisResults?: Maybe<Array<AnalysisResult>>;
  /**
   * Returns analysis results that are anomalous, filtered by the specified filter and scoped to the specific dataset.
   * Note: supplied dataset/tags and anomaliesOnly filters are ignored
   */
  anomalies?: Maybe<Array<AnalysisResult>>;
  /**
   * Alias for alertCountsV2
   * Returns aggregated anomaly counts over time and broken down by category for the segment.
   * Much more efficient than fetching anomaly objects directly, because aggregation happens in Druid.
   */
  anomalyCounts?: Maybe<AlertCategoryCounts>;
  datasetMetric?: Maybe<Metric>;
  datasetMetrics?: Maybe<Array<Metric>>;
  customMetrics?: Maybe<Array<CustomMetric>>;
  id: Scalars['String'];
  datasetId: Scalars['String'];
  /** @deprecated Segments don't have names, use tags instead */
  name: Scalars['String'];
  modelType: ModelType;
  assetCategory?: Maybe<AssetCategory>;
  tags: Array<SegmentTag>;
  resourceTags: Array<CustomTag>;
  creationTime?: Maybe<Scalars['Float']>;
  modelName: Scalars['String'];
  modelId: Scalars['String'];
  output?: Maybe<Feature>;
  outputs: Array<Feature>;
  filteredOutputs: FilteredFeatures;
  feature?: Maybe<Feature>;
  /** @deprecated This query returns ALL features in a dataset, which can be thousands. Please use 'filteredFeatures' query instead. */
  features: Array<Feature>;
  filteredFeatures: FilteredFeatures;
  batchFrequency: TimePeriod;
  batch?: Maybe<BatchMetadata>;
  /**
   * Fetches the dataset-level metadata by time range or from the specified timestamps.
   * If the `timestamps` argument is passed, it will be prioritized over the `from/to` args.
   * We probably shouldn't pass more than a couple timestamps at a time. For large queries, use a time range :)
   */
  batches: Array<BatchMetadata>;
  batchDateRanges: Array<DateRange>;
  /** @deprecated This query serves Monitor V2 data. Use 'anomalies' query instead */
  alerts: Array<DataQualityEvent>;
  /** @deprecated Use anomalyCounts instead - same query, but clearer naming. */
  alertCountsV2?: Maybe<AlertCategoryCounts>;
  /** @deprecated This query serves Monitor V2 data. Use 'latestAnomaly' query instead */
  latestAlert?: Maybe<DataQualityEvent>;
  /** @deprecated No longer supported. Use 'latestAnomalyTimestamp' instead */
  latestAnomaly?: Maybe<AnalysisResult>;
  /** Returns the timestamp of the latest anomaly, if one exists */
  latestAnomalyTimestamp?: Maybe<Scalars['Float']>;
  /** @deprecated This query serves Monitor V2 data. Use 'analysisResults' query instead */
  events: Array<DataQualityEvent>;
  featureCounts?: Maybe<FeatureCounts>;
  /** Available reference profiles that have been uploaded for this dataset */
  referenceProfiles?: Maybe<Array<ReferenceProfile>>;
  /** Individual profiles that have been uploaded for this dataset */
  individualProfileList?: Maybe<Array<IndividualProfileItem>>;
  /** Individual profiles that have been uploaded for this dataset */
  individualProfiles?: Maybe<Array<IndividualProfile>>;
  /** Constraints list (analyzers json string config) for this dataset */
  constraintsList?: Maybe<Array<Scalars['String']>>;
  /** Information about data availability for this dataset */
  dataAvailability?: Maybe<DataAvailability>;
  /** Date range of data lineage for this dataset with start and end timestamps fitting the batch bucket */
  dataLineage?: Maybe<DataLineage>;
  /** Information about entity schema for this dataset */
  entitySchema?: Maybe<EntitySchema>;
  /** Profile insights for this model */
  insights?: Maybe<Array<InsightEntry>>;
};


export type SegmentAnalysisResultsArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type SegmentAnomaliesArgs = {
  filter: AnalysisFilter;
  sortDirection: SortDirection;
};


export type SegmentAnomalyCountsArgs = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type SegmentDatasetMetricArgs = {
  name: Scalars['String'];
  granularity?: Maybe<TimePeriod>;
};


export type SegmentDatasetMetricsArgs = {
  granularity?: Maybe<TimePeriod>;
};


export type SegmentCustomMetricsArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
  granularity?: Maybe<TimePeriod>;
};


export type SegmentOutputArgs = {
  name: Scalars['String'];
};


export type SegmentFilteredOutputsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  sort?: Maybe<FilteredFeaturesSort>;
};


export type SegmentFeatureArgs = {
  name: Scalars['String'];
};


export type SegmentFeaturesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type SegmentFilteredFeaturesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  sort?: Maybe<FilteredFeaturesSort>;
};


export type SegmentBatchArgs = {
  datasetTimestamp: Scalars['Float'];
};


export type SegmentBatchesArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  timestamps?: Maybe<Array<Scalars['Float']>>;
};


export type SegmentBatchDateRangesArgs = {
  timestamps: Array<Scalars['Float']>;
};


export type SegmentAlertsArgs = {
  filter: EventFilter;
};


export type SegmentAlertCountsV2Args = {
  fromTimestamp: Scalars['Float'];
  toTimestamp?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<AnomalyCountGroupBy>;
  filter?: Maybe<AnomalyOptionalFilter>;
  granularityInclusion?: Maybe<GranularityInclusion>;
};


export type SegmentLatestAnomalyArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type SegmentLatestAnomalyTimestampArgs = {
  monitorIds?: Maybe<Array<Scalars['String']>>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
};


export type SegmentEventsArgs = {
  filter: EventFilter;
};


export type SegmentReferenceProfilesArgs = {
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
  profileIds?: Maybe<Array<Scalars['String']>>;
};


export type SegmentIndividualProfileListArgs = {
  from: Scalars['Float'];
  to: Scalars['Float'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type SegmentIndividualProfilesArgs = {
  retrievalTokens: Array<Scalars['String']>;
};


export type SegmentInsightsArgs = {
  batchProfileTimestamp?: Maybe<Scalars['Float']>;
  referenceProfileId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
};

export type SegmentFilter = {
  substring?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  fromTimestamp?: Maybe<Scalars['Float']>;
  toTimestamp?: Maybe<Scalars['Float']>;
};

export enum SegmentScope {
  Reference = 'Reference',
  Batch = 'Batch',
  Both = 'Both'
}

export type SegmentSort = {
  by: SegmentSortBy;
  direction: SortDirection;
};

export enum SegmentSortBy {
  /** Sort segments by name */
  Name = 'Name',
  /** Sort segments by the count of anomalies observed in them (must specify from/to timestamps when querying segments) */
  AnomalyCount = 'AnomalyCount'
}

export type SegmentTag = {
  __typename?: 'SegmentTag';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SegmentTagFilter = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SlackAction = {
  __typename?: 'SlackAction';
  slackWebhook: Scalars['String'];
};

export type SlackNotificationSettings = {
  __typename?: 'SlackNotificationSettings';
  /** WebHook to trigger a Slack channel message with the content of the Notification. */
  webhook?: Maybe<Scalars['String']>;
  general: GeneralNotificationSettings;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringSummary = {
  __typename?: 'StringSummary';
  uniqueCount?: Maybe<UniqueCountSummary>;
  frequentItems: Array<FrequentItem>;
  stringLength?: Maybe<NumberSummary>;
  tokenCount?: Maybe<NumberSummary>;
};

export enum SubscriptionTier {
  Paid = 'PAID',
  Free = 'FREE',
  AwsMarketplace = 'AWS_MARKETPLACE',
  Subscription = 'SUBSCRIPTION',
  Unknown = 'UNKNOWN'
}

export type ThresholdEvent = DataQualityEvent & {
  __typename?: 'ThresholdEvent';
  id: Scalars['String'];
  runId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  datasetId: Scalars['String'];
  /** If the event is an Alert, this flag determines whether it was designated as a False Alarm by a user. */
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
  sessionId?: Maybe<Scalars['String']>;
  /** @deprecated Use datasetTimestamp or creationTimestamp instead */
  timestamp: Scalars['Float'];
  creationTimestamp: Scalars['Float'];
  datasetTimestamp: Scalars['Float'];
  type: EventType;
  target: EventTargetType;
  category: AlertCategory;
  archetype: EventArchetype;
  algorithm: Algorithm;
  segment?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<SegmentTag>>;
  feature: Scalars['String'];
  metric: WhyLogsMetric;
  severity: Scalars['Float'];
  explanation: ThresholdEventExplanation;
};

export type ThresholdEventExplanation = {
  __typename?: 'ThresholdEventExplanation';
  minThreshold?: Maybe<EventParam>;
  maxThreshold?: Maybe<EventParam>;
  /** @deprecated Duplicate of event type, do not use */
  eventName: Scalars['String'];
  /** @deprecated Messages will not be populated on the backend going forward. Human-friendly alert descriptions can be generated and localized in the front end. */
  message: Scalars['String'];
  observedValue: Scalars['Float'];
  /** @deprecated Not human interpretable, do not surface in UI */
  expectedValue: Scalars['Float'];
};

export enum TimePeriod {
  Individual = 'INDIVIDUAL',
  P1M = 'P1M',
  P1W = 'P1W',
  P1D = 'P1D',
  Pt1H = 'PT1H',
  Unknown = 'UNKNOWN',
  All = 'ALL'
}

export type TypeInference = {
  __typename?: 'TypeInference';
  type: FeatureType;
  ratio: Scalars['Float'];
  count: Scalars['Float'];
};

export type TypeSummary = {
  __typename?: 'TypeSummary';
  type?: Maybe<FeatureType>;
  count?: Maybe<Scalars['Float']>;
};

export type UniqueCountSummary = {
  __typename?: 'UniqueCountSummary';
  estimate?: Maybe<Scalars['Float']>;
  upper?: Maybe<Scalars['Float']>;
  lower?: Maybe<Scalars['Float']>;
};

export type UnknownAction = {
  __typename?: 'UnknownAction';
  value?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  /** The user's real name */
  name?: Maybe<Scalars['String']>;
  /** The organization that the user is currently logged into */
  organization?: Maybe<Organization>;
  /** A list of all organizations that the user has access to */
  joinedOrganizations?: Maybe<Array<OrganizationMemberMetadata>>;
  /** User's email address */
  email?: Maybe<Scalars['String']>;
  /** Whether user's email is verified within their current login session (i.e. session cookie) */
  emailVerified?: Maybe<Scalars['Boolean']>;
  /**
   * Double checks email verification status within Auth0 if not verified in session.
   * This can be different from user's session state (and therefore emailVerified field) if they haven't logged in again yet after verifying their email in Auth0.
   * This field is slower to fetch and has rate limits.
   */
  auth0EmailVerified?: Maybe<Scalars['Boolean']>;
  picture?: Maybe<Scalars['String']>;
  /** @deprecated This field refers to Auth0 ID atm, but this can change. Use explicit Auth0Id/WhyLabsId fields instead */
  id?: Maybe<Scalars['String']>;
  auth0Id?: Maybe<Scalars['String']>;
  whyLabsId?: Maybe<Scalars['String']>;
  /** True, if the user is logged in */
  isAuthenticated: Scalars['Boolean'];
  /** User's permissions */
  permissions?: Maybe<Array<Permission>>;
  /** User's role */
  role?: Maybe<MembershipRole>;
  /** Additional user properties (e.g. settings) */
  metadata?: Maybe<UserMetadata>;
  /** User preferences */
  preferences?: Maybe<UserPreferences>;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  masquerade?: Maybe<Masquerade>;
};

/**
 * User preferences.
 * Fields here should probably be nullable, unless we plan on ensuring that they're set for ALL users at all times.
 */
export type UserPreferences = {
  __typename?: 'UserPreferences';
  foo?: Maybe<Scalars['String']>;
};

/** User preferences for input. */
export type UserPreferencesInput = {
  foo?: Maybe<Scalars['String']>;
};

export enum ValueSource {
  User = 'User',
  Learned = 'Learned',
  Default = 'Default',
  Unknown = 'Unknown'
}

export enum WhyLogsMetric {
  DistributionDistance = 'DistributionDistance',
  MissingValuesRatio = 'MissingValuesRatio',
  UniqueCount = 'UniqueCount',
  InferredDataType = 'InferredDataType',
  Quantile50 = 'Quantile50',
  TotalCount = 'TotalCount',
  Min = 'Min',
  Max = 'Max',
  StdDev = 'StdDev',
  Mean = 'Mean',
  Unknown = 'Unknown'
}

export type YamlTagsValidationResponse = {
  __typename?: 'YamlTagsValidationResponse';
  valid: Scalars['Boolean'];
  droppedTagsInUse?: Maybe<Array<CustomTag>>;
};

export type InsightSketchesFragment = { __typename?: 'FilteredFeatureSketches', totalCount: number, totalDiscrete: number, totalNonDiscrete: number, results: Array<{ __typename?: 'FeatureSketch', id: string, featureName: string, trueCount: number, booleanCount: number, integerCount: number, fractionCount: number, createdAt: number, totalCount: number, nullCount: number, nullRatio: number, showAsDiscrete: boolean, frequentItems: Array<{ __typename?: 'FrequentItem', value?: Maybe<string>, estimate?: Maybe<number>, upper?: Maybe<number> }>, uniqueCount?: Maybe<{ __typename?: 'UniqueCountSummary', estimate?: Maybe<number>, upper?: Maybe<number>, lower?: Maybe<number> }>, numberSummary?: Maybe<{ __typename?: 'NumberSummary', count?: Maybe<number>, min?: Maybe<number>, max?: Maybe<number>, mean?: Maybe<number>, stddev?: Maybe<number>, histogram?: Maybe<{ __typename?: 'Histogram', bins: Array<number>, counts: Array<number> }>, quantiles: { __typename?: 'Quantile', bins: Array<number>, counts: Array<number> } }>, schemaSummary?: Maybe<{ __typename?: 'SchemaSummary', inference?: Maybe<{ __typename?: 'TypeInference', type: FeatureType, ratio: number, count: number }>, typeCounts: Array<{ __typename?: 'TypeSummary', type?: Maybe<FeatureType>, count?: Maybe<number> }> }> }> };

export type GetProfileInsightInfoQueryVariables = Exact<{
  modelId: Scalars['String'];
  batchProfileIds: Array<Scalars['Float']>;
  referenceProfileIds: Array<Scalars['String']>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetProfileInsightInfoQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, datasetId: string, sketches: (
          { __typename?: 'FilteredFeatureSketches' }
          & InsightSketchesFragment
        ) }>, referenceProfiles?: Maybe<Array<{ __typename?: 'ReferenceProfile', id: string, datasetId: string, alias: string, sketches?: Maybe<(
          { __typename?: 'FilteredFeatureSketches' }
          & InsightSketchesFragment
        )> }>> }> }> };

export type GetIndividualProfilesQueryVariables = Exact<{
  modelId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
}>;


export type GetIndividualProfilesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, name: string, modelType: ModelType, individualProfileList?: Maybe<Array<{ __typename?: 'IndividualProfileItem', datasetTimestamp?: Maybe<number>, retrievalToken: string, traceId?: Maybe<string> }>> }> };

export type GetAnomalyCountsForWidgetQueryVariables = Exact<{
  datasetId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  analyzerIds?: Maybe<Array<Scalars['String']>>;
}>;


export type GetAnomalyCountsForWidgetQuery = { __typename?: 'Query', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> };

export type GetDatasetNameQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetDatasetNameQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', name: string }> };

export type GetMaintenanceBannerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaintenanceBannerQuery = { __typename?: 'Query', maintenanceBanner?: Maybe<{ __typename?: 'MaintenanceBanner', message: string, updatedAt: string }> };

export type MarketplaceMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketplaceMetadataQuery = { __typename?: 'Query', user: { __typename?: 'User', organization?: Maybe<{ __typename?: 'Organization', isAWSMarketplace: boolean, awsMarketplaceMetadata?: Maybe<{ __typename?: 'AWSMarketplaceMetadata', awsMarketplaceProductCode: string, dimension: string, expirationTime: number, expirationUpdateTime: number }> }> } };

export type ModelInfoForCodeHelpersFragment = { __typename?: 'Model', totalSegments: number };

export type GetModelAttributesForHelpersValidationQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetModelAttributesForHelpersValidationQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model' }
    & ModelInfoForCodeHelpersFragment
  )> };

export type MetricMetadataFragment = { __typename?: 'MetricSchema', source: MetricSource, tags?: Maybe<Array<string>>, queryDefinition?: Maybe<{ __typename?: 'MetricQueryDefinition', column?: Maybe<string>, metric: AnalysisMetric }> };

export type DatasetMetricsInfoFragment = { __typename?: 'Model', datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string, metadata?: Maybe<(
      { __typename?: 'MetricSchema' }
      & MetricMetadataFragment
    )> }>> };

export type GetModelMonitorMetricsQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetModelMonitorMetricsQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model' }
    & DatasetMetricsInfoFragment
  )> };

export type MonitorInputFeaturesFragment = (
  { __typename?: 'Feature', weight?: Maybe<{ __typename?: 'FeatureWeight', value?: Maybe<number> }> }
  & MonitorCommonFeaturesFieldsFragment
);

export type MonitorCommonFeaturesFieldsFragment = { __typename?: 'Feature', id: string, name: string, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> };

export type GetIoFeaturesQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetIoFeaturesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', weightMetadata?: Maybe<{ __typename?: 'ModelWeightMetadata', hasWeights: boolean }>, features: Array<(
      { __typename?: 'Feature' }
      & MonitorInputFeaturesFragment
    )>, outputs: Array<(
      { __typename?: 'Feature' }
      & MonitorCommonFeaturesFieldsFragment
    )> }> };

export type SegmentsListingQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type SegmentsListingQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segments: Array<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }>, mergedSegment?: Maybe<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }> }> };

export type GetRawAnalyzerByIdQueryVariables = Exact<{
  datasetId: Scalars['String'];
  analyzerId: Scalars['String'];
}>;


export type GetRawAnalyzerByIdQuery = { __typename?: 'Query', analyzer?: Maybe<string> };

export type GetMergedFeatureDataQueryVariables = Exact<{
  datasetId: Scalars['String'];
  column: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to: Scalars['Float'];
}>;


export type GetMergedFeatureDataQuery = { __typename?: 'Query', dataQueries?: Maybe<{ __typename?: 'DataInvestigator', getMergedFeatureData?: Maybe<(
      { __typename?: 'FeatureSketch' }
      & FullSketchFieldsFragment
    )> }> };

export type GetMergedFeatureUnifiedDataQueryVariables = Exact<{
  datasetId: Scalars['String'];
  column: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to: Scalars['Float'];
  splitpoints?: Maybe<Array<Scalars['Float']>>;
}>;


export type GetMergedFeatureUnifiedDataQuery = { __typename?: 'Query', dataQueries?: Maybe<{ __typename?: 'DataInvestigator', getMergedFeatureData?: Maybe<(
      { __typename?: 'FeatureSketch' }
      & FullSketchFieldsFragment
    )> }> };

export type GetOrgTierQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrgTierQuery = { __typename?: 'Query', user: { __typename?: 'User', organization?: Maybe<{ __typename?: 'Organization', subscriptionTier?: Maybe<SubscriptionTier> }> } };

export type AnomalyCountsCommonFieldsFragment = { __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> };

export type AnomalyDataAvailabilityFragment = { __typename?: 'Model', dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }> };

export type OverviewTimeSeriesFragment = { __typename?: 'GroupedAlertBatch', timestamp: number, counts: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> };

export type ModelCommonDataFragment = { __typename?: 'Model', id: string, name: string, batchFrequency: TimePeriod };

export type ResourceAnomalyCountTimeseriesFragment = { __typename?: 'AlertCategoryCounts', timeseries: Array<(
    { __typename?: 'GroupedAlertBatch' }
    & OverviewTimeSeriesFragment
  )> };

export type ModelOverviewInfoFragment = (
  { __typename?: 'Model', modelType: ModelType, creationTime?: Maybe<number>, assetCategory?: Maybe<AssetCategory>, totalSegments: number, tracesSummary?: Maybe<{ __typename?: 'LLMTraces', hasTraces: boolean }>, referenceProfiles?: Maybe<Array<{ __typename?: 'ReferenceProfile', id: string }>>, entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number } }>, resourceTags: Array<{ __typename?: 'CustomTag', key: string, value: string, color?: Maybe<string>, backgroundColor?: Maybe<string> }>, anomalyCounts?: Maybe<(
    { __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }
    & ResourceAnomalyCountTimeseriesFragment
  )>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }>, dataLineage?: Maybe<{ __typename?: 'DataLineage', latestProfileTimestamp?: Maybe<number>, oldestProfileTimestamp?: Maybe<number> }> }
  & ModelCommonDataFragment
  & LatestAlertOnModelFragment
);

export type LatestAlertOnModelFragment = { __typename?: 'Model', latestAnomalyTimestamp?: Maybe<number> };

export type DataAvailabilityFragment = { __typename?: 'Model', dataAvailability?: Maybe<{ __typename?: 'DataAvailability', oldestTimestamp?: Maybe<number>, latestTimestamp?: Maybe<number> }> };

export type ModelMetricsNameFragment = { __typename?: 'Model', modelType: ModelType, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>> };

export type GetMonitorConfigQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetMonitorConfigQuery = { __typename?: 'Query', monitorConfig?: Maybe<string> };

export type GetAnomaliesForMonitorListQueryVariables = Exact<{
  datasetId: Scalars['String'];
  monitorIDs: Array<Scalars['String']>;
  fromTimestamp: Scalars['Float'];
  toTimestamp: Scalars['Float'];
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetAnomaliesForMonitorListQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<{ __typename?: 'AnalysisResult', analysisId?: Maybe<string>, monitorIds?: Maybe<Array<string>>, datasetTimestamp?: Maybe<number> }>> };

export type PatchMonitorConfigMutationVariables = Exact<{
  modelId: Scalars['String'];
  config: Scalars['String'];
}>;


export type PatchMonitorConfigMutation = { __typename?: 'Mutation', monitorSettings?: Maybe<{ __typename?: 'MonitorSettingsManagement', patchMonitorConfig?: Maybe<boolean> }> };

export type UpdateMonitorMutationVariables = Exact<{
  modelId: Scalars['String'];
  config: Scalars['String'];
  monitorId: Scalars['String'];
}>;


export type UpdateMonitorMutation = { __typename?: 'Mutation', monitorSettings?: Maybe<{ __typename?: 'MonitorSettingsManagement', updateMonitor?: Maybe<boolean> }> };

export type UpdateAnalyzerMutationVariables = Exact<{
  modelId: Scalars['String'];
  config: Scalars['String'];
  analyzerId: Scalars['String'];
}>;


export type UpdateAnalyzerMutation = { __typename?: 'Mutation', monitorSettings?: Maybe<{ __typename?: 'MonitorSettingsManagement', updateAnalyzer?: Maybe<boolean> }> };

export type DeleteMonitorMutationVariables = Exact<{
  datasetId: Scalars['String'];
  monitorId: Scalars['String'];
}>;


export type DeleteMonitorMutation = { __typename?: 'Mutation', monitorSettings?: Maybe<{ __typename?: 'MonitorSettingsManagement', deleteMonitor?: Maybe<boolean> }> };

export type DeleteAnalyzerMutationVariables = Exact<{
  datasetId: Scalars['String'];
  analyzerId: Scalars['String'];
}>;


export type DeleteAnalyzerMutation = { __typename?: 'Mutation', monitorSettings?: Maybe<{ __typename?: 'MonitorSettingsManagement', deleteAnalyzer?: Maybe<boolean> }> };

export type GetNotificationIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationIdsQuery = { __typename?: 'Query', user: { __typename?: 'User', organization?: Maybe<{ __typename?: 'Organization', notifications?: Maybe<{ __typename?: 'NotificationSettings', slack?: Maybe<{ __typename?: 'SlackNotificationSettings', general: { __typename?: 'GeneralNotificationSettings', id: string } }>, email?: Maybe<{ __typename?: 'EmailNotificationSettings', general: { __typename?: 'GeneralNotificationSettings', id: string } }> }> }> } };

export type GetSemengtsListQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetSemengtsListQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segments: Array<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }> }> };

export type GetAnomalyDataQueryVariables = Exact<{
  modelId: Scalars['String'];
  dailyFrom: Scalars['Float'];
  dailyTo: Scalars['Float'];
  weeklyFrom: Scalars['Float'];
  weeklyTo: Scalars['Float'];
}>;


export type GetAnomalyDataQuery = { __typename?: 'Query', monitorConfig?: Maybe<string>, daily?: Maybe<(
    { __typename?: 'Model', anomalyCounts?: Maybe<(
      { __typename?: 'AlertCategoryCounts' }
      & AnomalyCountsCommonFieldsFragment
    )> }
    & AnomalyDataAvailabilityFragment
  )>, weekly?: Maybe<(
    { __typename?: 'Model', anomalyCounts?: Maybe<(
      { __typename?: 'AlertCategoryCounts' }
      & AnomalyCountsCommonFieldsFragment
    )> }
    & AnomalyDataAvailabilityFragment
  )> };

export type AnomaliesHealthDataFragment = { __typename?: 'AnalysisResult', metric?: Maybe<AnalysisMetric>, datasetTimestamp?: Maybe<number>, monitorIds?: Maybe<Array<string>> };

export type GetIntegrationHealthInfoQueryVariables = Exact<{
  modelId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  dataHealthFrom: Scalars['Float'];
  dataHealthTo: Scalars['Float'];
}>;


export type GetIntegrationHealthInfoQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number }>, anomaliesInRange?: Maybe<Array<{ __typename?: 'AnalysisResult', analysisId?: Maybe<string> }>>, dataHealthAnomalies?: Maybe<Array<(
      { __typename?: 'AnalysisResult' }
      & AnomaliesHealthDataFragment
    )>>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }> }> };

export type GetMonitorCoverageQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetMonitorCoverageQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', monitoredCategories?: Maybe<Array<AlertCategory>> }> };

export type GetLlmMetricsListQueryVariables = Exact<{
  modelId: Scalars['String'];
  granularity?: Maybe<TimePeriod>;
  tags?: Maybe<Array<Scalars['String']>>;
}>;


export type GetLlmMetricsListQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', customMetrics?: Maybe<Array<{ __typename?: 'CustomMetric', metadata?: Maybe<{ __typename?: 'MetricSchema', tags?: Maybe<Array<string>>, queryDefinition?: Maybe<{ __typename?: 'MetricQueryDefinition', column?: Maybe<string> }> }> }>> }> };

export type ColumnAndMetricAnalysisFragment = { __typename?: 'AnalysisResult', metric?: Maybe<AnalysisMetric>, column?: Maybe<string> };

export type GetAnomaliesByColumnsQueryVariables = Exact<{
  model: Scalars['String'];
  metrics: Array<Scalars['String']>;
  dailyFrom: Scalars['Float'];
  dailyTo: Scalars['Float'];
  weeklyFrom: Scalars['Float'];
  weeklyTo: Scalars['Float'];
}>;


export type GetAnomaliesByColumnsQuery = { __typename?: 'Query', daily?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & ColumnAndMetricAnalysisFragment
  )>>, weekly?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & ColumnAndMetricAnalysisFragment
  )>> };

export type GetInputOutputHealthDataQueryVariables = Exact<{
  modelId: Scalars['String'];
  dailyFrom: Scalars['Float'];
  dailyTo: Scalars['Float'];
  weeklyFrom: Scalars['Float'];
  weeklyTo: Scalars['Float'];
}>;


export type GetInputOutputHealthDataQuery = { __typename?: 'Query', daily?: Maybe<(
    { __typename?: 'Model' }
    & InputOutputHealthDataDailyFragment
  )>, weekly?: Maybe<(
    { __typename?: 'Model' }
    & InputOutputHealthDataWeeklyFragment
  )> };

export type InputOutputHealthDataDailyFragment = { __typename?: 'Model', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }>, outputs: Array<{ __typename?: 'Feature', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }>, entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number } }>, batches: Array<{ __typename?: 'BatchMetadata', inputCount: number, outputCount: number }>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }> };

export type InputOutputHealthDataWeeklyFragment = { __typename?: 'Model', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }>, outputs: Array<{ __typename?: 'Feature', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }>, entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number } }>, batches: Array<{ __typename?: 'BatchMetadata', inputCount: number, outputCount: number }>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }> };

export type GetSegmentsDiscreteFeaturesAlertsQueryVariables = Exact<{
  modelId: Scalars['String'];
  dailyFrom: Scalars['Float'];
  dailyTo?: Maybe<Scalars['Float']>;
  weeklyFrom: Scalars['Float'];
  weeklyTo?: Maybe<Scalars['Float']>;
}>;


export type GetSegmentsDiscreteFeaturesAlertsQuery = { __typename?: 'Query', dailyAnomalyCount?: Maybe<number>, weeklyAnomalyCount?: Maybe<number>, model?: Maybe<{ __typename?: 'Model', totalSegments: number, segments: Array<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', count: number }> }> }> }> };

export type GetLlmSecureSummaryCardQueryVariables = Exact<{
  modelId?: Maybe<Scalars['String']>;
  globalRangeFrom: Scalars['Float'];
  globalRangeTo: Scalars['Float'];
  shortRangeFrom: Scalars['Float'];
  shortRangeTo: Scalars['Float'];
  midRangeFrom: Scalars['Float'];
  midRangeTo: Scalars['Float'];
}>;


export type GetLlmSecureSummaryCardQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', tracesInRange?: Maybe<{ __typename?: 'LLMTraces', totalCount: number, latestTraceTimestamp?: Maybe<number> }>, currentIssuesData?: Maybe<{ __typename?: 'LLMTraces', blockedInteractionsCount: number, violationsCount: number }>, comparedIssuesData?: Maybe<{ __typename?: 'LLMTraces', blockedInteractionsCount: number, violationsCount: number }> }> };

export type GetCustomDashboardsByUsedOnMetadataQueryVariables = Exact<{
  usedOn: Scalars['String'];
}>;


export type GetCustomDashboardsByUsedOnMetadataQuery = { __typename?: 'Query', customDashboards?: Maybe<Array<{ __typename?: 'CustomDashboard', id: string, displayName: string }>> };

export type IdDataFragment = { __typename?: 'AnalysisResult', id?: Maybe<string>, orgId?: Maybe<string>, datasetId?: Maybe<string> };

export type TimestampDataFragment = { __typename?: 'AnalysisResult', creationTimestamp?: Maybe<number>, datasetTimestamp?: Maybe<number>, mostRecentDatasetDatalakeWriteTs?: Maybe<number> };

export type DriftDataFragment = { __typename?: 'AnalysisResult', drift_metricValue?: Maybe<number>, drift_minBatchSize?: Maybe<number>, drift_threshold?: Maybe<number> };

export type AlgorithmDataFragment = { __typename?: 'AnalysisResult', algorithm?: Maybe<string>, algorithmMode?: Maybe<string> };

export type AnalyzerInfoDataFragment = { __typename?: 'AnalysisResult', analyzerType?: Maybe<string>, analyzerId?: Maybe<string>, monitorIds?: Maybe<Array<string>>, monitorDisplayName?: Maybe<string> };

export type ThresholdInfoFragment = { __typename?: 'AnalysisResult', diff_mode?: Maybe<string>, diff_threshold?: Maybe<number>, diff_metricValue?: Maybe<number>, threshold_absoluteLower?: Maybe<number>, threshold_absoluteUpper?: Maybe<number>, threshold_baselineMetricValue?: Maybe<number>, threshold_calculatedLower?: Maybe<number>, threshold_calculatedUpper?: Maybe<number>, threshold_metricValue?: Maybe<number>, threshold_factor?: Maybe<number>, threshold_minBatchSize?: Maybe<number> };

export type FrequentStringDataFragment = { __typename?: 'AnalysisResult', frequentStringComparison_operator?: Maybe<string>, frequentStringComparison_sample?: Maybe<Array<string>> };

export type ThresholdAnalysisDataFragment = (
  { __typename?: 'AnalysisResult', targetLevel?: Maybe<AnalysisTargetLevel>, column?: Maybe<string>, isAnomaly?: Maybe<boolean>, isFalseAlarm?: Maybe<boolean>, analysisId?: Maybe<string>, runId?: Maybe<string>, metric?: Maybe<AnalysisMetric>, tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }
  & IdDataFragment
  & AlgorithmDataFragment
  & AnalyzerInfoDataFragment
  & TimestampDataFragment
  & ThresholdInfoFragment
);

export type DriftAnalysisDataFragment = (
  { __typename?: 'AnalysisResult', targetLevel?: Maybe<AnalysisTargetLevel>, column?: Maybe<string>, isAnomaly?: Maybe<boolean>, isFalseAlarm?: Maybe<boolean>, analysisId?: Maybe<string>, runId?: Maybe<string>, metric?: Maybe<AnalysisMetric>, tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }
  & IdDataFragment
  & AlgorithmDataFragment
  & AnalyzerInfoDataFragment
  & TimestampDataFragment
  & DriftDataFragment
  & FrequentStringDataFragment
);

export type GetMedianAnalysisQueryVariables = Exact<{
  datasetId: Scalars['String'];
  featureId: Scalars['String'];
  anomaliesOnly: Scalars['Boolean'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetMedianAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult', comparison_expected?: Maybe<string>, comparison_observed?: Maybe<string> }
    & ThresholdAnalysisDataFragment
  )>> };

export type GetStatisticalValueAnalysisQueryVariables = Exact<{
  datasetId: Scalars['String'];
  featureId: Scalars['String'];
  anomaliesOnly: Scalars['Boolean'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetStatisticalValueAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult', comparison_expected?: Maybe<string>, comparison_observed?: Maybe<string> }
    & ThresholdAnalysisDataFragment
  )>> };

export type GetMultiFeatureDriftAnalysisQueryVariables = Exact<{
  datasetId: Scalars['String'];
  featureIds: Array<Scalars['String']>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetMultiFeatureDriftAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult', targetLevel?: Maybe<AnalysisTargetLevel>, column?: Maybe<string>, isAnomaly?: Maybe<boolean>, isFalseAlarm?: Maybe<boolean>, metric?: Maybe<AnalysisMetric> }
    & IdDataFragment
    & AlgorithmDataFragment
    & AnalyzerInfoDataFragment
    & TimestampDataFragment
    & DriftDataFragment
  )>> };

export type GetNotificationActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationActionsQuery = { __typename?: 'Query', user: { __typename?: 'User', organization?: Maybe<{ __typename?: 'Organization', notifications?: Maybe<{ __typename?: 'NotificationSettings', email?: Maybe<{ __typename?: 'EmailNotificationSettings', general: { __typename?: 'GeneralNotificationSettings', id: string, enabled: boolean } }>, slack?: Maybe<{ __typename?: 'SlackNotificationSettings', general: { __typename?: 'GeneralNotificationSettings', id: string, enabled: boolean } }>, pagerDuty?: Maybe<{ __typename?: 'PagerDutyNotificationSettings', general: { __typename?: 'GeneralNotificationSettings', id: string, enabled: boolean } }> }> }> } };

export type GetDriftAnalysisQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  anomaliesOnly: Scalars['Boolean'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetDriftAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & DriftAnalysisDataFragment
  )>> };

export type GetUniqueAnalysisQueryVariables = Exact<{
  datasetId: Scalars['String'];
  featureId: Scalars['String'];
  anomaliesOnly: Scalars['Boolean'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetUniqueAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & ThresholdAnalysisDataFragment
  )>> };

export type GetMissingAnalysisQueryVariables = Exact<{
  datasetId: Scalars['String'];
  featureId: Scalars['String'];
  anomaliesOnly: Scalars['Boolean'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetMissingAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & ThresholdAnalysisDataFragment
  )>> };

export type GetInferredDataAnalysisQueryVariables = Exact<{
  datasetId: Scalars['String'];
  featureId: Scalars['String'];
  anomaliesOnly: Scalars['Boolean'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetInferredDataAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult', comparison_expected?: Maybe<string>, comparison_observed?: Maybe<string> }
    & ThresholdAnalysisDataFragment
  )>> };

export type GetMonitorAnomalyCountQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetMonitorAnomalyCountQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', anomalies?: Maybe<Array<{ __typename?: 'AnalysisResult', analyzerId?: Maybe<string>, isFalseAlarm?: Maybe<boolean> }>> }> };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', user: { __typename?: 'User', name?: Maybe<string>, auth0Id?: Maybe<string>, whyLabsId?: Maybe<string>, isAuthenticated: boolean, role?: Maybe<MembershipRole>, permissions?: Maybe<Array<Permission>>, email?: Maybe<string>, emailVerified?: Maybe<boolean>, metadata?: Maybe<{ __typename?: 'UserMetadata', masquerade?: Maybe<{ __typename?: 'Masquerade', isActiveNow: boolean, expiration?: Maybe<string> }> }>, organization?: Maybe<{ __typename?: 'Organization', id: string, name?: Maybe<string>, subscriptionTier?: Maybe<SubscriptionTier>, isAWSMarketplace: boolean, membershipType?: Maybe<MembershipType>, anonymousSession?: Maybe<{ __typename?: 'AnonymousSession', enabled: boolean, valid: boolean }> }> } };

export type ConfusionMatrixDataFragment = { __typename?: 'BatchMetadata', timestamp: number, metrics: { __typename?: 'ModelMetrics', confusion?: Maybe<{ __typename?: 'ConfusionMatrix', labels: Array<string>, counts: Array<Array<number>> }> } };

export type FprTprDataFragment = { __typename?: 'BatchMetadata', timestamp: number, metrics: { __typename?: 'ModelMetrics', fprTpr?: Maybe<{ __typename?: 'ROC', values: Array<Array<number>> }> } };

export type RecallPrecisionDataFragment = { __typename?: 'BatchMetadata', timestamp: number, metrics: { __typename?: 'ModelMetrics', recallPrecision?: Maybe<{ __typename?: 'ROC', values: Array<Array<number>> }> } };

export type BatchCountDataFragment = { __typename?: 'BatchMetadata', timestamp: number, inputCount: number, outputCount: number };

export type PerformanceMetadataFragment = { __typename?: 'BatchMetadata', timestamp: number };

export type AlertBasicData_DataQualityEventBase_Fragment = { __typename?: 'DataQualityEventBase', id: string, datasetTimestamp: number, archetype: EventArchetype, type: EventType, datasetId: string, feature: string, metric: WhyLogsMetric, isFalseAlarm?: Maybe<boolean>, runId?: Maybe<string>, tags?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, explanation: (
    { __typename?: 'DataTypeEventExplanation' }
    & DataTypeExplanationFragment
  ) | (
    { __typename?: 'ThresholdEventExplanation' }
    & ThresholdTypeExplanationFragment
  ) | { __typename?: 'BaseEventExplanation' } };

export type AlertBasicData_DataTypeEvent_Fragment = { __typename?: 'DataTypeEvent', id: string, datasetTimestamp: number, archetype: EventArchetype, type: EventType, datasetId: string, feature: string, metric: WhyLogsMetric, isFalseAlarm?: Maybe<boolean>, runId?: Maybe<string>, tags?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, explanation: (
    { __typename?: 'DataTypeEventExplanation' }
    & DataTypeExplanationFragment
  ) };

export type AlertBasicData_ThresholdEvent_Fragment = { __typename?: 'ThresholdEvent', id: string, datasetTimestamp: number, archetype: EventArchetype, type: EventType, datasetId: string, feature: string, metric: WhyLogsMetric, isFalseAlarm?: Maybe<boolean>, runId?: Maybe<string>, tags?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, explanation: (
    { __typename?: 'ThresholdEventExplanation' }
    & ThresholdTypeExplanationFragment
  ) };

export type AlertBasicDataFragment = AlertBasicData_DataQualityEventBase_Fragment | AlertBasicData_DataTypeEvent_Fragment | AlertBasicData_ThresholdEvent_Fragment;

export type AlertSparkData_DataQualityEventBase_Fragment = { __typename?: 'DataQualityEventBase', id: string, archetype: EventArchetype, type: EventType, metric: WhyLogsMetric, isFalseAlarm?: Maybe<boolean>, datasetTimestamp: number };

export type AlertSparkData_DataTypeEvent_Fragment = { __typename?: 'DataTypeEvent', id: string, archetype: EventArchetype, type: EventType, metric: WhyLogsMetric, isFalseAlarm?: Maybe<boolean>, datasetTimestamp: number };

export type AlertSparkData_ThresholdEvent_Fragment = { __typename?: 'ThresholdEvent', id: string, archetype: EventArchetype, type: EventType, metric: WhyLogsMetric, isFalseAlarm?: Maybe<boolean>, datasetTimestamp: number };

export type AlertSparkDataFragment = AlertSparkData_DataQualityEventBase_Fragment | AlertSparkData_DataTypeEvent_Fragment | AlertSparkData_ThresholdEvent_Fragment;

export type BaselineSparkDataFragment = { __typename?: 'Feature', schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean, inferredType: FeatureType }> };

export type FeatureSparklineDataFragment = (
  { __typename?: 'FeatureSketch', id: string, booleanCount: number, integerCount: number, fractionCount: number, uniqueCount?: Maybe<{ __typename?: 'UniqueCountSummary', estimate?: Maybe<number> }>, schemaSummary?: Maybe<{ __typename?: 'SchemaSummary', inference?: Maybe<{ __typename?: 'TypeInference', type: FeatureType, ratio: number }>, typeCounts: Array<{ __typename?: 'TypeSummary', type?: Maybe<FeatureType>, count?: Maybe<number> }> }> }
  & FeatureSketchFieldsFragment
);

export type TopLevelFeatureFieldsFragment = { __typename?: 'FeatureSketch', id: string, featureName: string, booleanCount: number, integerCount: number, fractionCount: number, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullCount: number, nullRatio: number, showAsDiscrete: boolean };

export type EntropySparklineData_DataQualityEventBase_Fragment = { __typename?: 'DataQualityEventBase', id: string, feature: string, datasetTimestamp: number, explanation: { __typename?: 'DataTypeEventExplanation' } | { __typename?: 'ThresholdEventExplanation', observedValue: number } | { __typename?: 'BaseEventExplanation' } };

export type EntropySparklineData_DataTypeEvent_Fragment = { __typename?: 'DataTypeEvent', id: string, feature: string, datasetTimestamp: number, explanation: { __typename?: 'DataTypeEventExplanation' } };

export type EntropySparklineData_ThresholdEvent_Fragment = { __typename?: 'ThresholdEvent', id: string, feature: string, datasetTimestamp: number, explanation: { __typename?: 'ThresholdEventExplanation', observedValue: number } };

export type EntropySparklineDataFragment = EntropySparklineData_DataQualityEventBase_Fragment | EntropySparklineData_DataTypeEvent_Fragment | EntropySparklineData_ThresholdEvent_Fragment;

export type FeatureSketchResultFragment = { __typename?: 'FilteredFeatureSketches', results: Array<{ __typename?: 'FeatureSketch', featureName: string, numberSummary?: Maybe<{ __typename?: 'NumberSummary', histogram?: Maybe<(
        { __typename?: 'Histogram' }
        & HistogramFieldsFragment
      )> }> }> };

export type StaticProfilesSketchesFragment = { __typename?: 'ReferenceProfile', id: string, sketches?: Maybe<(
    { __typename?: 'FilteredFeatureSketches' }
    & FeatureSketchResultFragment
  )> };

export type BatchProfilesSketchesFragment = { __typename?: 'BatchMetadata', timestamp: number, sketches: (
    { __typename?: 'FilteredFeatureSketches' }
    & FeatureSketchResultFragment
  ) };

export type GetFeatureUnifiedBinsQueryVariables = Exact<{
  modelId: Scalars['String'];
  featureId: Scalars['String'];
  timestamps?: Maybe<Array<Scalars['Float']>>;
  staticProfileIds?: Maybe<Array<Scalars['String']>>;
  splitpoints?: Maybe<Array<Scalars['Float']>>;
}>;


export type GetFeatureUnifiedBinsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batches: Array<(
      { __typename?: 'BatchMetadata' }
      & BatchProfilesSketchesFragment
    )>, referenceProfiles?: Maybe<Array<(
      { __typename?: 'ReferenceProfile' }
      & StaticProfilesSketchesFragment
    )>> }> };

export type GetSegmentCountQueryVariables = Exact<{
  model: Scalars['String'];
}>;


export type GetSegmentCountQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, totalSegments: number }> };

export type GetModelBatchFrequencyQueryVariables = Exact<{
  model: Scalars['String'];
}>;


export type GetModelBatchFrequencyQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', name: string, batchFrequency: TimePeriod }> };

export type GetResourceBasicDataQueryVariables = Exact<{
  resourceId: Scalars['String'];
}>;


export type GetResourceBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', modelType: ModelType, assetCategory?: Maybe<AssetCategory> }
    & ModelCommonDataFragment
  )> };

export type GetModelCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModelCountQuery = { __typename?: 'Query', models: Array<(
    { __typename?: 'Model' }
    & ModelCommonDataFragment
  )> };

export type GetTotalAlertsByTimeQueryVariables = Exact<{
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetTotalAlertsByTimeQuery = { __typename?: 'Query', models: Array<{ __typename?: 'Model', name: string, anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }>, timeseries: Array<{ __typename?: 'GroupedAlertBatch', timestamp: number, counts: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }> }> };

export type GetModelFeaturesQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetModelFeaturesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, entitySchema?: Maybe<{ __typename?: 'EntitySchema', hasInputs: boolean, inputs?: Maybe<Array<{ __typename?: 'EntityColumnSchema', name: string }>> }> }> };

export type ListOrgAvailableResourceTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListOrgAvailableResourceTagsQuery = { __typename?: 'Query', user: { __typename?: 'User', organization?: Maybe<{ __typename?: 'Organization', availableResourceTags?: Maybe<Array<{ __typename?: 'CustomTag', backgroundColor?: Maybe<string>, color?: Maybe<string>, value: string, key: string }>> }> } };

export type GetModelOverviewInformationQueryVariables = Exact<{
  from: Scalars['Float'];
  to: Scalars['Float'];
  resourceTags?: Maybe<Array<CustomTagFilter>>;
  resourceType?: Maybe<Array<ModelType>>;
  onlySecuredLLM?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type GetModelOverviewInformationQuery = { __typename?: 'Query', models: Array<(
    { __typename?: 'Model' }
    & ModelOverviewInfoFragment
  )> };

export type GetAllResourcesForOverviewPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllResourcesForOverviewPageQuery = { __typename?: 'Query', models: Array<{ __typename?: 'Model', id: string, assetCategory?: Maybe<AssetCategory>, dataLineage?: Maybe<{ __typename?: 'DataLineage', oldestProfileTimestamp?: Maybe<number> }>, resourceTags: Array<{ __typename?: 'CustomTag', backgroundColor?: Maybe<string>, color?: Maybe<string>, key: string, value: string }> }> };

export type GetLatestTimestampQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetLatestTimestampQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number>, oldestTimestamp?: Maybe<number> }> }> };

export type GetSegmentLatestTimestampQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetSegmentLatestTimestampQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number>, oldestTimestamp?: Maybe<number> }> }> }> };

export type GetSegmentProfileLineageQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetSegmentProfileLineageQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, assetCategory?: Maybe<AssetCategory>, segment?: Maybe<{ __typename?: 'Segment', dataLineage?: Maybe<{ __typename?: 'DataLineage', latestProfileTimestamp?: Maybe<number>, oldestProfileTimestamp?: Maybe<number> }> }> }> };

export type ListOrgResourcesCommonDataWithLineageQueryVariables = Exact<{ [key: string]: never; }>;


export type ListOrgResourcesCommonDataWithLineageQuery = { __typename?: 'Query', models: Array<(
    { __typename?: 'Model', modelType: ModelType, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>>, dataLineage?: Maybe<{ __typename?: 'DataLineage', latestProfileTimestamp?: Maybe<number>, oldestProfileTimestamp?: Maybe<number> }> }
    & ModelCommonDataFragment
  )> };

export type GetBatchFrequencyQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetBatchFrequencyQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod }> };

export type DiscreteDataFieldsFragment = { __typename?: 'Feature', id: string, name: string, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> };

export type FeatureDataSideTableFragment = (
  { __typename?: 'Feature', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }
  & DiscreteDataFieldsFragment
);

export type GetColumnActiveMonitorsQueryVariables = Exact<{
  resourceId: Scalars['String'];
  columnId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetColumnActiveMonitorsQuery = { __typename?: 'Query', columnMonitors: Array<Maybe<string>> };

export type GetAvailableFilteredFeaturesQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  adHocRunId?: Maybe<Scalars['String']>;
  sort?: Maybe<FilteredFeaturesSort>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetAvailableFilteredFeaturesQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number } }>, filteredFeatures: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
          { __typename?: 'Feature' }
          & FeatureDataSideTableFragment
        )> } }> }
    & ModelCommonDataFragment
  )> };

export type GetFilteredColumnNamesQueryVariables = Exact<{
  model: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  includeFeatures: Scalars['Boolean'];
  includeOutputs: Scalars['Boolean'];
  sort?: Maybe<FilteredFeaturesSort>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetFilteredColumnNamesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', filteredFeatures: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> }, filteredOutputs: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> } }> }> };

export type GetAvailableModelFeaturesDiscreteQueryVariables = Exact<{
  model: Scalars['String'];
}>;


export type GetAvailableModelFeaturesDiscreteQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, name: string, entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number, discrete: number, nonDiscrete: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number, discrete: number, nonDiscrete: number } }> }> };

export type GetAvailableFilteredSegmentedFeaturesQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  adHocRunId?: Maybe<Scalars['String']>;
  sort?: Maybe<FilteredFeaturesSort>;
}>;


export type GetAvailableFilteredSegmentedFeaturesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', filteredFeatures: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
          { __typename?: 'Feature' }
          & FeatureDataSideTableFragment
        )> } }> }> };

export type GetAvailableFilteredOutputsQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
  adHocRunId?: Maybe<Scalars['String']>;
  sort?: Maybe<FilteredFeaturesSort>;
}>;


export type GetAvailableFilteredOutputsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number, discrete: number, nonDiscrete: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number, discrete: number, nonDiscrete: number } }>, segment?: Maybe<{ __typename?: 'Segment', filteredOutputs: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
          { __typename?: 'Feature' }
          & FeatureDataSideTableFragment
        )> } }> }> };

export type BaselineFieldsFragment = { __typename?: 'Feature', name: string, schema?: Maybe<{ __typename?: 'FeatureSchema', inferredType: FeatureType, isDiscrete: boolean }> };

export type SketchCountFieldsFragment = { __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number> };

export type GetFeatureBasicDataQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetFeatureBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', feature?: Maybe<(
      { __typename?: 'Feature', id: string, sketches: Array<(
        { __typename?: 'FeatureSketch' }
        & SketchCountFieldsFragment
      )> }
      & BaselineFieldsFragment
    )> }
    & ModelCommonDataFragment
  )> };

export type GetSegmentedSingleOutputBasicDataQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
  tags: Array<SegmentTagFilter>;
}>;


export type GetSegmentedSingleOutputBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', output?: Maybe<(
        { __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & SketchCountFieldsFragment
        )> }
        & BaselineFieldsFragment
      )> }> }
    & ModelCommonDataFragment
  )> };

export type GetMetricBasicDataQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  metricName: Scalars['String'];
}>;


export type GetMetricBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', datasetMetric?: Maybe<(
      { __typename?: 'Metric' }
      & DatasetMetricFieldsFragment
    )> }
    & ModelCommonDataFragment
  )> };

export type GetSegmentedFeatureBasicDataQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetSegmentedFeatureBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, feature?: Maybe<(
        { __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & SketchCountFieldsFragment
        )> }
        & BaselineFieldsFragment
      )> }> }
    & ModelCommonDataFragment
  )> };

export type GetSegmentedOutputBasicDataQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetSegmentedOutputBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, output?: Maybe<(
        { __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & SketchCountFieldsFragment
        )> }
        & BaselineFieldsFragment
      )> }> }
    & ModelCommonDataFragment
  )> };

export type GetSegmentedSingleFeatureBasicDataQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetSegmentedSingleFeatureBasicDataQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, feature?: Maybe<{ __typename?: 'Feature', id: string, name: string, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean, inferredType: FeatureType }>, sketches: Array<{ __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number> }> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetOutputForModelQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  filter?: Maybe<FeatureSketchFilter>;
}>;


export type GetOutputForModelQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, outputCount: number, inputCount: number, sketches: { __typename?: 'FilteredFeatureSketches', results: Array<{ __typename?: 'FeatureSketch', totalCount: number, featureName: string }> } }> }
    & ModelCommonDataFragment
  )> };

export type GetOutputForSegmentQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  filter?: Maybe<FeatureSketchFilter>;
}>;


export type GetOutputForSegmentQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, outputCount: number, inputCount: number, sketches: { __typename?: 'FilteredFeatureSketches', results: Array<{ __typename?: 'FeatureSketch', totalCount: number, featureName: string }> } }> }> }
    & ModelCommonDataFragment
  )> };

export type FeatureSketchFieldsFragment = { __typename?: 'FeatureSketch', datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullCount: number, nullRatio: number, showAsDiscrete: boolean };

export type NumberSummaryFieldsFragment = (
  { __typename?: 'NumberSummary', count?: Maybe<number>, min?: Maybe<number>, max?: Maybe<number>, mean?: Maybe<number>, stddev?: Maybe<number>, histogram?: Maybe<(
    { __typename?: 'Histogram' }
    & HistogramFieldsFragment
  )> }
  & QuantileFieldsFragment
);

export type QuantileFieldsFragment = { __typename?: 'NumberSummary', quantiles: { __typename?: 'Quantile', bins: Array<number>, counts: Array<number> } };

export type HistogramFieldsFragment = { __typename?: 'Histogram', bins: Array<number>, counts: Array<number> };

export type FullSketchFieldsFragment = (
  { __typename?: 'FeatureSketch', frequentItems: Array<(
    { __typename?: 'FrequentItem' }
    & FrequentItemUnitFieldsFragment
  )>, uniqueCount?: Maybe<(
    { __typename?: 'UniqueCountSummary' }
    & UniqueCountFieldsFragment
  )>, numberSummary?: Maybe<(
    { __typename?: 'NumberSummary' }
    & NumberSummaryFieldsFragment
    & QuantileFieldsFragment
  )>, schemaSummary?: Maybe<(
    { __typename?: 'SchemaSummary' }
    & SchemaSummaryFieldsFragment
  )> }
  & TopLevelFeatureFieldsFragment
);

export type FrequentItemUnitFieldsFragment = { __typename?: 'FrequentItem', value?: Maybe<string>, estimate?: Maybe<number> };

export type UniqueCountFieldsFragment = { __typename?: 'UniqueCountSummary', upper?: Maybe<number>, estimate?: Maybe<number>, lower?: Maybe<number> };

export type UniqueSketchFieldsFragment = { __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, uniqueRatio?: Maybe<number>, uniqueCount?: Maybe<{ __typename?: 'UniqueCountSummary', upper?: Maybe<number>, estimate?: Maybe<number>, lower?: Maybe<number> }> };

export type GetUniqueSummariesForFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetUniqueSummariesForFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
        { __typename?: 'FeatureSketch' }
        & UniqueSketchFieldsFragment
      )>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> }> }
    & ModelCommonDataFragment
  )> };

export type GetUniqueSummariesForOutputQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetUniqueSummariesForOutputQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', output?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
        { __typename?: 'FeatureSketch' }
        & UniqueSketchFieldsFragment
      )>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> }> }
    & ModelCommonDataFragment
  )> };

export type GetUniqueSummariesForSegmentedFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetUniqueSummariesForSegmentedFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & UniqueSketchFieldsFragment
        )>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetUniqueSummariesForSegmentedOutputQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetUniqueSummariesForSegmentedOutputQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, output?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & UniqueSketchFieldsFragment
        )>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> }> }> }
    & ModelCommonDataFragment
  )> };

export type QuantileSummaryFieldsFragment = { __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, showAsDiscrete: boolean, numberSummary?: Maybe<{ __typename?: 'NumberSummary', count?: Maybe<number>, min?: Maybe<number>, max?: Maybe<number>, mean?: Maybe<number>, stddev?: Maybe<number>, quantiles: { __typename?: 'Quantile', bins: Array<number>, counts: Array<number> } }> };

export type GetQuantileSummariesForMetricQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  metricName: Scalars['String'];
}>;


export type GetQuantileSummariesForMetricQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', datasetMetric?: Maybe<(
      { __typename?: 'Metric' }
      & DatasetMetricFieldsFragment
    )> }
    & ModelCommonDataFragment
  )> };

export type GetQuantileSummariesForSegmentedFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetQuantileSummariesForSegmentedFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & QuantileSummaryFieldsFragment
        )> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetQuantileSummariesForSegmentedOutputQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetQuantileSummariesForSegmentedOutputQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', output?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & QuantileSummaryFieldsFragment
        )> }> }> }
    & ModelCommonDataFragment
  )> };

export type StringLengthFieldsFragment = { __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, stringSummary?: Maybe<{ __typename?: 'StringSummary', frequentItems: Array<(
      { __typename?: 'FrequentItem' }
      & FrequentItemUnitFieldsFragment
    )>, stringLength?: Maybe<(
      { __typename?: 'NumberSummary' }
      & NumberSummaryFieldsFragment
    )> }> };

export type GetFrequentStringLengthsForFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetFrequentStringLengthsForFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
        { __typename?: 'FeatureSketch' }
        & StringLengthFieldsFragment
      )> }> }
    & ModelCommonDataFragment
  )> };

export type FrequentItemsFieldsFragment = { __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, showAsDiscrete: boolean, frequentItems: Array<{ __typename?: 'FrequentItem', value?: Maybe<string>, estimate?: Maybe<number> }> };

export type GetFrequentItemsForSegmentedFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetFrequentItemsForSegmentedFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & FrequentItemsFieldsFragment
        )>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetFrequentItemsForSegmentedOutputQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags: Array<SegmentTagFilter>;
  outputName: Scalars['String'];
}>;


export type GetFrequentItemsForSegmentedOutputQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, output?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & FrequentItemsFieldsFragment
        )>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean }> }> }> }
    & ModelCommonDataFragment
  )> };

export type SchemaFieldsFragment = { __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, schemaSummary?: Maybe<{ __typename?: 'SchemaSummary', inference?: Maybe<{ __typename?: 'TypeInference', type: FeatureType, ratio: number, count: number }>, typeCounts: Array<{ __typename?: 'TypeSummary', type?: Maybe<FeatureType>, count?: Maybe<number> }> }> };

export type SchemaSummaryFieldsFragment = { __typename?: 'SchemaSummary', inference?: Maybe<{ __typename?: 'TypeInference', type: FeatureType, ratio: number, count: number }>, typeCounts: Array<{ __typename?: 'TypeSummary', type?: Maybe<FeatureType>, count?: Maybe<number> }> };

export type SchemaEventFields_DataQualityEventBase_Fragment = { __typename?: 'DataQualityEventBase', id: string, datasetTimestamp: number, runId?: Maybe<string>, explanation: { __typename?: 'DataTypeEventExplanation', value: FeatureType, previousValue: FeatureType } | { __typename?: 'ThresholdEventExplanation' } | { __typename?: 'BaseEventExplanation' } };

export type SchemaEventFields_DataTypeEvent_Fragment = { __typename?: 'DataTypeEvent', id: string, datasetTimestamp: number, runId?: Maybe<string>, explanation: { __typename?: 'DataTypeEventExplanation', value: FeatureType, previousValue: FeatureType } };

export type SchemaEventFields_ThresholdEvent_Fragment = { __typename?: 'ThresholdEvent', id: string, datasetTimestamp: number, runId?: Maybe<string>, explanation: { __typename?: 'ThresholdEventExplanation' } };

export type SchemaEventFieldsFragment = SchemaEventFields_DataQualityEventBase_Fragment | SchemaEventFields_DataTypeEvent_Fragment | SchemaEventFields_ThresholdEvent_Fragment;

export type GetSchemasForFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetSchemasForFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
        { __typename?: 'FeatureSketch' }
        & SchemaFieldsFragment
      )> }> }
    & ModelCommonDataFragment
  )> };

export type GetSchemasForOutputQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetSchemasForOutputQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', output?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
        { __typename?: 'FeatureSketch' }
        & SchemaFieldsFragment
      )> }> }
    & ModelCommonDataFragment
  )> };

export type GetSchemasForSegmentedFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetSchemasForSegmentedFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & SchemaFieldsFragment
        )> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetSchemasForSegmentedOutputQueryVariables = Exact<{
  model: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetSchemasForSegmentedOutputQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, output?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<(
          { __typename?: 'FeatureSketch' }
          & SchemaFieldsFragment
        )> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetMissingValuesForFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetMissingValuesForFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<{ __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullCount: number, nullRatio: number }> }> }
    & ModelCommonDataFragment
  )> };

export type GetMissingValuesForOutputFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  outputName: Scalars['String'];
}>;


export type GetMissingValuesForOutputFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', output?: Maybe<{ __typename?: 'Feature', id: string, name: string, sketches: Array<{ __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullCount: number, nullRatio: number }> }> }
    & ModelCommonDataFragment
  )> };

export type NullFractionEventFields_DataQualityEventBase_Fragment = { __typename?: 'DataQualityEventBase', id: string, creationTimestamp: number, datasetTimestamp: number, runId?: Maybe<string>, tags?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, explanation: { __typename?: 'DataTypeEventExplanation' } | { __typename?: 'ThresholdEventExplanation', observedValue: number, maxThreshold?: Maybe<{ __typename?: 'EventParam', value: number, source: ValueSource }>, minThreshold?: Maybe<{ __typename?: 'EventParam', value: number, source: ValueSource }> } | { __typename?: 'BaseEventExplanation' } };

export type NullFractionEventFields_DataTypeEvent_Fragment = { __typename?: 'DataTypeEvent', id: string, creationTimestamp: number, datasetTimestamp: number, runId?: Maybe<string>, tags?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, explanation: { __typename?: 'DataTypeEventExplanation' } };

export type NullFractionEventFields_ThresholdEvent_Fragment = { __typename?: 'ThresholdEvent', id: string, creationTimestamp: number, datasetTimestamp: number, runId?: Maybe<string>, tags?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, explanation: { __typename?: 'ThresholdEventExplanation', observedValue: number, maxThreshold?: Maybe<{ __typename?: 'EventParam', value: number, source: ValueSource }>, minThreshold?: Maybe<{ __typename?: 'EventParam', value: number, source: ValueSource }> } };

export type NullFractionEventFieldsFragment = NullFractionEventFields_DataQualityEventBase_Fragment | NullFractionEventFields_DataTypeEvent_Fragment | NullFractionEventFields_ThresholdEvent_Fragment;

export type GetMissingValuesForSegmentedFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  tags: Array<SegmentTagFilter>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetMissingValuesForSegmentedFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, feature?: Maybe<{ __typename?: 'Feature', id: string, sketches: Array<{ __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullCount: number, nullRatio: number }> }> }> }
    & ModelCommonDataFragment
  )> };

export type GetMissingValuesForSegmentOutputFeatureQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags: Array<SegmentTagFilter>;
  outputName: Scalars['String'];
}>;


export type GetMissingValuesForSegmentOutputFeatureQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, output?: Maybe<{ __typename?: 'Feature', id: string, name: string, sketches: Array<{ __typename?: 'FeatureSketch', id: string, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullCount: number, nullRatio: number }> }> }> }
    & ModelCommonDataFragment
  )> };

export type DataTypeExplanationFragment = { __typename?: 'DataTypeEventExplanation', value: FeatureType, previousValue: FeatureType };

export type ThresholdTypeExplanationFragment = { __typename?: 'ThresholdEventExplanation', observedValue: number, minThreshold?: Maybe<{ __typename?: 'EventParam', value: number, source: ValueSource }>, maxThreshold?: Maybe<{ __typename?: 'EventParam', value: number, source: ValueSource }> };

export type GetSegmentAlertCountQueryVariables = Exact<{
  model?: Maybe<Scalars['String']>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags: Array<SegmentTagFilter>;
  adHocRunId?: Maybe<Scalars['String']>;
}>;


export type GetSegmentAlertCountQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }> }> };

export type UpdateWholeMonitorConfigMutationVariables = Exact<{
  datasetId: Scalars['String'];
  config: Scalars['String'];
}>;


export type UpdateWholeMonitorConfigMutation = { __typename?: 'Mutation', monitorSettings?: Maybe<{ __typename?: 'MonitorSettingsManagement', updateMonitorConfig?: Maybe<boolean> }> };

export type SetFalseAlarmMutationVariables = Exact<{
  alertId: Scalars['String'];
  isFalseAlarm?: Maybe<Scalars['Boolean']>;
}>;


export type SetFalseAlarmMutation = { __typename?: 'Mutation', setFalseAlarm: boolean };

export type AdHocMonitorMutationVariables = Exact<{
  model: Scalars['String'];
  features: Array<Scalars['String']>;
  segments?: Maybe<Array<Array<SegmentTagFilter>>>;
  monitorConfig?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
}>;


export type AdHocMonitorMutation = { __typename?: 'Mutation', adHocMonitor?: Maybe<{ __typename?: 'AdHocMonitorMutations', run: { __typename?: 'AdHocMonitorJob', runId: string, numEvents: number, columns?: Maybe<Array<string>> } }> };

export type AdhocRunStatusQueryVariables = Exact<{
  runId: Scalars['String'];
  numEvents: Scalars['Int'];
}>;


export type AdhocRunStatusQuery = { __typename?: 'Query', adhocRunStatus: JobStatus };

export type RunBackfillAnalyzersMutationVariables = Exact<{
  datasetId: Scalars['String'];
  toTimestamp: Scalars['Float'];
  fromTimestamp: Scalars['Float'];
  analyzerIds?: Maybe<Array<Scalars['String']>>;
}>;


export type RunBackfillAnalyzersMutation = { __typename?: 'Mutation', backfillAnalyzers?: Maybe<{ __typename?: 'BackfillAnalyzersMutations', triggerBackfill: { __typename?: 'BackfillAnalyzersJob', runId: string } }> };

export type CancelRequestedBackfillJobMutationVariables = Exact<{
  runId: Scalars['String'];
}>;


export type CancelRequestedBackfillJobMutation = { __typename?: 'Mutation', backfillAnalyzers?: Maybe<{ __typename?: 'BackfillAnalyzersMutations', cancelJob: BackfillJobStatus }> };

export type GetOrgBackfillJobsQueryVariables = Exact<{
  datasetId?: Maybe<Scalars['String']>;
  onlyActive?: Maybe<Scalars['Boolean']>;
  runId?: Maybe<Scalars['String']>;
}>;


export type GetOrgBackfillJobsQuery = { __typename?: 'Query', queryBackfillJobs: Array<{ __typename?: 'BackfillJobInfo', runId: string, status: BackfillJobStatus, monitorsList?: Maybe<Array<string>>, progress: number, columns: number, segments: number, datasetId: string, duration?: Maybe<{ __typename?: 'DateRange', fromTimestamp: number, toTimestamp: number }> }> };

export type AnalysisDataFragment = { __typename?: 'AnalysisResult', id?: Maybe<string>, orgId?: Maybe<string>, datasetId?: Maybe<string>, runId?: Maybe<string>, creationTimestamp?: Maybe<number>, datasetTimestamp?: Maybe<number>, targetLevel?: Maybe<AnalysisTargetLevel>, category?: Maybe<AlertCategory>, column?: Maybe<string>, comparison_expected?: Maybe<string>, comparison_observed?: Maybe<string>, algorithm?: Maybe<string>, algorithmMode?: Maybe<string>, analysisId?: Maybe<string>, analyzerId?: Maybe<string>, analyzerConfigVersion?: Maybe<number>, analyzerType?: Maybe<string>, monitorIds?: Maybe<Array<string>>, monitorDisplayName?: Maybe<string>, isAnomaly?: Maybe<boolean>, isFalseAlarm?: Maybe<boolean>, granularity?: Maybe<string>, metric?: Maybe<AnalysisMetric>, weight?: Maybe<number>, calculationRuntimeNano?: Maybe<number>, columnList_added?: Maybe<number>, columnList_removed?: Maybe<number>, diff_metricValue?: Maybe<number>, diff_mode?: Maybe<string>, diff_threshold?: Maybe<number>, drift_metricValue?: Maybe<number>, drift_minBatchSize?: Maybe<number>, drift_threshold?: Maybe<number>, frequentStringComparison_operator?: Maybe<string>, frequentStringComparison_sample?: Maybe<Array<string>>, mostRecentDatasetDatalakeWriteTs?: Maybe<number>, threshold_absoluteLower?: Maybe<number>, threshold_absoluteUpper?: Maybe<number>, threshold_baselineMetricValue?: Maybe<number>, threshold_calculatedLower?: Maybe<number>, threshold_calculatedUpper?: Maybe<number>, threshold_metricValue?: Maybe<number>, threshold_factor?: Maybe<number>, threshold_minBatchSize?: Maybe<number>, failureType?: Maybe<string>, failureExplanation?: Maybe<string>, traceIds?: Maybe<Array<string>>, analyzerTags?: Maybe<Array<string>>, disableTargetRollup?: Maybe<boolean>, tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> };

export type GetAnalysisResultsQueryVariables = Exact<{
  anomaliesOnly: Scalars['Boolean'];
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  metrics?: Maybe<Array<AnalysisMetric>>;
  analyzerTypes?: Maybe<Array<Scalars['String']>>;
  columns?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetAnalysisResultsQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & AnalysisDataFragment
  )>> };

export type GetPaginatedAnalysisResultsQueryVariables = Exact<{
  anomaliesOnly: Scalars['Boolean'];
  includeFailed?: Maybe<Scalars['Boolean']>;
  includeUnhelpful?: Maybe<Scalars['Boolean']>;
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  metrics?: Maybe<Array<AnalysisMetric>>;
  analyzerTypes?: Maybe<Array<Scalars['String']>>;
  columns?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  analyzerIDs?: Maybe<Array<Scalars['String']>>;
  monitorIDs?: Maybe<Array<Scalars['String']>>;
  analysisIDs?: Maybe<Array<Scalars['String']>>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortDirection: SortDirection;
}>;


export type GetPaginatedAnalysisResultsQuery = { __typename?: 'Query', paginatedAnalysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & AnalysisDataFragment
  )>> };

export type GetAnomalyCountQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  analyzerIDs?: Maybe<Array<Scalars['String']>>;
  monitorIDs?: Maybe<Array<Scalars['String']>>;
}>;


export type GetAnomalyCountQuery = { __typename?: 'Query', anomalyCount?: Maybe<Array<{ __typename?: 'AnomalyCount', timestamp: number, anomalyCount: number, resultCount: number }>> };

export type GetAnomalyCountByAnalyzerQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  analyzerIDs: Array<Scalars['String']>;
}>;


export type GetAnomalyCountByAnalyzerQuery = { __typename?: 'Query', anomalyCountByAnalyzer?: Maybe<Array<{ __typename?: 'AnalyzerAnomalyCount', analyzerId: string, anomalyCount: number, failedCount: number, resultCount: number }>> };

export type AnalyzerRunDataFragment = { __typename?: 'AnalyzerRunResult', datasetId?: Maybe<string>, runCompleted?: Maybe<number>, anomalyCount?: Maybe<number>, columnCount?: Maybe<number>, failureTypes?: Maybe<Array<string>>, analyzerId?: Maybe<string>, monitorIds?: Maybe<Array<string>>, runId?: Maybe<string> };

export type GetAnalyzerRunsQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  analyzerId?: Maybe<Scalars['String']>;
  monitorId?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortDirection?: Maybe<SortDirection>;
}>;


export type GetAnalyzerRunsQuery = { __typename?: 'Query', analyzerRuns?: Maybe<Array<(
    { __typename?: 'AnalyzerRunResult' }
    & AnalyzerRunDataFragment
  )>> };

export type GetAnalyzerRunCountQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  analyzerId?: Maybe<Scalars['String']>;
  monitorId?: Maybe<Scalars['String']>;
}>;


export type GetAnalyzerRunCountQuery = { __typename?: 'Query', runCount?: Maybe<{ __typename?: 'AnalyzerRunCountResult', count?: Maybe<number> }> };

export type MetricResultFragment = { __typename?: 'MetricResult', datasetId: string, metric: AnalysisMetric, feature: string, segment?: Maybe<Array<{ __typename?: 'SegmentTag', key: string, value: string }>>, points: Array<(
    { __typename?: 'FloatDataPoint' }
    & MetricResultPointFragment
  )> };

export type MetricResultPointFragment = { __typename?: 'FloatDataPoint', timestamp: number, value: number };

export type GetMetricDataQueryVariables = Exact<{
  from: Scalars['Float'];
  to: Scalars['Float'];
  queries: Array<MetricQuery>;
  granularity?: Maybe<TimePeriod>;
  segmentTags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetMetricDataQuery = { __typename?: 'Query', dataQueries?: Maybe<{ __typename?: 'DataInvestigator', getMetricData?: Maybe<Array<(
      { __typename?: 'MetricResult' }
      & MetricResultFragment
    )>> }> };

export type GetMetricDataRollupQueryVariables = Exact<{
  from: Scalars['Float'];
  to: Scalars['Float'];
  queries: Array<MetricQuery>;
}>;


export type GetMetricDataRollupQuery = { __typename?: 'Query', dataQueries?: Maybe<{ __typename?: 'DataInvestigator', getMetricData?: Maybe<Array<(
      { __typename?: 'MetricResult' }
      & MetricResultFragment
    )>> }> };

export type GetBatchDateRangeQueryVariables = Exact<{
  modelId: Scalars['String'];
  timestamps: Array<Scalars['Float']>;
}>;


export type GetBatchDateRangeQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchDateRanges: Array<{ __typename?: 'DateRange', fromTimestamp: number, toTimestamp: number }> }> };

export type MetricSchemaFragment = { __typename?: 'MetricSchema', name?: Maybe<AnalysisMetric>, label: string, dataType: MetricDataType, showAsPercent?: Maybe<boolean>, unitInterval?: Maybe<boolean>, source: MetricSource, metricKind?: Maybe<MetricKind>, metricDirection?: Maybe<MetricDirection>, tags?: Maybe<Array<string>>, bounds?: Maybe<{ __typename?: 'MetricBounds', upper?: Maybe<AnalysisMetric>, lower?: Maybe<AnalysisMetric> }>, queryDefinition?: Maybe<{ __typename?: 'MetricQueryDefinition', targetLevel: AnalysisTargetLevel, column?: Maybe<string>, metric: AnalysisMetric }> };

export type GetAvailableMetricsQueryVariables = Exact<{
  modelType?: Maybe<ModelType>;
  datasetId?: Maybe<Scalars['String']>;
  metricTags?: Maybe<Array<Scalars['String']>>;
}>;


export type GetAvailableMetricsQuery = { __typename?: 'Query', dataQueries?: Maybe<{ __typename?: 'DataInvestigator', availableMetrics: Array<(
      { __typename?: 'MetricSchema' }
      & MetricSchemaFragment
    )> }> };

export type FeatureWeightsFragment = { __typename?: 'Feature', name: string, weight?: Maybe<{ __typename?: 'FeatureWeight', value?: Maybe<number>, rank?: Maybe<number> }> };

export type GetFeatureWeightsQueryVariables = Exact<{
  id: Scalars['String'];
  by: FeatureSortBy;
  direction: SortDirection;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureFilter;
}>;


export type GetFeatureWeightsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', name: string, weightMetadata?: Maybe<{ __typename?: 'ModelWeightMetadata', hasWeights: boolean }>, filteredFeatures: { __typename?: 'FilteredFeatures', results: Array<(
        { __typename?: 'Feature' }
        & FeatureWeightsFragment
      )> } }> };

export type GetFeatureWeightsCardInfoQueryVariables = Exact<{
  id: Scalars['String'];
  filter: FeatureFilter;
}>;


export type GetFeatureWeightsCardInfoQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', name: string, weightMetadata?: Maybe<{ __typename?: 'ModelWeightMetadata', hasWeights: boolean, lastUpdatedAt?: Maybe<number> }>, filteredFeatures: { __typename?: 'FilteredFeatures', results: Array<(
        { __typename?: 'Feature' }
        & FeatureWeightsFragment
      )> } }> };

export type GetSegmentKeysQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetSegmentKeysQuery = { __typename?: 'Query', searchSegmentKeys?: Maybe<Array<string>> };

export type GetSegmentValueForKeyQueryVariables = Exact<{
  modelId: Scalars['String'];
  key: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetSegmentValueForKeyQuery = { __typename?: 'Query', searchSegmentValues?: Maybe<Array<string>> };

export type GetIntegrationCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIntegrationCardsQuery = { __typename?: 'Query', integrationCards?: Maybe<Array<{ __typename?: 'IntegrationCard', category?: Maybe<string>, coming_soon?: Maybe<boolean>, description?: Maybe<string>, logo?: Maybe<string>, title?: Maybe<string>, url?: Maybe<string> }>> };

export type GetResourceDataQueryVariables = Exact<{
  resourceId: Scalars['String'];
}>;


export type GetResourceDataQuery = { __typename?: 'Query', resource?: Maybe<{ __typename?: 'Model', id: string, name: string, batchFrequency: TimePeriod, category?: Maybe<AssetCategory>, type: ModelType, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', oldestTimestamp?: Maybe<number>, latestTimestamp?: Maybe<number> }> }> };

export type GetResourceModelTypeQueryVariables = Exact<{
  resourceId: Scalars['String'];
}>;


export type GetResourceModelTypeQuery = { __typename?: 'Query', resource?: Maybe<{ __typename?: 'Model', type: ModelType, category?: Maybe<AssetCategory> }> };

export type GetUserFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFlagsQuery = { __typename?: 'Query', featureFlags?: Maybe<Array<{ __typename?: 'FeatureFlag', key: string, value: boolean }>> };

export type ListAllOrgsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type ListAllOrgsQuery = { __typename?: 'Query', admin?: Maybe<{ __typename?: 'InternalAdminInfo', organizations: Array<{ __typename?: 'Organization', id: string, name?: Maybe<string>, emailDomains?: Maybe<Array<string>>, subscriptionTier?: Maybe<SubscriptionTier> }> }> };

export type ListUsersForOrgQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type ListUsersForOrgQuery = { __typename?: 'Query', admin?: Maybe<{ __typename?: 'InternalAdminInfo', organization?: Maybe<{ __typename?: 'Organization', id: string, members?: Maybe<Array<{ __typename?: 'Member', userId: string, orgId: string, email: string, role: MembershipRole }>> }> }> };

export type ListMembershipsForUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ListMembershipsForUserQuery = { __typename?: 'Query', admin?: Maybe<{ __typename?: 'InternalAdminInfo', memberships: Array<{ __typename?: 'Member', userId: string, orgId: string, role: MembershipRole, email: string }> }> };

export type RemoveAllMembershipsMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RemoveAllMembershipsMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', memberships?: Maybe<{ __typename?: 'InternalMembershipManagement', clear: boolean }> }> };

export type ListModelsForOrgQueryVariables = Exact<{
  orgId: Scalars['String'];
  search?: Maybe<Scalars['String']>;
}>;


export type ListModelsForOrgQuery = { __typename?: 'Query', admin?: Maybe<{ __typename?: 'InternalAdminInfo', models: Array<{ __typename?: 'Model', id: string, name: string, modelType: ModelType }> }> };

export type CheckEmailVerifiedQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailVerifiedQuery = { __typename?: 'Query', admin?: Maybe<{ __typename?: 'InternalAdminInfo', checkAuth0Verified: boolean }> };

export type ImpersonateUserMutationVariables = Exact<{
  userId: Scalars['String'];
  durationMinutes: Scalars['Int'];
}>;


export type ImpersonateUserMutation = { __typename?: 'Mutation', impersonation?: Maybe<{ __typename?: 'InternalImpersonationManagement', impersonate?: Maybe<boolean> }> };

export type ClearImpersonationMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearImpersonationMutation = { __typename?: 'Mutation', clearImpersonation?: Maybe<boolean> };

export type NewModelMutationVariables = Exact<{
  orgId: Scalars['String'];
  name: Scalars['String'];
  modelType: ModelType;
}>;


export type NewModelMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', models?: Maybe<{ __typename?: 'InternalModelManagement', create: { __typename?: 'Model', id: string } }> }> };

export type NewOrgMutationVariables = Exact<{
  name: Scalars['String'];
  emailDomains?: Maybe<Array<Scalars['String']>>;
}>;


export type NewOrgMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', organizations?: Maybe<{ __typename?: 'InternalOrganizationManagement', create: { __typename?: 'Organization', id: string, name?: Maybe<string> } }> }> };

export type AddMemberMutationVariables = Exact<{
  orgId: Scalars['String'];
  email: Scalars['String'];
  role: MembershipRole;
}>;


export type AddMemberMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', memberships?: Maybe<{ __typename?: 'InternalMembershipManagement', add: { __typename?: 'Member', userId: string } }> }> };

export type InternalUpdateMemberMutationVariables = Exact<{
  orgId: Scalars['String'];
  email: Scalars['String'];
  role: MembershipRole;
}>;


export type InternalUpdateMemberMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', memberships?: Maybe<{ __typename?: 'InternalMembershipManagement', update: { __typename?: 'Member', userId: string } }> }> };

export type RemoveMemberMutationVariables = Exact<{
  orgId: Scalars['String'];
  email: Scalars['String'];
}>;


export type RemoveMemberMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', memberships?: Maybe<{ __typename?: 'InternalMembershipManagement', remove: { __typename?: 'Member', userId: string } }> }> };

export type SetMemberDefaultOrgMutationVariables = Exact<{
  orgId: Scalars['String'];
  email: Scalars['String'];
}>;


export type SetMemberDefaultOrgMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', memberships?: Maybe<{ __typename?: 'InternalMembershipManagement', setDefaultOrg: boolean }> }> };

export type ListEmailDomainsForOrgQueryVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type ListEmailDomainsForOrgQuery = { __typename?: 'Query', admin?: Maybe<{ __typename?: 'InternalAdminInfo', organization?: Maybe<{ __typename?: 'Organization', id: string, emailDomains?: Maybe<Array<string>> }> }> };

export type UpdateEmailDomainsMutationVariables = Exact<{
  orgId: Scalars['String'];
  emailDomains: Array<Scalars['String']>;
}>;


export type UpdateEmailDomainsMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', organization?: Maybe<{ __typename?: 'InternalOrganizationUpdate', updateEmailDomains: { __typename?: 'Organization', emailDomains?: Maybe<Array<string>> } }> }> };

export type UpdateSubscriptionTierMutationVariables = Exact<{
  orgId: Scalars['String'];
  subscriptionTier: SubscriptionTier;
}>;


export type UpdateSubscriptionTierMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', organization?: Maybe<{ __typename?: 'InternalOrganizationUpdate', updateSubscriptionTier: { __typename?: 'Organization', subscriptionTier?: Maybe<SubscriptionTier> } }> }> };

export type GetMaintenanceBannerForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaintenanceBannerForAdminQuery = { __typename?: 'Query', maintenanceBanner?: Maybe<{ __typename?: 'MaintenanceBanner', message: string, updatedAt: string, updatedBy: string }> };

export type UpdateMaintenanceBarMutationVariables = Exact<{
  message: Scalars['String'];
}>;


export type UpdateMaintenanceBarMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', global?: Maybe<{ __typename?: 'InternalGlobalManagement', updateMaintenanceBanner: boolean }> }> };

export type ClearMaintenanceBarMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearMaintenanceBarMutation = { __typename?: 'Mutation', admin?: Maybe<{ __typename?: 'PlatformAdministration', global?: Maybe<{ __typename?: 'InternalGlobalManagement', clearMaintenanceBanner: boolean }> }> };

export type ResendVerificationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendVerificationEmailMutation = { __typename?: 'Mutation', emailVerification?: Maybe<{ __typename?: 'EmailVerificationManagement', resendVerificationEmail?: Maybe<boolean> }> };

export type CheckAuth0EmailVerificationQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAuth0EmailVerificationQuery = { __typename?: 'Query', user: { __typename?: 'User', auth0Id?: Maybe<string>, auth0EmailVerified?: Maybe<boolean>, email?: Maybe<string> } };

export type GetModelValuesForFeatureCardsQueryVariables = Exact<{
  modelId: Scalars['String'];
  featureId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetModelValuesForFeatureCardsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, feature?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', showAsDiscrete: boolean, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, numberSummary?: Maybe<(
          { __typename?: 'NumberSummary' }
          & NumberSummaryFieldsFragment
        )> }> }> }> };

export type GetTotalCountQueryVariables = Exact<{
  modelId: Scalars['String'];
  featureId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetTotalCountQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, feature?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number }> }> }> };

export type GetSketchesForTotalCountCardQueryVariables = Exact<{
  modelId: Scalars['String'];
  columnName: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  isOutput?: Maybe<Scalars['Boolean']>;
}>;


export type GetSketchesForTotalCountCardQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', feature?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number }> }>, output?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number }> }> }> }> };

export type GetSegmentedTotalCountQueryVariables = Exact<{
  modelId: Scalars['String'];
  featureId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetSegmentedTotalCountQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', feature?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number }> }> }> }> };

export type GetSketchesForSingleValuesCardQueryVariables = Exact<{
  modelId: Scalars['String'];
  columnName: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  isOutput?: Maybe<Scalars['Boolean']>;
}>;


export type GetSketchesForSingleValuesCardQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', feature?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', showAsDiscrete: boolean, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, numberSummary?: Maybe<(
            { __typename?: 'NumberSummary' }
            & NumberSummaryFieldsFragment
          )> }> }>, output?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', showAsDiscrete: boolean, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, numberSummary?: Maybe<(
            { __typename?: 'NumberSummary' }
            & NumberSummaryFieldsFragment
          )> }> }> }> }> };

export type GetSegmentValuesForFeatureCardsQueryVariables = Exact<{
  modelId: Scalars['String'];
  featureId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetSegmentValuesForFeatureCardsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', feature?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', showAsDiscrete: boolean, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, numberSummary?: Maybe<(
            { __typename?: 'NumberSummary' }
            & NumberSummaryFieldsFragment
          )> }> }> }> }> };

export type GetOutputValuesForFeatureCardQueryVariables = Exact<{
  modelId: Scalars['String'];
  outputId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetOutputValuesForFeatureCardQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, output?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', showAsDiscrete: boolean, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, numberSummary?: Maybe<(
          { __typename?: 'NumberSummary' }
          & NumberSummaryFieldsFragment
        )> }> }> }> };

export type GetOutputValuesForSegmentFeatureCardQueryVariables = Exact<{
  modelId: Scalars['String'];
  outputId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetOutputValuesForSegmentFeatureCardQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', output?: Maybe<{ __typename?: 'Feature', name: string, sketches: Array<{ __typename?: 'FeatureSketch', showAsDiscrete: boolean, datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, numberSummary?: Maybe<(
            { __typename?: 'NumberSummary' }
            & NumberSummaryFieldsFragment
          )> }> }> }> }> };

export type SendGraphFeedbackMutationVariables = Exact<{
  feedback: Feedback;
  submitAnonymously: Scalars['Boolean'];
}>;


export type SendGraphFeedbackMutation = { __typename?: 'Mutation', submitFeedback: boolean };

export type ResourceBasicInfoFragment = { __typename?: 'Model', name: string, id: string, batchFrequency: TimePeriod, category?: Maybe<AssetCategory>, type: ModelType };

export type GetAllResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllResourcesQuery = { __typename?: 'Query', resources: Array<(
    { __typename?: 'Model' }
    & ResourceBasicInfoFragment
  )> };

export type MetricSchemaInfoFragment = { __typename?: 'MetricSchema', label: string, dataType: MetricDataType, unitInterval?: Maybe<boolean>, metricKind?: Maybe<MetricKind>, metricDirection?: Maybe<MetricDirection>, showAsPercent?: Maybe<boolean>, tags?: Maybe<Array<string>>, description?: Maybe<string>, bounds?: Maybe<{ __typename?: 'MetricBounds', upper?: Maybe<AnalysisMetric>, lower?: Maybe<AnalysisMetric> }>, queryDefinition?: Maybe<{ __typename?: 'MetricQueryDefinition', metric: AnalysisMetric, targetLevel: AnalysisTargetLevel, metricLabel?: Maybe<string>, column?: Maybe<string> }> };

export type MetricDataFragment = { __typename?: 'CustomMetric', id: string, datasetGranularity: TimePeriod, datasetId: string, name: string, segmentTags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, metadata?: Maybe<(
    { __typename?: 'MetricSchema' }
    & MetricSchemaInfoFragment
  )>, distributionValues?: Maybe<Array<{ __typename?: 'DistributionValue', timestamp: number, frequentItems?: Maybe<Array<(
      { __typename?: 'FrequentItem' }
      & FrequentItemUnitFieldsFragment
    )>>, numberSummary?: Maybe<(
      { __typename?: 'NumberSummary' }
      & NumberSummaryFieldsFragment
    )> }>>, numericValues?: Maybe<Array<{ __typename?: 'MetricValue', timestamp: number, value: number }>> };

export type GetMetricsByTagQueryVariables = Exact<{
  resourceId: Scalars['String'];
  tags: Array<Scalars['String']>;
  segmentTags?: Maybe<Array<SegmentTagFilter>>;
  granularity: TimePeriod;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetMetricsByTagQuery = { __typename?: 'Query', resource?: Maybe<{ __typename?: 'Model', id: string, name: string, batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, customMetrics?: Maybe<Array<(
        { __typename?: 'CustomMetric' }
        & MetricDataFragment
      )>> }>, customMetrics?: Maybe<Array<(
      { __typename?: 'CustomMetric' }
      & MetricDataFragment
    )>> }> };

export type GetProfileTimestampsQueryVariables = Exact<{
  modelId?: Maybe<Scalars['String']>;
  timestamps: Array<Scalars['Float']>;
}>;


export type GetProfileTimestampsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchDateRanges: Array<{ __typename?: 'DateRange', fromTimestamp: number, toTimestamp: number }> }> };

export type GetFrequentItemsAnalysisQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetFrequentItemsAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult' }
    & LlmFrequentItemsAnalysisDataFragment
  )>> };

export type LlmCommonAnalysisDataFragment = { __typename?: 'AnalysisResult', id?: Maybe<string>, isAnomaly?: Maybe<boolean>, isFalseAlarm?: Maybe<boolean>, metric?: Maybe<AnalysisMetric>, analyzerId?: Maybe<string>, analysisId?: Maybe<string>, monitorIds?: Maybe<Array<string>>, monitorDisplayName?: Maybe<string>, analyzerType?: Maybe<string>, datasetTimestamp?: Maybe<number>, creationTimestamp?: Maybe<number>, tags: Array<{ __typename?: 'SegmentTag', value: string, key: string }> };

export type LlmFrequentItemsAnalysisDataFragment = (
  { __typename?: 'AnalysisResult', algorithm?: Maybe<string>, algorithmMode?: Maybe<string>, drift_metricValue?: Maybe<number>, drift_threshold?: Maybe<number>, frequentStringComparison_operator?: Maybe<string>, frequentStringComparison_sample?: Maybe<Array<string>> }
  & LlmCommonAnalysisDataFragment
);

export type GetContinuousMetricsAnalysisQueryVariables = Exact<{
  model: Scalars['String'];
  feature: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  metrics?: Maybe<Array<AnalysisMetric>>;
  adhocRunId?: Maybe<Scalars['String']>;
}>;


export type GetContinuousMetricsAnalysisQuery = { __typename?: 'Query', analysisResults?: Maybe<Array<(
    { __typename?: 'AnalysisResult', comparison_expected?: Maybe<string>, comparison_observed?: Maybe<string> }
    & ThresholdAnalysisDataFragment
  )>> };

export type GetInsightsQueryVariables = Exact<{
  model: Scalars['String'];
  batchProfile?: Maybe<Scalars['Float']>;
  refProfileId?: Maybe<Scalars['String']>;
  tags: Array<SegmentTagFilter>;
}>;


export type GetInsightsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', insights?: Maybe<Array<{ __typename?: 'InsightEntry', name: string }>> }> };

export type SubmitSecureTrialFormMutationVariables = Exact<{
  form: SecureForm;
}>;


export type SubmitSecureTrialFormMutation = { __typename?: 'Mutation', submitSecureForm: boolean };

export type GetLlmTracesInfoQueryVariables = Exact<{
  modelId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
}>;


export type GetLlmTracesInfoQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', tracesSummary?: Maybe<{ __typename?: 'LLMTraces', hasTraces: boolean }> }> };

export type DatasetMetricsFieldsFragment = { __typename?: 'Metric', id: string, datasetGranularity: TimePeriod, datasetId: string, name: string, segmentTags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, values: Array<{ __typename?: 'MetricValue', timestamp: number, value: number }> };

export type GetMetricDetailPanelDataQueryVariables = Exact<{
  datasetId: Scalars['String'];
  metricName: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetMetricDetailPanelDataQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, name: string, datasetMetric?: Maybe<(
      { __typename?: 'Metric' }
      & DatasetMetricsFieldsFragment
    )> }> };

export type GetOutputFeaturesForSideTableQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  adHocRunId?: Maybe<Scalars['String']>;
}>;


export type GetOutputFeaturesForSideTableQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, name: string, outputs: Array<(
      { __typename?: 'Feature' }
      & FeatureDataSideTableFragment
    )> }> };

export type GetAllModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllModelsQuery = { __typename?: 'Query', models: Array<(
    { __typename?: 'Model' }
    & ModelCommonDataFragment
    & LatestAlertOnModelFragment
    & ModelMetricsNameFragment
  )> };

export type MetricValueFieldsFragment = { __typename?: 'MetricValue', timestamp: number, value: number };

export type DatasetMetricFieldsFragment = { __typename?: 'Metric', name: string, values: Array<(
    { __typename?: 'MetricValue' }
    & MetricValueFieldsFragment
  )> };

export type ModelPerformanceFragment = { __typename?: 'Model', datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string, metadata?: Maybe<{ __typename?: 'MetricSchema', source: MetricSource, showAsPercent?: Maybe<boolean>, queryDefinition?: Maybe<{ __typename?: 'MetricQueryDefinition', metric: AnalysisMetric, column?: Maybe<string> }> }>, values: Array<{ __typename?: 'MetricValue', timestamp: number, value: number }> }>>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }>, batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, inputCount: number, outputCount: number, metrics: { __typename?: 'ModelMetrics', calibration?: Maybe<{ __typename?: 'CalibrationCurve', values: Array<Array<Maybe<number>>> }>, confusion?: Maybe<{ __typename?: 'ConfusionMatrix', labels: Array<string>, counts: Array<Array<number>> }>, recallPrecision?: Maybe<{ __typename?: 'ROC', values: Array<Array<number>> }>, fprTpr?: Maybe<{ __typename?: 'ROC', values: Array<Array<number>> }> } }> };

export type SegmentPerformanceFragment = { __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string, metadata?: Maybe<{ __typename?: 'MetricSchema', source: MetricSource, showAsPercent?: Maybe<boolean>, queryDefinition?: Maybe<{ __typename?: 'MetricQueryDefinition', metric: AnalysisMetric, column?: Maybe<string> }> }>, values: Array<{ __typename?: 'MetricValue', timestamp: number, value: number }> }>>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }>, batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, inputCount: number, outputCount: number, metrics: { __typename?: 'ModelMetrics', calibration?: Maybe<{ __typename?: 'CalibrationCurve', values: Array<Array<Maybe<number>>> }>, confusion?: Maybe<{ __typename?: 'ConfusionMatrix', labels: Array<string>, counts: Array<Array<number>> }>, recallPrecision?: Maybe<{ __typename?: 'ROC', values: Array<Array<number>> }>, fprTpr?: Maybe<{ __typename?: 'ROC', values: Array<Array<number>> }> } }> };

export type GetModelPerformanceQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetModelPerformanceQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', id: string, name: string, modelType: ModelType, batchFrequency: TimePeriod }
    & ModelPerformanceFragment
  )> };

export type GetModelPerformanceCardDataAvailabilityQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  granularity?: Maybe<TimePeriod>;
}>;


export type GetModelPerformanceCardDataAvailabilityQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', modelType: ModelType, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number> }>, timeseries?: Maybe<Array<(
      { __typename?: 'Metric' }
      & DatasetMetricFieldsFragment
    )>> }> };

export type GetModelPerformanceRollupQueryVariables = Exact<{
  datasetId: Scalars['String'];
  dailyFrom: Scalars['Float'];
  dailyTo: Scalars['Float'];
  weeklyFrom: Scalars['Float'];
  weeklyTo: Scalars['Float'];
}>;


export type GetModelPerformanceRollupQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', rollup?: Maybe<Array<{ __typename?: 'Metric', name: string, oneDay: Array<(
        { __typename?: 'MetricValue' }
        & MetricValueFieldsFragment
      )>, oneWeek: Array<(
        { __typename?: 'MetricValue' }
        & MetricValueFieldsFragment
      )> }>> }> };

export type FeatureTableInfoFragment = { __typename?: 'Feature', name: string, id: string, anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }>, timeseries: Array<{ __typename?: 'GroupedAlertBatch', timestamp: number, counts: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number, metric?: Maybe<AnalysisMetric> }> }> }>, sketches: Array<{ __typename?: 'FeatureSketch', datasetTimestamp?: Maybe<number>, lastUploadTimestamp?: Maybe<number>, totalCount: number, nullRatio: number, schemaSummary?: Maybe<{ __typename?: 'SchemaSummary', inference?: Maybe<{ __typename?: 'TypeInference', count: number }>, typeCounts: Array<{ __typename?: 'TypeSummary', type?: Maybe<FeatureType>, count?: Maybe<number> }> }>, uniqueCount?: Maybe<{ __typename?: 'UniqueCountSummary', estimate?: Maybe<number> }> }>, schema?: Maybe<{ __typename?: 'FeatureSchema', isDiscrete: boolean, inferredType: FeatureType }> };

export type OutputTableQueryVariables = Exact<{
  datasetId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  sort?: Maybe<FilteredFeaturesSort>;
  text?: Maybe<Scalars['String']>;
  includeDiscrete?: Maybe<Scalars['Boolean']>;
  includeNonDiscrete?: Maybe<Scalars['Boolean']>;
  anomalyCategories?: Maybe<Array<AlertCategory>>;
}>;


export type OutputTableQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, weightMetadata?: Maybe<{ __typename?: 'ModelWeightMetadata', hasWeights: boolean }>, filteredOutputs: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
        { __typename?: 'Feature' }
        & FeatureTableInfoFragment
      )> } }> };

export type GetOutputNamesQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetOutputNamesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', outputs: Array<{ __typename?: 'Feature', name: string }> }> };

export type GetDataExistenceInformationQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetDataExistenceInformationQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', totalSegments: number, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>>, entitySchema?: Maybe<{ __typename?: 'EntitySchema', hasInputs: boolean, hasOutputs: boolean, hasColumns: boolean }> }> };

export type GetModelAvailablePerformanceMetricsQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetModelAvailablePerformanceMetricsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>> }> };

export type FeatureTableQueryVariables = Exact<{
  datasetId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  includeDiscrete?: Maybe<Scalars['Boolean']>;
  includeNonDiscrete?: Maybe<Scalars['Boolean']>;
  anomalyCategories?: Maybe<Array<AlertCategory>>;
  sort?: Maybe<FilteredFeaturesSort>;
}>;


export type FeatureTableQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, weightMetadata?: Maybe<{ __typename?: 'ModelWeightMetadata', hasWeights: boolean }>, filteredFeatures: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
        { __typename?: 'Feature', weight?: Maybe<{ __typename?: 'FeatureWeight', value?: Maybe<number> }> }
        & FeatureTableInfoFragment
      )> } }> };

export type BatchProfileFragment = { __typename?: 'BatchMetadata', timestamp: number };

export type ReferenceProfileFragment = { __typename?: 'ReferenceProfile', id: string, alias: string, datasetId: string, uploadTimestamp?: Maybe<number>, datasetTimestamp?: Maybe<number> };

export type GetModelProfilesQueryVariables = Exact<{
  modelId: Scalars['String'];
  from?: Maybe<Scalars['Float']>;
  to?: Maybe<Scalars['Float']>;
}>;


export type GetModelProfilesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, id: string, modelType: ModelType, name: string, batches: Array<(
      { __typename?: 'BatchMetadata' }
      & BatchProfileFragment
    )>, referenceProfiles?: Maybe<Array<(
      { __typename?: 'ReferenceProfile' }
      & ReferenceProfileFragment
    )>> }> };

export type GetModelProfilesForMonitorConfigQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetModelProfilesForMonitorConfigQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number }> }> };

export type MetricRollupFragment = { __typename?: 'MetricRollupResult', datasetId: string, metric: AnalysisMetric, feature: string, segmentGroup: string, value: number };

export type GetSegmentMetricDataRollupQueryVariables = Exact<{
  from: Scalars['Float'];
  to: Scalars['Float'];
  segmentKey: Scalars['String'];
  queries: Array<MetricQuery>;
}>;


export type GetSegmentMetricDataRollupQuery = { __typename?: 'Query', dataQueries?: Maybe<{ __typename?: 'DataInvestigator', getSegmentMetricDataRollup?: Maybe<Array<(
      { __typename?: 'MetricRollupResult' }
      & MetricRollupFragment
    )>> }> };

export type SearchSegmentKeysQueryVariables = Exact<{
  datasetId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  searchString?: Maybe<Scalars['String']>;
  limitSpec?: Maybe<LimitSpec>;
}>;


export type SearchSegmentKeysQuery = { __typename?: 'Query', searchSegmentKeys?: Maybe<Array<string>> };

export type GetModelDataAvailabilityQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetModelDataAvailabilityQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', dataAvailability?: Maybe<{ __typename?: 'DataAvailability', hasData?: Maybe<boolean> }> }> };

export type GetSingleModelProfileQueryVariables = Exact<{
  modelId: Scalars['String'];
  datasetTimestamp: Scalars['Float'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureSketchFilter;
}>;


export type GetSingleModelProfileQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batch?: Maybe<{ __typename?: 'BatchMetadata', sketches: { __typename?: 'FilteredFeatureSketches', results: Array<(
          { __typename?: 'FeatureSketch' }
          & FullSketchFieldsFragment
        )> } }> }> };

export type ProfilesSketchFieldsFragment = { __typename?: 'FilteredFeatureSketches', totalCount: number, results: Array<(
    { __typename?: 'FeatureSketch' }
    & FullSketchFieldsFragment
  )> };

export type GetModelSelectedProfilesQueryVariables = Exact<{
  modelId: Scalars['String'];
  timestamps: Array<Scalars['Float']>;
  staticProfileIds?: Maybe<Array<Scalars['String']>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter: FeatureSketchFilter;
  retrievalTokens: Array<Scalars['String']>;
  allowIndividualProfiles?: Maybe<Scalars['Boolean']>;
}>;


export type GetModelSelectedProfilesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, sketches: (
        { __typename?: 'FilteredFeatureSketches' }
        & ProfilesSketchFieldsFragment
      ) }>, referenceProfiles?: Maybe<Array<{ __typename?: 'ReferenceProfile', id: string, sketches?: Maybe<(
        { __typename?: 'FilteredFeatureSketches' }
        & ProfilesSketchFieldsFragment
      )> }>>, individualProfiles?: Maybe<Array<{ __typename?: 'IndividualProfile', retrievalToken: string, sketches?: Maybe<(
        { __typename?: 'FilteredFeatureSketches' }
        & ProfilesSketchFieldsFragment
      )> }>>, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>>, outputs: Array<{ __typename?: 'Feature', name: string }> }> };

export type GetModelColumnsCountsQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetModelColumnsCountsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', discrete: number, nonDiscrete: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', discrete: number, nonDiscrete: number } }> }> };

export type GetFeaturesAndOutputsQueryVariables = Exact<{
  modelId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetFeaturesAndOutputsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', outputs: Array<{ __typename?: 'Feature', sketches: Array<{ __typename?: 'FeatureSketch', featureName: string, totalCount: number }> }>, features: Array<{ __typename?: 'Feature', sketches: Array<{ __typename?: 'FeatureSketch', featureName: string, totalCount: number }> }> }> };

export type GetFilteredFeaturesWithNameQueryVariables = Exact<{
  modelId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  featureFilter: FeatureFilter;
}>;


export type GetFilteredFeaturesWithNameQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', filteredFeatures: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> }, filteredOutputs: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> } }> };

export type GetAllFilteredFeaturesOutputMetricNamesQueryVariables = Exact<{
  modelId: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  featuresOffset?: Maybe<Scalars['Int']>;
  outputsOffset?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  featureFilter: FeatureFilter;
}>;


export type GetAllFilteredFeaturesOutputMetricNamesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', filteredFeatures: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> }, filteredOutputs: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> }, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>> }> };

export type GetAnomaliesForSpecificProfileQueryVariables = Exact<{
  modelId: Scalars['String'];
  filter: AnalysisFilter;
  sort: SortDirection;
}>;


export type GetAnomaliesForSpecificProfileQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', anomalies?: Maybe<Array<{ __typename?: 'AnalysisResult', datasetId?: Maybe<string>, datasetTimestamp?: Maybe<number>, metric?: Maybe<AnalysisMetric>, analyzerType?: Maybe<string>, threshold_absoluteLower?: Maybe<number>, threshold_absoluteUpper?: Maybe<number>, threshold_calculatedLower?: Maybe<number>, threshold_calculatedUpper?: Maybe<number>, threshold_metricValue?: Maybe<number>, diff_metricValue?: Maybe<number>, diff_threshold?: Maybe<number>, drift_metricValue?: Maybe<number>, drift_threshold?: Maybe<number>, column?: Maybe<string>, tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }>> }> };

export type GetModelsFeatureNamesQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetModelsFeatureNamesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', name: string, features: Array<{ __typename?: 'Feature', id: string, name: string }> }> };

export type GetModelReferenceProfilesForMonitorConfigQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetModelReferenceProfilesForMonitorConfigQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', referenceProfiles?: Maybe<Array<{ __typename?: 'ReferenceProfile', uploadTimestamp?: Maybe<number>, alias: string, id: string }>> }> };

export type GetComparisonSegmentsQueryVariables = Exact<{
  datasetId: Scalars['String'];
}>;


export type GetComparisonSegmentsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segments: Array<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }> }> };

export type SegmentListingFieldsFragment = { __typename?: 'Segment', id: string, tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, batches: Array<{ __typename?: 'BatchMetadata', inputCount: number, outputCount: number, timestamp: number }>, anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', timeseries: Array<{ __typename?: 'GroupedAlertBatch', timestamp: number, counts: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }> };

export type SegmentsListingTableQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<SegmentSort>;
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type SegmentsListingTableQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', totalFilteredSegments: number, segments: Array<(
      { __typename?: 'Segment' }
      & SegmentListingFieldsFragment
    )> }
    & ModelCommonDataFragment
  )> };

export type GetMergedSegmentQueryVariables = Exact<{
  model: Scalars['String'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  tags: Array<SegmentTagFilter>;
}>;


export type GetMergedSegmentQuery = { __typename?: 'Query', model?: Maybe<(
    { __typename?: 'Model', segment?: Maybe<(
      { __typename?: 'Segment' }
      & SegmentListingFieldsFragment
    )> }
    & ModelCommonDataFragment
  )> };

export type ProvisionOrgMutationVariables = Exact<{
  orgName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
}>;


export type ProvisionOrgMutation = { __typename?: 'Mutation', provisionOrganization: boolean };

export type GetResourceBasicInfoForOrgQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResourceBasicInfoForOrgQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Model', id: string, assetCategory?: Maybe<AssetCategory>, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', latestTimestamp?: Maybe<number>, oldestTimestamp?: Maybe<number> }> }> };

export type GetResourceBasicCategoryInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResourceBasicCategoryInfoQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Model', id: string, name: string, assetCategory?: Maybe<AssetCategory>, modelType: ModelType }> };

export type GetResourceCoverageInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResourceCoverageInfoQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Model', id: string, name: string, assetCategory?: Maybe<AssetCategory>, modelType: ModelType, monitoredCategories?: Maybe<Array<AlertCategory>> }> };

export type GetResourceBatchesCountQueryVariables = Exact<{
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  skipInputs?: Maybe<Scalars['Boolean']>;
  skipOutputs?: Maybe<Scalars['Boolean']>;
  skipTimestamps?: Maybe<Scalars['Boolean']>;
}>;


export type GetResourceBatchesCountQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Model', assetCategory?: Maybe<AssetCategory>, batches: Array<{ __typename?: 'BatchMetadata', inputCount: number, outputCount: number, timestamp: number }> }> };

export type GetResourceBasicInfoWithAnomaliesQueryVariables = Exact<{
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetResourceBasicInfoWithAnomaliesQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Model', id: string, name: string, assetCategory?: Maybe<AssetCategory>, modelType: ModelType, allAnomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number, metric?: Maybe<AnalysisMetric> }> }> }> };

export type GetResourceAnomaliesTimeSeriesDataQueryVariables = Exact<{
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetResourceAnomaliesTimeSeriesDataQuery = { __typename?: 'Query', resources: Array<{ __typename?: 'Model', id: string, name: string, assetCategory?: Maybe<AssetCategory>, modelType: ModelType, allAnomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number, metric?: Maybe<AnalysisMetric> }>, timeseries: Array<{ __typename?: 'GroupedAlertBatch', timestamp: number, counts: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number, metric?: Maybe<AnalysisMetric> }> }> }> }> };

export type ResourcesExecutiveTableInfoFragment = { __typename?: 'Model', id: string, name: string, assetCategory?: Maybe<AssetCategory>, modelType: ModelType, entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', total: number } }>, allAnomalyCounts?: Maybe<(
    { __typename?: 'AlertCategoryCounts' }
    & ResourceAnomalyCountTimeseriesFragment
  )>, batches: Array<{ __typename?: 'BatchMetadata', inputCount: number }>, metrics?: Maybe<Array<{ __typename?: 'Metric', name: string, values: Array<(
      { __typename?: 'MetricValue' }
      & MetricValueFieldsFragment
    )> }>>, freshness?: Maybe<{ __typename?: 'DataAvailability', oldestTimestamp?: Maybe<number>, latestTimestamp?: Maybe<number> }> };

export type GetResourcesTableInfoQueryVariables = Exact<{
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetResourcesTableInfoQuery = { __typename?: 'Query', resources: Array<(
    { __typename?: 'Model' }
    & ResourcesExecutiveTableInfoFragment
  )> };

export type GetSegmentOutputFeaturesQueryVariables = Exact<{
  model: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  adHocRunId?: Maybe<Scalars['String']>;
}>;


export type GetSegmentOutputFeaturesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', id: string, outputs: Array<(
        { __typename?: 'Feature' }
        & FeatureDataSideTableFragment
      )> }> }> };

export type GetSegmentCountAndSegmentsForModelQueryVariables = Exact<{
  modelId: Scalars['String'];
}>;


export type GetSegmentCountAndSegmentsForModelQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segments: Array<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }> }> };

export type GetAllSegmentsQueryVariables = Exact<{
  dataset: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
}>;


export type GetAllSegmentsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segments: Array<{ __typename?: 'Segment', tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }>, anomalyCounts?: Maybe<{ __typename?: 'AlertCategoryCounts', totals: Array<{ __typename?: 'AlertCategoryCount', category: AlertCategory, count: number }> }> }> }> };

export type GetSegmentPerformanceQueryVariables = Exact<{
  datasetId: Scalars['String'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetSegmentPerformanceQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', id: string, name: string, batchFrequency: TimePeriod, modelType: ModelType, segment?: Maybe<(
      { __typename?: 'Segment' }
      & SegmentPerformanceFragment
    )> }> };

export type SegmentFeaturesTableQueryVariables = Exact<{
  datasetId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  includeDiscrete?: Maybe<Scalars['Boolean']>;
  includeNonDiscrete?: Maybe<Scalars['Boolean']>;
  anomalyCategories?: Maybe<Array<AlertCategory>>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  sort?: Maybe<FilteredFeaturesSort>;
}>;


export type SegmentFeaturesTableQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, weightMetadata?: Maybe<{ __typename?: 'ModelWeightMetadata', hasWeights: boolean }>, segment?: Maybe<{ __typename?: 'Segment', filteredFeatures: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
          { __typename?: 'Feature', weight?: Maybe<{ __typename?: 'FeatureWeight', value?: Maybe<number> }> }
          & FeatureTableInfoFragment
        )> } }> }> };

export type SegmentOutputsTableQueryVariables = Exact<{
  datasetId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  from: Scalars['Float'];
  to?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  sort?: Maybe<FilteredFeaturesSort>;
}>;


export type SegmentOutputsTableQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', filteredOutputs: { __typename?: 'FilteredFeatures', totalCount: number, results: Array<(
          { __typename?: 'Feature' }
          & FeatureTableInfoFragment
        )> } }> }> };

export type GetSegmentProfilesQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  from: Scalars['Float'];
  to: Scalars['Float'];
}>;


export type GetSegmentProfilesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', batches: Array<(
        { __typename?: 'BatchMetadata' }
        & BatchProfileFragment
      )>, referenceProfiles?: Maybe<Array<(
        { __typename?: 'ReferenceProfile' }
        & ReferenceProfileFragment
      )>> }> }> };

export type GetSegmentSelectedProfilesQueryVariables = Exact<{
  modelId: Scalars['String'];
  timestamps: Array<Scalars['Float']>;
  staticProfileIds?: Maybe<Array<Scalars['String']>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<FeatureSketchFilter>;
  tags?: Maybe<Array<SegmentTagFilter>>;
  retrievalTokens: Array<Scalars['String']>;
  allowIndividualProfiles?: Maybe<Scalars['Boolean']>;
  excludeEmpty?: Maybe<Scalars['Boolean']>;
}>;


export type GetSegmentSelectedProfilesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', batches: Array<{ __typename?: 'BatchMetadata', timestamp: number, sketches: (
          { __typename?: 'FilteredFeatureSketches' }
          & ProfilesSketchFieldsFragment
        ) }>, referenceProfiles?: Maybe<Array<{ __typename?: 'ReferenceProfile', id: string, alias: string, sketches?: Maybe<(
          { __typename?: 'FilteredFeatureSketches' }
          & ProfilesSketchFieldsFragment
        )> }>>, individualProfiles?: Maybe<Array<{ __typename?: 'IndividualProfile', retrievalToken: string, sketches?: Maybe<(
          { __typename?: 'FilteredFeatureSketches' }
          & ProfilesSketchFieldsFragment
        )> }>>, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>>, outputs: Array<{ __typename?: 'Feature', name: string }> }> }> };

export type GetModelSegmentColumnCountsQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetModelSegmentColumnCountsQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', batchFrequency: TimePeriod, segment?: Maybe<{ __typename?: 'Segment', entitySchema?: Maybe<{ __typename?: 'EntitySchema', inputCounts: { __typename?: 'EntitySchemaColumnCounts', discrete: number, nonDiscrete: number }, outputCounts: { __typename?: 'EntitySchemaColumnCounts', discrete: number, nonDiscrete: number } }> }> }> };

export type GetSegmentedFilteredFeaturesQueryVariables = Exact<{
  modelId: Scalars['String'];
  tags?: Maybe<Array<SegmentTagFilter>>;
  featuresOffset?: Maybe<Scalars['Int']>;
  outputsOffset?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  from: Scalars['Float'];
  to: Scalars['Float'];
  featureFilter: FeatureFilter;
}>;


export type GetSegmentedFilteredFeaturesQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', filteredFeatures: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> }, filteredOutputs: { __typename?: 'FilteredFeatures', results: Array<{ __typename?: 'Feature', name: string }> }, datasetMetrics?: Maybe<Array<{ __typename?: 'Metric', name: string }>> }> }> };

export type GetAnomaliesForSpecificSegmentedProfileQueryVariables = Exact<{
  modelId: Scalars['String'];
  filter: AnalysisFilter;
  sort: SortDirection;
  tags?: Maybe<Array<SegmentTagFilter>>;
}>;


export type GetAnomaliesForSpecificSegmentedProfileQuery = { __typename?: 'Query', model?: Maybe<{ __typename?: 'Model', segment?: Maybe<{ __typename?: 'Segment', anomalies?: Maybe<Array<{ __typename?: 'AnalysisResult', datasetId?: Maybe<string>, datasetTimestamp?: Maybe<number>, metric?: Maybe<AnalysisMetric>, analyzerType?: Maybe<string>, threshold_absoluteLower?: Maybe<number>, threshold_absoluteUpper?: Maybe<number>, threshold_calculatedLower?: Maybe<number>, threshold_calculatedUpper?: Maybe<number>, threshold_metricValue?: Maybe<number>, diff_metricValue?: Maybe<number>, diff_threshold?: Maybe<number>, drift_metricValue?: Maybe<number>, drift_threshold?: Maybe<number>, column?: Maybe<string>, tags: Array<{ __typename?: 'SegmentTag', key: string, value: string }> }>> }> }> };

export type GetAllAccessTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAccessTokensQuery = { __typename?: 'Query', accessTokens: Array<{ __typename?: 'AccessTokenMetadata', name: string, id: string, userId: string, createdAt: number, expiresAt?: Maybe<number>, scopes: Array<string>, isRevoked?: Maybe<boolean> }> };

export type GenerateNewAccessTokenMutationVariables = Exact<{
  tokenName: Scalars['String'];
  expiresAt?: Maybe<Scalars['Float']>;
  scopes?: Maybe<Array<RequestableTokenScope>>;
}>;


export type GenerateNewAccessTokenMutation = { __typename?: 'Mutation', accessToken: { __typename?: 'AccessTokenManagement', generate: { __typename?: 'AccessTokenMetadata', name: string, id: string, userId: string, createdAt: number, expiresAt?: Maybe<number>, scopes: Array<string>, secret?: Maybe<string> } } };

export type RevokeAccessTokenMutationVariables = Exact<{
  tokenId: Scalars['String'];
  userId: Scalars['String'];
}>;


export type RevokeAccessTokenMutation = { __typename?: 'Mutation', accessToken: { __typename?: 'AccessTokenManagement', revoke: { __typename?: 'AccessTokenMetadata', id: string } } };

export type GetProjectDataForIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectDataForIntegrationsQuery = { __typename?: 'Query', models: Array<{ __typename?: 'Model', id: string, name: string, modelType: ModelType, dataAvailability?: Maybe<{ __typename?: 'DataAvailability', hasData?: Maybe<boolean> }> }> };

export type GetAllModelsForSettingsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllModelsForSettingsPageQuery = { __typename?: 'Query', models: Array<{ __typename?: 'Model', name: string, id: string, type: ModelType, timePeriod: TimePeriod }> };

export type CreateBulkSettingsPageMutationVariables = Exact<{
  modelName: Scalars['String'];
  quantityNum: Scalars['Int'];
  modelType: ModelType;
  timePeriod?: Maybe<TimePeriod>;
}>;


export type CreateBulkSettingsPageMutation = { __typename?: 'Mutation', models?: Maybe<{ __typename?: 'ModelManagement', createBulk: Array<{ __typename?: 'Model', id: string, name: string }> }> };

export type DeleteModelForSettingsPageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteModelForSettingsPageMutation = { __typename?: 'Mutation', models?: Maybe<{ __typename?: 'ModelManagement', delete: { __typename?: 'Model', id: string, name: string } }> };

export type UpdateModelMutationVariables = Exact<{
  model: ModelUpdateParams;
}>;


export type UpdateModelMutation = { __typename?: 'Mutation', models?: Maybe<{ __typename?: 'ModelManagement', update: { __typename?: 'Model', name: string } }> };

export type BulkUpdateModelsMutationVariables = Exact<{
  models: Array<ModelUpdateParams>;
}>;


export type BulkUpdateModelsMutation = { __typename?: 'Mutation', models?: Maybe<{ __typename?: 'ModelManagement', bulkUpdate: Array<{ __typename?: 'Model', name: string }> }> };

export type GetGlobalActionIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalActionIdsQuery = { __typename?: 'Query', globalActions: { __typename?: 'GlobalActionsFetching', listGlobalActions?: Maybe<Array<{ __typename?: 'GenericNotificationAction', id: string, enabled: boolean }>> } };

export type GetModelsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModelsInfoQuery = { __typename?: 'Query', models: Array<{ __typename?: 'Model', name: string, modelType: ModelType, id: string }> };

export type ActionDetailsFragment = { __typename: 'GenericNotificationAction', id: string, enabled: boolean, type: ActionType, createdAt?: Maybe<number>, updatedAt?: Maybe<number>, references?: Maybe<Array<Maybe<{ __typename?: 'NotificationRelationshipItem', itemId?: Maybe<string>, datasetId?: Maybe<string>, itemDisplayName?: Maybe<string>, type?: Maybe<NotificationRelationshipType> }>>>, payload?: Maybe<{ __typename: 'SlackAction', slackWebhook: string } | { __typename: 'EmailAction', email: string } | { __typename: 'PagerDutyAction', pagerDutyKey: string } | { __typename: 'MSTeamsAction', webhook: string } | { __typename: 'CustomWebhookAction', url: string, method: string, body?: Maybe<string>, headers?: Maybe<Array<{ __typename?: 'HeaderTuple', key: string, value: string }>> } | { __typename: 'UnknownAction', value?: Maybe<string> }> };

export type GetGlobalActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalActionsQuery = { __typename?: 'Query', globalActions: { __typename?: 'GlobalActionsFetching', listGlobalActions?: Maybe<Array<(
      { __typename?: 'GenericNotificationAction' }
      & ActionDetailsFragment
    )>> } };

export type FindGlobalActionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindGlobalActionQuery = { __typename?: 'Query', globalActions: { __typename?: 'GlobalActionsFetching', findGlobalAction?: Maybe<(
      { __typename?: 'GenericNotificationAction' }
      & ActionDetailsFragment
    )> } };

export type UpdateGlobalActionMutationVariables = Exact<{
  id: Scalars['String'];
  type: ActionType;
  payload: Scalars['String'];
}>;


export type UpdateGlobalActionMutation = { __typename?: 'Mutation', globalActions: { __typename?: 'GlobalActionsManagement', updateGlobalAction?: Maybe<boolean> } };

export type CreateGlobalActionMutationVariables = Exact<{
  id: Scalars['String'];
  type: ActionType;
  payload: Scalars['String'];
}>;


export type CreateGlobalActionMutation = { __typename?: 'Mutation', globalActions: { __typename?: 'GlobalActionsManagement', createGlobalAction?: Maybe<boolean> } };

export type DeleteGlobalActionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteGlobalActionMutation = { __typename?: 'Mutation', globalActions: { __typename?: 'GlobalActionsManagement', deleteGlobalAction?: Maybe<boolean> } };

export type DisableGlobalActionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DisableGlobalActionMutation = { __typename?: 'Mutation', globalActions: { __typename?: 'GlobalActionsManagement', disableGlobalAction?: Maybe<boolean> } };

export type EnableGlobalActionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnableGlobalActionMutation = { __typename?: 'Mutation', globalActions: { __typename?: 'GlobalActionsManagement', enableGlobalAction?: Maybe<boolean> } };

export type TestNotificationActionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type TestNotificationActionMutation = { __typename?: 'Mutation', globalActions: { __typename?: 'GlobalActionsManagement', testGlobalAction?: Maybe<boolean> } };

export type GetUsersOrganizationMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersOrganizationMembersQuery = { __typename?: 'Query', user: { __typename?: 'User', auth0Id?: Maybe<string>, name?: Maybe<string>, isAuthenticated: boolean, organization?: Maybe<{ __typename?: 'Organization', id: string, name?: Maybe<string>, members?: Maybe<Array<{ __typename?: 'Member', userId: string, role: MembershipRole, email: string }>> }> } };

export type JoinedOrganizationFragment = { __typename?: 'OrganizationMemberMetadata', orgId: string, name: string, membershipType?: Maybe<MembershipType> };

export type GetUserOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserOrganizationsQuery = { __typename?: 'Query', user: { __typename?: 'User', auth0Id?: Maybe<string>, name?: Maybe<string>, isAuthenticated: boolean, joinedOrganizations?: Maybe<Array<(
      { __typename?: 'OrganizationMemberMetadata' }
      & JoinedOrganizationFragment
    )>> } };

export type SelectDefaultOrganizationMutationVariables = Exact<{
  orgId: Scalars['String'];
}>;


export type SelectDefaultOrganizationMutation = { __typename?: 'Mutation', memberships?: Maybe<{ __typename?: 'MembershipManagement', setDefaultOrganization?: Maybe<boolean> }> };

export type AddUserToOrganisationMutationVariables = Exact<{
  email: Scalars['String'];
  role?: Maybe<MembershipRole>;
}>;


export type AddUserToOrganisationMutation = { __typename?: 'Mutation', memberships?: Maybe<{ __typename?: 'MembershipManagement', add?: Maybe<{ __typename?: 'Member', userId: string }> }> };

export type AddMultipleUsersToOrganisationMutationVariables = Exact<{
  emails: Array<Scalars['String']>;
  role?: Maybe<MembershipRole>;
}>;


export type AddMultipleUsersToOrganisationMutation = { __typename?: 'Mutation', memberships?: Maybe<{ __typename?: 'MembershipManagement', bulkAdd?: Maybe<Array<{ __typename?: 'Member', email: string }>> }> };

export type UpdateMemberMutationVariables = Exact<{
  email: Scalars['String'];
  role: MembershipRole;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', memberships?: Maybe<{ __typename?: 'MembershipManagement', update?: Maybe<{ __typename?: 'Member', userId: string }> }> };

export type RemoveMemberFromOrgMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RemoveMemberFromOrgMutation = { __typename?: 'Mutation', memberships?: Maybe<{ __typename?: 'MembershipManagement', remove?: Maybe<{ __typename?: 'Member', email: string }> }> };

export type ClaimSessionMutationVariables = Exact<{
  sessionToken: Scalars['String'];
}>;


export type ClaimSessionMutation = { __typename?: 'Mutation', claimSession: boolean };

export const InsightSketchesFragmentDoc = gql`
    fragment InsightSketches on FilteredFeatureSketches {
  totalCount
  totalDiscrete
  totalNonDiscrete
  results {
    id
    featureName
    trueCount
    booleanCount
    integerCount
    fractionCount
    createdAt
    totalCount
    nullCount
    nullRatio
    showAsDiscrete
    frequentItems {
      value
      estimate
      upper
    }
    uniqueCount {
      estimate
      upper
      lower
    }
    numberSummary {
      histogram {
        bins
        counts
      }
      quantiles {
        bins
        counts
      }
      count
      min
      max
      mean
      stddev
    }
    schemaSummary {
      inference {
        type
        ratio
        count
      }
      typeCounts {
        type
        count
      }
    }
  }
}
    `;
export const ModelInfoForCodeHelpersFragmentDoc = gql`
    fragment ModelInfoForCodeHelpers on Model {
  totalSegments
}
    `;
export const MetricMetadataFragmentDoc = gql`
    fragment MetricMetadata on MetricSchema {
  queryDefinition {
    column
    metric
  }
  source
  tags
}
    `;
export const DatasetMetricsInfoFragmentDoc = gql`
    fragment DatasetMetricsInfo on Model {
  datasetMetrics {
    name
    metadata {
      ...MetricMetadata
    }
  }
}
    ${MetricMetadataFragmentDoc}`;
export const MonitorCommonFeaturesFieldsFragmentDoc = gql`
    fragment MonitorCommonFeaturesFields on Feature {
  id
  name
  schema {
    isDiscrete
  }
}
    `;
export const MonitorInputFeaturesFragmentDoc = gql`
    fragment MonitorInputFeatures on Feature {
  weight {
    value
  }
  ...MonitorCommonFeaturesFields
}
    ${MonitorCommonFeaturesFieldsFragmentDoc}`;
export const AnomalyCountsCommonFieldsFragmentDoc = gql`
    fragment AnomalyCountsCommonFields on AlertCategoryCounts {
  totals {
    category
    count
  }
}
    `;
export const AnomalyDataAvailabilityFragmentDoc = gql`
    fragment AnomalyDataAvailability on Model {
  dataAvailability {
    latestTimestamp
  }
}
    `;
export const ModelCommonDataFragmentDoc = gql`
    fragment ModelCommonData on Model {
  id
  name
  batchFrequency
}
    `;
export const LatestAlertOnModelFragmentDoc = gql`
    fragment LatestAlertOnModel on Model {
  latestAnomalyTimestamp
}
    `;
export const OverviewTimeSeriesFragmentDoc = gql`
    fragment OverviewTimeSeries on GroupedAlertBatch {
  timestamp
  counts {
    category
    count
  }
}
    `;
export const ResourceAnomalyCountTimeseriesFragmentDoc = gql`
    fragment ResourceAnomalyCountTimeseries on AlertCategoryCounts {
  timeseries {
    ...OverviewTimeSeries
  }
}
    ${OverviewTimeSeriesFragmentDoc}`;
export const ModelOverviewInfoFragmentDoc = gql`
    fragment ModelOverviewInfo on Model {
  ...ModelCommonData
  modelType
  creationTime
  tracesSummary(fromTimestamp: $from, toTimestamp: $to) {
    hasTraces
  }
  referenceProfiles {
    id
  }
  assetCategory
  entitySchema {
    inputCounts {
      total
    }
    outputCounts {
      total
    }
  }
  resourceTags {
    key
    value
    color
    backgroundColor
  }
  totalSegments
  ...LatestAlertOnModel
  anomalyCounts: allAnomalyCounts(fromTimestamp: $from, toTimestamp: $to) {
    totals {
      category
      count
    }
    ...ResourceAnomalyCountTimeseries
  }
  dataAvailability {
    latestTimestamp
  }
  dataLineage {
    latestProfileTimestamp
    oldestProfileTimestamp
  }
}
    ${ModelCommonDataFragmentDoc}
${LatestAlertOnModelFragmentDoc}
${ResourceAnomalyCountTimeseriesFragmentDoc}`;
export const DataAvailabilityFragmentDoc = gql`
    fragment DataAvailability on Model {
  dataAvailability {
    oldestTimestamp
    latestTimestamp
  }
}
    `;
export const ModelMetricsNameFragmentDoc = gql`
    fragment ModelMetricsName on Model {
  modelType
  datasetMetrics {
    name
  }
}
    `;
export const AnomaliesHealthDataFragmentDoc = gql`
    fragment AnomaliesHealthData on AnalysisResult {
  metric
  datasetTimestamp
  monitorIds
}
    `;
export const ColumnAndMetricAnalysisFragmentDoc = gql`
    fragment ColumnAndMetricAnalysis on AnalysisResult {
  metric
  column
}
    `;
export const InputOutputHealthDataDailyFragmentDoc = gql`
    fragment InputOutputHealthDataDaily on Model {
  anomalyCounts(fromTimestamp: $dailyFrom, toTimestamp: $dailyTo) {
    totals {
      category
      count
    }
  }
  outputs {
    anomalyCounts(fromTimestamp: $dailyFrom, toTimestamp: $dailyTo) {
      totals {
        category
        count
      }
    }
  }
  entitySchema {
    inputCounts {
      total
    }
    outputCounts {
      total
    }
  }
  batches(from: $dailyFrom, to: $dailyTo) {
    inputCount
    outputCount
  }
  dataAvailability {
    latestTimestamp
  }
}
    `;
export const InputOutputHealthDataWeeklyFragmentDoc = gql`
    fragment InputOutputHealthDataWeekly on Model {
  anomalyCounts(fromTimestamp: $weeklyFrom, toTimestamp: $weeklyTo) {
    totals {
      category
      count
    }
  }
  outputs {
    anomalyCounts(fromTimestamp: $weeklyFrom, toTimestamp: $weeklyTo) {
      totals {
        category
        count
      }
    }
  }
  entitySchema {
    inputCounts {
      total
    }
    outputCounts {
      total
    }
  }
  batches(from: $weeklyFrom, to: $weeklyTo) {
    inputCount
    outputCount
  }
  dataAvailability {
    latestTimestamp
  }
}
    `;
export const IdDataFragmentDoc = gql`
    fragment IdData on AnalysisResult {
  id
  orgId
  datasetId
}
    `;
export const AlgorithmDataFragmentDoc = gql`
    fragment AlgorithmData on AnalysisResult {
  algorithm
  algorithmMode
}
    `;
export const AnalyzerInfoDataFragmentDoc = gql`
    fragment AnalyzerInfoData on AnalysisResult {
  analyzerType
  analyzerId
  monitorIds
  monitorDisplayName
}
    `;
export const TimestampDataFragmentDoc = gql`
    fragment TimestampData on AnalysisResult {
  creationTimestamp
  datasetTimestamp
  mostRecentDatasetDatalakeWriteTs
}
    `;
export const ThresholdInfoFragmentDoc = gql`
    fragment ThresholdInfo on AnalysisResult {
  diff_mode
  diff_threshold
  diff_metricValue
  threshold_absoluteLower
  threshold_absoluteUpper
  threshold_baselineMetricValue
  threshold_calculatedLower
  threshold_calculatedUpper
  threshold_metricValue
  threshold_factor
  threshold_minBatchSize
}
    `;
export const ThresholdAnalysisDataFragmentDoc = gql`
    fragment ThresholdAnalysisData on AnalysisResult {
  ...IdData
  targetLevel
  column
  isAnomaly
  isFalseAlarm
  analysisId
  tags {
    key
    value
  }
  runId
  metric
  ...AlgorithmData
  ...AnalyzerInfoData
  ...TimestampData
  ...ThresholdInfo
}
    ${IdDataFragmentDoc}
${AlgorithmDataFragmentDoc}
${AnalyzerInfoDataFragmentDoc}
${TimestampDataFragmentDoc}
${ThresholdInfoFragmentDoc}`;
export const DriftDataFragmentDoc = gql`
    fragment DriftData on AnalysisResult {
  drift_metricValue
  drift_minBatchSize
  drift_threshold
}
    `;
export const FrequentStringDataFragmentDoc = gql`
    fragment FrequentStringData on AnalysisResult {
  frequentStringComparison_operator
  frequentStringComparison_sample
}
    `;
export const DriftAnalysisDataFragmentDoc = gql`
    fragment DriftAnalysisData on AnalysisResult {
  ...IdData
  targetLevel
  column
  isAnomaly
  isFalseAlarm
  analysisId
  tags {
    key
    value
  }
  runId
  metric
  ...AlgorithmData
  ...AnalyzerInfoData
  ...TimestampData
  ...DriftData
  ...FrequentStringData
}
    ${IdDataFragmentDoc}
${AlgorithmDataFragmentDoc}
${AnalyzerInfoDataFragmentDoc}
${TimestampDataFragmentDoc}
${DriftDataFragmentDoc}
${FrequentStringDataFragmentDoc}`;
export const ConfusionMatrixDataFragmentDoc = gql`
    fragment ConfusionMatrixData on BatchMetadata {
  timestamp
  metrics {
    confusion {
      labels
      counts
    }
  }
}
    `;
export const FprTprDataFragmentDoc = gql`
    fragment FprTprData on BatchMetadata {
  timestamp
  metrics {
    fprTpr {
      values
    }
  }
}
    `;
export const RecallPrecisionDataFragmentDoc = gql`
    fragment RecallPrecisionData on BatchMetadata {
  timestamp
  metrics {
    recallPrecision {
      values
    }
  }
}
    `;
export const BatchCountDataFragmentDoc = gql`
    fragment BatchCountData on BatchMetadata {
  timestamp
  inputCount
  outputCount
}
    `;
export const PerformanceMetadataFragmentDoc = gql`
    fragment PerformanceMetadata on BatchMetadata {
  timestamp
}
    `;
export const ThresholdTypeExplanationFragmentDoc = gql`
    fragment thresholdTypeExplanation on ThresholdEventExplanation {
  observedValue
  minThreshold {
    value
    source
  }
  maxThreshold {
    value
    source
  }
}
    `;
export const DataTypeExplanationFragmentDoc = gql`
    fragment dataTypeExplanation on DataTypeEventExplanation {
  value
  previousValue
}
    `;
export const AlertBasicDataFragmentDoc = gql`
    fragment AlertBasicData on DataQualityEvent {
  id
  datasetTimestamp
  archetype
  type
  datasetId
  feature
  metric
  isFalseAlarm
  runId
  tags {
    key
    value
  }
  explanation {
    ...thresholdTypeExplanation
    ...dataTypeExplanation
  }
}
    ${ThresholdTypeExplanationFragmentDoc}
${DataTypeExplanationFragmentDoc}`;
export const AlertSparkDataFragmentDoc = gql`
    fragment AlertSparkData on DataQualityEvent {
  id
  archetype
  type
  metric
  isFalseAlarm
  datasetTimestamp
}
    `;
export const BaselineSparkDataFragmentDoc = gql`
    fragment BaselineSparkData on Feature {
  schema {
    isDiscrete
    inferredType
  }
}
    `;
export const FeatureSketchFieldsFragmentDoc = gql`
    fragment FeatureSketchFields on FeatureSketch {
  datasetTimestamp
  lastUploadTimestamp
  totalCount
  nullCount
  nullRatio
  showAsDiscrete
}
    `;
export const FeatureSparklineDataFragmentDoc = gql`
    fragment FeatureSparklineData on FeatureSketch {
  ...FeatureSketchFields
  id
  booleanCount
  integerCount
  fractionCount
  uniqueCount {
    estimate
  }
  schemaSummary {
    inference {
      type
      ratio
    }
    typeCounts {
      type
      count
    }
  }
}
    ${FeatureSketchFieldsFragmentDoc}`;
export const EntropySparklineDataFragmentDoc = gql`
    fragment EntropySparklineData on DataQualityEvent {
  id
  feature
  datasetTimestamp
  explanation {
    ... on ThresholdEventExplanation {
      observedValue
    }
  }
}
    `;
export const HistogramFieldsFragmentDoc = gql`
    fragment HistogramFields on Histogram {
  bins
  counts
}
    `;
export const FeatureSketchResultFragmentDoc = gql`
    fragment FeatureSketchResult on FilteredFeatureSketches {
  results {
    featureName
    numberSummary {
      histogram {
        ...HistogramFields
      }
    }
  }
}
    ${HistogramFieldsFragmentDoc}`;
export const StaticProfilesSketchesFragmentDoc = gql`
    fragment StaticProfilesSketches on ReferenceProfile {
  id
  sketches(filter: {featureName: $featureId}, histogramSplitPoints: $splitpoints) {
    ...FeatureSketchResult
  }
}
    ${FeatureSketchResultFragmentDoc}`;
export const BatchProfilesSketchesFragmentDoc = gql`
    fragment BatchProfilesSketches on BatchMetadata {
  timestamp
  sketches(filter: {featureName: $featureId}, histogramSplitPoints: $splitpoints) {
    ...FeatureSketchResult
  }
}
    ${FeatureSketchResultFragmentDoc}`;
export const DiscreteDataFieldsFragmentDoc = gql`
    fragment DiscreteDataFields on Feature {
  id
  name
  schema {
    isDiscrete
  }
}
    `;
export const FeatureDataSideTableFragmentDoc = gql`
    fragment FeatureDataSideTable on Feature {
  ...DiscreteDataFields
  anomalyCounts(fromTimestamp: $from, toTimestamp: $to, filter: {adhocRunId: $adHocRunId}) {
    totals {
      category
      count
    }
  }
}
    ${DiscreteDataFieldsFragmentDoc}`;
export const BaselineFieldsFragmentDoc = gql`
    fragment BaselineFields on Feature {
  name
  schema {
    inferredType
    isDiscrete
  }
}
    `;
export const SketchCountFieldsFragmentDoc = gql`
    fragment SketchCountFields on FeatureSketch {
  id
  datasetTimestamp
  lastUploadTimestamp
}
    `;
export const UniqueSketchFieldsFragmentDoc = gql`
    fragment UniqueSketchFields on FeatureSketch {
  id
  datasetTimestamp
  lastUploadTimestamp
  uniqueCount {
    upper
    estimate
    lower
  }
  uniqueRatio
}
    `;
export const QuantileSummaryFieldsFragmentDoc = gql`
    fragment QuantileSummaryFields on FeatureSketch {
  id
  datasetTimestamp
  lastUploadTimestamp
  showAsDiscrete
  numberSummary {
    quantiles {
      bins
      counts
    }
    count
    min
    max
    mean
    stddev
  }
}
    `;
export const FrequentItemUnitFieldsFragmentDoc = gql`
    fragment FrequentItemUnitFields on FrequentItem {
  value
  estimate
}
    `;
export const QuantileFieldsFragmentDoc = gql`
    fragment QuantileFields on NumberSummary {
  quantiles {
    bins
    counts
  }
}
    `;
export const NumberSummaryFieldsFragmentDoc = gql`
    fragment NumberSummaryFields on NumberSummary {
  count
  min
  max
  mean
  stddev
  histogram {
    ...HistogramFields
  }
  ...QuantileFields
}
    ${HistogramFieldsFragmentDoc}
${QuantileFieldsFragmentDoc}`;
export const StringLengthFieldsFragmentDoc = gql`
    fragment StringLengthFields on FeatureSketch {
  id
  datasetTimestamp
  lastUploadTimestamp
  stringSummary {
    frequentItems {
      ...FrequentItemUnitFields
    }
    stringLength {
      ...NumberSummaryFields
    }
  }
}
    ${FrequentItemUnitFieldsFragmentDoc}
${NumberSummaryFieldsFragmentDoc}`;
export const FrequentItemsFieldsFragmentDoc = gql`
    fragment FrequentItemsFields on FeatureSketch {
  id
  datasetTimestamp
  lastUploadTimestamp
  showAsDiscrete
  frequentItems {
    value
    estimate
  }
}
    `;
export const SchemaFieldsFragmentDoc = gql`
    fragment SchemaFields on FeatureSketch {
  id
  datasetTimestamp
  lastUploadTimestamp
  schemaSummary {
    inference {
      type
      ratio
      count
    }
    typeCounts {
      type
      count
    }
  }
}
    `;
export const SchemaEventFieldsFragmentDoc = gql`
    fragment SchemaEventFields on DataQualityEvent {
  id
  datasetTimestamp
  runId
  explanation {
    ... on DataTypeEventExplanation {
      value
      previousValue
    }
  }
}
    `;
export const NullFractionEventFieldsFragmentDoc = gql`
    fragment NullFractionEventFields on DataQualityEvent {
  id
  creationTimestamp
  datasetTimestamp
  runId
  tags {
    key
    value
  }
  explanation {
    ... on ThresholdEventExplanation {
      maxThreshold {
        value
        source
      }
      minThreshold {
        value
        source
      }
      observedValue
    }
  }
}
    `;
export const AnalysisDataFragmentDoc = gql`
    fragment AnalysisData on AnalysisResult {
  id
  orgId
  datasetId
  runId
  creationTimestamp
  datasetTimestamp
  targetLevel
  category
  column
  comparison_expected
  comparison_observed
  algorithm
  algorithmMode
  analysisId
  analyzerId
  analyzerConfigVersion
  analyzerType
  monitorIds
  monitorDisplayName
  isAnomaly
  isFalseAlarm
  granularity
  metric
  weight
  calculationRuntimeNano
  comparison_expected
  comparison_observed
  columnList_added
  columnList_removed
  diff_metricValue
  diff_mode
  diff_threshold
  drift_metricValue
  drift_minBatchSize
  drift_threshold
  frequentStringComparison_operator
  frequentStringComparison_sample
  mostRecentDatasetDatalakeWriteTs
  threshold_absoluteLower
  threshold_absoluteUpper
  threshold_baselineMetricValue
  threshold_calculatedLower
  threshold_calculatedUpper
  threshold_metricValue
  threshold_factor
  threshold_minBatchSize
  failureType
  failureExplanation
  tags {
    key
    value
  }
  traceIds
  analyzerTags
  disableTargetRollup
}
    `;
export const AnalyzerRunDataFragmentDoc = gql`
    fragment AnalyzerRunData on AnalyzerRunResult {
  datasetId
  runCompleted
  anomalyCount
  columnCount
  failureTypes
  analyzerId
  monitorIds
  runId
}
    `;
export const MetricResultPointFragmentDoc = gql`
    fragment MetricResultPoint on FloatDataPoint {
  timestamp
  value
}
    `;
export const MetricResultFragmentDoc = gql`
    fragment MetricResult on MetricResult {
  datasetId
  metric
  feature
  segment {
    key
    value
  }
  points {
    ...MetricResultPoint
  }
}
    ${MetricResultPointFragmentDoc}`;
export const MetricSchemaFragmentDoc = gql`
    fragment MetricSchema on MetricSchema {
  name
  label
  dataType
  showAsPercent
  unitInterval
  source
  metricKind
  metricDirection
  bounds {
    upper
    lower
  }
  queryDefinition {
    targetLevel
    column
    metric
  }
  tags
}
    `;
export const FeatureWeightsFragmentDoc = gql`
    fragment FeatureWeights on Feature {
  name
  weight {
    value
    rank
  }
}
    `;
export const ResourceBasicInfoFragmentDoc = gql`
    fragment ResourceBasicInfo on Model {
  name
  id
  category: assetCategory
  type: modelType
  batchFrequency
}
    `;
export const MetricSchemaInfoFragmentDoc = gql`
    fragment MetricSchemaInfo on MetricSchema {
  label
  dataType
  unitInterval
  metricKind
  metricDirection
  showAsPercent
  bounds {
    upper
    lower
  }
  queryDefinition {
    metric
    targetLevel
    metricLabel
    column
    metric
  }
  tags
  description
}
    `;
export const MetricDataFragmentDoc = gql`
    fragment MetricData on CustomMetric {
  id
  datasetGranularity
  datasetId
  name
  segmentTags {
    key
    value
  }
  metadata {
    ...MetricSchemaInfo
  }
  distributionValues(fromTimestamp: $from, toTimestamp: $to) {
    timestamp
    frequentItems {
      ...FrequentItemUnitFields
    }
    numberSummary {
      ...NumberSummaryFields
    }
  }
  numericValues(fromTimestamp: $from, toTimestamp: $to) {
    timestamp
    value
  }
}
    ${MetricSchemaInfoFragmentDoc}
${FrequentItemUnitFieldsFragmentDoc}
${NumberSummaryFieldsFragmentDoc}`;
export const LlmCommonAnalysisDataFragmentDoc = gql`
    fragment LLMCommonAnalysisData on AnalysisResult {
  id
  isAnomaly
  isFalseAlarm
  metric
  analyzerId
  analysisId
  monitorIds
  monitorDisplayName
  analyzerType
  datasetTimestamp
  creationTimestamp
  tags {
    value
    key
  }
}
    `;
export const LlmFrequentItemsAnalysisDataFragmentDoc = gql`
    fragment LLMFrequentItemsAnalysisData on AnalysisResult {
  algorithm
  algorithmMode
  drift_metricValue
  drift_threshold
  frequentStringComparison_operator
  frequentStringComparison_sample
  ...LLMCommonAnalysisData
}
    ${LlmCommonAnalysisDataFragmentDoc}`;
export const DatasetMetricsFieldsFragmentDoc = gql`
    fragment DatasetMetricsFields on Metric {
  id
  datasetGranularity
  datasetId
  segmentTags {
    key
    value
  }
  name
  values(fromTimestamp: $from, toTimestamp: $to) {
    timestamp
    value
  }
}
    `;
export const MetricValueFieldsFragmentDoc = gql`
    fragment MetricValueFields on MetricValue {
  timestamp
  value
}
    `;
export const DatasetMetricFieldsFragmentDoc = gql`
    fragment DatasetMetricFields on Metric {
  name
  values(fromTimestamp: $from, toTimestamp: $to) {
    ...MetricValueFields
  }
}
    ${MetricValueFieldsFragmentDoc}`;
export const ModelPerformanceFragmentDoc = gql`
    fragment ModelPerformance on Model {
  datasetMetrics {
    name
    metadata {
      source
      showAsPercent
      queryDefinition {
        metric
        column
      }
    }
    values(fromTimestamp: $from, toTimestamp: $to) {
      timestamp
      value
    }
  }
  dataAvailability {
    latestTimestamp
  }
  batches(from: $from, to: $to) {
    timestamp
    inputCount
    outputCount
    metrics {
      calibration {
        values
      }
      confusion {
        labels
        counts
      }
      recallPrecision {
        values
      }
      fprTpr {
        values
      }
    }
  }
}
    `;
export const SegmentPerformanceFragmentDoc = gql`
    fragment SegmentPerformance on Segment {
  tags {
    key
    value
  }
  datasetMetrics {
    name
    metadata {
      source
      queryDefinition {
        metric
        column
      }
      showAsPercent
    }
    values(fromTimestamp: $from, toTimestamp: $to) {
      timestamp
      value
    }
  }
  dataAvailability {
    latestTimestamp
  }
  batches(from: $from, to: $to) {
    timestamp
    inputCount
    outputCount
    metrics {
      calibration {
        values
      }
      confusion {
        labels
        counts
      }
      recallPrecision {
        values
      }
      fprTpr {
        values
      }
    }
  }
}
    `;
export const FeatureTableInfoFragmentDoc = gql`
    fragment FeatureTableInfo on Feature {
  name
  id
  anomalyCounts(fromTimestamp: $from, toTimestamp: $to, groupBy: METRIC) {
    totals {
      category
      count
    }
    timeseries {
      timestamp
      counts {
        category
        count
        metric
      }
    }
  }
  sketches(from: $from, to: $to) {
    datasetTimestamp
    lastUploadTimestamp
    schemaSummary {
      inference {
        count
      }
      typeCounts {
        type
        count
      }
    }
    totalCount
    nullRatio
    uniqueCount {
      estimate
    }
  }
  schema {
    isDiscrete
    inferredType
  }
}
    `;
export const BatchProfileFragmentDoc = gql`
    fragment BatchProfile on BatchMetadata {
  timestamp
}
    `;
export const ReferenceProfileFragmentDoc = gql`
    fragment ReferenceProfile on ReferenceProfile {
  id
  alias
  datasetId
  uploadTimestamp
  datasetTimestamp
}
    `;
export const MetricRollupFragmentDoc = gql`
    fragment MetricRollup on MetricRollupResult {
  datasetId
  metric
  feature
  segmentGroup
  value
}
    `;
export const TopLevelFeatureFieldsFragmentDoc = gql`
    fragment TopLevelFeatureFields on FeatureSketch {
  id
  featureName
  booleanCount
  integerCount
  fractionCount
  datasetTimestamp
  lastUploadTimestamp
  totalCount
  nullCount
  nullRatio
  showAsDiscrete
}
    `;
export const UniqueCountFieldsFragmentDoc = gql`
    fragment UniqueCountFields on UniqueCountSummary {
  upper
  estimate
  lower
}
    `;
export const SchemaSummaryFieldsFragmentDoc = gql`
    fragment SchemaSummaryFields on SchemaSummary {
  inference {
    type
    ratio
    count
  }
  typeCounts {
    type
    count
  }
}
    `;
export const FullSketchFieldsFragmentDoc = gql`
    fragment FullSketchFields on FeatureSketch {
  ...TopLevelFeatureFields
  frequentItems {
    ...FrequentItemUnitFields
  }
  uniqueCount {
    ...UniqueCountFields
  }
  numberSummary {
    ...NumberSummaryFields
    ...QuantileFields
  }
  schemaSummary {
    ...SchemaSummaryFields
  }
}
    ${TopLevelFeatureFieldsFragmentDoc}
${FrequentItemUnitFieldsFragmentDoc}
${UniqueCountFieldsFragmentDoc}
${NumberSummaryFieldsFragmentDoc}
${QuantileFieldsFragmentDoc}
${SchemaSummaryFieldsFragmentDoc}`;
export const ProfilesSketchFieldsFragmentDoc = gql`
    fragment ProfilesSketchFields on FilteredFeatureSketches {
  totalCount
  results {
    ...FullSketchFields
  }
}
    ${FullSketchFieldsFragmentDoc}`;
export const SegmentListingFieldsFragmentDoc = gql`
    fragment SegmentListingFields on Segment {
  id
  tags {
    key
    value
  }
  batches(from: $from, to: $to) {
    inputCount
    outputCount
    timestamp
  }
  anomalyCounts(fromTimestamp: $from, toTimestamp: $to) {
    timeseries {
      timestamp
      counts {
        category
        count
      }
    }
  }
}
    `;
export const ResourcesExecutiveTableInfoFragmentDoc = gql`
    fragment ResourcesExecutiveTableInfo on Model {
  id
  name
  entitySchema {
    inputCounts {
      total
    }
  }
  assetCategory
  modelType
  allAnomalyCounts(fromTimestamp: $from, toTimestamp: $to) {
    ...ResourceAnomalyCountTimeseries
  }
  batches(from: $from, to: $to) {
    inputCount
  }
  metrics: datasetMetrics(granularity: ALL) {
    name
    values(fromTimestamp: $from, toTimestamp: $to) {
      ...MetricValueFields
    }
  }
  freshness: dataAvailability {
    oldestTimestamp
    latestTimestamp
  }
}
    ${ResourceAnomalyCountTimeseriesFragmentDoc}
${MetricValueFieldsFragmentDoc}`;
export const ActionDetailsFragmentDoc = gql`
    fragment ActionDetails on GenericNotificationAction {
  id
  enabled
  type
  createdAt
  updatedAt
  references {
    itemId
    datasetId
    itemDisplayName
    type
  }
  __typename
  payload {
    __typename
    ... on SlackAction {
      slackWebhook
    }
    ... on EmailAction {
      email
    }
    ... on PagerDutyAction {
      pagerDutyKey
    }
    ... on MSTeamsAction {
      webhook
    }
    ... on CustomWebhookAction {
      url
      method
      headers {
        key
        value
      }
      body
    }
    ... on UnknownAction {
      value
    }
  }
}
    `;
export const JoinedOrganizationFragmentDoc = gql`
    fragment JoinedOrganization on OrganizationMemberMetadata {
  orgId
  name
  membershipType
}
    `;
export const GetProfileInsightInfoDocument = gql`
    query getProfileInsightInfo($modelId: String!, $batchProfileIds: [Float!]!, $referenceProfileIds: [String!]!, $tags: [SegmentTagFilter!]!) {
  model(id: $modelId) {
    segment(tags: $tags) {
      batches(timestamps: $batchProfileIds) {
        timestamp
        datasetId
        sketches {
          ...InsightSketches
        }
      }
      referenceProfiles(profileIds: $referenceProfileIds) {
        id
        datasetId
        alias
        sketches {
          ...InsightSketches
        }
      }
    }
  }
}
    ${InsightSketchesFragmentDoc}`;

/**
 * __useGetProfileInsightInfoQuery__
 *
 * To run a query within a React component, call `useGetProfileInsightInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileInsightInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileInsightInfoQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      batchProfileIds: // value for 'batchProfileIds'
 *      referenceProfileIds: // value for 'referenceProfileIds'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetProfileInsightInfoQuery(baseOptions: Apollo.QueryHookOptions<GetProfileInsightInfoQuery, GetProfileInsightInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileInsightInfoQuery, GetProfileInsightInfoQueryVariables>(GetProfileInsightInfoDocument, options);
      }
export function useGetProfileInsightInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileInsightInfoQuery, GetProfileInsightInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileInsightInfoQuery, GetProfileInsightInfoQueryVariables>(GetProfileInsightInfoDocument, options);
        }
export type GetProfileInsightInfoQueryHookResult = ReturnType<typeof useGetProfileInsightInfoQuery>;
export type GetProfileInsightInfoLazyQueryHookResult = ReturnType<typeof useGetProfileInsightInfoLazyQuery>;
export type GetProfileInsightInfoQueryResult = Apollo.QueryResult<GetProfileInsightInfoQuery, GetProfileInsightInfoQueryVariables>;
export const GetIndividualProfilesDocument = gql`
    query getIndividualProfiles($modelId: String!, $from: Float!, $to: Float!) {
  model(id: $modelId) {
    id
    name
    modelType
    individualProfileList(from: $from, to: $to, offset: 0, limit: 1000) {
      datasetTimestamp
      retrievalToken
      traceId
    }
  }
}
    `;

/**
 * __useGetIndividualProfilesQuery__
 *
 * To run a query within a React component, call `useGetIndividualProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndividualProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndividualProfilesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetIndividualProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetIndividualProfilesQuery, GetIndividualProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndividualProfilesQuery, GetIndividualProfilesQueryVariables>(GetIndividualProfilesDocument, options);
      }
export function useGetIndividualProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndividualProfilesQuery, GetIndividualProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndividualProfilesQuery, GetIndividualProfilesQueryVariables>(GetIndividualProfilesDocument, options);
        }
export type GetIndividualProfilesQueryHookResult = ReturnType<typeof useGetIndividualProfilesQuery>;
export type GetIndividualProfilesLazyQueryHookResult = ReturnType<typeof useGetIndividualProfilesLazyQuery>;
export type GetIndividualProfilesQueryResult = Apollo.QueryResult<GetIndividualProfilesQuery, GetIndividualProfilesQueryVariables>;
export const GetAnomalyCountsForWidgetDocument = gql`
    query getAnomalyCountsForWidget($datasetId: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float, $analyzerIds: [String!]) {
  anomalyCounts(datasetId: $datasetId, tags: $tags, fromTimestamp: $from, toTimestamp: $to, filter: {analyzerIDs: $analyzerIds}) {
    totals {
      category
      count
    }
  }
}
    `;

/**
 * __useGetAnomalyCountsForWidgetQuery__
 *
 * To run a query within a React component, call `useGetAnomalyCountsForWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomalyCountsForWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomalyCountsForWidgetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      analyzerIds: // value for 'analyzerIds'
 *   },
 * });
 */
export function useGetAnomalyCountsForWidgetQuery(baseOptions: Apollo.QueryHookOptions<GetAnomalyCountsForWidgetQuery, GetAnomalyCountsForWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomalyCountsForWidgetQuery, GetAnomalyCountsForWidgetQueryVariables>(GetAnomalyCountsForWidgetDocument, options);
      }
export function useGetAnomalyCountsForWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomalyCountsForWidgetQuery, GetAnomalyCountsForWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomalyCountsForWidgetQuery, GetAnomalyCountsForWidgetQueryVariables>(GetAnomalyCountsForWidgetDocument, options);
        }
export type GetAnomalyCountsForWidgetQueryHookResult = ReturnType<typeof useGetAnomalyCountsForWidgetQuery>;
export type GetAnomalyCountsForWidgetLazyQueryHookResult = ReturnType<typeof useGetAnomalyCountsForWidgetLazyQuery>;
export type GetAnomalyCountsForWidgetQueryResult = Apollo.QueryResult<GetAnomalyCountsForWidgetQuery, GetAnomalyCountsForWidgetQueryVariables>;
export const GetDatasetNameDocument = gql`
    query getDatasetName($datasetId: String!) {
  model(id: $datasetId) {
    name
  }
}
    `;

/**
 * __useGetDatasetNameQuery__
 *
 * To run a query within a React component, call `useGetDatasetNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatasetNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatasetNameQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDatasetNameQuery(baseOptions: Apollo.QueryHookOptions<GetDatasetNameQuery, GetDatasetNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatasetNameQuery, GetDatasetNameQueryVariables>(GetDatasetNameDocument, options);
      }
export function useGetDatasetNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatasetNameQuery, GetDatasetNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatasetNameQuery, GetDatasetNameQueryVariables>(GetDatasetNameDocument, options);
        }
export type GetDatasetNameQueryHookResult = ReturnType<typeof useGetDatasetNameQuery>;
export type GetDatasetNameLazyQueryHookResult = ReturnType<typeof useGetDatasetNameLazyQuery>;
export type GetDatasetNameQueryResult = Apollo.QueryResult<GetDatasetNameQuery, GetDatasetNameQueryVariables>;
export const GetMaintenanceBannerDocument = gql`
    query getMaintenanceBanner {
  maintenanceBanner {
    message
    updatedAt
  }
}
    `;

/**
 * __useGetMaintenanceBannerQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaintenanceBannerQuery(baseOptions?: Apollo.QueryHookOptions<GetMaintenanceBannerQuery, GetMaintenanceBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaintenanceBannerQuery, GetMaintenanceBannerQueryVariables>(GetMaintenanceBannerDocument, options);
      }
export function useGetMaintenanceBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaintenanceBannerQuery, GetMaintenanceBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaintenanceBannerQuery, GetMaintenanceBannerQueryVariables>(GetMaintenanceBannerDocument, options);
        }
export type GetMaintenanceBannerQueryHookResult = ReturnType<typeof useGetMaintenanceBannerQuery>;
export type GetMaintenanceBannerLazyQueryHookResult = ReturnType<typeof useGetMaintenanceBannerLazyQuery>;
export type GetMaintenanceBannerQueryResult = Apollo.QueryResult<GetMaintenanceBannerQuery, GetMaintenanceBannerQueryVariables>;
export const MarketplaceMetadataDocument = gql`
    query marketplaceMetadata {
  user {
    organization {
      isAWSMarketplace
      awsMarketplaceMetadata {
        awsMarketplaceProductCode
        dimension
        expirationTime
        expirationUpdateTime
      }
    }
  }
}
    `;

/**
 * __useMarketplaceMetadataQuery__
 *
 * To run a query within a React component, call `useMarketplaceMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketplaceMetadataQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceMetadataQuery, MarketplaceMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceMetadataQuery, MarketplaceMetadataQueryVariables>(MarketplaceMetadataDocument, options);
      }
export function useMarketplaceMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceMetadataQuery, MarketplaceMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceMetadataQuery, MarketplaceMetadataQueryVariables>(MarketplaceMetadataDocument, options);
        }
export type MarketplaceMetadataQueryHookResult = ReturnType<typeof useMarketplaceMetadataQuery>;
export type MarketplaceMetadataLazyQueryHookResult = ReturnType<typeof useMarketplaceMetadataLazyQuery>;
export type MarketplaceMetadataQueryResult = Apollo.QueryResult<MarketplaceMetadataQuery, MarketplaceMetadataQueryVariables>;
export const GetModelAttributesForHelpersValidationDocument = gql`
    query getModelAttributesForHelpersValidation($datasetId: String!) {
  model(id: $datasetId) {
    ...ModelInfoForCodeHelpers
  }
}
    ${ModelInfoForCodeHelpersFragmentDoc}`;

/**
 * __useGetModelAttributesForHelpersValidationQuery__
 *
 * To run a query within a React component, call `useGetModelAttributesForHelpersValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelAttributesForHelpersValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelAttributesForHelpersValidationQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetModelAttributesForHelpersValidationQuery(baseOptions: Apollo.QueryHookOptions<GetModelAttributesForHelpersValidationQuery, GetModelAttributesForHelpersValidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelAttributesForHelpersValidationQuery, GetModelAttributesForHelpersValidationQueryVariables>(GetModelAttributesForHelpersValidationDocument, options);
      }
export function useGetModelAttributesForHelpersValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelAttributesForHelpersValidationQuery, GetModelAttributesForHelpersValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelAttributesForHelpersValidationQuery, GetModelAttributesForHelpersValidationQueryVariables>(GetModelAttributesForHelpersValidationDocument, options);
        }
export type GetModelAttributesForHelpersValidationQueryHookResult = ReturnType<typeof useGetModelAttributesForHelpersValidationQuery>;
export type GetModelAttributesForHelpersValidationLazyQueryHookResult = ReturnType<typeof useGetModelAttributesForHelpersValidationLazyQuery>;
export type GetModelAttributesForHelpersValidationQueryResult = Apollo.QueryResult<GetModelAttributesForHelpersValidationQuery, GetModelAttributesForHelpersValidationQueryVariables>;
export const GetModelMonitorMetricsDocument = gql`
    query getModelMonitorMetrics($datasetId: String!) {
  model(id: $datasetId) {
    ...DatasetMetricsInfo
  }
}
    ${DatasetMetricsInfoFragmentDoc}`;

/**
 * __useGetModelMonitorMetricsQuery__
 *
 * To run a query within a React component, call `useGetModelMonitorMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelMonitorMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelMonitorMetricsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetModelMonitorMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetModelMonitorMetricsQuery, GetModelMonitorMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelMonitorMetricsQuery, GetModelMonitorMetricsQueryVariables>(GetModelMonitorMetricsDocument, options);
      }
export function useGetModelMonitorMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelMonitorMetricsQuery, GetModelMonitorMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelMonitorMetricsQuery, GetModelMonitorMetricsQueryVariables>(GetModelMonitorMetricsDocument, options);
        }
export type GetModelMonitorMetricsQueryHookResult = ReturnType<typeof useGetModelMonitorMetricsQuery>;
export type GetModelMonitorMetricsLazyQueryHookResult = ReturnType<typeof useGetModelMonitorMetricsLazyQuery>;
export type GetModelMonitorMetricsQueryResult = Apollo.QueryResult<GetModelMonitorMetricsQuery, GetModelMonitorMetricsQueryVariables>;
export const GetIoFeaturesDocument = gql`
    query getIOFeatures($modelId: String!) {
  model(id: $modelId) {
    weightMetadata {
      hasWeights
    }
    features {
      ...MonitorInputFeatures
    }
    outputs {
      ...MonitorCommonFeaturesFields
    }
  }
}
    ${MonitorInputFeaturesFragmentDoc}
${MonitorCommonFeaturesFieldsFragmentDoc}`;

/**
 * __useGetIoFeaturesQuery__
 *
 * To run a query within a React component, call `useGetIoFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIoFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIoFeaturesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetIoFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetIoFeaturesQuery, GetIoFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIoFeaturesQuery, GetIoFeaturesQueryVariables>(GetIoFeaturesDocument, options);
      }
export function useGetIoFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIoFeaturesQuery, GetIoFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIoFeaturesQuery, GetIoFeaturesQueryVariables>(GetIoFeaturesDocument, options);
        }
export type GetIoFeaturesQueryHookResult = ReturnType<typeof useGetIoFeaturesQuery>;
export type GetIoFeaturesLazyQueryHookResult = ReturnType<typeof useGetIoFeaturesLazyQuery>;
export type GetIoFeaturesQueryResult = Apollo.QueryResult<GetIoFeaturesQuery, GetIoFeaturesQueryVariables>;
export const SegmentsListingDocument = gql`
    query segmentsListing($modelId: String!, $tags: [SegmentTagFilter!]) {
  model(id: $modelId) {
    segments(filter: {tags: $tags}) {
      tags {
        key
        value
      }
    }
    mergedSegment: segment(tags: $tags) {
      tags {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useSegmentsListingQuery__
 *
 * To run a query within a React component, call `useSegmentsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentsListingQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSegmentsListingQuery(baseOptions: Apollo.QueryHookOptions<SegmentsListingQuery, SegmentsListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentsListingQuery, SegmentsListingQueryVariables>(SegmentsListingDocument, options);
      }
export function useSegmentsListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentsListingQuery, SegmentsListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentsListingQuery, SegmentsListingQueryVariables>(SegmentsListingDocument, options);
        }
export type SegmentsListingQueryHookResult = ReturnType<typeof useSegmentsListingQuery>;
export type SegmentsListingLazyQueryHookResult = ReturnType<typeof useSegmentsListingLazyQuery>;
export type SegmentsListingQueryResult = Apollo.QueryResult<SegmentsListingQuery, SegmentsListingQueryVariables>;
export const GetRawAnalyzerByIdDocument = gql`
    query getRawAnalyzerById($datasetId: String!, $analyzerId: String!) {
  analyzer(datasetId: $datasetId, analyzerId: $analyzerId)
}
    `;

/**
 * __useGetRawAnalyzerByIdQuery__
 *
 * To run a query within a React component, call `useGetRawAnalyzerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRawAnalyzerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRawAnalyzerByIdQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      analyzerId: // value for 'analyzerId'
 *   },
 * });
 */
export function useGetRawAnalyzerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRawAnalyzerByIdQuery, GetRawAnalyzerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRawAnalyzerByIdQuery, GetRawAnalyzerByIdQueryVariables>(GetRawAnalyzerByIdDocument, options);
      }
export function useGetRawAnalyzerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRawAnalyzerByIdQuery, GetRawAnalyzerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRawAnalyzerByIdQuery, GetRawAnalyzerByIdQueryVariables>(GetRawAnalyzerByIdDocument, options);
        }
export type GetRawAnalyzerByIdQueryHookResult = ReturnType<typeof useGetRawAnalyzerByIdQuery>;
export type GetRawAnalyzerByIdLazyQueryHookResult = ReturnType<typeof useGetRawAnalyzerByIdLazyQuery>;
export type GetRawAnalyzerByIdQueryResult = Apollo.QueryResult<GetRawAnalyzerByIdQuery, GetRawAnalyzerByIdQueryVariables>;
export const GetMergedFeatureDataDocument = gql`
    query getMergedFeatureData($datasetId: String!, $column: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float!) {
  dataQueries {
    getMergedFeatureData(datasetId: $datasetId, column: $column, segment: $tags, fromTimestamp: $from, toTimestamp: $to) {
      ...FullSketchFields
    }
  }
}
    ${FullSketchFieldsFragmentDoc}`;

/**
 * __useGetMergedFeatureDataQuery__
 *
 * To run a query within a React component, call `useGetMergedFeatureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMergedFeatureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMergedFeatureDataQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      column: // value for 'column'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMergedFeatureDataQuery(baseOptions: Apollo.QueryHookOptions<GetMergedFeatureDataQuery, GetMergedFeatureDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMergedFeatureDataQuery, GetMergedFeatureDataQueryVariables>(GetMergedFeatureDataDocument, options);
      }
export function useGetMergedFeatureDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMergedFeatureDataQuery, GetMergedFeatureDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMergedFeatureDataQuery, GetMergedFeatureDataQueryVariables>(GetMergedFeatureDataDocument, options);
        }
export type GetMergedFeatureDataQueryHookResult = ReturnType<typeof useGetMergedFeatureDataQuery>;
export type GetMergedFeatureDataLazyQueryHookResult = ReturnType<typeof useGetMergedFeatureDataLazyQuery>;
export type GetMergedFeatureDataQueryResult = Apollo.QueryResult<GetMergedFeatureDataQuery, GetMergedFeatureDataQueryVariables>;
export const GetMergedFeatureUnifiedDataDocument = gql`
    query getMergedFeatureUnifiedData($datasetId: String!, $column: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float!, $splitpoints: [Float!]) {
  dataQueries {
    getMergedFeatureData(datasetId: $datasetId, column: $column, segment: $tags, fromTimestamp: $from, toTimestamp: $to, splitpoints: $splitpoints) {
      ...FullSketchFields
    }
  }
}
    ${FullSketchFieldsFragmentDoc}`;

/**
 * __useGetMergedFeatureUnifiedDataQuery__
 *
 * To run a query within a React component, call `useGetMergedFeatureUnifiedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMergedFeatureUnifiedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMergedFeatureUnifiedDataQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      column: // value for 'column'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      splitpoints: // value for 'splitpoints'
 *   },
 * });
 */
export function useGetMergedFeatureUnifiedDataQuery(baseOptions: Apollo.QueryHookOptions<GetMergedFeatureUnifiedDataQuery, GetMergedFeatureUnifiedDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMergedFeatureUnifiedDataQuery, GetMergedFeatureUnifiedDataQueryVariables>(GetMergedFeatureUnifiedDataDocument, options);
      }
export function useGetMergedFeatureUnifiedDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMergedFeatureUnifiedDataQuery, GetMergedFeatureUnifiedDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMergedFeatureUnifiedDataQuery, GetMergedFeatureUnifiedDataQueryVariables>(GetMergedFeatureUnifiedDataDocument, options);
        }
export type GetMergedFeatureUnifiedDataQueryHookResult = ReturnType<typeof useGetMergedFeatureUnifiedDataQuery>;
export type GetMergedFeatureUnifiedDataLazyQueryHookResult = ReturnType<typeof useGetMergedFeatureUnifiedDataLazyQuery>;
export type GetMergedFeatureUnifiedDataQueryResult = Apollo.QueryResult<GetMergedFeatureUnifiedDataQuery, GetMergedFeatureUnifiedDataQueryVariables>;
export const GetOrgTierDocument = gql`
    query getOrgTier {
  user {
    organization {
      subscriptionTier
    }
  }
}
    `;

/**
 * __useGetOrgTierQuery__
 *
 * To run a query within a React component, call `useGetOrgTierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgTierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgTierQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgTierQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgTierQuery, GetOrgTierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgTierQuery, GetOrgTierQueryVariables>(GetOrgTierDocument, options);
      }
export function useGetOrgTierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgTierQuery, GetOrgTierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgTierQuery, GetOrgTierQueryVariables>(GetOrgTierDocument, options);
        }
export type GetOrgTierQueryHookResult = ReturnType<typeof useGetOrgTierQuery>;
export type GetOrgTierLazyQueryHookResult = ReturnType<typeof useGetOrgTierLazyQuery>;
export type GetOrgTierQueryResult = Apollo.QueryResult<GetOrgTierQuery, GetOrgTierQueryVariables>;
export const GetMonitorConfigDocument = gql`
    query getMonitorConfig($modelId: String!) {
  monitorConfig(datasetId: $modelId)
}
    `;

/**
 * __useGetMonitorConfigQuery__
 *
 * To run a query within a React component, call `useGetMonitorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonitorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonitorConfigQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetMonitorConfigQuery(baseOptions: Apollo.QueryHookOptions<GetMonitorConfigQuery, GetMonitorConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonitorConfigQuery, GetMonitorConfigQueryVariables>(GetMonitorConfigDocument, options);
      }
export function useGetMonitorConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonitorConfigQuery, GetMonitorConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonitorConfigQuery, GetMonitorConfigQueryVariables>(GetMonitorConfigDocument, options);
        }
export type GetMonitorConfigQueryHookResult = ReturnType<typeof useGetMonitorConfigQuery>;
export type GetMonitorConfigLazyQueryHookResult = ReturnType<typeof useGetMonitorConfigLazyQuery>;
export type GetMonitorConfigQueryResult = Apollo.QueryResult<GetMonitorConfigQuery, GetMonitorConfigQueryVariables>;
export const GetAnomaliesForMonitorListDocument = gql`
    query getAnomaliesForMonitorList($datasetId: String!, $monitorIDs: [String!]!, $fromTimestamp: Float!, $toTimestamp: Float!, $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: true, datasetId: $datasetId, monitorIDs: $monitorIDs, fromTimestamp: $fromTimestamp, toTimestamp: $toTimestamp, adhocRunId: $adhocRunId}, sortDirection: ASC) {
    analysisId
    monitorIds
    datasetTimestamp
  }
}
    `;

/**
 * __useGetAnomaliesForMonitorListQuery__
 *
 * To run a query within a React component, call `useGetAnomaliesForMonitorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomaliesForMonitorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomaliesForMonitorListQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      monitorIDs: // value for 'monitorIDs'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetAnomaliesForMonitorListQuery(baseOptions: Apollo.QueryHookOptions<GetAnomaliesForMonitorListQuery, GetAnomaliesForMonitorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomaliesForMonitorListQuery, GetAnomaliesForMonitorListQueryVariables>(GetAnomaliesForMonitorListDocument, options);
      }
export function useGetAnomaliesForMonitorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomaliesForMonitorListQuery, GetAnomaliesForMonitorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomaliesForMonitorListQuery, GetAnomaliesForMonitorListQueryVariables>(GetAnomaliesForMonitorListDocument, options);
        }
export type GetAnomaliesForMonitorListQueryHookResult = ReturnType<typeof useGetAnomaliesForMonitorListQuery>;
export type GetAnomaliesForMonitorListLazyQueryHookResult = ReturnType<typeof useGetAnomaliesForMonitorListLazyQuery>;
export type GetAnomaliesForMonitorListQueryResult = Apollo.QueryResult<GetAnomaliesForMonitorListQuery, GetAnomaliesForMonitorListQueryVariables>;
export const PatchMonitorConfigDocument = gql`
    mutation patchMonitorConfig($modelId: String!, $config: String!) {
  monitorSettings {
    patchMonitorConfig(datasetId: $modelId, config: $config)
  }
}
    `;
export type PatchMonitorConfigMutationFn = Apollo.MutationFunction<PatchMonitorConfigMutation, PatchMonitorConfigMutationVariables>;

/**
 * __usePatchMonitorConfigMutation__
 *
 * To run a mutation, you first call `usePatchMonitorConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchMonitorConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchMonitorConfigMutation, { data, loading, error }] = usePatchMonitorConfigMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function usePatchMonitorConfigMutation(baseOptions?: Apollo.MutationHookOptions<PatchMonitorConfigMutation, PatchMonitorConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchMonitorConfigMutation, PatchMonitorConfigMutationVariables>(PatchMonitorConfigDocument, options);
      }
export type PatchMonitorConfigMutationHookResult = ReturnType<typeof usePatchMonitorConfigMutation>;
export type PatchMonitorConfigMutationResult = Apollo.MutationResult<PatchMonitorConfigMutation>;
export type PatchMonitorConfigMutationOptions = Apollo.BaseMutationOptions<PatchMonitorConfigMutation, PatchMonitorConfigMutationVariables>;
export const UpdateMonitorDocument = gql`
    mutation updateMonitor($modelId: String!, $config: String!, $monitorId: String!) {
  monitorSettings {
    updateMonitor(datasetId: $modelId, config: $config, monitorId: $monitorId)
  }
}
    `;
export type UpdateMonitorMutationFn = Apollo.MutationFunction<UpdateMonitorMutation, UpdateMonitorMutationVariables>;

/**
 * __useUpdateMonitorMutation__
 *
 * To run a mutation, you first call `useUpdateMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonitorMutation, { data, loading, error }] = useUpdateMonitorMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      config: // value for 'config'
 *      monitorId: // value for 'monitorId'
 *   },
 * });
 */
export function useUpdateMonitorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMonitorMutation, UpdateMonitorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMonitorMutation, UpdateMonitorMutationVariables>(UpdateMonitorDocument, options);
      }
export type UpdateMonitorMutationHookResult = ReturnType<typeof useUpdateMonitorMutation>;
export type UpdateMonitorMutationResult = Apollo.MutationResult<UpdateMonitorMutation>;
export type UpdateMonitorMutationOptions = Apollo.BaseMutationOptions<UpdateMonitorMutation, UpdateMonitorMutationVariables>;
export const UpdateAnalyzerDocument = gql`
    mutation updateAnalyzer($modelId: String!, $config: String!, $analyzerId: String!) {
  monitorSettings {
    updateAnalyzer(datasetId: $modelId, config: $config, analyzerId: $analyzerId)
  }
}
    `;
export type UpdateAnalyzerMutationFn = Apollo.MutationFunction<UpdateAnalyzerMutation, UpdateAnalyzerMutationVariables>;

/**
 * __useUpdateAnalyzerMutation__
 *
 * To run a mutation, you first call `useUpdateAnalyzerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalyzerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalyzerMutation, { data, loading, error }] = useUpdateAnalyzerMutation({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      config: // value for 'config'
 *      analyzerId: // value for 'analyzerId'
 *   },
 * });
 */
export function useUpdateAnalyzerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnalyzerMutation, UpdateAnalyzerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnalyzerMutation, UpdateAnalyzerMutationVariables>(UpdateAnalyzerDocument, options);
      }
export type UpdateAnalyzerMutationHookResult = ReturnType<typeof useUpdateAnalyzerMutation>;
export type UpdateAnalyzerMutationResult = Apollo.MutationResult<UpdateAnalyzerMutation>;
export type UpdateAnalyzerMutationOptions = Apollo.BaseMutationOptions<UpdateAnalyzerMutation, UpdateAnalyzerMutationVariables>;
export const DeleteMonitorDocument = gql`
    mutation deleteMonitor($datasetId: String!, $monitorId: String!) {
  monitorSettings {
    deleteMonitor(datasetId: $datasetId, monitorId: $monitorId)
  }
}
    `;
export type DeleteMonitorMutationFn = Apollo.MutationFunction<DeleteMonitorMutation, DeleteMonitorMutationVariables>;

/**
 * __useDeleteMonitorMutation__
 *
 * To run a mutation, you first call `useDeleteMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonitorMutation, { data, loading, error }] = useDeleteMonitorMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      monitorId: // value for 'monitorId'
 *   },
 * });
 */
export function useDeleteMonitorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMonitorMutation, DeleteMonitorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMonitorMutation, DeleteMonitorMutationVariables>(DeleteMonitorDocument, options);
      }
export type DeleteMonitorMutationHookResult = ReturnType<typeof useDeleteMonitorMutation>;
export type DeleteMonitorMutationResult = Apollo.MutationResult<DeleteMonitorMutation>;
export type DeleteMonitorMutationOptions = Apollo.BaseMutationOptions<DeleteMonitorMutation, DeleteMonitorMutationVariables>;
export const DeleteAnalyzerDocument = gql`
    mutation deleteAnalyzer($datasetId: String!, $analyzerId: String!) {
  monitorSettings {
    deleteAnalyzer(datasetId: $datasetId, analyzerId: $analyzerId)
  }
}
    `;
export type DeleteAnalyzerMutationFn = Apollo.MutationFunction<DeleteAnalyzerMutation, DeleteAnalyzerMutationVariables>;

/**
 * __useDeleteAnalyzerMutation__
 *
 * To run a mutation, you first call `useDeleteAnalyzerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalyzerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalyzerMutation, { data, loading, error }] = useDeleteAnalyzerMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      analyzerId: // value for 'analyzerId'
 *   },
 * });
 */
export function useDeleteAnalyzerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnalyzerMutation, DeleteAnalyzerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnalyzerMutation, DeleteAnalyzerMutationVariables>(DeleteAnalyzerDocument, options);
      }
export type DeleteAnalyzerMutationHookResult = ReturnType<typeof useDeleteAnalyzerMutation>;
export type DeleteAnalyzerMutationResult = Apollo.MutationResult<DeleteAnalyzerMutation>;
export type DeleteAnalyzerMutationOptions = Apollo.BaseMutationOptions<DeleteAnalyzerMutation, DeleteAnalyzerMutationVariables>;
export const GetNotificationIdsDocument = gql`
    query getNotificationIds {
  user {
    organization {
      notifications {
        slack {
          general {
            id
          }
        }
        email {
          general {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetNotificationIdsQuery__
 *
 * To run a query within a React component, call `useGetNotificationIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationIdsQuery, GetNotificationIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationIdsQuery, GetNotificationIdsQueryVariables>(GetNotificationIdsDocument, options);
      }
export function useGetNotificationIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationIdsQuery, GetNotificationIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationIdsQuery, GetNotificationIdsQueryVariables>(GetNotificationIdsDocument, options);
        }
export type GetNotificationIdsQueryHookResult = ReturnType<typeof useGetNotificationIdsQuery>;
export type GetNotificationIdsLazyQueryHookResult = ReturnType<typeof useGetNotificationIdsLazyQuery>;
export type GetNotificationIdsQueryResult = Apollo.QueryResult<GetNotificationIdsQuery, GetNotificationIdsQueryVariables>;
export const GetSemengtsListDocument = gql`
    query getSemengtsList($modelId: String!) {
  model(id: $modelId) {
    segments {
      tags {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetSemengtsListQuery__
 *
 * To run a query within a React component, call `useGetSemengtsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSemengtsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSemengtsListQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetSemengtsListQuery(baseOptions: Apollo.QueryHookOptions<GetSemengtsListQuery, GetSemengtsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSemengtsListQuery, GetSemengtsListQueryVariables>(GetSemengtsListDocument, options);
      }
export function useGetSemengtsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSemengtsListQuery, GetSemengtsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSemengtsListQuery, GetSemengtsListQueryVariables>(GetSemengtsListDocument, options);
        }
export type GetSemengtsListQueryHookResult = ReturnType<typeof useGetSemengtsListQuery>;
export type GetSemengtsListLazyQueryHookResult = ReturnType<typeof useGetSemengtsListLazyQuery>;
export type GetSemengtsListQueryResult = Apollo.QueryResult<GetSemengtsListQuery, GetSemengtsListQueryVariables>;
export const GetAnomalyDataDocument = gql`
    query getAnomalyData($modelId: String!, $dailyFrom: Float!, $dailyTo: Float!, $weeklyFrom: Float!, $weeklyTo: Float!) {
  daily: model(id: $modelId) {
    ...AnomalyDataAvailability
    anomalyCounts: allAnomalyCounts(fromTimestamp: $dailyFrom, toTimestamp: $dailyTo) {
      ...AnomalyCountsCommonFields
    }
  }
  monitorConfig(datasetId: $modelId)
  weekly: model(id: $modelId) {
    ...AnomalyDataAvailability
    anomalyCounts: allAnomalyCounts(fromTimestamp: $weeklyFrom, toTimestamp: $weeklyTo) {
      ...AnomalyCountsCommonFields
    }
  }
}
    ${AnomalyDataAvailabilityFragmentDoc}
${AnomalyCountsCommonFieldsFragmentDoc}`;

/**
 * __useGetAnomalyDataQuery__
 *
 * To run a query within a React component, call `useGetAnomalyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomalyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomalyDataQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      dailyFrom: // value for 'dailyFrom'
 *      dailyTo: // value for 'dailyTo'
 *      weeklyFrom: // value for 'weeklyFrom'
 *      weeklyTo: // value for 'weeklyTo'
 *   },
 * });
 */
export function useGetAnomalyDataQuery(baseOptions: Apollo.QueryHookOptions<GetAnomalyDataQuery, GetAnomalyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomalyDataQuery, GetAnomalyDataQueryVariables>(GetAnomalyDataDocument, options);
      }
export function useGetAnomalyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomalyDataQuery, GetAnomalyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomalyDataQuery, GetAnomalyDataQueryVariables>(GetAnomalyDataDocument, options);
        }
export type GetAnomalyDataQueryHookResult = ReturnType<typeof useGetAnomalyDataQuery>;
export type GetAnomalyDataLazyQueryHookResult = ReturnType<typeof useGetAnomalyDataLazyQuery>;
export type GetAnomalyDataQueryResult = Apollo.QueryResult<GetAnomalyDataQuery, GetAnomalyDataQueryVariables>;
export const GetIntegrationHealthInfoDocument = gql`
    query getIntegrationHealthInfo($modelId: String!, $from: Float!, $to: Float!, $dataHealthFrom: Float!, $dataHealthTo: Float!) {
  model(id: $modelId) {
    batches(from: $from, to: $to) {
      timestamp
    }
    anomaliesInRange: anomalies(filter: {anomaliesOnly: true, includeUnhelpful: false, metrics: [SECONDS_SINCE_LAST_UPLOAD, MISSING_DATAPOINT], fromTimestamp: $from, toTimestamp: $to}, sortDirection: ASC) {
      analysisId
    }
    dataHealthAnomalies: anomalies(filter: {anomaliesOnly: true, includeUnhelpful: false, metrics: [SECONDS_SINCE_LAST_UPLOAD, MISSING_DATAPOINT], fromTimestamp: $dataHealthFrom, toTimestamp: $dataHealthTo}, sortDirection: ASC) {
      ...AnomaliesHealthData
    }
    dataAvailability {
      latestTimestamp
    }
  }
}
    ${AnomaliesHealthDataFragmentDoc}`;

/**
 * __useGetIntegrationHealthInfoQuery__
 *
 * To run a query within a React component, call `useGetIntegrationHealthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationHealthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationHealthInfoQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      dataHealthFrom: // value for 'dataHealthFrom'
 *      dataHealthTo: // value for 'dataHealthTo'
 *   },
 * });
 */
export function useGetIntegrationHealthInfoQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationHealthInfoQuery, GetIntegrationHealthInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationHealthInfoQuery, GetIntegrationHealthInfoQueryVariables>(GetIntegrationHealthInfoDocument, options);
      }
export function useGetIntegrationHealthInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationHealthInfoQuery, GetIntegrationHealthInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationHealthInfoQuery, GetIntegrationHealthInfoQueryVariables>(GetIntegrationHealthInfoDocument, options);
        }
export type GetIntegrationHealthInfoQueryHookResult = ReturnType<typeof useGetIntegrationHealthInfoQuery>;
export type GetIntegrationHealthInfoLazyQueryHookResult = ReturnType<typeof useGetIntegrationHealthInfoLazyQuery>;
export type GetIntegrationHealthInfoQueryResult = Apollo.QueryResult<GetIntegrationHealthInfoQuery, GetIntegrationHealthInfoQueryVariables>;
export const GetMonitorCoverageDocument = gql`
    query getMonitorCoverage($modelId: String!) {
  model(id: $modelId) {
    monitoredCategories
  }
}
    `;

/**
 * __useGetMonitorCoverageQuery__
 *
 * To run a query within a React component, call `useGetMonitorCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonitorCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonitorCoverageQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetMonitorCoverageQuery(baseOptions: Apollo.QueryHookOptions<GetMonitorCoverageQuery, GetMonitorCoverageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonitorCoverageQuery, GetMonitorCoverageQueryVariables>(GetMonitorCoverageDocument, options);
      }
export function useGetMonitorCoverageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonitorCoverageQuery, GetMonitorCoverageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonitorCoverageQuery, GetMonitorCoverageQueryVariables>(GetMonitorCoverageDocument, options);
        }
export type GetMonitorCoverageQueryHookResult = ReturnType<typeof useGetMonitorCoverageQuery>;
export type GetMonitorCoverageLazyQueryHookResult = ReturnType<typeof useGetMonitorCoverageLazyQuery>;
export type GetMonitorCoverageQueryResult = Apollo.QueryResult<GetMonitorCoverageQuery, GetMonitorCoverageQueryVariables>;
export const GetLlmMetricsListDocument = gql`
    query getLLMMetricsList($modelId: String!, $granularity: TimePeriod, $tags: [String!]) {
  model(id: $modelId) {
    customMetrics(granularity: $granularity, tags: $tags) {
      metadata {
        queryDefinition {
          column
        }
        tags
      }
    }
  }
}
    `;

/**
 * __useGetLlmMetricsListQuery__
 *
 * To run a query within a React component, call `useGetLlmMetricsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmMetricsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmMetricsListQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      granularity: // value for 'granularity'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetLlmMetricsListQuery(baseOptions: Apollo.QueryHookOptions<GetLlmMetricsListQuery, GetLlmMetricsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLlmMetricsListQuery, GetLlmMetricsListQueryVariables>(GetLlmMetricsListDocument, options);
      }
export function useGetLlmMetricsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLlmMetricsListQuery, GetLlmMetricsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLlmMetricsListQuery, GetLlmMetricsListQueryVariables>(GetLlmMetricsListDocument, options);
        }
export type GetLlmMetricsListQueryHookResult = ReturnType<typeof useGetLlmMetricsListQuery>;
export type GetLlmMetricsListLazyQueryHookResult = ReturnType<typeof useGetLlmMetricsListLazyQuery>;
export type GetLlmMetricsListQueryResult = Apollo.QueryResult<GetLlmMetricsListQuery, GetLlmMetricsListQueryVariables>;
export const GetAnomaliesByColumnsDocument = gql`
    query getAnomaliesByColumns($model: String!, $metrics: [String!]!, $dailyFrom: Float!, $dailyTo: Float!, $weeklyFrom: Float!, $weeklyTo: Float!) {
  daily: analysisResults(filter: {anomaliesOnly: true, includeUnhelpful: false, columns: $metrics, datasetId: $model, fromTimestamp: $dailyFrom, toTimestamp: $dailyTo, includeFailed: false}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ColumnAndMetricAnalysis
  }
  weekly: analysisResults(filter: {anomaliesOnly: true, includeUnhelpful: false, columns: $metrics, datasetId: $model, fromTimestamp: $weeklyFrom, toTimestamp: $weeklyTo, includeFailed: false}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ColumnAndMetricAnalysis
  }
}
    ${ColumnAndMetricAnalysisFragmentDoc}`;

/**
 * __useGetAnomaliesByColumnsQuery__
 *
 * To run a query within a React component, call `useGetAnomaliesByColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomaliesByColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomaliesByColumnsQuery({
 *   variables: {
 *      model: // value for 'model'
 *      metrics: // value for 'metrics'
 *      dailyFrom: // value for 'dailyFrom'
 *      dailyTo: // value for 'dailyTo'
 *      weeklyFrom: // value for 'weeklyFrom'
 *      weeklyTo: // value for 'weeklyTo'
 *   },
 * });
 */
export function useGetAnomaliesByColumnsQuery(baseOptions: Apollo.QueryHookOptions<GetAnomaliesByColumnsQuery, GetAnomaliesByColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomaliesByColumnsQuery, GetAnomaliesByColumnsQueryVariables>(GetAnomaliesByColumnsDocument, options);
      }
export function useGetAnomaliesByColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomaliesByColumnsQuery, GetAnomaliesByColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomaliesByColumnsQuery, GetAnomaliesByColumnsQueryVariables>(GetAnomaliesByColumnsDocument, options);
        }
export type GetAnomaliesByColumnsQueryHookResult = ReturnType<typeof useGetAnomaliesByColumnsQuery>;
export type GetAnomaliesByColumnsLazyQueryHookResult = ReturnType<typeof useGetAnomaliesByColumnsLazyQuery>;
export type GetAnomaliesByColumnsQueryResult = Apollo.QueryResult<GetAnomaliesByColumnsQuery, GetAnomaliesByColumnsQueryVariables>;
export const GetInputOutputHealthDataDocument = gql`
    query getInputOutputHealthData($modelId: String!, $dailyFrom: Float!, $dailyTo: Float!, $weeklyFrom: Float!, $weeklyTo: Float!) {
  daily: model(id: $modelId) {
    ...InputOutputHealthDataDaily
  }
  weekly: model(id: $modelId) {
    ...InputOutputHealthDataWeekly
  }
}
    ${InputOutputHealthDataDailyFragmentDoc}
${InputOutputHealthDataWeeklyFragmentDoc}`;

/**
 * __useGetInputOutputHealthDataQuery__
 *
 * To run a query within a React component, call `useGetInputOutputHealthDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInputOutputHealthDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInputOutputHealthDataQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      dailyFrom: // value for 'dailyFrom'
 *      dailyTo: // value for 'dailyTo'
 *      weeklyFrom: // value for 'weeklyFrom'
 *      weeklyTo: // value for 'weeklyTo'
 *   },
 * });
 */
export function useGetInputOutputHealthDataQuery(baseOptions: Apollo.QueryHookOptions<GetInputOutputHealthDataQuery, GetInputOutputHealthDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInputOutputHealthDataQuery, GetInputOutputHealthDataQueryVariables>(GetInputOutputHealthDataDocument, options);
      }
export function useGetInputOutputHealthDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInputOutputHealthDataQuery, GetInputOutputHealthDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInputOutputHealthDataQuery, GetInputOutputHealthDataQueryVariables>(GetInputOutputHealthDataDocument, options);
        }
export type GetInputOutputHealthDataQueryHookResult = ReturnType<typeof useGetInputOutputHealthDataQuery>;
export type GetInputOutputHealthDataLazyQueryHookResult = ReturnType<typeof useGetInputOutputHealthDataLazyQuery>;
export type GetInputOutputHealthDataQueryResult = Apollo.QueryResult<GetInputOutputHealthDataQuery, GetInputOutputHealthDataQueryVariables>;
export const GetSegmentsDiscreteFeaturesAlertsDocument = gql`
    query getSegmentsDiscreteFeaturesAlerts($modelId: String!, $dailyFrom: Float!, $dailyTo: Float, $weeklyFrom: Float!, $weeklyTo: Float) {
  model(id: $modelId) {
    totalSegments
    segments(sort: {by: AnomalyCount, direction: DESC}, limit: 3, filter: {fromTimestamp: $dailyFrom, toTimestamp: $dailyTo}) {
      tags {
        key
        value
      }
      anomalyCounts(fromTimestamp: $dailyFrom, toTimestamp: $dailyTo) {
        totals {
          count
        }
      }
    }
  }
  dailyAnomalyCount: segmentedAnomalyCount(datasetId: $modelId, fromTimestamp: $dailyFrom, toTimestamp: $dailyTo)
  weeklyAnomalyCount: segmentedAnomalyCount(datasetId: $modelId, fromTimestamp: $weeklyFrom, toTimestamp: $weeklyTo)
}
    `;

/**
 * __useGetSegmentsDiscreteFeaturesAlertsQuery__
 *
 * To run a query within a React component, call `useGetSegmentsDiscreteFeaturesAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentsDiscreteFeaturesAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentsDiscreteFeaturesAlertsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      dailyFrom: // value for 'dailyFrom'
 *      dailyTo: // value for 'dailyTo'
 *      weeklyFrom: // value for 'weeklyFrom'
 *      weeklyTo: // value for 'weeklyTo'
 *   },
 * });
 */
export function useGetSegmentsDiscreteFeaturesAlertsQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentsDiscreteFeaturesAlertsQuery, GetSegmentsDiscreteFeaturesAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentsDiscreteFeaturesAlertsQuery, GetSegmentsDiscreteFeaturesAlertsQueryVariables>(GetSegmentsDiscreteFeaturesAlertsDocument, options);
      }
export function useGetSegmentsDiscreteFeaturesAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentsDiscreteFeaturesAlertsQuery, GetSegmentsDiscreteFeaturesAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentsDiscreteFeaturesAlertsQuery, GetSegmentsDiscreteFeaturesAlertsQueryVariables>(GetSegmentsDiscreteFeaturesAlertsDocument, options);
        }
export type GetSegmentsDiscreteFeaturesAlertsQueryHookResult = ReturnType<typeof useGetSegmentsDiscreteFeaturesAlertsQuery>;
export type GetSegmentsDiscreteFeaturesAlertsLazyQueryHookResult = ReturnType<typeof useGetSegmentsDiscreteFeaturesAlertsLazyQuery>;
export type GetSegmentsDiscreteFeaturesAlertsQueryResult = Apollo.QueryResult<GetSegmentsDiscreteFeaturesAlertsQuery, GetSegmentsDiscreteFeaturesAlertsQueryVariables>;
export const GetLlmSecureSummaryCardDocument = gql`
    query getLlmSecureSummaryCard($modelId: String, $globalRangeFrom: Float!, $globalRangeTo: Float!, $shortRangeFrom: Float!, $shortRangeTo: Float!, $midRangeFrom: Float!, $midRangeTo: Float!) {
  model(id: $modelId) {
    tracesInRange: tracesSummary(fromTimestamp: $globalRangeFrom, toTimestamp: $globalRangeTo) {
      totalCount
      latestTraceTimestamp
    }
    currentIssuesData: tracesSummary(fromTimestamp: $shortRangeFrom, toTimestamp: $shortRangeTo) {
      blockedInteractionsCount
      violationsCount
    }
    comparedIssuesData: tracesSummary(fromTimestamp: $midRangeFrom, toTimestamp: $midRangeTo) {
      blockedInteractionsCount
      violationsCount
    }
  }
}
    `;

/**
 * __useGetLlmSecureSummaryCardQuery__
 *
 * To run a query within a React component, call `useGetLlmSecureSummaryCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmSecureSummaryCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmSecureSummaryCardQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      globalRangeFrom: // value for 'globalRangeFrom'
 *      globalRangeTo: // value for 'globalRangeTo'
 *      shortRangeFrom: // value for 'shortRangeFrom'
 *      shortRangeTo: // value for 'shortRangeTo'
 *      midRangeFrom: // value for 'midRangeFrom'
 *      midRangeTo: // value for 'midRangeTo'
 *   },
 * });
 */
export function useGetLlmSecureSummaryCardQuery(baseOptions: Apollo.QueryHookOptions<GetLlmSecureSummaryCardQuery, GetLlmSecureSummaryCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLlmSecureSummaryCardQuery, GetLlmSecureSummaryCardQueryVariables>(GetLlmSecureSummaryCardDocument, options);
      }
export function useGetLlmSecureSummaryCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLlmSecureSummaryCardQuery, GetLlmSecureSummaryCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLlmSecureSummaryCardQuery, GetLlmSecureSummaryCardQueryVariables>(GetLlmSecureSummaryCardDocument, options);
        }
export type GetLlmSecureSummaryCardQueryHookResult = ReturnType<typeof useGetLlmSecureSummaryCardQuery>;
export type GetLlmSecureSummaryCardLazyQueryHookResult = ReturnType<typeof useGetLlmSecureSummaryCardLazyQuery>;
export type GetLlmSecureSummaryCardQueryResult = Apollo.QueryResult<GetLlmSecureSummaryCardQuery, GetLlmSecureSummaryCardQueryVariables>;
export const GetCustomDashboardsByUsedOnMetadataDocument = gql`
    query getCustomDashboardsByUsedOnMetadata($usedOn: String!) {
  customDashboards(usedOn: $usedOn) {
    id
    displayName
  }
}
    `;

/**
 * __useGetCustomDashboardsByUsedOnMetadataQuery__
 *
 * To run a query within a React component, call `useGetCustomDashboardsByUsedOnMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomDashboardsByUsedOnMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomDashboardsByUsedOnMetadataQuery({
 *   variables: {
 *      usedOn: // value for 'usedOn'
 *   },
 * });
 */
export function useGetCustomDashboardsByUsedOnMetadataQuery(baseOptions: Apollo.QueryHookOptions<GetCustomDashboardsByUsedOnMetadataQuery, GetCustomDashboardsByUsedOnMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomDashboardsByUsedOnMetadataQuery, GetCustomDashboardsByUsedOnMetadataQueryVariables>(GetCustomDashboardsByUsedOnMetadataDocument, options);
      }
export function useGetCustomDashboardsByUsedOnMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomDashboardsByUsedOnMetadataQuery, GetCustomDashboardsByUsedOnMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomDashboardsByUsedOnMetadataQuery, GetCustomDashboardsByUsedOnMetadataQueryVariables>(GetCustomDashboardsByUsedOnMetadataDocument, options);
        }
export type GetCustomDashboardsByUsedOnMetadataQueryHookResult = ReturnType<typeof useGetCustomDashboardsByUsedOnMetadataQuery>;
export type GetCustomDashboardsByUsedOnMetadataLazyQueryHookResult = ReturnType<typeof useGetCustomDashboardsByUsedOnMetadataLazyQuery>;
export type GetCustomDashboardsByUsedOnMetadataQueryResult = Apollo.QueryResult<GetCustomDashboardsByUsedOnMetadataQuery, GetCustomDashboardsByUsedOnMetadataQueryVariables>;
export const GetMedianAnalysisDocument = gql`
    query getMedianAnalysis($datasetId: String!, $featureId: String!, $anomaliesOnly: Boolean!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, columns: [$featureId], metrics: [MEDIAN], datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ThresholdAnalysisData
    comparison_expected
    comparison_observed
  }
}
    ${ThresholdAnalysisDataFragmentDoc}`;

/**
 * __useGetMedianAnalysisQuery__
 *
 * To run a query within a React component, call `useGetMedianAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedianAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedianAnalysisQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      featureId: // value for 'featureId'
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetMedianAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetMedianAnalysisQuery, GetMedianAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMedianAnalysisQuery, GetMedianAnalysisQueryVariables>(GetMedianAnalysisDocument, options);
      }
export function useGetMedianAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMedianAnalysisQuery, GetMedianAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMedianAnalysisQuery, GetMedianAnalysisQueryVariables>(GetMedianAnalysisDocument, options);
        }
export type GetMedianAnalysisQueryHookResult = ReturnType<typeof useGetMedianAnalysisQuery>;
export type GetMedianAnalysisLazyQueryHookResult = ReturnType<typeof useGetMedianAnalysisLazyQuery>;
export type GetMedianAnalysisQueryResult = Apollo.QueryResult<GetMedianAnalysisQuery, GetMedianAnalysisQueryVariables>;
export const GetStatisticalValueAnalysisDocument = gql`
    query getStatisticalValueAnalysis($datasetId: String!, $featureId: String!, $anomaliesOnly: Boolean!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, columns: [$featureId], metrics: [MEDIAN, MIN, MAX, MEAN, STD_DEV, QUANTILE_99], datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ThresholdAnalysisData
    comparison_expected
    comparison_observed
  }
}
    ${ThresholdAnalysisDataFragmentDoc}`;

/**
 * __useGetStatisticalValueAnalysisQuery__
 *
 * To run a query within a React component, call `useGetStatisticalValueAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticalValueAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticalValueAnalysisQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      featureId: // value for 'featureId'
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetStatisticalValueAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticalValueAnalysisQuery, GetStatisticalValueAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticalValueAnalysisQuery, GetStatisticalValueAnalysisQueryVariables>(GetStatisticalValueAnalysisDocument, options);
      }
export function useGetStatisticalValueAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticalValueAnalysisQuery, GetStatisticalValueAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticalValueAnalysisQuery, GetStatisticalValueAnalysisQueryVariables>(GetStatisticalValueAnalysisDocument, options);
        }
export type GetStatisticalValueAnalysisQueryHookResult = ReturnType<typeof useGetStatisticalValueAnalysisQuery>;
export type GetStatisticalValueAnalysisLazyQueryHookResult = ReturnType<typeof useGetStatisticalValueAnalysisLazyQuery>;
export type GetStatisticalValueAnalysisQueryResult = Apollo.QueryResult<GetStatisticalValueAnalysisQuery, GetStatisticalValueAnalysisQueryVariables>;
export const GetMultiFeatureDriftAnalysisDocument = gql`
    query getMultiFeatureDriftAnalysis($datasetId: String!, $featureIds: [String!]!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: false, analyzerTypes: ["drift"], columns: $featureIds, datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...IdData
    targetLevel
    column
    isAnomaly
    isFalseAlarm
    metric
    ...AlgorithmData
    ...AnalyzerInfoData
    ...TimestampData
    ...DriftData
  }
}
    ${IdDataFragmentDoc}
${AlgorithmDataFragmentDoc}
${AnalyzerInfoDataFragmentDoc}
${TimestampDataFragmentDoc}
${DriftDataFragmentDoc}`;

/**
 * __useGetMultiFeatureDriftAnalysisQuery__
 *
 * To run a query within a React component, call `useGetMultiFeatureDriftAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiFeatureDriftAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiFeatureDriftAnalysisQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      featureIds: // value for 'featureIds'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetMultiFeatureDriftAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetMultiFeatureDriftAnalysisQuery, GetMultiFeatureDriftAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultiFeatureDriftAnalysisQuery, GetMultiFeatureDriftAnalysisQueryVariables>(GetMultiFeatureDriftAnalysisDocument, options);
      }
export function useGetMultiFeatureDriftAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultiFeatureDriftAnalysisQuery, GetMultiFeatureDriftAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultiFeatureDriftAnalysisQuery, GetMultiFeatureDriftAnalysisQueryVariables>(GetMultiFeatureDriftAnalysisDocument, options);
        }
export type GetMultiFeatureDriftAnalysisQueryHookResult = ReturnType<typeof useGetMultiFeatureDriftAnalysisQuery>;
export type GetMultiFeatureDriftAnalysisLazyQueryHookResult = ReturnType<typeof useGetMultiFeatureDriftAnalysisLazyQuery>;
export type GetMultiFeatureDriftAnalysisQueryResult = Apollo.QueryResult<GetMultiFeatureDriftAnalysisQuery, GetMultiFeatureDriftAnalysisQueryVariables>;
export const GetNotificationActionsDocument = gql`
    query getNotificationActions {
  user {
    organization {
      notifications {
        email {
          general {
            id
            enabled
          }
        }
        slack {
          general {
            id
            enabled
          }
        }
        pagerDuty {
          general {
            id
            enabled
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetNotificationActionsQuery__
 *
 * To run a query within a React component, call `useGetNotificationActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationActionsQuery, GetNotificationActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationActionsQuery, GetNotificationActionsQueryVariables>(GetNotificationActionsDocument, options);
      }
export function useGetNotificationActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationActionsQuery, GetNotificationActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationActionsQuery, GetNotificationActionsQueryVariables>(GetNotificationActionsDocument, options);
        }
export type GetNotificationActionsQueryHookResult = ReturnType<typeof useGetNotificationActionsQuery>;
export type GetNotificationActionsLazyQueryHookResult = ReturnType<typeof useGetNotificationActionsLazyQuery>;
export type GetNotificationActionsQueryResult = Apollo.QueryResult<GetNotificationActionsQuery, GetNotificationActionsQueryVariables>;
export const GetDriftAnalysisDocument = gql`
    query getDriftAnalysis($model: String!, $feature: String!, $anomaliesOnly: Boolean!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, columns: [$feature], datasetId: $model, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, metrics: [FREQUENT_ITEMS, HISTOGRAM], adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...DriftAnalysisData
  }
}
    ${DriftAnalysisDataFragmentDoc}`;

/**
 * __useGetDriftAnalysisQuery__
 *
 * To run a query within a React component, call `useGetDriftAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriftAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriftAnalysisQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetDriftAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetDriftAnalysisQuery, GetDriftAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriftAnalysisQuery, GetDriftAnalysisQueryVariables>(GetDriftAnalysisDocument, options);
      }
export function useGetDriftAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriftAnalysisQuery, GetDriftAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriftAnalysisQuery, GetDriftAnalysisQueryVariables>(GetDriftAnalysisDocument, options);
        }
export type GetDriftAnalysisQueryHookResult = ReturnType<typeof useGetDriftAnalysisQuery>;
export type GetDriftAnalysisLazyQueryHookResult = ReturnType<typeof useGetDriftAnalysisLazyQuery>;
export type GetDriftAnalysisQueryResult = Apollo.QueryResult<GetDriftAnalysisQuery, GetDriftAnalysisQueryVariables>;
export const GetUniqueAnalysisDocument = gql`
    query getUniqueAnalysis($datasetId: String!, $featureId: String!, $anomaliesOnly: Boolean!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, columns: [$featureId], datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, metrics: [UNIQUE_EST, UNIQUE_EST_RATIO], adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ThresholdAnalysisData
  }
}
    ${ThresholdAnalysisDataFragmentDoc}`;

/**
 * __useGetUniqueAnalysisQuery__
 *
 * To run a query within a React component, call `useGetUniqueAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueAnalysisQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      featureId: // value for 'featureId'
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetUniqueAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetUniqueAnalysisQuery, GetUniqueAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueAnalysisQuery, GetUniqueAnalysisQueryVariables>(GetUniqueAnalysisDocument, options);
      }
export function useGetUniqueAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueAnalysisQuery, GetUniqueAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueAnalysisQuery, GetUniqueAnalysisQueryVariables>(GetUniqueAnalysisDocument, options);
        }
export type GetUniqueAnalysisQueryHookResult = ReturnType<typeof useGetUniqueAnalysisQuery>;
export type GetUniqueAnalysisLazyQueryHookResult = ReturnType<typeof useGetUniqueAnalysisLazyQuery>;
export type GetUniqueAnalysisQueryResult = Apollo.QueryResult<GetUniqueAnalysisQuery, GetUniqueAnalysisQueryVariables>;
export const GetMissingAnalysisDocument = gql`
    query getMissingAnalysis($datasetId: String!, $featureId: String!, $anomaliesOnly: Boolean!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, columns: [$featureId], datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, metrics: [COUNT_NULL_RATIO, COUNT_NULL], adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ThresholdAnalysisData
  }
}
    ${ThresholdAnalysisDataFragmentDoc}`;

/**
 * __useGetMissingAnalysisQuery__
 *
 * To run a query within a React component, call `useGetMissingAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingAnalysisQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      featureId: // value for 'featureId'
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetMissingAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetMissingAnalysisQuery, GetMissingAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingAnalysisQuery, GetMissingAnalysisQueryVariables>(GetMissingAnalysisDocument, options);
      }
export function useGetMissingAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingAnalysisQuery, GetMissingAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingAnalysisQuery, GetMissingAnalysisQueryVariables>(GetMissingAnalysisDocument, options);
        }
export type GetMissingAnalysisQueryHookResult = ReturnType<typeof useGetMissingAnalysisQuery>;
export type GetMissingAnalysisLazyQueryHookResult = ReturnType<typeof useGetMissingAnalysisLazyQuery>;
export type GetMissingAnalysisQueryResult = Apollo.QueryResult<GetMissingAnalysisQuery, GetMissingAnalysisQueryVariables>;
export const GetInferredDataAnalysisDocument = gql`
    query getInferredDataAnalysis($datasetId: String!, $featureId: String!, $anomaliesOnly: Boolean!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, columns: [$featureId], datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, metrics: INFERRED_DATA_TYPE, adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ThresholdAnalysisData
    comparison_expected
    comparison_observed
  }
}
    ${ThresholdAnalysisDataFragmentDoc}`;

/**
 * __useGetInferredDataAnalysisQuery__
 *
 * To run a query within a React component, call `useGetInferredDataAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInferredDataAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInferredDataAnalysisQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      featureId: // value for 'featureId'
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetInferredDataAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetInferredDataAnalysisQuery, GetInferredDataAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInferredDataAnalysisQuery, GetInferredDataAnalysisQueryVariables>(GetInferredDataAnalysisDocument, options);
      }
export function useGetInferredDataAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInferredDataAnalysisQuery, GetInferredDataAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInferredDataAnalysisQuery, GetInferredDataAnalysisQueryVariables>(GetInferredDataAnalysisDocument, options);
        }
export type GetInferredDataAnalysisQueryHookResult = ReturnType<typeof useGetInferredDataAnalysisQuery>;
export type GetInferredDataAnalysisLazyQueryHookResult = ReturnType<typeof useGetInferredDataAnalysisLazyQuery>;
export type GetInferredDataAnalysisQueryResult = Apollo.QueryResult<GetInferredDataAnalysisQuery, GetInferredDataAnalysisQueryVariables>;
export const GetMonitorAnomalyCountDocument = gql`
    query getMonitorAnomalyCount($datasetId: String!, $from: Float!, $to: Float) {
  model(id: $datasetId) {
    anomalies(filter: {fromTimestamp: $from, toTimestamp: $to, anomaliesOnly: true}, sortDirection: ASC) {
      analyzerId
      isFalseAlarm
    }
  }
}
    `;

/**
 * __useGetMonitorAnomalyCountQuery__
 *
 * To run a query within a React component, call `useGetMonitorAnomalyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonitorAnomalyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonitorAnomalyCountQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMonitorAnomalyCountQuery(baseOptions: Apollo.QueryHookOptions<GetMonitorAnomalyCountQuery, GetMonitorAnomalyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonitorAnomalyCountQuery, GetMonitorAnomalyCountQueryVariables>(GetMonitorAnomalyCountDocument, options);
      }
export function useGetMonitorAnomalyCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonitorAnomalyCountQuery, GetMonitorAnomalyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonitorAnomalyCountQuery, GetMonitorAnomalyCountQueryVariables>(GetMonitorAnomalyCountDocument, options);
        }
export type GetMonitorAnomalyCountQueryHookResult = ReturnType<typeof useGetMonitorAnomalyCountQuery>;
export type GetMonitorAnomalyCountLazyQueryHookResult = ReturnType<typeof useGetMonitorAnomalyCountLazyQuery>;
export type GetMonitorAnomalyCountQueryResult = Apollo.QueryResult<GetMonitorAnomalyCountQuery, GetMonitorAnomalyCountQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  user {
    name
    auth0Id
    whyLabsId
    isAuthenticated
    role
    permissions
    email
    emailVerified
    metadata {
      masquerade {
        isActiveNow
        expiration
      }
    }
    organization {
      id
      name
      subscriptionTier
      isAWSMarketplace
      membershipType
      anonymousSession {
        enabled
        valid
      }
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetFeatureUnifiedBinsDocument = gql`
    query getFeatureUnifiedBins($modelId: String!, $featureId: String!, $timestamps: [Float!], $staticProfileIds: [String!], $splitpoints: [Float!]) {
  model(id: $modelId) {
    batches(timestamps: $timestamps) {
      ...BatchProfilesSketches
    }
    referenceProfiles(profileIds: $staticProfileIds) {
      ...StaticProfilesSketches
    }
  }
}
    ${BatchProfilesSketchesFragmentDoc}
${StaticProfilesSketchesFragmentDoc}`;

/**
 * __useGetFeatureUnifiedBinsQuery__
 *
 * To run a query within a React component, call `useGetFeatureUnifiedBinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureUnifiedBinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureUnifiedBinsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      featureId: // value for 'featureId'
 *      timestamps: // value for 'timestamps'
 *      staticProfileIds: // value for 'staticProfileIds'
 *      splitpoints: // value for 'splitpoints'
 *   },
 * });
 */
export function useGetFeatureUnifiedBinsQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureUnifiedBinsQuery, GetFeatureUnifiedBinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureUnifiedBinsQuery, GetFeatureUnifiedBinsQueryVariables>(GetFeatureUnifiedBinsDocument, options);
      }
export function useGetFeatureUnifiedBinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureUnifiedBinsQuery, GetFeatureUnifiedBinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureUnifiedBinsQuery, GetFeatureUnifiedBinsQueryVariables>(GetFeatureUnifiedBinsDocument, options);
        }
export type GetFeatureUnifiedBinsQueryHookResult = ReturnType<typeof useGetFeatureUnifiedBinsQuery>;
export type GetFeatureUnifiedBinsLazyQueryHookResult = ReturnType<typeof useGetFeatureUnifiedBinsLazyQuery>;
export type GetFeatureUnifiedBinsQueryResult = Apollo.QueryResult<GetFeatureUnifiedBinsQuery, GetFeatureUnifiedBinsQueryVariables>;
export const GetSegmentCountDocument = gql`
    query getSegmentCount($model: String!) {
  model(id: $model) {
    id
    totalSegments
  }
}
    `;

/**
 * __useGetSegmentCountQuery__
 *
 * To run a query within a React component, call `useGetSegmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentCountQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useGetSegmentCountQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentCountQuery, GetSegmentCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentCountQuery, GetSegmentCountQueryVariables>(GetSegmentCountDocument, options);
      }
export function useGetSegmentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentCountQuery, GetSegmentCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentCountQuery, GetSegmentCountQueryVariables>(GetSegmentCountDocument, options);
        }
export type GetSegmentCountQueryHookResult = ReturnType<typeof useGetSegmentCountQuery>;
export type GetSegmentCountLazyQueryHookResult = ReturnType<typeof useGetSegmentCountLazyQuery>;
export type GetSegmentCountQueryResult = Apollo.QueryResult<GetSegmentCountQuery, GetSegmentCountQueryVariables>;
export const GetModelBatchFrequencyDocument = gql`
    query getModelBatchFrequency($model: String!) {
  model(id: $model) {
    name
    batchFrequency
  }
}
    `;

/**
 * __useGetModelBatchFrequencyQuery__
 *
 * To run a query within a React component, call `useGetModelBatchFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelBatchFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelBatchFrequencyQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useGetModelBatchFrequencyQuery(baseOptions: Apollo.QueryHookOptions<GetModelBatchFrequencyQuery, GetModelBatchFrequencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelBatchFrequencyQuery, GetModelBatchFrequencyQueryVariables>(GetModelBatchFrequencyDocument, options);
      }
export function useGetModelBatchFrequencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelBatchFrequencyQuery, GetModelBatchFrequencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelBatchFrequencyQuery, GetModelBatchFrequencyQueryVariables>(GetModelBatchFrequencyDocument, options);
        }
export type GetModelBatchFrequencyQueryHookResult = ReturnType<typeof useGetModelBatchFrequencyQuery>;
export type GetModelBatchFrequencyLazyQueryHookResult = ReturnType<typeof useGetModelBatchFrequencyLazyQuery>;
export type GetModelBatchFrequencyQueryResult = Apollo.QueryResult<GetModelBatchFrequencyQuery, GetModelBatchFrequencyQueryVariables>;
export const GetResourceBasicDataDocument = gql`
    query getResourceBasicData($resourceId: String!) {
  model(id: $resourceId) {
    ...ModelCommonData
    modelType
    assetCategory
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetResourceBasicDataQuery__
 *
 * To run a query within a React component, call `useGetResourceBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceBasicDataQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useGetResourceBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetResourceBasicDataQuery, GetResourceBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceBasicDataQuery, GetResourceBasicDataQueryVariables>(GetResourceBasicDataDocument, options);
      }
export function useGetResourceBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceBasicDataQuery, GetResourceBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceBasicDataQuery, GetResourceBasicDataQueryVariables>(GetResourceBasicDataDocument, options);
        }
export type GetResourceBasicDataQueryHookResult = ReturnType<typeof useGetResourceBasicDataQuery>;
export type GetResourceBasicDataLazyQueryHookResult = ReturnType<typeof useGetResourceBasicDataLazyQuery>;
export type GetResourceBasicDataQueryResult = Apollo.QueryResult<GetResourceBasicDataQuery, GetResourceBasicDataQueryVariables>;
export const GetModelCountDocument = gql`
    query getModelCount {
  models {
    ...ModelCommonData
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetModelCountQuery__
 *
 * To run a query within a React component, call `useGetModelCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelCountQuery(baseOptions?: Apollo.QueryHookOptions<GetModelCountQuery, GetModelCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelCountQuery, GetModelCountQueryVariables>(GetModelCountDocument, options);
      }
export function useGetModelCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelCountQuery, GetModelCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelCountQuery, GetModelCountQueryVariables>(GetModelCountDocument, options);
        }
export type GetModelCountQueryHookResult = ReturnType<typeof useGetModelCountQuery>;
export type GetModelCountLazyQueryHookResult = ReturnType<typeof useGetModelCountLazyQuery>;
export type GetModelCountQueryResult = Apollo.QueryResult<GetModelCountQuery, GetModelCountQueryVariables>;
export const GetTotalAlertsByTimeDocument = gql`
    query getTotalAlertsByTime($from: Float!, $to: Float) {
  models {
    name
    anomalyCounts: allAnomalyCounts(fromTimestamp: $from, toTimestamp: $to) {
      totals {
        category
        count
      }
      timeseries {
        timestamp
        counts {
          category
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetTotalAlertsByTimeQuery__
 *
 * To run a query within a React component, call `useGetTotalAlertsByTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalAlertsByTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalAlertsByTimeQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetTotalAlertsByTimeQuery(baseOptions: Apollo.QueryHookOptions<GetTotalAlertsByTimeQuery, GetTotalAlertsByTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalAlertsByTimeQuery, GetTotalAlertsByTimeQueryVariables>(GetTotalAlertsByTimeDocument, options);
      }
export function useGetTotalAlertsByTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalAlertsByTimeQuery, GetTotalAlertsByTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalAlertsByTimeQuery, GetTotalAlertsByTimeQueryVariables>(GetTotalAlertsByTimeDocument, options);
        }
export type GetTotalAlertsByTimeQueryHookResult = ReturnType<typeof useGetTotalAlertsByTimeQuery>;
export type GetTotalAlertsByTimeLazyQueryHookResult = ReturnType<typeof useGetTotalAlertsByTimeLazyQuery>;
export type GetTotalAlertsByTimeQueryResult = Apollo.QueryResult<GetTotalAlertsByTimeQuery, GetTotalAlertsByTimeQueryVariables>;
export const GetModelFeaturesDocument = gql`
    query getModelFeatures($modelId: String!) {
  model(id: $modelId) {
    id
    entitySchema {
      hasInputs
      inputs {
        name
      }
    }
  }
}
    `;

/**
 * __useGetModelFeaturesQuery__
 *
 * To run a query within a React component, call `useGetModelFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelFeaturesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetModelFeaturesQuery, GetModelFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelFeaturesQuery, GetModelFeaturesQueryVariables>(GetModelFeaturesDocument, options);
      }
export function useGetModelFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelFeaturesQuery, GetModelFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelFeaturesQuery, GetModelFeaturesQueryVariables>(GetModelFeaturesDocument, options);
        }
export type GetModelFeaturesQueryHookResult = ReturnType<typeof useGetModelFeaturesQuery>;
export type GetModelFeaturesLazyQueryHookResult = ReturnType<typeof useGetModelFeaturesLazyQuery>;
export type GetModelFeaturesQueryResult = Apollo.QueryResult<GetModelFeaturesQuery, GetModelFeaturesQueryVariables>;
export const ListOrgAvailableResourceTagsDocument = gql`
    query listOrgAvailableResourceTags {
  user {
    organization {
      availableResourceTags {
        backgroundColor
        color
        value
        key
      }
    }
  }
}
    `;

/**
 * __useListOrgAvailableResourceTagsQuery__
 *
 * To run a query within a React component, call `useListOrgAvailableResourceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrgAvailableResourceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrgAvailableResourceTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrgAvailableResourceTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListOrgAvailableResourceTagsQuery, ListOrgAvailableResourceTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrgAvailableResourceTagsQuery, ListOrgAvailableResourceTagsQueryVariables>(ListOrgAvailableResourceTagsDocument, options);
      }
export function useListOrgAvailableResourceTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrgAvailableResourceTagsQuery, ListOrgAvailableResourceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrgAvailableResourceTagsQuery, ListOrgAvailableResourceTagsQueryVariables>(ListOrgAvailableResourceTagsDocument, options);
        }
export type ListOrgAvailableResourceTagsQueryHookResult = ReturnType<typeof useListOrgAvailableResourceTagsQuery>;
export type ListOrgAvailableResourceTagsLazyQueryHookResult = ReturnType<typeof useListOrgAvailableResourceTagsLazyQuery>;
export type ListOrgAvailableResourceTagsQueryResult = Apollo.QueryResult<ListOrgAvailableResourceTagsQuery, ListOrgAvailableResourceTagsQueryVariables>;
export const GetModelOverviewInformationDocument = gql`
    query getModelOverviewInformation($from: Float!, $to: Float!, $resourceTags: [CustomTagFilter!], $resourceType: [ModelType!], $onlySecuredLLM: Boolean, $searchTerm: String) {
  models(resourceTags: $resourceTags, resourceType: $resourceType, onlySecuredLLM: $onlySecuredLLM, searchTerm: $searchTerm) {
    ...ModelOverviewInfo
  }
}
    ${ModelOverviewInfoFragmentDoc}`;

/**
 * __useGetModelOverviewInformationQuery__
 *
 * To run a query within a React component, call `useGetModelOverviewInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelOverviewInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelOverviewInformationQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      resourceTags: // value for 'resourceTags'
 *      resourceType: // value for 'resourceType'
 *      onlySecuredLLM: // value for 'onlySecuredLLM'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetModelOverviewInformationQuery(baseOptions: Apollo.QueryHookOptions<GetModelOverviewInformationQuery, GetModelOverviewInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelOverviewInformationQuery, GetModelOverviewInformationQueryVariables>(GetModelOverviewInformationDocument, options);
      }
export function useGetModelOverviewInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelOverviewInformationQuery, GetModelOverviewInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelOverviewInformationQuery, GetModelOverviewInformationQueryVariables>(GetModelOverviewInformationDocument, options);
        }
export type GetModelOverviewInformationQueryHookResult = ReturnType<typeof useGetModelOverviewInformationQuery>;
export type GetModelOverviewInformationLazyQueryHookResult = ReturnType<typeof useGetModelOverviewInformationLazyQuery>;
export type GetModelOverviewInformationQueryResult = Apollo.QueryResult<GetModelOverviewInformationQuery, GetModelOverviewInformationQueryVariables>;
export const GetAllResourcesForOverviewPageDocument = gql`
    query getAllResourcesForOverviewPage {
  models {
    id
    assetCategory
    dataLineage {
      oldestProfileTimestamp
    }
    resourceTags {
      backgroundColor
      color
      key
      value
    }
  }
}
    `;

/**
 * __useGetAllResourcesForOverviewPageQuery__
 *
 * To run a query within a React component, call `useGetAllResourcesForOverviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllResourcesForOverviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllResourcesForOverviewPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllResourcesForOverviewPageQuery(baseOptions?: Apollo.QueryHookOptions<GetAllResourcesForOverviewPageQuery, GetAllResourcesForOverviewPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllResourcesForOverviewPageQuery, GetAllResourcesForOverviewPageQueryVariables>(GetAllResourcesForOverviewPageDocument, options);
      }
export function useGetAllResourcesForOverviewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllResourcesForOverviewPageQuery, GetAllResourcesForOverviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllResourcesForOverviewPageQuery, GetAllResourcesForOverviewPageQueryVariables>(GetAllResourcesForOverviewPageDocument, options);
        }
export type GetAllResourcesForOverviewPageQueryHookResult = ReturnType<typeof useGetAllResourcesForOverviewPageQuery>;
export type GetAllResourcesForOverviewPageLazyQueryHookResult = ReturnType<typeof useGetAllResourcesForOverviewPageLazyQuery>;
export type GetAllResourcesForOverviewPageQueryResult = Apollo.QueryResult<GetAllResourcesForOverviewPageQuery, GetAllResourcesForOverviewPageQueryVariables>;
export const GetLatestTimestampDocument = gql`
    query getLatestTimestamp($modelId: String!) {
  model(id: $modelId) {
    dataAvailability {
      latestTimestamp
      oldestTimestamp
    }
  }
}
    `;

/**
 * __useGetLatestTimestampQuery__
 *
 * To run a query within a React component, call `useGetLatestTimestampQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTimestampQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTimestampQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetLatestTimestampQuery(baseOptions: Apollo.QueryHookOptions<GetLatestTimestampQuery, GetLatestTimestampQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestTimestampQuery, GetLatestTimestampQueryVariables>(GetLatestTimestampDocument, options);
      }
export function useGetLatestTimestampLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestTimestampQuery, GetLatestTimestampQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestTimestampQuery, GetLatestTimestampQueryVariables>(GetLatestTimestampDocument, options);
        }
export type GetLatestTimestampQueryHookResult = ReturnType<typeof useGetLatestTimestampQuery>;
export type GetLatestTimestampLazyQueryHookResult = ReturnType<typeof useGetLatestTimestampLazyQuery>;
export type GetLatestTimestampQueryResult = Apollo.QueryResult<GetLatestTimestampQuery, GetLatestTimestampQueryVariables>;
export const GetSegmentLatestTimestampDocument = gql`
    query getSegmentLatestTimestamp($modelId: String!, $tags: [SegmentTagFilter!]) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      dataAvailability {
        latestTimestamp
        oldestTimestamp
      }
    }
  }
}
    `;

/**
 * __useGetSegmentLatestTimestampQuery__
 *
 * To run a query within a React component, call `useGetSegmentLatestTimestampQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentLatestTimestampQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentLatestTimestampQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentLatestTimestampQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentLatestTimestampQuery, GetSegmentLatestTimestampQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentLatestTimestampQuery, GetSegmentLatestTimestampQueryVariables>(GetSegmentLatestTimestampDocument, options);
      }
export function useGetSegmentLatestTimestampLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentLatestTimestampQuery, GetSegmentLatestTimestampQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentLatestTimestampQuery, GetSegmentLatestTimestampQueryVariables>(GetSegmentLatestTimestampDocument, options);
        }
export type GetSegmentLatestTimestampQueryHookResult = ReturnType<typeof useGetSegmentLatestTimestampQuery>;
export type GetSegmentLatestTimestampLazyQueryHookResult = ReturnType<typeof useGetSegmentLatestTimestampLazyQuery>;
export type GetSegmentLatestTimestampQueryResult = Apollo.QueryResult<GetSegmentLatestTimestampQuery, GetSegmentLatestTimestampQueryVariables>;
export const GetSegmentProfileLineageDocument = gql`
    query getSegmentProfileLineage($modelId: String!, $tags: [SegmentTagFilter!]) {
  model(id: $modelId) {
    batchFrequency
    assetCategory
    segment(tags: $tags) {
      dataLineage {
        latestProfileTimestamp
        oldestProfileTimestamp
      }
    }
  }
}
    `;

/**
 * __useGetSegmentProfileLineageQuery__
 *
 * To run a query within a React component, call `useGetSegmentProfileLineageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentProfileLineageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentProfileLineageQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentProfileLineageQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentProfileLineageQuery, GetSegmentProfileLineageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentProfileLineageQuery, GetSegmentProfileLineageQueryVariables>(GetSegmentProfileLineageDocument, options);
      }
export function useGetSegmentProfileLineageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentProfileLineageQuery, GetSegmentProfileLineageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentProfileLineageQuery, GetSegmentProfileLineageQueryVariables>(GetSegmentProfileLineageDocument, options);
        }
export type GetSegmentProfileLineageQueryHookResult = ReturnType<typeof useGetSegmentProfileLineageQuery>;
export type GetSegmentProfileLineageLazyQueryHookResult = ReturnType<typeof useGetSegmentProfileLineageLazyQuery>;
export type GetSegmentProfileLineageQueryResult = Apollo.QueryResult<GetSegmentProfileLineageQuery, GetSegmentProfileLineageQueryVariables>;
export const ListOrgResourcesCommonDataWithLineageDocument = gql`
    query listOrgResourcesCommonDataWithLineage {
  models {
    ...ModelCommonData
    modelType
    datasetMetrics {
      name
    }
    dataLineage {
      latestProfileTimestamp
      oldestProfileTimestamp
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useListOrgResourcesCommonDataWithLineageQuery__
 *
 * To run a query within a React component, call `useListOrgResourcesCommonDataWithLineageQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrgResourcesCommonDataWithLineageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrgResourcesCommonDataWithLineageQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrgResourcesCommonDataWithLineageQuery(baseOptions?: Apollo.QueryHookOptions<ListOrgResourcesCommonDataWithLineageQuery, ListOrgResourcesCommonDataWithLineageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrgResourcesCommonDataWithLineageQuery, ListOrgResourcesCommonDataWithLineageQueryVariables>(ListOrgResourcesCommonDataWithLineageDocument, options);
      }
export function useListOrgResourcesCommonDataWithLineageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrgResourcesCommonDataWithLineageQuery, ListOrgResourcesCommonDataWithLineageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrgResourcesCommonDataWithLineageQuery, ListOrgResourcesCommonDataWithLineageQueryVariables>(ListOrgResourcesCommonDataWithLineageDocument, options);
        }
export type ListOrgResourcesCommonDataWithLineageQueryHookResult = ReturnType<typeof useListOrgResourcesCommonDataWithLineageQuery>;
export type ListOrgResourcesCommonDataWithLineageLazyQueryHookResult = ReturnType<typeof useListOrgResourcesCommonDataWithLineageLazyQuery>;
export type ListOrgResourcesCommonDataWithLineageQueryResult = Apollo.QueryResult<ListOrgResourcesCommonDataWithLineageQuery, ListOrgResourcesCommonDataWithLineageQueryVariables>;
export const GetBatchFrequencyDocument = gql`
    query getBatchFrequency($modelId: String!) {
  model(id: $modelId) {
    batchFrequency
  }
}
    `;

/**
 * __useGetBatchFrequencyQuery__
 *
 * To run a query within a React component, call `useGetBatchFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchFrequencyQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetBatchFrequencyQuery(baseOptions: Apollo.QueryHookOptions<GetBatchFrequencyQuery, GetBatchFrequencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchFrequencyQuery, GetBatchFrequencyQueryVariables>(GetBatchFrequencyDocument, options);
      }
export function useGetBatchFrequencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchFrequencyQuery, GetBatchFrequencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchFrequencyQuery, GetBatchFrequencyQueryVariables>(GetBatchFrequencyDocument, options);
        }
export type GetBatchFrequencyQueryHookResult = ReturnType<typeof useGetBatchFrequencyQuery>;
export type GetBatchFrequencyLazyQueryHookResult = ReturnType<typeof useGetBatchFrequencyLazyQuery>;
export type GetBatchFrequencyQueryResult = Apollo.QueryResult<GetBatchFrequencyQuery, GetBatchFrequencyQueryVariables>;
export const GetColumnActiveMonitorsDocument = gql`
    query getColumnActiveMonitors($resourceId: String!, $columnId: String!, $tags: [SegmentTagFilter!]) {
  columnMonitors(datasetId: $resourceId, columnId: $columnId, tags: $tags)
}
    `;

/**
 * __useGetColumnActiveMonitorsQuery__
 *
 * To run a query within a React component, call `useGetColumnActiveMonitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColumnActiveMonitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColumnActiveMonitorsQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      columnId: // value for 'columnId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetColumnActiveMonitorsQuery(baseOptions: Apollo.QueryHookOptions<GetColumnActiveMonitorsQuery, GetColumnActiveMonitorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetColumnActiveMonitorsQuery, GetColumnActiveMonitorsQueryVariables>(GetColumnActiveMonitorsDocument, options);
      }
export function useGetColumnActiveMonitorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetColumnActiveMonitorsQuery, GetColumnActiveMonitorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetColumnActiveMonitorsQuery, GetColumnActiveMonitorsQueryVariables>(GetColumnActiveMonitorsDocument, options);
        }
export type GetColumnActiveMonitorsQueryHookResult = ReturnType<typeof useGetColumnActiveMonitorsQuery>;
export type GetColumnActiveMonitorsLazyQueryHookResult = ReturnType<typeof useGetColumnActiveMonitorsLazyQuery>;
export type GetColumnActiveMonitorsQueryResult = Apollo.QueryResult<GetColumnActiveMonitorsQuery, GetColumnActiveMonitorsQueryVariables>;
export const GetAvailableFilteredFeaturesDocument = gql`
    query getAvailableFilteredFeatures($model: String!, $from: Float!, $to: Float, $offset: Int, $limit: Int, $filter: FeatureFilter!, $adHocRunId: String, $sort: FilteredFeaturesSort, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      entitySchema {
        inputCounts {
          total
        }
      }
      filteredFeatures(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
        totalCount
        results {
          ...FeatureDataSideTable
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${FeatureDataSideTableFragmentDoc}`;

/**
 * __useGetAvailableFilteredFeaturesQuery__
 *
 * To run a query within a React component, call `useGetAvailableFilteredFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableFilteredFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableFilteredFeaturesQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      adHocRunId: // value for 'adHocRunId'
 *      sort: // value for 'sort'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetAvailableFilteredFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableFilteredFeaturesQuery, GetAvailableFilteredFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableFilteredFeaturesQuery, GetAvailableFilteredFeaturesQueryVariables>(GetAvailableFilteredFeaturesDocument, options);
      }
export function useGetAvailableFilteredFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableFilteredFeaturesQuery, GetAvailableFilteredFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableFilteredFeaturesQuery, GetAvailableFilteredFeaturesQueryVariables>(GetAvailableFilteredFeaturesDocument, options);
        }
export type GetAvailableFilteredFeaturesQueryHookResult = ReturnType<typeof useGetAvailableFilteredFeaturesQuery>;
export type GetAvailableFilteredFeaturesLazyQueryHookResult = ReturnType<typeof useGetAvailableFilteredFeaturesLazyQuery>;
export type GetAvailableFilteredFeaturesQueryResult = Apollo.QueryResult<GetAvailableFilteredFeaturesQuery, GetAvailableFilteredFeaturesQueryVariables>;
export const GetFilteredColumnNamesDocument = gql`
    query getFilteredColumnNames($model: String!, $offset: Int, $limit: Int, $filter: FeatureFilter!, $includeFeatures: Boolean!, $includeOutputs: Boolean!, $sort: FilteredFeaturesSort, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    segment(tags: $tags) {
      filteredFeatures(offset: $offset, limit: $limit, filter: $filter, sort: $sort) @include(if: $includeFeatures) {
        results {
          name
        }
      }
      filteredOutputs(offset: $offset, limit: $limit, filter: $filter, sort: $sort) @include(if: $includeOutputs) {
        results {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetFilteredColumnNamesQuery__
 *
 * To run a query within a React component, call `useGetFilteredColumnNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredColumnNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredColumnNamesQuery({
 *   variables: {
 *      model: // value for 'model'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      includeFeatures: // value for 'includeFeatures'
 *      includeOutputs: // value for 'includeOutputs'
 *      sort: // value for 'sort'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetFilteredColumnNamesQuery(baseOptions: Apollo.QueryHookOptions<GetFilteredColumnNamesQuery, GetFilteredColumnNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredColumnNamesQuery, GetFilteredColumnNamesQueryVariables>(GetFilteredColumnNamesDocument, options);
      }
export function useGetFilteredColumnNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredColumnNamesQuery, GetFilteredColumnNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredColumnNamesQuery, GetFilteredColumnNamesQueryVariables>(GetFilteredColumnNamesDocument, options);
        }
export type GetFilteredColumnNamesQueryHookResult = ReturnType<typeof useGetFilteredColumnNamesQuery>;
export type GetFilteredColumnNamesLazyQueryHookResult = ReturnType<typeof useGetFilteredColumnNamesLazyQuery>;
export type GetFilteredColumnNamesQueryResult = Apollo.QueryResult<GetFilteredColumnNamesQuery, GetFilteredColumnNamesQueryVariables>;
export const GetAvailableModelFeaturesDiscreteDocument = gql`
    query getAvailableModelFeaturesDiscrete($model: String!) {
  model(id: $model) {
    id
    name
    entitySchema {
      inputCounts {
        total
        discrete
        nonDiscrete
      }
      outputCounts {
        total
        discrete
        nonDiscrete
      }
    }
  }
}
    `;

/**
 * __useGetAvailableModelFeaturesDiscreteQuery__
 *
 * To run a query within a React component, call `useGetAvailableModelFeaturesDiscreteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableModelFeaturesDiscreteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableModelFeaturesDiscreteQuery({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useGetAvailableModelFeaturesDiscreteQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableModelFeaturesDiscreteQuery, GetAvailableModelFeaturesDiscreteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableModelFeaturesDiscreteQuery, GetAvailableModelFeaturesDiscreteQueryVariables>(GetAvailableModelFeaturesDiscreteDocument, options);
      }
export function useGetAvailableModelFeaturesDiscreteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableModelFeaturesDiscreteQuery, GetAvailableModelFeaturesDiscreteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableModelFeaturesDiscreteQuery, GetAvailableModelFeaturesDiscreteQueryVariables>(GetAvailableModelFeaturesDiscreteDocument, options);
        }
export type GetAvailableModelFeaturesDiscreteQueryHookResult = ReturnType<typeof useGetAvailableModelFeaturesDiscreteQuery>;
export type GetAvailableModelFeaturesDiscreteLazyQueryHookResult = ReturnType<typeof useGetAvailableModelFeaturesDiscreteLazyQuery>;
export type GetAvailableModelFeaturesDiscreteQueryResult = Apollo.QueryResult<GetAvailableModelFeaturesDiscreteQuery, GetAvailableModelFeaturesDiscreteQueryVariables>;
export const GetAvailableFilteredSegmentedFeaturesDocument = gql`
    query getAvailableFilteredSegmentedFeatures($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $offset: Int, $limit: Int, $filter: FeatureFilter!, $adHocRunId: String, $sort: FilteredFeaturesSort) {
  model(id: $model) {
    segment(tags: $tags) {
      filteredFeatures(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
        totalCount
        results {
          ...FeatureDataSideTable
        }
      }
    }
  }
}
    ${FeatureDataSideTableFragmentDoc}`;

/**
 * __useGetAvailableFilteredSegmentedFeaturesQuery__
 *
 * To run a query within a React component, call `useGetAvailableFilteredSegmentedFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableFilteredSegmentedFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableFilteredSegmentedFeaturesQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      adHocRunId: // value for 'adHocRunId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAvailableFilteredSegmentedFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableFilteredSegmentedFeaturesQuery, GetAvailableFilteredSegmentedFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableFilteredSegmentedFeaturesQuery, GetAvailableFilteredSegmentedFeaturesQueryVariables>(GetAvailableFilteredSegmentedFeaturesDocument, options);
      }
export function useGetAvailableFilteredSegmentedFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableFilteredSegmentedFeaturesQuery, GetAvailableFilteredSegmentedFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableFilteredSegmentedFeaturesQuery, GetAvailableFilteredSegmentedFeaturesQueryVariables>(GetAvailableFilteredSegmentedFeaturesDocument, options);
        }
export type GetAvailableFilteredSegmentedFeaturesQueryHookResult = ReturnType<typeof useGetAvailableFilteredSegmentedFeaturesQuery>;
export type GetAvailableFilteredSegmentedFeaturesLazyQueryHookResult = ReturnType<typeof useGetAvailableFilteredSegmentedFeaturesLazyQuery>;
export type GetAvailableFilteredSegmentedFeaturesQueryResult = Apollo.QueryResult<GetAvailableFilteredSegmentedFeaturesQuery, GetAvailableFilteredSegmentedFeaturesQueryVariables>;
export const GetAvailableFilteredOutputsDocument = gql`
    query getAvailableFilteredOutputs($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $offset: Int, $limit: Int, $filter: FeatureFilter!, $adHocRunId: String, $sort: FilteredFeaturesSort) {
  model(id: $model) {
    entitySchema {
      inputCounts {
        total
        discrete
        nonDiscrete
      }
      outputCounts {
        total
        discrete
        nonDiscrete
      }
    }
    segment(tags: $tags) {
      filteredOutputs(offset: $offset, limit: $limit, filter: $filter, sort: $sort) {
        totalCount
        results {
          ...FeatureDataSideTable
        }
      }
    }
  }
}
    ${FeatureDataSideTableFragmentDoc}`;

/**
 * __useGetAvailableFilteredOutputsQuery__
 *
 * To run a query within a React component, call `useGetAvailableFilteredOutputsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableFilteredOutputsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableFilteredOutputsQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      adHocRunId: // value for 'adHocRunId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAvailableFilteredOutputsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableFilteredOutputsQuery, GetAvailableFilteredOutputsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableFilteredOutputsQuery, GetAvailableFilteredOutputsQueryVariables>(GetAvailableFilteredOutputsDocument, options);
      }
export function useGetAvailableFilteredOutputsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableFilteredOutputsQuery, GetAvailableFilteredOutputsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableFilteredOutputsQuery, GetAvailableFilteredOutputsQueryVariables>(GetAvailableFilteredOutputsDocument, options);
        }
export type GetAvailableFilteredOutputsQueryHookResult = ReturnType<typeof useGetAvailableFilteredOutputsQuery>;
export type GetAvailableFilteredOutputsLazyQueryHookResult = ReturnType<typeof useGetAvailableFilteredOutputsLazyQuery>;
export type GetAvailableFilteredOutputsQueryResult = Apollo.QueryResult<GetAvailableFilteredOutputsQuery, GetAvailableFilteredOutputsQueryVariables>;
export const GetFeatureBasicDataDocument = gql`
    query getFeatureBasicData($model: String!, $feature: String!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    feature(name: $feature) {
      id
      ...BaselineFields
      sketches(from: $from, to: $to) {
        ...SketchCountFields
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${BaselineFieldsFragmentDoc}
${SketchCountFieldsFragmentDoc}`;

/**
 * __useGetFeatureBasicDataQuery__
 *
 * To run a query within a React component, call `useGetFeatureBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureBasicDataQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetFeatureBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureBasicDataQuery, GetFeatureBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureBasicDataQuery, GetFeatureBasicDataQueryVariables>(GetFeatureBasicDataDocument, options);
      }
export function useGetFeatureBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureBasicDataQuery, GetFeatureBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureBasicDataQuery, GetFeatureBasicDataQueryVariables>(GetFeatureBasicDataDocument, options);
        }
export type GetFeatureBasicDataQueryHookResult = ReturnType<typeof useGetFeatureBasicDataQuery>;
export type GetFeatureBasicDataLazyQueryHookResult = ReturnType<typeof useGetFeatureBasicDataLazyQuery>;
export type GetFeatureBasicDataQueryResult = Apollo.QueryResult<GetFeatureBasicDataQuery, GetFeatureBasicDataQueryVariables>;
export const GetSegmentedSingleOutputBasicDataDocument = gql`
    query getSegmentedSingleOutputBasicData($model: String!, $from: Float!, $to: Float, $outputName: String!, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      output(name: $outputName) {
        id
        ...BaselineFields
        sketches(from: $from, to: $to) {
          ...SketchCountFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${BaselineFieldsFragmentDoc}
${SketchCountFieldsFragmentDoc}`;

/**
 * __useGetSegmentedSingleOutputBasicDataQuery__
 *
 * To run a query within a React component, call `useGetSegmentedSingleOutputBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentedSingleOutputBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentedSingleOutputBasicDataQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentedSingleOutputBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentedSingleOutputBasicDataQuery, GetSegmentedSingleOutputBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentedSingleOutputBasicDataQuery, GetSegmentedSingleOutputBasicDataQueryVariables>(GetSegmentedSingleOutputBasicDataDocument, options);
      }
export function useGetSegmentedSingleOutputBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentedSingleOutputBasicDataQuery, GetSegmentedSingleOutputBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentedSingleOutputBasicDataQuery, GetSegmentedSingleOutputBasicDataQueryVariables>(GetSegmentedSingleOutputBasicDataDocument, options);
        }
export type GetSegmentedSingleOutputBasicDataQueryHookResult = ReturnType<typeof useGetSegmentedSingleOutputBasicDataQuery>;
export type GetSegmentedSingleOutputBasicDataLazyQueryHookResult = ReturnType<typeof useGetSegmentedSingleOutputBasicDataLazyQuery>;
export type GetSegmentedSingleOutputBasicDataQueryResult = Apollo.QueryResult<GetSegmentedSingleOutputBasicDataQuery, GetSegmentedSingleOutputBasicDataQueryVariables>;
export const GetMetricBasicDataDocument = gql`
    query getMetricBasicData($model: String!, $from: Float!, $to: Float, $metricName: String!) {
  model(id: $model) {
    ...ModelCommonData
    datasetMetric(name: $metricName) {
      ...DatasetMetricFields
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${DatasetMetricFieldsFragmentDoc}`;

/**
 * __useGetMetricBasicDataQuery__
 *
 * To run a query within a React component, call `useGetMetricBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricBasicDataQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      metricName: // value for 'metricName'
 *   },
 * });
 */
export function useGetMetricBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetMetricBasicDataQuery, GetMetricBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricBasicDataQuery, GetMetricBasicDataQueryVariables>(GetMetricBasicDataDocument, options);
      }
export function useGetMetricBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricBasicDataQuery, GetMetricBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricBasicDataQuery, GetMetricBasicDataQueryVariables>(GetMetricBasicDataDocument, options);
        }
export type GetMetricBasicDataQueryHookResult = ReturnType<typeof useGetMetricBasicDataQuery>;
export type GetMetricBasicDataLazyQueryHookResult = ReturnType<typeof useGetMetricBasicDataLazyQuery>;
export type GetMetricBasicDataQueryResult = Apollo.QueryResult<GetMetricBasicDataQuery, GetMetricBasicDataQueryVariables>;
export const GetSegmentedFeatureBasicDataDocument = gql`
    query getSegmentedFeatureBasicData($model: String!, $feature: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      feature(name: $feature) {
        id
        ...BaselineFields
        sketches(from: $from, to: $to) {
          ...SketchCountFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${BaselineFieldsFragmentDoc}
${SketchCountFieldsFragmentDoc}`;

/**
 * __useGetSegmentedFeatureBasicDataQuery__
 *
 * To run a query within a React component, call `useGetSegmentedFeatureBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentedFeatureBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentedFeatureBasicDataQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetSegmentedFeatureBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentedFeatureBasicDataQuery, GetSegmentedFeatureBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentedFeatureBasicDataQuery, GetSegmentedFeatureBasicDataQueryVariables>(GetSegmentedFeatureBasicDataDocument, options);
      }
export function useGetSegmentedFeatureBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentedFeatureBasicDataQuery, GetSegmentedFeatureBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentedFeatureBasicDataQuery, GetSegmentedFeatureBasicDataQueryVariables>(GetSegmentedFeatureBasicDataDocument, options);
        }
export type GetSegmentedFeatureBasicDataQueryHookResult = ReturnType<typeof useGetSegmentedFeatureBasicDataQuery>;
export type GetSegmentedFeatureBasicDataLazyQueryHookResult = ReturnType<typeof useGetSegmentedFeatureBasicDataLazyQuery>;
export type GetSegmentedFeatureBasicDataQueryResult = Apollo.QueryResult<GetSegmentedFeatureBasicDataQuery, GetSegmentedFeatureBasicDataQueryVariables>;
export const GetSegmentedOutputBasicDataDocument = gql`
    query getSegmentedOutputBasicData($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      output(name: $outputName) {
        id
        ...BaselineFields
        sketches(from: $from, to: $to) {
          ...SketchCountFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${BaselineFieldsFragmentDoc}
${SketchCountFieldsFragmentDoc}`;

/**
 * __useGetSegmentedOutputBasicDataQuery__
 *
 * To run a query within a React component, call `useGetSegmentedOutputBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentedOutputBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentedOutputBasicDataQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetSegmentedOutputBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentedOutputBasicDataQuery, GetSegmentedOutputBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentedOutputBasicDataQuery, GetSegmentedOutputBasicDataQueryVariables>(GetSegmentedOutputBasicDataDocument, options);
      }
export function useGetSegmentedOutputBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentedOutputBasicDataQuery, GetSegmentedOutputBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentedOutputBasicDataQuery, GetSegmentedOutputBasicDataQueryVariables>(GetSegmentedOutputBasicDataDocument, options);
        }
export type GetSegmentedOutputBasicDataQueryHookResult = ReturnType<typeof useGetSegmentedOutputBasicDataQuery>;
export type GetSegmentedOutputBasicDataLazyQueryHookResult = ReturnType<typeof useGetSegmentedOutputBasicDataLazyQuery>;
export type GetSegmentedOutputBasicDataQueryResult = Apollo.QueryResult<GetSegmentedOutputBasicDataQuery, GetSegmentedOutputBasicDataQueryVariables>;
export const GetSegmentedSingleFeatureBasicDataDocument = gql`
    query getSegmentedSingleFeatureBasicData($model: String!, $feature: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      feature(name: $feature) {
        id
        name
        schema {
          isDiscrete
          inferredType
        }
        sketches(from: $from, to: $to) {
          id
          datasetTimestamp
          lastUploadTimestamp
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetSegmentedSingleFeatureBasicDataQuery__
 *
 * To run a query within a React component, call `useGetSegmentedSingleFeatureBasicDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentedSingleFeatureBasicDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentedSingleFeatureBasicDataQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentedSingleFeatureBasicDataQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentedSingleFeatureBasicDataQuery, GetSegmentedSingleFeatureBasicDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentedSingleFeatureBasicDataQuery, GetSegmentedSingleFeatureBasicDataQueryVariables>(GetSegmentedSingleFeatureBasicDataDocument, options);
      }
export function useGetSegmentedSingleFeatureBasicDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentedSingleFeatureBasicDataQuery, GetSegmentedSingleFeatureBasicDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentedSingleFeatureBasicDataQuery, GetSegmentedSingleFeatureBasicDataQueryVariables>(GetSegmentedSingleFeatureBasicDataDocument, options);
        }
export type GetSegmentedSingleFeatureBasicDataQueryHookResult = ReturnType<typeof useGetSegmentedSingleFeatureBasicDataQuery>;
export type GetSegmentedSingleFeatureBasicDataLazyQueryHookResult = ReturnType<typeof useGetSegmentedSingleFeatureBasicDataLazyQuery>;
export type GetSegmentedSingleFeatureBasicDataQueryResult = Apollo.QueryResult<GetSegmentedSingleFeatureBasicDataQuery, GetSegmentedSingleFeatureBasicDataQueryVariables>;
export const GetOutputForModelDocument = gql`
    query getOutputForModel($model: String!, $from: Float!, $to: Float, $filter: FeatureSketchFilter) {
  model(id: $model) {
    ...ModelCommonData
    batches(from: $from, to: $to) {
      timestamp
      outputCount
      inputCount
      sketches(filter: $filter) {
        results {
          totalCount
          featureName
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetOutputForModelQuery__
 *
 * To run a query within a React component, call `useGetOutputForModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputForModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputForModelQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOutputForModelQuery(baseOptions: Apollo.QueryHookOptions<GetOutputForModelQuery, GetOutputForModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputForModelQuery, GetOutputForModelQueryVariables>(GetOutputForModelDocument, options);
      }
export function useGetOutputForModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputForModelQuery, GetOutputForModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputForModelQuery, GetOutputForModelQueryVariables>(GetOutputForModelDocument, options);
        }
export type GetOutputForModelQueryHookResult = ReturnType<typeof useGetOutputForModelQuery>;
export type GetOutputForModelLazyQueryHookResult = ReturnType<typeof useGetOutputForModelLazyQuery>;
export type GetOutputForModelQueryResult = Apollo.QueryResult<GetOutputForModelQuery, GetOutputForModelQueryVariables>;
export const GetOutputForSegmentDocument = gql`
    query getOutputForSegment($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $filter: FeatureSketchFilter) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      batches(from: $from, to: $to) {
        timestamp
        outputCount
        inputCount
        sketches(filter: $filter) {
          results {
            totalCount
            featureName
          }
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetOutputForSegmentQuery__
 *
 * To run a query within a React component, call `useGetOutputForSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputForSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputForSegmentQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOutputForSegmentQuery(baseOptions: Apollo.QueryHookOptions<GetOutputForSegmentQuery, GetOutputForSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputForSegmentQuery, GetOutputForSegmentQueryVariables>(GetOutputForSegmentDocument, options);
      }
export function useGetOutputForSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputForSegmentQuery, GetOutputForSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputForSegmentQuery, GetOutputForSegmentQueryVariables>(GetOutputForSegmentDocument, options);
        }
export type GetOutputForSegmentQueryHookResult = ReturnType<typeof useGetOutputForSegmentQuery>;
export type GetOutputForSegmentLazyQueryHookResult = ReturnType<typeof useGetOutputForSegmentLazyQuery>;
export type GetOutputForSegmentQueryResult = Apollo.QueryResult<GetOutputForSegmentQuery, GetOutputForSegmentQueryVariables>;
export const GetUniqueSummariesForFeatureDocument = gql`
    query getUniqueSummariesForFeature($model: String!, $feature: String!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    feature(name: $feature) {
      id
      sketches(from: $from, to: $to) {
        ...UniqueSketchFields
      }
      schema {
        isDiscrete
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${UniqueSketchFieldsFragmentDoc}`;

/**
 * __useGetUniqueSummariesForFeatureQuery__
 *
 * To run a query within a React component, call `useGetUniqueSummariesForFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueSummariesForFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueSummariesForFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetUniqueSummariesForFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetUniqueSummariesForFeatureQuery, GetUniqueSummariesForFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueSummariesForFeatureQuery, GetUniqueSummariesForFeatureQueryVariables>(GetUniqueSummariesForFeatureDocument, options);
      }
export function useGetUniqueSummariesForFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueSummariesForFeatureQuery, GetUniqueSummariesForFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueSummariesForFeatureQuery, GetUniqueSummariesForFeatureQueryVariables>(GetUniqueSummariesForFeatureDocument, options);
        }
export type GetUniqueSummariesForFeatureQueryHookResult = ReturnType<typeof useGetUniqueSummariesForFeatureQuery>;
export type GetUniqueSummariesForFeatureLazyQueryHookResult = ReturnType<typeof useGetUniqueSummariesForFeatureLazyQuery>;
export type GetUniqueSummariesForFeatureQueryResult = Apollo.QueryResult<GetUniqueSummariesForFeatureQuery, GetUniqueSummariesForFeatureQueryVariables>;
export const GetUniqueSummariesForOutputDocument = gql`
    query getUniqueSummariesForOutput($model: String!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    output(name: $outputName) {
      id
      sketches(from: $from, to: $to) {
        ...UniqueSketchFields
      }
      schema {
        isDiscrete
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${UniqueSketchFieldsFragmentDoc}`;

/**
 * __useGetUniqueSummariesForOutputQuery__
 *
 * To run a query within a React component, call `useGetUniqueSummariesForOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueSummariesForOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueSummariesForOutputQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetUniqueSummariesForOutputQuery(baseOptions: Apollo.QueryHookOptions<GetUniqueSummariesForOutputQuery, GetUniqueSummariesForOutputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueSummariesForOutputQuery, GetUniqueSummariesForOutputQueryVariables>(GetUniqueSummariesForOutputDocument, options);
      }
export function useGetUniqueSummariesForOutputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueSummariesForOutputQuery, GetUniqueSummariesForOutputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueSummariesForOutputQuery, GetUniqueSummariesForOutputQueryVariables>(GetUniqueSummariesForOutputDocument, options);
        }
export type GetUniqueSummariesForOutputQueryHookResult = ReturnType<typeof useGetUniqueSummariesForOutputQuery>;
export type GetUniqueSummariesForOutputLazyQueryHookResult = ReturnType<typeof useGetUniqueSummariesForOutputLazyQuery>;
export type GetUniqueSummariesForOutputQueryResult = Apollo.QueryResult<GetUniqueSummariesForOutputQuery, GetUniqueSummariesForOutputQueryVariables>;
export const GetUniqueSummariesForSegmentedFeatureDocument = gql`
    query getUniqueSummariesForSegmentedFeature($model: String!, $feature: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      feature(name: $feature) {
        id
        sketches(from: $from, to: $to) {
          ...UniqueSketchFields
        }
        schema {
          isDiscrete
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${UniqueSketchFieldsFragmentDoc}`;

/**
 * __useGetUniqueSummariesForSegmentedFeatureQuery__
 *
 * To run a query within a React component, call `useGetUniqueSummariesForSegmentedFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueSummariesForSegmentedFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueSummariesForSegmentedFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetUniqueSummariesForSegmentedFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetUniqueSummariesForSegmentedFeatureQuery, GetUniqueSummariesForSegmentedFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueSummariesForSegmentedFeatureQuery, GetUniqueSummariesForSegmentedFeatureQueryVariables>(GetUniqueSummariesForSegmentedFeatureDocument, options);
      }
export function useGetUniqueSummariesForSegmentedFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueSummariesForSegmentedFeatureQuery, GetUniqueSummariesForSegmentedFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueSummariesForSegmentedFeatureQuery, GetUniqueSummariesForSegmentedFeatureQueryVariables>(GetUniqueSummariesForSegmentedFeatureDocument, options);
        }
export type GetUniqueSummariesForSegmentedFeatureQueryHookResult = ReturnType<typeof useGetUniqueSummariesForSegmentedFeatureQuery>;
export type GetUniqueSummariesForSegmentedFeatureLazyQueryHookResult = ReturnType<typeof useGetUniqueSummariesForSegmentedFeatureLazyQuery>;
export type GetUniqueSummariesForSegmentedFeatureQueryResult = Apollo.QueryResult<GetUniqueSummariesForSegmentedFeatureQuery, GetUniqueSummariesForSegmentedFeatureQueryVariables>;
export const GetUniqueSummariesForSegmentedOutputDocument = gql`
    query getUniqueSummariesForSegmentedOutput($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      output(name: $outputName) {
        id
        sketches(from: $from, to: $to) {
          ...UniqueSketchFields
        }
        schema {
          isDiscrete
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${UniqueSketchFieldsFragmentDoc}`;

/**
 * __useGetUniqueSummariesForSegmentedOutputQuery__
 *
 * To run a query within a React component, call `useGetUniqueSummariesForSegmentedOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueSummariesForSegmentedOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueSummariesForSegmentedOutputQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetUniqueSummariesForSegmentedOutputQuery(baseOptions: Apollo.QueryHookOptions<GetUniqueSummariesForSegmentedOutputQuery, GetUniqueSummariesForSegmentedOutputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniqueSummariesForSegmentedOutputQuery, GetUniqueSummariesForSegmentedOutputQueryVariables>(GetUniqueSummariesForSegmentedOutputDocument, options);
      }
export function useGetUniqueSummariesForSegmentedOutputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniqueSummariesForSegmentedOutputQuery, GetUniqueSummariesForSegmentedOutputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniqueSummariesForSegmentedOutputQuery, GetUniqueSummariesForSegmentedOutputQueryVariables>(GetUniqueSummariesForSegmentedOutputDocument, options);
        }
export type GetUniqueSummariesForSegmentedOutputQueryHookResult = ReturnType<typeof useGetUniqueSummariesForSegmentedOutputQuery>;
export type GetUniqueSummariesForSegmentedOutputLazyQueryHookResult = ReturnType<typeof useGetUniqueSummariesForSegmentedOutputLazyQuery>;
export type GetUniqueSummariesForSegmentedOutputQueryResult = Apollo.QueryResult<GetUniqueSummariesForSegmentedOutputQuery, GetUniqueSummariesForSegmentedOutputQueryVariables>;
export const GetQuantileSummariesForMetricDocument = gql`
    query getQuantileSummariesForMetric($model: String!, $from: Float!, $to: Float, $metricName: String!) {
  model(id: $model) {
    ...ModelCommonData
    datasetMetric(name: $metricName) {
      ...DatasetMetricFields
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${DatasetMetricFieldsFragmentDoc}`;

/**
 * __useGetQuantileSummariesForMetricQuery__
 *
 * To run a query within a React component, call `useGetQuantileSummariesForMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuantileSummariesForMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuantileSummariesForMetricQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      metricName: // value for 'metricName'
 *   },
 * });
 */
export function useGetQuantileSummariesForMetricQuery(baseOptions: Apollo.QueryHookOptions<GetQuantileSummariesForMetricQuery, GetQuantileSummariesForMetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuantileSummariesForMetricQuery, GetQuantileSummariesForMetricQueryVariables>(GetQuantileSummariesForMetricDocument, options);
      }
export function useGetQuantileSummariesForMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuantileSummariesForMetricQuery, GetQuantileSummariesForMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuantileSummariesForMetricQuery, GetQuantileSummariesForMetricQueryVariables>(GetQuantileSummariesForMetricDocument, options);
        }
export type GetQuantileSummariesForMetricQueryHookResult = ReturnType<typeof useGetQuantileSummariesForMetricQuery>;
export type GetQuantileSummariesForMetricLazyQueryHookResult = ReturnType<typeof useGetQuantileSummariesForMetricLazyQuery>;
export type GetQuantileSummariesForMetricQueryResult = Apollo.QueryResult<GetQuantileSummariesForMetricQuery, GetQuantileSummariesForMetricQueryVariables>;
export const GetQuantileSummariesForSegmentedFeatureDocument = gql`
    query getQuantileSummariesForSegmentedFeature($model: String!, $feature: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      feature(name: $feature) {
        id
        sketches(from: $from, to: $to) {
          ...QuantileSummaryFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${QuantileSummaryFieldsFragmentDoc}`;

/**
 * __useGetQuantileSummariesForSegmentedFeatureQuery__
 *
 * To run a query within a React component, call `useGetQuantileSummariesForSegmentedFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuantileSummariesForSegmentedFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuantileSummariesForSegmentedFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetQuantileSummariesForSegmentedFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetQuantileSummariesForSegmentedFeatureQuery, GetQuantileSummariesForSegmentedFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuantileSummariesForSegmentedFeatureQuery, GetQuantileSummariesForSegmentedFeatureQueryVariables>(GetQuantileSummariesForSegmentedFeatureDocument, options);
      }
export function useGetQuantileSummariesForSegmentedFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuantileSummariesForSegmentedFeatureQuery, GetQuantileSummariesForSegmentedFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuantileSummariesForSegmentedFeatureQuery, GetQuantileSummariesForSegmentedFeatureQueryVariables>(GetQuantileSummariesForSegmentedFeatureDocument, options);
        }
export type GetQuantileSummariesForSegmentedFeatureQueryHookResult = ReturnType<typeof useGetQuantileSummariesForSegmentedFeatureQuery>;
export type GetQuantileSummariesForSegmentedFeatureLazyQueryHookResult = ReturnType<typeof useGetQuantileSummariesForSegmentedFeatureLazyQuery>;
export type GetQuantileSummariesForSegmentedFeatureQueryResult = Apollo.QueryResult<GetQuantileSummariesForSegmentedFeatureQuery, GetQuantileSummariesForSegmentedFeatureQueryVariables>;
export const GetQuantileSummariesForSegmentedOutputDocument = gql`
    query getQuantileSummariesForSegmentedOutput($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      output(name: $outputName) {
        id
        sketches(from: $from, to: $to) {
          ...QuantileSummaryFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${QuantileSummaryFieldsFragmentDoc}`;

/**
 * __useGetQuantileSummariesForSegmentedOutputQuery__
 *
 * To run a query within a React component, call `useGetQuantileSummariesForSegmentedOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuantileSummariesForSegmentedOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuantileSummariesForSegmentedOutputQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetQuantileSummariesForSegmentedOutputQuery(baseOptions: Apollo.QueryHookOptions<GetQuantileSummariesForSegmentedOutputQuery, GetQuantileSummariesForSegmentedOutputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuantileSummariesForSegmentedOutputQuery, GetQuantileSummariesForSegmentedOutputQueryVariables>(GetQuantileSummariesForSegmentedOutputDocument, options);
      }
export function useGetQuantileSummariesForSegmentedOutputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuantileSummariesForSegmentedOutputQuery, GetQuantileSummariesForSegmentedOutputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuantileSummariesForSegmentedOutputQuery, GetQuantileSummariesForSegmentedOutputQueryVariables>(GetQuantileSummariesForSegmentedOutputDocument, options);
        }
export type GetQuantileSummariesForSegmentedOutputQueryHookResult = ReturnType<typeof useGetQuantileSummariesForSegmentedOutputQuery>;
export type GetQuantileSummariesForSegmentedOutputLazyQueryHookResult = ReturnType<typeof useGetQuantileSummariesForSegmentedOutputLazyQuery>;
export type GetQuantileSummariesForSegmentedOutputQueryResult = Apollo.QueryResult<GetQuantileSummariesForSegmentedOutputQuery, GetQuantileSummariesForSegmentedOutputQueryVariables>;
export const GetFrequentStringLengthsForFeatureDocument = gql`
    query getFrequentStringLengthsForFeature($model: String!, $feature: String!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    feature(name: $feature) {
      id
      sketches(from: $from, to: $to) {
        ...StringLengthFields
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${StringLengthFieldsFragmentDoc}`;

/**
 * __useGetFrequentStringLengthsForFeatureQuery__
 *
 * To run a query within a React component, call `useGetFrequentStringLengthsForFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentStringLengthsForFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentStringLengthsForFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetFrequentStringLengthsForFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetFrequentStringLengthsForFeatureQuery, GetFrequentStringLengthsForFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFrequentStringLengthsForFeatureQuery, GetFrequentStringLengthsForFeatureQueryVariables>(GetFrequentStringLengthsForFeatureDocument, options);
      }
export function useGetFrequentStringLengthsForFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrequentStringLengthsForFeatureQuery, GetFrequentStringLengthsForFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFrequentStringLengthsForFeatureQuery, GetFrequentStringLengthsForFeatureQueryVariables>(GetFrequentStringLengthsForFeatureDocument, options);
        }
export type GetFrequentStringLengthsForFeatureQueryHookResult = ReturnType<typeof useGetFrequentStringLengthsForFeatureQuery>;
export type GetFrequentStringLengthsForFeatureLazyQueryHookResult = ReturnType<typeof useGetFrequentStringLengthsForFeatureLazyQuery>;
export type GetFrequentStringLengthsForFeatureQueryResult = Apollo.QueryResult<GetFrequentStringLengthsForFeatureQuery, GetFrequentStringLengthsForFeatureQueryVariables>;
export const GetFrequentItemsForSegmentedFeatureDocument = gql`
    query getFrequentItemsForSegmentedFeature($model: String!, $feature: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      feature(name: $feature) {
        id
        sketches(from: $from, to: $to) {
          ...FrequentItemsFields
        }
        schema {
          isDiscrete
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${FrequentItemsFieldsFragmentDoc}`;

/**
 * __useGetFrequentItemsForSegmentedFeatureQuery__
 *
 * To run a query within a React component, call `useGetFrequentItemsForSegmentedFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentItemsForSegmentedFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentItemsForSegmentedFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetFrequentItemsForSegmentedFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetFrequentItemsForSegmentedFeatureQuery, GetFrequentItemsForSegmentedFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFrequentItemsForSegmentedFeatureQuery, GetFrequentItemsForSegmentedFeatureQueryVariables>(GetFrequentItemsForSegmentedFeatureDocument, options);
      }
export function useGetFrequentItemsForSegmentedFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrequentItemsForSegmentedFeatureQuery, GetFrequentItemsForSegmentedFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFrequentItemsForSegmentedFeatureQuery, GetFrequentItemsForSegmentedFeatureQueryVariables>(GetFrequentItemsForSegmentedFeatureDocument, options);
        }
export type GetFrequentItemsForSegmentedFeatureQueryHookResult = ReturnType<typeof useGetFrequentItemsForSegmentedFeatureQuery>;
export type GetFrequentItemsForSegmentedFeatureLazyQueryHookResult = ReturnType<typeof useGetFrequentItemsForSegmentedFeatureLazyQuery>;
export type GetFrequentItemsForSegmentedFeatureQueryResult = Apollo.QueryResult<GetFrequentItemsForSegmentedFeatureQuery, GetFrequentItemsForSegmentedFeatureQueryVariables>;
export const GetFrequentItemsForSegmentedOutputDocument = gql`
    query getFrequentItemsForSegmentedOutput($model: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]!, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      output(name: $outputName) {
        id
        sketches(from: $from, to: $to) {
          ...FrequentItemsFields
        }
        schema {
          isDiscrete
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${FrequentItemsFieldsFragmentDoc}`;

/**
 * __useGetFrequentItemsForSegmentedOutputQuery__
 *
 * To run a query within a React component, call `useGetFrequentItemsForSegmentedOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentItemsForSegmentedOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentItemsForSegmentedOutputQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetFrequentItemsForSegmentedOutputQuery(baseOptions: Apollo.QueryHookOptions<GetFrequentItemsForSegmentedOutputQuery, GetFrequentItemsForSegmentedOutputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFrequentItemsForSegmentedOutputQuery, GetFrequentItemsForSegmentedOutputQueryVariables>(GetFrequentItemsForSegmentedOutputDocument, options);
      }
export function useGetFrequentItemsForSegmentedOutputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrequentItemsForSegmentedOutputQuery, GetFrequentItemsForSegmentedOutputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFrequentItemsForSegmentedOutputQuery, GetFrequentItemsForSegmentedOutputQueryVariables>(GetFrequentItemsForSegmentedOutputDocument, options);
        }
export type GetFrequentItemsForSegmentedOutputQueryHookResult = ReturnType<typeof useGetFrequentItemsForSegmentedOutputQuery>;
export type GetFrequentItemsForSegmentedOutputLazyQueryHookResult = ReturnType<typeof useGetFrequentItemsForSegmentedOutputLazyQuery>;
export type GetFrequentItemsForSegmentedOutputQueryResult = Apollo.QueryResult<GetFrequentItemsForSegmentedOutputQuery, GetFrequentItemsForSegmentedOutputQueryVariables>;
export const GetSchemasForFeatureDocument = gql`
    query getSchemasForFeature($model: String!, $feature: String!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    feature(name: $feature) {
      id
      sketches(from: $from, to: $to) {
        ...SchemaFields
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${SchemaFieldsFragmentDoc}`;

/**
 * __useGetSchemasForFeatureQuery__
 *
 * To run a query within a React component, call `useGetSchemasForFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasForFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasForFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetSchemasForFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetSchemasForFeatureQuery, GetSchemasForFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchemasForFeatureQuery, GetSchemasForFeatureQueryVariables>(GetSchemasForFeatureDocument, options);
      }
export function useGetSchemasForFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchemasForFeatureQuery, GetSchemasForFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchemasForFeatureQuery, GetSchemasForFeatureQueryVariables>(GetSchemasForFeatureDocument, options);
        }
export type GetSchemasForFeatureQueryHookResult = ReturnType<typeof useGetSchemasForFeatureQuery>;
export type GetSchemasForFeatureLazyQueryHookResult = ReturnType<typeof useGetSchemasForFeatureLazyQuery>;
export type GetSchemasForFeatureQueryResult = Apollo.QueryResult<GetSchemasForFeatureQuery, GetSchemasForFeatureQueryVariables>;
export const GetSchemasForOutputDocument = gql`
    query getSchemasForOutput($model: String!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    output(name: $outputName) {
      id
      sketches(from: $from, to: $to) {
        ...SchemaFields
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${SchemaFieldsFragmentDoc}`;

/**
 * __useGetSchemasForOutputQuery__
 *
 * To run a query within a React component, call `useGetSchemasForOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasForOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasForOutputQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetSchemasForOutputQuery(baseOptions: Apollo.QueryHookOptions<GetSchemasForOutputQuery, GetSchemasForOutputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchemasForOutputQuery, GetSchemasForOutputQueryVariables>(GetSchemasForOutputDocument, options);
      }
export function useGetSchemasForOutputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchemasForOutputQuery, GetSchemasForOutputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchemasForOutputQuery, GetSchemasForOutputQueryVariables>(GetSchemasForOutputDocument, options);
        }
export type GetSchemasForOutputQueryHookResult = ReturnType<typeof useGetSchemasForOutputQuery>;
export type GetSchemasForOutputLazyQueryHookResult = ReturnType<typeof useGetSchemasForOutputLazyQuery>;
export type GetSchemasForOutputQueryResult = Apollo.QueryResult<GetSchemasForOutputQuery, GetSchemasForOutputQueryVariables>;
export const GetSchemasForSegmentedFeatureDocument = gql`
    query getSchemasForSegmentedFeature($model: String!, $feature: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      feature(name: $feature) {
        id
        sketches(from: $from, to: $to) {
          ...SchemaFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${SchemaFieldsFragmentDoc}`;

/**
 * __useGetSchemasForSegmentedFeatureQuery__
 *
 * To run a query within a React component, call `useGetSchemasForSegmentedFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasForSegmentedFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasForSegmentedFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSchemasForSegmentedFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetSchemasForSegmentedFeatureQuery, GetSchemasForSegmentedFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchemasForSegmentedFeatureQuery, GetSchemasForSegmentedFeatureQueryVariables>(GetSchemasForSegmentedFeatureDocument, options);
      }
export function useGetSchemasForSegmentedFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchemasForSegmentedFeatureQuery, GetSchemasForSegmentedFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchemasForSegmentedFeatureQuery, GetSchemasForSegmentedFeatureQueryVariables>(GetSchemasForSegmentedFeatureDocument, options);
        }
export type GetSchemasForSegmentedFeatureQueryHookResult = ReturnType<typeof useGetSchemasForSegmentedFeatureQuery>;
export type GetSchemasForSegmentedFeatureLazyQueryHookResult = ReturnType<typeof useGetSchemasForSegmentedFeatureLazyQuery>;
export type GetSchemasForSegmentedFeatureQueryResult = Apollo.QueryResult<GetSchemasForSegmentedFeatureQuery, GetSchemasForSegmentedFeatureQueryVariables>;
export const GetSchemasForSegmentedOutputDocument = gql`
    query getSchemasForSegmentedOutput($model: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      output(name: $outputName) {
        id
        sketches(from: $from, to: $to) {
          ...SchemaFields
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${SchemaFieldsFragmentDoc}`;

/**
 * __useGetSchemasForSegmentedOutputQuery__
 *
 * To run a query within a React component, call `useGetSchemasForSegmentedOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchemasForSegmentedOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchemasForSegmentedOutputQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetSchemasForSegmentedOutputQuery(baseOptions: Apollo.QueryHookOptions<GetSchemasForSegmentedOutputQuery, GetSchemasForSegmentedOutputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchemasForSegmentedOutputQuery, GetSchemasForSegmentedOutputQueryVariables>(GetSchemasForSegmentedOutputDocument, options);
      }
export function useGetSchemasForSegmentedOutputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchemasForSegmentedOutputQuery, GetSchemasForSegmentedOutputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchemasForSegmentedOutputQuery, GetSchemasForSegmentedOutputQueryVariables>(GetSchemasForSegmentedOutputDocument, options);
        }
export type GetSchemasForSegmentedOutputQueryHookResult = ReturnType<typeof useGetSchemasForSegmentedOutputQuery>;
export type GetSchemasForSegmentedOutputLazyQueryHookResult = ReturnType<typeof useGetSchemasForSegmentedOutputLazyQuery>;
export type GetSchemasForSegmentedOutputQueryResult = Apollo.QueryResult<GetSchemasForSegmentedOutputQuery, GetSchemasForSegmentedOutputQueryVariables>;
export const GetMissingValuesForFeatureDocument = gql`
    query getMissingValuesForFeature($model: String!, $feature: String!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    feature(name: $feature) {
      id
      sketches(from: $from, to: $to) {
        id
        datasetTimestamp
        lastUploadTimestamp
        totalCount
        nullCount
        nullRatio
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetMissingValuesForFeatureQuery__
 *
 * To run a query within a React component, call `useGetMissingValuesForFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingValuesForFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingValuesForFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMissingValuesForFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetMissingValuesForFeatureQuery, GetMissingValuesForFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingValuesForFeatureQuery, GetMissingValuesForFeatureQueryVariables>(GetMissingValuesForFeatureDocument, options);
      }
export function useGetMissingValuesForFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingValuesForFeatureQuery, GetMissingValuesForFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingValuesForFeatureQuery, GetMissingValuesForFeatureQueryVariables>(GetMissingValuesForFeatureDocument, options);
        }
export type GetMissingValuesForFeatureQueryHookResult = ReturnType<typeof useGetMissingValuesForFeatureQuery>;
export type GetMissingValuesForFeatureLazyQueryHookResult = ReturnType<typeof useGetMissingValuesForFeatureLazyQuery>;
export type GetMissingValuesForFeatureQueryResult = Apollo.QueryResult<GetMissingValuesForFeatureQuery, GetMissingValuesForFeatureQueryVariables>;
export const GetMissingValuesForOutputFeatureDocument = gql`
    query getMissingValuesForOutputFeature($model: String!, $from: Float!, $to: Float, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    output(name: $outputName) {
      id
      name
      sketches(from: $from, to: $to) {
        id
        datasetTimestamp
        lastUploadTimestamp
        totalCount
        nullCount
        nullRatio
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetMissingValuesForOutputFeatureQuery__
 *
 * To run a query within a React component, call `useGetMissingValuesForOutputFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingValuesForOutputFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingValuesForOutputFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetMissingValuesForOutputFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetMissingValuesForOutputFeatureQuery, GetMissingValuesForOutputFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingValuesForOutputFeatureQuery, GetMissingValuesForOutputFeatureQueryVariables>(GetMissingValuesForOutputFeatureDocument, options);
      }
export function useGetMissingValuesForOutputFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingValuesForOutputFeatureQuery, GetMissingValuesForOutputFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingValuesForOutputFeatureQuery, GetMissingValuesForOutputFeatureQueryVariables>(GetMissingValuesForOutputFeatureDocument, options);
        }
export type GetMissingValuesForOutputFeatureQueryHookResult = ReturnType<typeof useGetMissingValuesForOutputFeatureQuery>;
export type GetMissingValuesForOutputFeatureLazyQueryHookResult = ReturnType<typeof useGetMissingValuesForOutputFeatureLazyQuery>;
export type GetMissingValuesForOutputFeatureQueryResult = Apollo.QueryResult<GetMissingValuesForOutputFeatureQuery, GetMissingValuesForOutputFeatureQueryVariables>;
export const GetMissingValuesForSegmentedFeatureDocument = gql`
    query getMissingValuesForSegmentedFeature($model: String!, $feature: String!, $tags: [SegmentTagFilter!]!, $from: Float!, $to: Float) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      feature(name: $feature) {
        id
        sketches(from: $from, to: $to) {
          id
          datasetTimestamp
          lastUploadTimestamp
          totalCount
          nullCount
          nullRatio
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetMissingValuesForSegmentedFeatureQuery__
 *
 * To run a query within a React component, call `useGetMissingValuesForSegmentedFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingValuesForSegmentedFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingValuesForSegmentedFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMissingValuesForSegmentedFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetMissingValuesForSegmentedFeatureQuery, GetMissingValuesForSegmentedFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingValuesForSegmentedFeatureQuery, GetMissingValuesForSegmentedFeatureQueryVariables>(GetMissingValuesForSegmentedFeatureDocument, options);
      }
export function useGetMissingValuesForSegmentedFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingValuesForSegmentedFeatureQuery, GetMissingValuesForSegmentedFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingValuesForSegmentedFeatureQuery, GetMissingValuesForSegmentedFeatureQueryVariables>(GetMissingValuesForSegmentedFeatureDocument, options);
        }
export type GetMissingValuesForSegmentedFeatureQueryHookResult = ReturnType<typeof useGetMissingValuesForSegmentedFeatureQuery>;
export type GetMissingValuesForSegmentedFeatureLazyQueryHookResult = ReturnType<typeof useGetMissingValuesForSegmentedFeatureLazyQuery>;
export type GetMissingValuesForSegmentedFeatureQueryResult = Apollo.QueryResult<GetMissingValuesForSegmentedFeatureQuery, GetMissingValuesForSegmentedFeatureQueryVariables>;
export const GetMissingValuesForSegmentOutputFeatureDocument = gql`
    query getMissingValuesForSegmentOutputFeature($model: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]!, $outputName: String!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      id
      output(name: $outputName) {
        id
        name
        sketches(from: $from, to: $to) {
          id
          datasetTimestamp
          lastUploadTimestamp
          totalCount
          nullCount
          nullRatio
        }
      }
    }
  }
}
    ${ModelCommonDataFragmentDoc}`;

/**
 * __useGetMissingValuesForSegmentOutputFeatureQuery__
 *
 * To run a query within a React component, call `useGetMissingValuesForSegmentOutputFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingValuesForSegmentOutputFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingValuesForSegmentOutputFeatureQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      outputName: // value for 'outputName'
 *   },
 * });
 */
export function useGetMissingValuesForSegmentOutputFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetMissingValuesForSegmentOutputFeatureQuery, GetMissingValuesForSegmentOutputFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingValuesForSegmentOutputFeatureQuery, GetMissingValuesForSegmentOutputFeatureQueryVariables>(GetMissingValuesForSegmentOutputFeatureDocument, options);
      }
export function useGetMissingValuesForSegmentOutputFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingValuesForSegmentOutputFeatureQuery, GetMissingValuesForSegmentOutputFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingValuesForSegmentOutputFeatureQuery, GetMissingValuesForSegmentOutputFeatureQueryVariables>(GetMissingValuesForSegmentOutputFeatureDocument, options);
        }
export type GetMissingValuesForSegmentOutputFeatureQueryHookResult = ReturnType<typeof useGetMissingValuesForSegmentOutputFeatureQuery>;
export type GetMissingValuesForSegmentOutputFeatureLazyQueryHookResult = ReturnType<typeof useGetMissingValuesForSegmentOutputFeatureLazyQuery>;
export type GetMissingValuesForSegmentOutputFeatureQueryResult = Apollo.QueryResult<GetMissingValuesForSegmentOutputFeatureQuery, GetMissingValuesForSegmentOutputFeatureQueryVariables>;
export const GetSegmentAlertCountDocument = gql`
    query getSegmentAlertCount($model: String, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]!, $adHocRunId: String) {
  model(id: $model) {
    segment(tags: $tags) {
      anomalyCounts(fromTimestamp: $from, toTimestamp: $to, filter: {adhocRunId: $adHocRunId}) {
        totals {
          category
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetSegmentAlertCountQuery__
 *
 * To run a query within a React component, call `useGetSegmentAlertCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentAlertCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentAlertCountQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      adHocRunId: // value for 'adHocRunId'
 *   },
 * });
 */
export function useGetSegmentAlertCountQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentAlertCountQuery, GetSegmentAlertCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentAlertCountQuery, GetSegmentAlertCountQueryVariables>(GetSegmentAlertCountDocument, options);
      }
export function useGetSegmentAlertCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentAlertCountQuery, GetSegmentAlertCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentAlertCountQuery, GetSegmentAlertCountQueryVariables>(GetSegmentAlertCountDocument, options);
        }
export type GetSegmentAlertCountQueryHookResult = ReturnType<typeof useGetSegmentAlertCountQuery>;
export type GetSegmentAlertCountLazyQueryHookResult = ReturnType<typeof useGetSegmentAlertCountLazyQuery>;
export type GetSegmentAlertCountQueryResult = Apollo.QueryResult<GetSegmentAlertCountQuery, GetSegmentAlertCountQueryVariables>;
export const UpdateWholeMonitorConfigDocument = gql`
    mutation updateWholeMonitorConfig($datasetId: String!, $config: String!) {
  monitorSettings {
    updateMonitorConfig(datasetId: $datasetId, config: $config)
  }
}
    `;
export type UpdateWholeMonitorConfigMutationFn = Apollo.MutationFunction<UpdateWholeMonitorConfigMutation, UpdateWholeMonitorConfigMutationVariables>;

/**
 * __useUpdateWholeMonitorConfigMutation__
 *
 * To run a mutation, you first call `useUpdateWholeMonitorConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWholeMonitorConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWholeMonitorConfigMutation, { data, loading, error }] = useUpdateWholeMonitorConfigMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateWholeMonitorConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWholeMonitorConfigMutation, UpdateWholeMonitorConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWholeMonitorConfigMutation, UpdateWholeMonitorConfigMutationVariables>(UpdateWholeMonitorConfigDocument, options);
      }
export type UpdateWholeMonitorConfigMutationHookResult = ReturnType<typeof useUpdateWholeMonitorConfigMutation>;
export type UpdateWholeMonitorConfigMutationResult = Apollo.MutationResult<UpdateWholeMonitorConfigMutation>;
export type UpdateWholeMonitorConfigMutationOptions = Apollo.BaseMutationOptions<UpdateWholeMonitorConfigMutation, UpdateWholeMonitorConfigMutationVariables>;
export const SetFalseAlarmDocument = gql`
    mutation setFalseAlarm($alertId: String!, $isFalseAlarm: Boolean) {
  setFalseAlarm(alertId: $alertId, isFalseAlarm: $isFalseAlarm)
}
    `;
export type SetFalseAlarmMutationFn = Apollo.MutationFunction<SetFalseAlarmMutation, SetFalseAlarmMutationVariables>;

/**
 * __useSetFalseAlarmMutation__
 *
 * To run a mutation, you first call `useSetFalseAlarmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFalseAlarmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFalseAlarmMutation, { data, loading, error }] = useSetFalseAlarmMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      isFalseAlarm: // value for 'isFalseAlarm'
 *   },
 * });
 */
export function useSetFalseAlarmMutation(baseOptions?: Apollo.MutationHookOptions<SetFalseAlarmMutation, SetFalseAlarmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFalseAlarmMutation, SetFalseAlarmMutationVariables>(SetFalseAlarmDocument, options);
      }
export type SetFalseAlarmMutationHookResult = ReturnType<typeof useSetFalseAlarmMutation>;
export type SetFalseAlarmMutationResult = Apollo.MutationResult<SetFalseAlarmMutation>;
export type SetFalseAlarmMutationOptions = Apollo.BaseMutationOptions<SetFalseAlarmMutation, SetFalseAlarmMutationVariables>;
export const AdHocMonitorDocument = gql`
    mutation adHocMonitor($model: String!, $features: [String!]!, $segments: [[SegmentTagFilter!]!], $monitorConfig: String, $to: Float, $from: Float) {
  adHocMonitor {
    run(datasetId: $model, features: $features, monitorConfig: $monitorConfig, segments: $segments, toTimestamp: $to, fromTimestamp: $from) {
      runId
      numEvents
      columns
    }
  }
}
    `;
export type AdHocMonitorMutationFn = Apollo.MutationFunction<AdHocMonitorMutation, AdHocMonitorMutationVariables>;

/**
 * __useAdHocMonitorMutation__
 *
 * To run a mutation, you first call `useAdHocMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdHocMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adHocMonitorMutation, { data, loading, error }] = useAdHocMonitorMutation({
 *   variables: {
 *      model: // value for 'model'
 *      features: // value for 'features'
 *      segments: // value for 'segments'
 *      monitorConfig: // value for 'monitorConfig'
 *      to: // value for 'to'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useAdHocMonitorMutation(baseOptions?: Apollo.MutationHookOptions<AdHocMonitorMutation, AdHocMonitorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdHocMonitorMutation, AdHocMonitorMutationVariables>(AdHocMonitorDocument, options);
      }
export type AdHocMonitorMutationHookResult = ReturnType<typeof useAdHocMonitorMutation>;
export type AdHocMonitorMutationResult = Apollo.MutationResult<AdHocMonitorMutation>;
export type AdHocMonitorMutationOptions = Apollo.BaseMutationOptions<AdHocMonitorMutation, AdHocMonitorMutationVariables>;
export const AdhocRunStatusDocument = gql`
    query adhocRunStatus($runId: String!, $numEvents: Int!) {
  adhocRunStatus(runId: $runId, numEvents: $numEvents)
}
    `;

/**
 * __useAdhocRunStatusQuery__
 *
 * To run a query within a React component, call `useAdhocRunStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdhocRunStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdhocRunStatusQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *      numEvents: // value for 'numEvents'
 *   },
 * });
 */
export function useAdhocRunStatusQuery(baseOptions: Apollo.QueryHookOptions<AdhocRunStatusQuery, AdhocRunStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdhocRunStatusQuery, AdhocRunStatusQueryVariables>(AdhocRunStatusDocument, options);
      }
export function useAdhocRunStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdhocRunStatusQuery, AdhocRunStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdhocRunStatusQuery, AdhocRunStatusQueryVariables>(AdhocRunStatusDocument, options);
        }
export type AdhocRunStatusQueryHookResult = ReturnType<typeof useAdhocRunStatusQuery>;
export type AdhocRunStatusLazyQueryHookResult = ReturnType<typeof useAdhocRunStatusLazyQuery>;
export type AdhocRunStatusQueryResult = Apollo.QueryResult<AdhocRunStatusQuery, AdhocRunStatusQueryVariables>;
export const RunBackfillAnalyzersDocument = gql`
    mutation runBackfillAnalyzers($datasetId: String!, $toTimestamp: Float!, $fromTimestamp: Float!, $analyzerIds: [String!]) {
  backfillAnalyzers {
    triggerBackfill(datasetId: $datasetId, toTimestamp: $toTimestamp, fromTimestamp: $fromTimestamp, analyzerIds: $analyzerIds) {
      runId
    }
  }
}
    `;
export type RunBackfillAnalyzersMutationFn = Apollo.MutationFunction<RunBackfillAnalyzersMutation, RunBackfillAnalyzersMutationVariables>;

/**
 * __useRunBackfillAnalyzersMutation__
 *
 * To run a mutation, you first call `useRunBackfillAnalyzersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunBackfillAnalyzersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runBackfillAnalyzersMutation, { data, loading, error }] = useRunBackfillAnalyzersMutation({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      toTimestamp: // value for 'toTimestamp'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      analyzerIds: // value for 'analyzerIds'
 *   },
 * });
 */
export function useRunBackfillAnalyzersMutation(baseOptions?: Apollo.MutationHookOptions<RunBackfillAnalyzersMutation, RunBackfillAnalyzersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunBackfillAnalyzersMutation, RunBackfillAnalyzersMutationVariables>(RunBackfillAnalyzersDocument, options);
      }
export type RunBackfillAnalyzersMutationHookResult = ReturnType<typeof useRunBackfillAnalyzersMutation>;
export type RunBackfillAnalyzersMutationResult = Apollo.MutationResult<RunBackfillAnalyzersMutation>;
export type RunBackfillAnalyzersMutationOptions = Apollo.BaseMutationOptions<RunBackfillAnalyzersMutation, RunBackfillAnalyzersMutationVariables>;
export const CancelRequestedBackfillJobDocument = gql`
    mutation cancelRequestedBackfillJob($runId: String!) {
  backfillAnalyzers {
    cancelJob(runId: $runId)
  }
}
    `;
export type CancelRequestedBackfillJobMutationFn = Apollo.MutationFunction<CancelRequestedBackfillJobMutation, CancelRequestedBackfillJobMutationVariables>;

/**
 * __useCancelRequestedBackfillJobMutation__
 *
 * To run a mutation, you first call `useCancelRequestedBackfillJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestedBackfillJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestedBackfillJobMutation, { data, loading, error }] = useCancelRequestedBackfillJobMutation({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useCancelRequestedBackfillJobMutation(baseOptions?: Apollo.MutationHookOptions<CancelRequestedBackfillJobMutation, CancelRequestedBackfillJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRequestedBackfillJobMutation, CancelRequestedBackfillJobMutationVariables>(CancelRequestedBackfillJobDocument, options);
      }
export type CancelRequestedBackfillJobMutationHookResult = ReturnType<typeof useCancelRequestedBackfillJobMutation>;
export type CancelRequestedBackfillJobMutationResult = Apollo.MutationResult<CancelRequestedBackfillJobMutation>;
export type CancelRequestedBackfillJobMutationOptions = Apollo.BaseMutationOptions<CancelRequestedBackfillJobMutation, CancelRequestedBackfillJobMutationVariables>;
export const GetOrgBackfillJobsDocument = gql`
    query getOrgBackfillJobs($datasetId: String, $onlyActive: Boolean, $runId: String) {
  queryBackfillJobs(datasetId: $datasetId, onlyActive: $onlyActive, runId: $runId) {
    runId
    duration {
      fromTimestamp
      toTimestamp
    }
    status
    monitorsList
    progress
    columns
    segments
    datasetId
  }
}
    `;

/**
 * __useGetOrgBackfillJobsQuery__
 *
 * To run a query within a React component, call `useGetOrgBackfillJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgBackfillJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgBackfillJobsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      onlyActive: // value for 'onlyActive'
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetOrgBackfillJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrgBackfillJobsQuery, GetOrgBackfillJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgBackfillJobsQuery, GetOrgBackfillJobsQueryVariables>(GetOrgBackfillJobsDocument, options);
      }
export function useGetOrgBackfillJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgBackfillJobsQuery, GetOrgBackfillJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgBackfillJobsQuery, GetOrgBackfillJobsQueryVariables>(GetOrgBackfillJobsDocument, options);
        }
export type GetOrgBackfillJobsQueryHookResult = ReturnType<typeof useGetOrgBackfillJobsQuery>;
export type GetOrgBackfillJobsLazyQueryHookResult = ReturnType<typeof useGetOrgBackfillJobsLazyQuery>;
export type GetOrgBackfillJobsQueryResult = Apollo.QueryResult<GetOrgBackfillJobsQuery, GetOrgBackfillJobsQueryVariables>;
export const GetAnalysisResultsDocument = gql`
    query getAnalysisResults($anomaliesOnly: Boolean!, $datasetId: String!, $from: Float!, $to: Float, $metrics: [AnalysisMetric!], $analyzerTypes: [String!], $columns: [String!], $tags: [SegmentTagFilter!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: $anomaliesOnly, analyzerTypes: $analyzerTypes, metrics: $metrics, datasetId: $datasetId, columns: $columns, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...AnalysisData
  }
}
    ${AnalysisDataFragmentDoc}`;

/**
 * __useGetAnalysisResultsQuery__
 *
 * To run a query within a React component, call `useGetAnalysisResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalysisResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalysisResultsQuery({
 *   variables: {
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      metrics: // value for 'metrics'
 *      analyzerTypes: // value for 'analyzerTypes'
 *      columns: // value for 'columns'
 *      tags: // value for 'tags'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetAnalysisResultsQuery(baseOptions: Apollo.QueryHookOptions<GetAnalysisResultsQuery, GetAnalysisResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalysisResultsQuery, GetAnalysisResultsQueryVariables>(GetAnalysisResultsDocument, options);
      }
export function useGetAnalysisResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalysisResultsQuery, GetAnalysisResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalysisResultsQuery, GetAnalysisResultsQueryVariables>(GetAnalysisResultsDocument, options);
        }
export type GetAnalysisResultsQueryHookResult = ReturnType<typeof useGetAnalysisResultsQuery>;
export type GetAnalysisResultsLazyQueryHookResult = ReturnType<typeof useGetAnalysisResultsLazyQuery>;
export type GetAnalysisResultsQueryResult = Apollo.QueryResult<GetAnalysisResultsQuery, GetAnalysisResultsQueryVariables>;
export const GetPaginatedAnalysisResultsDocument = gql`
    query getPaginatedAnalysisResults($anomaliesOnly: Boolean!, $includeFailed: Boolean = true, $includeUnhelpful: Boolean = true, $datasetId: String!, $from: Float!, $to: Float, $metrics: [AnalysisMetric!], $analyzerTypes: [String!], $columns: [String!], $tags: [SegmentTagFilter!], $analyzerIDs: [String!], $monitorIDs: [String!], $analysisIDs: [String!], $limit: Int!, $offset: Int!, $sortDirection: SortDirection!) {
  paginatedAnalysisResults(filter: {anomaliesOnly: $anomaliesOnly, includeFailed: $includeFailed, includeUnhelpful: $includeUnhelpful, analyzerTypes: $analyzerTypes, metrics: $metrics, datasetId: $datasetId, columns: $columns, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, analyzerIDs: $analyzerIDs, monitorIDs: $monitorIDs, analysisIDs: $analysisIDs}, offset: $offset, limit: $limit, sortDirection: $sortDirection) {
    ...AnalysisData
  }
}
    ${AnalysisDataFragmentDoc}`;

/**
 * __useGetPaginatedAnalysisResultsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedAnalysisResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedAnalysisResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedAnalysisResultsQuery({
 *   variables: {
 *      anomaliesOnly: // value for 'anomaliesOnly'
 *      includeFailed: // value for 'includeFailed'
 *      includeUnhelpful: // value for 'includeUnhelpful'
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      metrics: // value for 'metrics'
 *      analyzerTypes: // value for 'analyzerTypes'
 *      columns: // value for 'columns'
 *      tags: // value for 'tags'
 *      analyzerIDs: // value for 'analyzerIDs'
 *      monitorIDs: // value for 'monitorIDs'
 *      analysisIDs: // value for 'analysisIDs'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetPaginatedAnalysisResultsQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedAnalysisResultsQuery, GetPaginatedAnalysisResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedAnalysisResultsQuery, GetPaginatedAnalysisResultsQueryVariables>(GetPaginatedAnalysisResultsDocument, options);
      }
export function useGetPaginatedAnalysisResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedAnalysisResultsQuery, GetPaginatedAnalysisResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedAnalysisResultsQuery, GetPaginatedAnalysisResultsQueryVariables>(GetPaginatedAnalysisResultsDocument, options);
        }
export type GetPaginatedAnalysisResultsQueryHookResult = ReturnType<typeof useGetPaginatedAnalysisResultsQuery>;
export type GetPaginatedAnalysisResultsLazyQueryHookResult = ReturnType<typeof useGetPaginatedAnalysisResultsLazyQuery>;
export type GetPaginatedAnalysisResultsQueryResult = Apollo.QueryResult<GetPaginatedAnalysisResultsQuery, GetPaginatedAnalysisResultsQueryVariables>;
export const GetAnomalyCountDocument = gql`
    query getAnomalyCount($datasetId: String!, $from: Float!, $to: Float, $analyzerIDs: [String!], $monitorIDs: [String!]) {
  anomalyCount(filter: {datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, monitorIDs: $monitorIDs, analyzerIDs: $analyzerIDs}, timePeriod: P1D) {
    timestamp
    anomalyCount
    resultCount
  }
}
    `;

/**
 * __useGetAnomalyCountQuery__
 *
 * To run a query within a React component, call `useGetAnomalyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomalyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomalyCountQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      analyzerIDs: // value for 'analyzerIDs'
 *      monitorIDs: // value for 'monitorIDs'
 *   },
 * });
 */
export function useGetAnomalyCountQuery(baseOptions: Apollo.QueryHookOptions<GetAnomalyCountQuery, GetAnomalyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomalyCountQuery, GetAnomalyCountQueryVariables>(GetAnomalyCountDocument, options);
      }
export function useGetAnomalyCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomalyCountQuery, GetAnomalyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomalyCountQuery, GetAnomalyCountQueryVariables>(GetAnomalyCountDocument, options);
        }
export type GetAnomalyCountQueryHookResult = ReturnType<typeof useGetAnomalyCountQuery>;
export type GetAnomalyCountLazyQueryHookResult = ReturnType<typeof useGetAnomalyCountLazyQuery>;
export type GetAnomalyCountQueryResult = Apollo.QueryResult<GetAnomalyCountQuery, GetAnomalyCountQueryVariables>;
export const GetAnomalyCountByAnalyzerDocument = gql`
    query getAnomalyCountByAnalyzer($datasetId: String!, $from: Float!, $to: Float!, $analyzerIDs: [String!]!) {
  anomalyCountByAnalyzer(datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, analyzerIDs: $analyzerIDs) {
    analyzerId
    anomalyCount
    failedCount
    resultCount
  }
}
    `;

/**
 * __useGetAnomalyCountByAnalyzerQuery__
 *
 * To run a query within a React component, call `useGetAnomalyCountByAnalyzerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomalyCountByAnalyzerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomalyCountByAnalyzerQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      analyzerIDs: // value for 'analyzerIDs'
 *   },
 * });
 */
export function useGetAnomalyCountByAnalyzerQuery(baseOptions: Apollo.QueryHookOptions<GetAnomalyCountByAnalyzerQuery, GetAnomalyCountByAnalyzerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomalyCountByAnalyzerQuery, GetAnomalyCountByAnalyzerQueryVariables>(GetAnomalyCountByAnalyzerDocument, options);
      }
export function useGetAnomalyCountByAnalyzerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomalyCountByAnalyzerQuery, GetAnomalyCountByAnalyzerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomalyCountByAnalyzerQuery, GetAnomalyCountByAnalyzerQueryVariables>(GetAnomalyCountByAnalyzerDocument, options);
        }
export type GetAnomalyCountByAnalyzerQueryHookResult = ReturnType<typeof useGetAnomalyCountByAnalyzerQuery>;
export type GetAnomalyCountByAnalyzerLazyQueryHookResult = ReturnType<typeof useGetAnomalyCountByAnalyzerLazyQuery>;
export type GetAnomalyCountByAnalyzerQueryResult = Apollo.QueryResult<GetAnomalyCountByAnalyzerQuery, GetAnomalyCountByAnalyzerQueryVariables>;
export const GetAnalyzerRunsDocument = gql`
    query getAnalyzerRuns($datasetId: String!, $from: Float!, $to: Float, $analyzerId: String, $monitorId: String, $limit: Int!, $offset: Int!, $sortDirection: SortDirection) {
  analyzerRuns(filter: {datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, analyzerId: $analyzerId, monitorId: $monitorId}, offset: $offset, limit: $limit, sortDirection: $sortDirection) {
    ...AnalyzerRunData
  }
}
    ${AnalyzerRunDataFragmentDoc}`;

/**
 * __useGetAnalyzerRunsQuery__
 *
 * To run a query within a React component, call `useGetAnalyzerRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyzerRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyzerRunsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      analyzerId: // value for 'analyzerId'
 *      monitorId: // value for 'monitorId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetAnalyzerRunsQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyzerRunsQuery, GetAnalyzerRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyzerRunsQuery, GetAnalyzerRunsQueryVariables>(GetAnalyzerRunsDocument, options);
      }
export function useGetAnalyzerRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyzerRunsQuery, GetAnalyzerRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyzerRunsQuery, GetAnalyzerRunsQueryVariables>(GetAnalyzerRunsDocument, options);
        }
export type GetAnalyzerRunsQueryHookResult = ReturnType<typeof useGetAnalyzerRunsQuery>;
export type GetAnalyzerRunsLazyQueryHookResult = ReturnType<typeof useGetAnalyzerRunsLazyQuery>;
export type GetAnalyzerRunsQueryResult = Apollo.QueryResult<GetAnalyzerRunsQuery, GetAnalyzerRunsQueryVariables>;
export const GetAnalyzerRunCountDocument = gql`
    query getAnalyzerRunCount($datasetId: String!, $from: Float!, $to: Float, $analyzerId: String, $monitorId: String) {
  runCount(filter: {datasetId: $datasetId, fromTimestamp: $from, toTimestamp: $to, analyzerId: $analyzerId, monitorId: $monitorId}) {
    count
  }
}
    `;

/**
 * __useGetAnalyzerRunCountQuery__
 *
 * To run a query within a React component, call `useGetAnalyzerRunCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyzerRunCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyzerRunCountQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      analyzerId: // value for 'analyzerId'
 *      monitorId: // value for 'monitorId'
 *   },
 * });
 */
export function useGetAnalyzerRunCountQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyzerRunCountQuery, GetAnalyzerRunCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyzerRunCountQuery, GetAnalyzerRunCountQueryVariables>(GetAnalyzerRunCountDocument, options);
      }
export function useGetAnalyzerRunCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyzerRunCountQuery, GetAnalyzerRunCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyzerRunCountQuery, GetAnalyzerRunCountQueryVariables>(GetAnalyzerRunCountDocument, options);
        }
export type GetAnalyzerRunCountQueryHookResult = ReturnType<typeof useGetAnalyzerRunCountQuery>;
export type GetAnalyzerRunCountLazyQueryHookResult = ReturnType<typeof useGetAnalyzerRunCountLazyQuery>;
export type GetAnalyzerRunCountQueryResult = Apollo.QueryResult<GetAnalyzerRunCountQuery, GetAnalyzerRunCountQueryVariables>;
export const GetMetricDataDocument = gql`
    query getMetricData($from: Float!, $to: Float!, $queries: [MetricQuery!]!, $granularity: TimePeriod, $segmentTags: [SegmentTagFilter!]) {
  dataQueries {
    getMetricData(fromTimestamp: $from, toTimestamp: $to, queries: $queries, granularity: $granularity, segment: $segmentTags) {
      ...MetricResult
    }
  }
}
    ${MetricResultFragmentDoc}`;

/**
 * __useGetMetricDataQuery__
 *
 * To run a query within a React component, call `useGetMetricDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricDataQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      queries: // value for 'queries'
 *      granularity: // value for 'granularity'
 *      segmentTags: // value for 'segmentTags'
 *   },
 * });
 */
export function useGetMetricDataQuery(baseOptions: Apollo.QueryHookOptions<GetMetricDataQuery, GetMetricDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricDataQuery, GetMetricDataQueryVariables>(GetMetricDataDocument, options);
      }
export function useGetMetricDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricDataQuery, GetMetricDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricDataQuery, GetMetricDataQueryVariables>(GetMetricDataDocument, options);
        }
export type GetMetricDataQueryHookResult = ReturnType<typeof useGetMetricDataQuery>;
export type GetMetricDataLazyQueryHookResult = ReturnType<typeof useGetMetricDataLazyQuery>;
export type GetMetricDataQueryResult = Apollo.QueryResult<GetMetricDataQuery, GetMetricDataQueryVariables>;
export const GetMetricDataRollupDocument = gql`
    query getMetricDataRollup($from: Float!, $to: Float!, $queries: [MetricQuery!]!) {
  dataQueries {
    getMetricData(fromTimestamp: $from, toTimestamp: $to, queries: $queries, granularity: ALL) {
      ...MetricResult
    }
  }
}
    ${MetricResultFragmentDoc}`;

/**
 * __useGetMetricDataRollupQuery__
 *
 * To run a query within a React component, call `useGetMetricDataRollupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricDataRollupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricDataRollupQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useGetMetricDataRollupQuery(baseOptions: Apollo.QueryHookOptions<GetMetricDataRollupQuery, GetMetricDataRollupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricDataRollupQuery, GetMetricDataRollupQueryVariables>(GetMetricDataRollupDocument, options);
      }
export function useGetMetricDataRollupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricDataRollupQuery, GetMetricDataRollupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricDataRollupQuery, GetMetricDataRollupQueryVariables>(GetMetricDataRollupDocument, options);
        }
export type GetMetricDataRollupQueryHookResult = ReturnType<typeof useGetMetricDataRollupQuery>;
export type GetMetricDataRollupLazyQueryHookResult = ReturnType<typeof useGetMetricDataRollupLazyQuery>;
export type GetMetricDataRollupQueryResult = Apollo.QueryResult<GetMetricDataRollupQuery, GetMetricDataRollupQueryVariables>;
export const GetBatchDateRangeDocument = gql`
    query getBatchDateRange($modelId: String!, $timestamps: [Float!]!) {
  model(id: $modelId) {
    batchDateRanges(timestamps: $timestamps) {
      fromTimestamp
      toTimestamp
    }
  }
}
    `;

/**
 * __useGetBatchDateRangeQuery__
 *
 * To run a query within a React component, call `useGetBatchDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchDateRangeQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      timestamps: // value for 'timestamps'
 *   },
 * });
 */
export function useGetBatchDateRangeQuery(baseOptions: Apollo.QueryHookOptions<GetBatchDateRangeQuery, GetBatchDateRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBatchDateRangeQuery, GetBatchDateRangeQueryVariables>(GetBatchDateRangeDocument, options);
      }
export function useGetBatchDateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBatchDateRangeQuery, GetBatchDateRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBatchDateRangeQuery, GetBatchDateRangeQueryVariables>(GetBatchDateRangeDocument, options);
        }
export type GetBatchDateRangeQueryHookResult = ReturnType<typeof useGetBatchDateRangeQuery>;
export type GetBatchDateRangeLazyQueryHookResult = ReturnType<typeof useGetBatchDateRangeLazyQuery>;
export type GetBatchDateRangeQueryResult = Apollo.QueryResult<GetBatchDateRangeQuery, GetBatchDateRangeQueryVariables>;
export const GetAvailableMetricsDocument = gql`
    query getAvailableMetrics($modelType: ModelType, $datasetId: String, $metricTags: [String!]) {
  dataQueries {
    availableMetrics(modelType: $modelType, datasetId: $datasetId, metricTags: $metricTags) {
      ...MetricSchema
    }
  }
}
    ${MetricSchemaFragmentDoc}`;

/**
 * __useGetAvailableMetricsQuery__
 *
 * To run a query within a React component, call `useGetAvailableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableMetricsQuery({
 *   variables: {
 *      modelType: // value for 'modelType'
 *      datasetId: // value for 'datasetId'
 *      metricTags: // value for 'metricTags'
 *   },
 * });
 */
export function useGetAvailableMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableMetricsQuery, GetAvailableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableMetricsQuery, GetAvailableMetricsQueryVariables>(GetAvailableMetricsDocument, options);
      }
export function useGetAvailableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableMetricsQuery, GetAvailableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableMetricsQuery, GetAvailableMetricsQueryVariables>(GetAvailableMetricsDocument, options);
        }
export type GetAvailableMetricsQueryHookResult = ReturnType<typeof useGetAvailableMetricsQuery>;
export type GetAvailableMetricsLazyQueryHookResult = ReturnType<typeof useGetAvailableMetricsLazyQuery>;
export type GetAvailableMetricsQueryResult = Apollo.QueryResult<GetAvailableMetricsQuery, GetAvailableMetricsQueryVariables>;
export const GetFeatureWeightsDocument = gql`
    query getFeatureWeights($id: String!, $by: FeatureSortBy!, $direction: SortDirection!, $limit: Int = 30, $filter: FeatureFilter!) {
  model(id: $id) {
    name
    weightMetadata {
      hasWeights
    }
    filteredFeatures(limit: $limit, sort: {by: $by, direction: $direction}, filter: $filter) {
      results {
        ...FeatureWeights
      }
    }
  }
}
    ${FeatureWeightsFragmentDoc}`;

/**
 * __useGetFeatureWeightsQuery__
 *
 * To run a query within a React component, call `useGetFeatureWeightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureWeightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureWeightsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      by: // value for 'by'
 *      direction: // value for 'direction'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFeatureWeightsQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureWeightsQuery, GetFeatureWeightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureWeightsQuery, GetFeatureWeightsQueryVariables>(GetFeatureWeightsDocument, options);
      }
export function useGetFeatureWeightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureWeightsQuery, GetFeatureWeightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureWeightsQuery, GetFeatureWeightsQueryVariables>(GetFeatureWeightsDocument, options);
        }
export type GetFeatureWeightsQueryHookResult = ReturnType<typeof useGetFeatureWeightsQuery>;
export type GetFeatureWeightsLazyQueryHookResult = ReturnType<typeof useGetFeatureWeightsLazyQuery>;
export type GetFeatureWeightsQueryResult = Apollo.QueryResult<GetFeatureWeightsQuery, GetFeatureWeightsQueryVariables>;
export const GetFeatureWeightsCardInfoDocument = gql`
    query getFeatureWeightsCardInfo($id: String!, $filter: FeatureFilter!) {
  model(id: $id) {
    name
    weightMetadata {
      hasWeights
      lastUpdatedAt
    }
    filteredFeatures(limit: 5, sort: {by: AbsoluteWeight, direction: DESC}, filter: $filter) {
      results {
        ...FeatureWeights
      }
    }
  }
}
    ${FeatureWeightsFragmentDoc}`;

/**
 * __useGetFeatureWeightsCardInfoQuery__
 *
 * To run a query within a React component, call `useGetFeatureWeightsCardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureWeightsCardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureWeightsCardInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFeatureWeightsCardInfoQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureWeightsCardInfoQuery, GetFeatureWeightsCardInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureWeightsCardInfoQuery, GetFeatureWeightsCardInfoQueryVariables>(GetFeatureWeightsCardInfoDocument, options);
      }
export function useGetFeatureWeightsCardInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureWeightsCardInfoQuery, GetFeatureWeightsCardInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureWeightsCardInfoQuery, GetFeatureWeightsCardInfoQueryVariables>(GetFeatureWeightsCardInfoDocument, options);
        }
export type GetFeatureWeightsCardInfoQueryHookResult = ReturnType<typeof useGetFeatureWeightsCardInfoQuery>;
export type GetFeatureWeightsCardInfoLazyQueryHookResult = ReturnType<typeof useGetFeatureWeightsCardInfoLazyQuery>;
export type GetFeatureWeightsCardInfoQueryResult = Apollo.QueryResult<GetFeatureWeightsCardInfoQuery, GetFeatureWeightsCardInfoQueryVariables>;
export const GetSegmentKeysDocument = gql`
    query getSegmentKeys($modelId: String!, $tags: [SegmentTagFilter!]) {
  searchSegmentKeys(datasetId: $modelId, tags: $tags)
}
    `;

/**
 * __useGetSegmentKeysQuery__
 *
 * To run a query within a React component, call `useGetSegmentKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentKeysQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentKeysQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentKeysQuery, GetSegmentKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentKeysQuery, GetSegmentKeysQueryVariables>(GetSegmentKeysDocument, options);
      }
export function useGetSegmentKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentKeysQuery, GetSegmentKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentKeysQuery, GetSegmentKeysQueryVariables>(GetSegmentKeysDocument, options);
        }
export type GetSegmentKeysQueryHookResult = ReturnType<typeof useGetSegmentKeysQuery>;
export type GetSegmentKeysLazyQueryHookResult = ReturnType<typeof useGetSegmentKeysLazyQuery>;
export type GetSegmentKeysQueryResult = Apollo.QueryResult<GetSegmentKeysQuery, GetSegmentKeysQueryVariables>;
export const GetSegmentValueForKeyDocument = gql`
    query getSegmentValueForKey($modelId: String!, $key: String!, $tags: [SegmentTagFilter!]) {
  searchSegmentValues(datasetId: $modelId, key: $key, tags: $tags)
}
    `;

/**
 * __useGetSegmentValueForKeyQuery__
 *
 * To run a query within a React component, call `useGetSegmentValueForKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentValueForKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentValueForKeyQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      key: // value for 'key'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentValueForKeyQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentValueForKeyQuery, GetSegmentValueForKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentValueForKeyQuery, GetSegmentValueForKeyQueryVariables>(GetSegmentValueForKeyDocument, options);
      }
export function useGetSegmentValueForKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentValueForKeyQuery, GetSegmentValueForKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentValueForKeyQuery, GetSegmentValueForKeyQueryVariables>(GetSegmentValueForKeyDocument, options);
        }
export type GetSegmentValueForKeyQueryHookResult = ReturnType<typeof useGetSegmentValueForKeyQuery>;
export type GetSegmentValueForKeyLazyQueryHookResult = ReturnType<typeof useGetSegmentValueForKeyLazyQuery>;
export type GetSegmentValueForKeyQueryResult = Apollo.QueryResult<GetSegmentValueForKeyQuery, GetSegmentValueForKeyQueryVariables>;
export const GetIntegrationCardsDocument = gql`
    query getIntegrationCards {
  integrationCards {
    category
    coming_soon
    description
    logo
    title
    url
  }
}
    `;

/**
 * __useGetIntegrationCardsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetIntegrationCardsQuery, GetIntegrationCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationCardsQuery, GetIntegrationCardsQueryVariables>(GetIntegrationCardsDocument, options);
      }
export function useGetIntegrationCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationCardsQuery, GetIntegrationCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationCardsQuery, GetIntegrationCardsQueryVariables>(GetIntegrationCardsDocument, options);
        }
export type GetIntegrationCardsQueryHookResult = ReturnType<typeof useGetIntegrationCardsQuery>;
export type GetIntegrationCardsLazyQueryHookResult = ReturnType<typeof useGetIntegrationCardsLazyQuery>;
export type GetIntegrationCardsQueryResult = Apollo.QueryResult<GetIntegrationCardsQuery, GetIntegrationCardsQueryVariables>;
export const GetResourceDataDocument = gql`
    query getResourceData($resourceId: String!) {
  resource: model(id: $resourceId) {
    id
    category: assetCategory
    name
    type: modelType
    batchFrequency
    dataAvailability {
      oldestTimestamp
      latestTimestamp
    }
  }
}
    `;

/**
 * __useGetResourceDataQuery__
 *
 * To run a query within a React component, call `useGetResourceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceDataQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useGetResourceDataQuery(baseOptions: Apollo.QueryHookOptions<GetResourceDataQuery, GetResourceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceDataQuery, GetResourceDataQueryVariables>(GetResourceDataDocument, options);
      }
export function useGetResourceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceDataQuery, GetResourceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceDataQuery, GetResourceDataQueryVariables>(GetResourceDataDocument, options);
        }
export type GetResourceDataQueryHookResult = ReturnType<typeof useGetResourceDataQuery>;
export type GetResourceDataLazyQueryHookResult = ReturnType<typeof useGetResourceDataLazyQuery>;
export type GetResourceDataQueryResult = Apollo.QueryResult<GetResourceDataQuery, GetResourceDataQueryVariables>;
export const GetResourceModelTypeDocument = gql`
    query getResourceModelType($resourceId: String!) {
  resource: model(id: $resourceId) {
    type: modelType
    category: assetCategory
  }
}
    `;

/**
 * __useGetResourceModelTypeQuery__
 *
 * To run a query within a React component, call `useGetResourceModelTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceModelTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceModelTypeQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useGetResourceModelTypeQuery(baseOptions: Apollo.QueryHookOptions<GetResourceModelTypeQuery, GetResourceModelTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceModelTypeQuery, GetResourceModelTypeQueryVariables>(GetResourceModelTypeDocument, options);
      }
export function useGetResourceModelTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceModelTypeQuery, GetResourceModelTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceModelTypeQuery, GetResourceModelTypeQueryVariables>(GetResourceModelTypeDocument, options);
        }
export type GetResourceModelTypeQueryHookResult = ReturnType<typeof useGetResourceModelTypeQuery>;
export type GetResourceModelTypeLazyQueryHookResult = ReturnType<typeof useGetResourceModelTypeLazyQuery>;
export type GetResourceModelTypeQueryResult = Apollo.QueryResult<GetResourceModelTypeQuery, GetResourceModelTypeQueryVariables>;
export const GetUserFlagsDocument = gql`
    query getUserFlags {
  featureFlags {
    key
    value
  }
}
    `;

/**
 * __useGetUserFlagsQuery__
 *
 * To run a query within a React component, call `useGetUserFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFlagsQuery, GetUserFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFlagsQuery, GetUserFlagsQueryVariables>(GetUserFlagsDocument, options);
      }
export function useGetUserFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFlagsQuery, GetUserFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFlagsQuery, GetUserFlagsQueryVariables>(GetUserFlagsDocument, options);
        }
export type GetUserFlagsQueryHookResult = ReturnType<typeof useGetUserFlagsQuery>;
export type GetUserFlagsLazyQueryHookResult = ReturnType<typeof useGetUserFlagsLazyQuery>;
export type GetUserFlagsQueryResult = Apollo.QueryResult<GetUserFlagsQuery, GetUserFlagsQueryVariables>;
export const ListAllOrgsDocument = gql`
    query ListAllOrgs($search: String) {
  admin {
    organizations(search: $search) {
      id
      name
      emailDomains
      subscriptionTier
    }
  }
}
    `;

/**
 * __useListAllOrgsQuery__
 *
 * To run a query within a React component, call `useListAllOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllOrgsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListAllOrgsQuery(baseOptions?: Apollo.QueryHookOptions<ListAllOrgsQuery, ListAllOrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllOrgsQuery, ListAllOrgsQueryVariables>(ListAllOrgsDocument, options);
      }
export function useListAllOrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllOrgsQuery, ListAllOrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllOrgsQuery, ListAllOrgsQueryVariables>(ListAllOrgsDocument, options);
        }
export type ListAllOrgsQueryHookResult = ReturnType<typeof useListAllOrgsQuery>;
export type ListAllOrgsLazyQueryHookResult = ReturnType<typeof useListAllOrgsLazyQuery>;
export type ListAllOrgsQueryResult = Apollo.QueryResult<ListAllOrgsQuery, ListAllOrgsQueryVariables>;
export const ListUsersForOrgDocument = gql`
    query ListUsersForOrg($orgId: String!) {
  admin {
    organization(orgId: $orgId) {
      id
      members {
        userId
        orgId
        email
        role
      }
    }
  }
}
    `;

/**
 * __useListUsersForOrgQuery__
 *
 * To run a query within a React component, call `useListUsersForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersForOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useListUsersForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListUsersForOrgQuery, ListUsersForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersForOrgQuery, ListUsersForOrgQueryVariables>(ListUsersForOrgDocument, options);
      }
export function useListUsersForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersForOrgQuery, ListUsersForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersForOrgQuery, ListUsersForOrgQueryVariables>(ListUsersForOrgDocument, options);
        }
export type ListUsersForOrgQueryHookResult = ReturnType<typeof useListUsersForOrgQuery>;
export type ListUsersForOrgLazyQueryHookResult = ReturnType<typeof useListUsersForOrgLazyQuery>;
export type ListUsersForOrgQueryResult = Apollo.QueryResult<ListUsersForOrgQuery, ListUsersForOrgQueryVariables>;
export const ListMembershipsForUserDocument = gql`
    query ListMembershipsForUser($email: String!) {
  admin {
    memberships(email: $email) {
      userId
      orgId
      role
      email
    }
  }
}
    `;

/**
 * __useListMembershipsForUserQuery__
 *
 * To run a query within a React component, call `useListMembershipsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMembershipsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMembershipsForUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useListMembershipsForUserQuery(baseOptions: Apollo.QueryHookOptions<ListMembershipsForUserQuery, ListMembershipsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMembershipsForUserQuery, ListMembershipsForUserQueryVariables>(ListMembershipsForUserDocument, options);
      }
export function useListMembershipsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMembershipsForUserQuery, ListMembershipsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMembershipsForUserQuery, ListMembershipsForUserQueryVariables>(ListMembershipsForUserDocument, options);
        }
export type ListMembershipsForUserQueryHookResult = ReturnType<typeof useListMembershipsForUserQuery>;
export type ListMembershipsForUserLazyQueryHookResult = ReturnType<typeof useListMembershipsForUserLazyQuery>;
export type ListMembershipsForUserQueryResult = Apollo.QueryResult<ListMembershipsForUserQuery, ListMembershipsForUserQueryVariables>;
export const RemoveAllMembershipsDocument = gql`
    mutation RemoveAllMemberships($email: String!) {
  admin {
    memberships {
      clear(email: $email)
    }
  }
}
    `;
export type RemoveAllMembershipsMutationFn = Apollo.MutationFunction<RemoveAllMembershipsMutation, RemoveAllMembershipsMutationVariables>;

/**
 * __useRemoveAllMembershipsMutation__
 *
 * To run a mutation, you first call `useRemoveAllMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAllMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAllMembershipsMutation, { data, loading, error }] = useRemoveAllMembershipsMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveAllMembershipsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAllMembershipsMutation, RemoveAllMembershipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAllMembershipsMutation, RemoveAllMembershipsMutationVariables>(RemoveAllMembershipsDocument, options);
      }
export type RemoveAllMembershipsMutationHookResult = ReturnType<typeof useRemoveAllMembershipsMutation>;
export type RemoveAllMembershipsMutationResult = Apollo.MutationResult<RemoveAllMembershipsMutation>;
export type RemoveAllMembershipsMutationOptions = Apollo.BaseMutationOptions<RemoveAllMembershipsMutation, RemoveAllMembershipsMutationVariables>;
export const ListModelsForOrgDocument = gql`
    query ListModelsForOrg($orgId: String!, $search: String) {
  admin {
    models(orgId: $orgId, search: $search) {
      id
      name
      modelType
    }
  }
}
    `;

/**
 * __useListModelsForOrgQuery__
 *
 * To run a query within a React component, call `useListModelsForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListModelsForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListModelsForOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useListModelsForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListModelsForOrgQuery, ListModelsForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListModelsForOrgQuery, ListModelsForOrgQueryVariables>(ListModelsForOrgDocument, options);
      }
export function useListModelsForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListModelsForOrgQuery, ListModelsForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListModelsForOrgQuery, ListModelsForOrgQueryVariables>(ListModelsForOrgDocument, options);
        }
export type ListModelsForOrgQueryHookResult = ReturnType<typeof useListModelsForOrgQuery>;
export type ListModelsForOrgLazyQueryHookResult = ReturnType<typeof useListModelsForOrgLazyQuery>;
export type ListModelsForOrgQueryResult = Apollo.QueryResult<ListModelsForOrgQuery, ListModelsForOrgQueryVariables>;
export const CheckEmailVerifiedDocument = gql`
    query CheckEmailVerified($email: String!) {
  admin {
    checkAuth0Verified(email: $email)
  }
}
    `;

/**
 * __useCheckEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useCheckEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailVerifiedQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailVerifiedQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailVerifiedQuery, CheckEmailVerifiedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailVerifiedQuery, CheckEmailVerifiedQueryVariables>(CheckEmailVerifiedDocument, options);
      }
export function useCheckEmailVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailVerifiedQuery, CheckEmailVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailVerifiedQuery, CheckEmailVerifiedQueryVariables>(CheckEmailVerifiedDocument, options);
        }
export type CheckEmailVerifiedQueryHookResult = ReturnType<typeof useCheckEmailVerifiedQuery>;
export type CheckEmailVerifiedLazyQueryHookResult = ReturnType<typeof useCheckEmailVerifiedLazyQuery>;
export type CheckEmailVerifiedQueryResult = Apollo.QueryResult<CheckEmailVerifiedQuery, CheckEmailVerifiedQueryVariables>;
export const ImpersonateUserDocument = gql`
    mutation ImpersonateUser($userId: String!, $durationMinutes: Int!) {
  impersonation {
    impersonate(userId: $userId, durationMinutes: $durationMinutes)
  }
}
    `;
export type ImpersonateUserMutationFn = Apollo.MutationFunction<ImpersonateUserMutation, ImpersonateUserMutationVariables>;

/**
 * __useImpersonateUserMutation__
 *
 * To run a mutation, you first call `useImpersonateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserMutation, { data, loading, error }] = useImpersonateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      durationMinutes: // value for 'durationMinutes'
 *   },
 * });
 */
export function useImpersonateUserMutation(baseOptions?: Apollo.MutationHookOptions<ImpersonateUserMutation, ImpersonateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImpersonateUserMutation, ImpersonateUserMutationVariables>(ImpersonateUserDocument, options);
      }
export type ImpersonateUserMutationHookResult = ReturnType<typeof useImpersonateUserMutation>;
export type ImpersonateUserMutationResult = Apollo.MutationResult<ImpersonateUserMutation>;
export type ImpersonateUserMutationOptions = Apollo.BaseMutationOptions<ImpersonateUserMutation, ImpersonateUserMutationVariables>;
export const ClearImpersonationDocument = gql`
    mutation ClearImpersonation {
  clearImpersonation
}
    `;
export type ClearImpersonationMutationFn = Apollo.MutationFunction<ClearImpersonationMutation, ClearImpersonationMutationVariables>;

/**
 * __useClearImpersonationMutation__
 *
 * To run a mutation, you first call `useClearImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearImpersonationMutation, { data, loading, error }] = useClearImpersonationMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearImpersonationMutation(baseOptions?: Apollo.MutationHookOptions<ClearImpersonationMutation, ClearImpersonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearImpersonationMutation, ClearImpersonationMutationVariables>(ClearImpersonationDocument, options);
      }
export type ClearImpersonationMutationHookResult = ReturnType<typeof useClearImpersonationMutation>;
export type ClearImpersonationMutationResult = Apollo.MutationResult<ClearImpersonationMutation>;
export type ClearImpersonationMutationOptions = Apollo.BaseMutationOptions<ClearImpersonationMutation, ClearImpersonationMutationVariables>;
export const NewModelDocument = gql`
    mutation NewModel($orgId: String!, $name: String!, $modelType: ModelType!) {
  admin {
    models {
      create(orgId: $orgId, name: $name, type: $modelType) {
        id
      }
    }
  }
}
    `;
export type NewModelMutationFn = Apollo.MutationFunction<NewModelMutation, NewModelMutationVariables>;

/**
 * __useNewModelMutation__
 *
 * To run a mutation, you first call `useNewModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newModelMutation, { data, loading, error }] = useNewModelMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      name: // value for 'name'
 *      modelType: // value for 'modelType'
 *   },
 * });
 */
export function useNewModelMutation(baseOptions?: Apollo.MutationHookOptions<NewModelMutation, NewModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewModelMutation, NewModelMutationVariables>(NewModelDocument, options);
      }
export type NewModelMutationHookResult = ReturnType<typeof useNewModelMutation>;
export type NewModelMutationResult = Apollo.MutationResult<NewModelMutation>;
export type NewModelMutationOptions = Apollo.BaseMutationOptions<NewModelMutation, NewModelMutationVariables>;
export const NewOrgDocument = gql`
    mutation NewOrg($name: String!, $emailDomains: [String!]) {
  admin {
    organizations {
      create(name: $name, emailDomains: $emailDomains) {
        id
        name
      }
    }
  }
}
    `;
export type NewOrgMutationFn = Apollo.MutationFunction<NewOrgMutation, NewOrgMutationVariables>;

/**
 * __useNewOrgMutation__
 *
 * To run a mutation, you first call `useNewOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrgMutation, { data, loading, error }] = useNewOrgMutation({
 *   variables: {
 *      name: // value for 'name'
 *      emailDomains: // value for 'emailDomains'
 *   },
 * });
 */
export function useNewOrgMutation(baseOptions?: Apollo.MutationHookOptions<NewOrgMutation, NewOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewOrgMutation, NewOrgMutationVariables>(NewOrgDocument, options);
      }
export type NewOrgMutationHookResult = ReturnType<typeof useNewOrgMutation>;
export type NewOrgMutationResult = Apollo.MutationResult<NewOrgMutation>;
export type NewOrgMutationOptions = Apollo.BaseMutationOptions<NewOrgMutation, NewOrgMutationVariables>;
export const AddMemberDocument = gql`
    mutation AddMember($orgId: String!, $email: String!, $role: MembershipRole!) {
  admin {
    memberships {
      add(email: $email, role: $role, orgId: $orgId) {
        userId
      }
    }
  }
}
    `;
export type AddMemberMutationFn = Apollo.MutationFunction<AddMemberMutation, AddMemberMutationVariables>;

/**
 * __useAddMemberMutation__
 *
 * To run a mutation, you first call `useAddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberMutation, { data, loading, error }] = useAddMemberMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberMutation, AddMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMemberMutation, AddMemberMutationVariables>(AddMemberDocument, options);
      }
export type AddMemberMutationHookResult = ReturnType<typeof useAddMemberMutation>;
export type AddMemberMutationResult = Apollo.MutationResult<AddMemberMutation>;
export type AddMemberMutationOptions = Apollo.BaseMutationOptions<AddMemberMutation, AddMemberMutationVariables>;
export const InternalUpdateMemberDocument = gql`
    mutation InternalUpdateMember($orgId: String!, $email: String!, $role: MembershipRole!) {
  admin {
    memberships {
      update(email: $email, role: $role, orgId: $orgId) {
        userId
      }
    }
  }
}
    `;
export type InternalUpdateMemberMutationFn = Apollo.MutationFunction<InternalUpdateMemberMutation, InternalUpdateMemberMutationVariables>;

/**
 * __useInternalUpdateMemberMutation__
 *
 * To run a mutation, you first call `useInternalUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalUpdateMemberMutation, { data, loading, error }] = useInternalUpdateMemberMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInternalUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<InternalUpdateMemberMutation, InternalUpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InternalUpdateMemberMutation, InternalUpdateMemberMutationVariables>(InternalUpdateMemberDocument, options);
      }
export type InternalUpdateMemberMutationHookResult = ReturnType<typeof useInternalUpdateMemberMutation>;
export type InternalUpdateMemberMutationResult = Apollo.MutationResult<InternalUpdateMemberMutation>;
export type InternalUpdateMemberMutationOptions = Apollo.BaseMutationOptions<InternalUpdateMemberMutation, InternalUpdateMemberMutationVariables>;
export const RemoveMemberDocument = gql`
    mutation RemoveMember($orgId: String!, $email: String!) {
  admin {
    memberships {
      remove(orgId: $orgId, email: $email) {
        userId
      }
    }
  }
}
    `;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const SetMemberDefaultOrgDocument = gql`
    mutation SetMemberDefaultOrg($orgId: String!, $email: String!) {
  admin {
    memberships {
      setDefaultOrg(orgId: $orgId, email: $email)
    }
  }
}
    `;
export type SetMemberDefaultOrgMutationFn = Apollo.MutationFunction<SetMemberDefaultOrgMutation, SetMemberDefaultOrgMutationVariables>;

/**
 * __useSetMemberDefaultOrgMutation__
 *
 * To run a mutation, you first call `useSetMemberDefaultOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMemberDefaultOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMemberDefaultOrgMutation, { data, loading, error }] = useSetMemberDefaultOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSetMemberDefaultOrgMutation(baseOptions?: Apollo.MutationHookOptions<SetMemberDefaultOrgMutation, SetMemberDefaultOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMemberDefaultOrgMutation, SetMemberDefaultOrgMutationVariables>(SetMemberDefaultOrgDocument, options);
      }
export type SetMemberDefaultOrgMutationHookResult = ReturnType<typeof useSetMemberDefaultOrgMutation>;
export type SetMemberDefaultOrgMutationResult = Apollo.MutationResult<SetMemberDefaultOrgMutation>;
export type SetMemberDefaultOrgMutationOptions = Apollo.BaseMutationOptions<SetMemberDefaultOrgMutation, SetMemberDefaultOrgMutationVariables>;
export const ListEmailDomainsForOrgDocument = gql`
    query ListEmailDomainsForOrg($orgId: String!) {
  admin {
    organization(orgId: $orgId) {
      id
      emailDomains
    }
  }
}
    `;

/**
 * __useListEmailDomainsForOrgQuery__
 *
 * To run a query within a React component, call `useListEmailDomainsForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmailDomainsForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmailDomainsForOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useListEmailDomainsForOrgQuery(baseOptions: Apollo.QueryHookOptions<ListEmailDomainsForOrgQuery, ListEmailDomainsForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmailDomainsForOrgQuery, ListEmailDomainsForOrgQueryVariables>(ListEmailDomainsForOrgDocument, options);
      }
export function useListEmailDomainsForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmailDomainsForOrgQuery, ListEmailDomainsForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmailDomainsForOrgQuery, ListEmailDomainsForOrgQueryVariables>(ListEmailDomainsForOrgDocument, options);
        }
export type ListEmailDomainsForOrgQueryHookResult = ReturnType<typeof useListEmailDomainsForOrgQuery>;
export type ListEmailDomainsForOrgLazyQueryHookResult = ReturnType<typeof useListEmailDomainsForOrgLazyQuery>;
export type ListEmailDomainsForOrgQueryResult = Apollo.QueryResult<ListEmailDomainsForOrgQuery, ListEmailDomainsForOrgQueryVariables>;
export const UpdateEmailDomainsDocument = gql`
    mutation UpdateEmailDomains($orgId: String!, $emailDomains: [String!]!) {
  admin {
    organization(orgId: $orgId) {
      updateEmailDomains(emailDomains: $emailDomains) {
        emailDomains
      }
    }
  }
}
    `;
export type UpdateEmailDomainsMutationFn = Apollo.MutationFunction<UpdateEmailDomainsMutation, UpdateEmailDomainsMutationVariables>;

/**
 * __useUpdateEmailDomainsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailDomainsMutation, { data, loading, error }] = useUpdateEmailDomainsMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      emailDomains: // value for 'emailDomains'
 *   },
 * });
 */
export function useUpdateEmailDomainsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailDomainsMutation, UpdateEmailDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailDomainsMutation, UpdateEmailDomainsMutationVariables>(UpdateEmailDomainsDocument, options);
      }
export type UpdateEmailDomainsMutationHookResult = ReturnType<typeof useUpdateEmailDomainsMutation>;
export type UpdateEmailDomainsMutationResult = Apollo.MutationResult<UpdateEmailDomainsMutation>;
export type UpdateEmailDomainsMutationOptions = Apollo.BaseMutationOptions<UpdateEmailDomainsMutation, UpdateEmailDomainsMutationVariables>;
export const UpdateSubscriptionTierDocument = gql`
    mutation UpdateSubscriptionTier($orgId: String!, $subscriptionTier: SubscriptionTier!) {
  admin {
    organization(orgId: $orgId) {
      updateSubscriptionTier(subscriptionTier: $subscriptionTier) {
        subscriptionTier
      }
    }
  }
}
    `;
export type UpdateSubscriptionTierMutationFn = Apollo.MutationFunction<UpdateSubscriptionTierMutation, UpdateSubscriptionTierMutationVariables>;

/**
 * __useUpdateSubscriptionTierMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionTierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionTierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionTierMutation, { data, loading, error }] = useUpdateSubscriptionTierMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      subscriptionTier: // value for 'subscriptionTier'
 *   },
 * });
 */
export function useUpdateSubscriptionTierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionTierMutation, UpdateSubscriptionTierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionTierMutation, UpdateSubscriptionTierMutationVariables>(UpdateSubscriptionTierDocument, options);
      }
export type UpdateSubscriptionTierMutationHookResult = ReturnType<typeof useUpdateSubscriptionTierMutation>;
export type UpdateSubscriptionTierMutationResult = Apollo.MutationResult<UpdateSubscriptionTierMutation>;
export type UpdateSubscriptionTierMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionTierMutation, UpdateSubscriptionTierMutationVariables>;
export const GetMaintenanceBannerForAdminDocument = gql`
    query GetMaintenanceBannerForAdmin {
  maintenanceBanner {
    message
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useGetMaintenanceBannerForAdminQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceBannerForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceBannerForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceBannerForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaintenanceBannerForAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetMaintenanceBannerForAdminQuery, GetMaintenanceBannerForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaintenanceBannerForAdminQuery, GetMaintenanceBannerForAdminQueryVariables>(GetMaintenanceBannerForAdminDocument, options);
      }
export function useGetMaintenanceBannerForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaintenanceBannerForAdminQuery, GetMaintenanceBannerForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaintenanceBannerForAdminQuery, GetMaintenanceBannerForAdminQueryVariables>(GetMaintenanceBannerForAdminDocument, options);
        }
export type GetMaintenanceBannerForAdminQueryHookResult = ReturnType<typeof useGetMaintenanceBannerForAdminQuery>;
export type GetMaintenanceBannerForAdminLazyQueryHookResult = ReturnType<typeof useGetMaintenanceBannerForAdminLazyQuery>;
export type GetMaintenanceBannerForAdminQueryResult = Apollo.QueryResult<GetMaintenanceBannerForAdminQuery, GetMaintenanceBannerForAdminQueryVariables>;
export const UpdateMaintenanceBarDocument = gql`
    mutation UpdateMaintenanceBar($message: String!) {
  admin {
    global {
      updateMaintenanceBanner(message: $message)
    }
  }
}
    `;
export type UpdateMaintenanceBarMutationFn = Apollo.MutationFunction<UpdateMaintenanceBarMutation, UpdateMaintenanceBarMutationVariables>;

/**
 * __useUpdateMaintenanceBarMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceBarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceBarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceBarMutation, { data, loading, error }] = useUpdateMaintenanceBarMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateMaintenanceBarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceBarMutation, UpdateMaintenanceBarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMaintenanceBarMutation, UpdateMaintenanceBarMutationVariables>(UpdateMaintenanceBarDocument, options);
      }
export type UpdateMaintenanceBarMutationHookResult = ReturnType<typeof useUpdateMaintenanceBarMutation>;
export type UpdateMaintenanceBarMutationResult = Apollo.MutationResult<UpdateMaintenanceBarMutation>;
export type UpdateMaintenanceBarMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceBarMutation, UpdateMaintenanceBarMutationVariables>;
export const ClearMaintenanceBarDocument = gql`
    mutation ClearMaintenanceBar {
  admin {
    global {
      clearMaintenanceBanner
    }
  }
}
    `;
export type ClearMaintenanceBarMutationFn = Apollo.MutationFunction<ClearMaintenanceBarMutation, ClearMaintenanceBarMutationVariables>;

/**
 * __useClearMaintenanceBarMutation__
 *
 * To run a mutation, you first call `useClearMaintenanceBarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearMaintenanceBarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearMaintenanceBarMutation, { data, loading, error }] = useClearMaintenanceBarMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearMaintenanceBarMutation(baseOptions?: Apollo.MutationHookOptions<ClearMaintenanceBarMutation, ClearMaintenanceBarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearMaintenanceBarMutation, ClearMaintenanceBarMutationVariables>(ClearMaintenanceBarDocument, options);
      }
export type ClearMaintenanceBarMutationHookResult = ReturnType<typeof useClearMaintenanceBarMutation>;
export type ClearMaintenanceBarMutationResult = Apollo.MutationResult<ClearMaintenanceBarMutation>;
export type ClearMaintenanceBarMutationOptions = Apollo.BaseMutationOptions<ClearMaintenanceBarMutation, ClearMaintenanceBarMutationVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail {
  emailVerification {
    resendVerificationEmail
  }
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, options);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const CheckAuth0EmailVerificationDocument = gql`
    query checkAuth0EmailVerification {
  user {
    auth0Id
    auth0EmailVerified
    email
  }
}
    `;

/**
 * __useCheckAuth0EmailVerificationQuery__
 *
 * To run a query within a React component, call `useCheckAuth0EmailVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuth0EmailVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuth0EmailVerificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuth0EmailVerificationQuery(baseOptions?: Apollo.QueryHookOptions<CheckAuth0EmailVerificationQuery, CheckAuth0EmailVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAuth0EmailVerificationQuery, CheckAuth0EmailVerificationQueryVariables>(CheckAuth0EmailVerificationDocument, options);
      }
export function useCheckAuth0EmailVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAuth0EmailVerificationQuery, CheckAuth0EmailVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAuth0EmailVerificationQuery, CheckAuth0EmailVerificationQueryVariables>(CheckAuth0EmailVerificationDocument, options);
        }
export type CheckAuth0EmailVerificationQueryHookResult = ReturnType<typeof useCheckAuth0EmailVerificationQuery>;
export type CheckAuth0EmailVerificationLazyQueryHookResult = ReturnType<typeof useCheckAuth0EmailVerificationLazyQuery>;
export type CheckAuth0EmailVerificationQueryResult = Apollo.QueryResult<CheckAuth0EmailVerificationQuery, CheckAuth0EmailVerificationQueryVariables>;
export const GetModelValuesForFeatureCardsDocument = gql`
    query getModelValuesForFeatureCards($modelId: String!, $featureId: String!, $from: Float!, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    feature(name: $featureId) {
      name
      sketches(from: $from, to: $to) {
        showAsDiscrete
        datasetTimestamp
        lastUploadTimestamp
        numberSummary {
          ...NumberSummaryFields
        }
      }
    }
  }
}
    ${NumberSummaryFieldsFragmentDoc}`;

/**
 * __useGetModelValuesForFeatureCardsQuery__
 *
 * To run a query within a React component, call `useGetModelValuesForFeatureCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelValuesForFeatureCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelValuesForFeatureCardsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      featureId: // value for 'featureId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetModelValuesForFeatureCardsQuery(baseOptions: Apollo.QueryHookOptions<GetModelValuesForFeatureCardsQuery, GetModelValuesForFeatureCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelValuesForFeatureCardsQuery, GetModelValuesForFeatureCardsQueryVariables>(GetModelValuesForFeatureCardsDocument, options);
      }
export function useGetModelValuesForFeatureCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelValuesForFeatureCardsQuery, GetModelValuesForFeatureCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelValuesForFeatureCardsQuery, GetModelValuesForFeatureCardsQueryVariables>(GetModelValuesForFeatureCardsDocument, options);
        }
export type GetModelValuesForFeatureCardsQueryHookResult = ReturnType<typeof useGetModelValuesForFeatureCardsQuery>;
export type GetModelValuesForFeatureCardsLazyQueryHookResult = ReturnType<typeof useGetModelValuesForFeatureCardsLazyQuery>;
export type GetModelValuesForFeatureCardsQueryResult = Apollo.QueryResult<GetModelValuesForFeatureCardsQuery, GetModelValuesForFeatureCardsQueryVariables>;
export const GetTotalCountDocument = gql`
    query getTotalCount($modelId: String!, $featureId: String!, $from: Float!, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    feature(name: $featureId) {
      name
      sketches(from: $from, to: $to) {
        datasetTimestamp
        lastUploadTimestamp
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetTotalCountQuery__
 *
 * To run a query within a React component, call `useGetTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalCountQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      featureId: // value for 'featureId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetTotalCountQuery(baseOptions: Apollo.QueryHookOptions<GetTotalCountQuery, GetTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalCountQuery, GetTotalCountQueryVariables>(GetTotalCountDocument, options);
      }
export function useGetTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalCountQuery, GetTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalCountQuery, GetTotalCountQueryVariables>(GetTotalCountDocument, options);
        }
export type GetTotalCountQueryHookResult = ReturnType<typeof useGetTotalCountQuery>;
export type GetTotalCountLazyQueryHookResult = ReturnType<typeof useGetTotalCountLazyQuery>;
export type GetTotalCountQueryResult = Apollo.QueryResult<GetTotalCountQuery, GetTotalCountQueryVariables>;
export const GetSketchesForTotalCountCardDocument = gql`
    query getSketchesForTotalCountCard($modelId: String!, $columnName: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float, $isOutput: Boolean = false) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      feature(name: $columnName) @skip(if: $isOutput) {
        name
        sketches(from: $from, to: $to) {
          datasetTimestamp
          lastUploadTimestamp
          totalCount
        }
      }
      output(name: $columnName) @include(if: $isOutput) {
        name
        sketches(from: $from, to: $to) {
          datasetTimestamp
          lastUploadTimestamp
          totalCount
        }
      }
    }
  }
}
    `;

/**
 * __useGetSketchesForTotalCountCardQuery__
 *
 * To run a query within a React component, call `useGetSketchesForTotalCountCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSketchesForTotalCountCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSketchesForTotalCountCardQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      columnName: // value for 'columnName'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      isOutput: // value for 'isOutput'
 *   },
 * });
 */
export function useGetSketchesForTotalCountCardQuery(baseOptions: Apollo.QueryHookOptions<GetSketchesForTotalCountCardQuery, GetSketchesForTotalCountCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSketchesForTotalCountCardQuery, GetSketchesForTotalCountCardQueryVariables>(GetSketchesForTotalCountCardDocument, options);
      }
export function useGetSketchesForTotalCountCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSketchesForTotalCountCardQuery, GetSketchesForTotalCountCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSketchesForTotalCountCardQuery, GetSketchesForTotalCountCardQueryVariables>(GetSketchesForTotalCountCardDocument, options);
        }
export type GetSketchesForTotalCountCardQueryHookResult = ReturnType<typeof useGetSketchesForTotalCountCardQuery>;
export type GetSketchesForTotalCountCardLazyQueryHookResult = ReturnType<typeof useGetSketchesForTotalCountCardLazyQuery>;
export type GetSketchesForTotalCountCardQueryResult = Apollo.QueryResult<GetSketchesForTotalCountCardQuery, GetSketchesForTotalCountCardQueryVariables>;
export const GetSegmentedTotalCountDocument = gql`
    query getSegmentedTotalCount($modelId: String!, $featureId: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      feature(name: $featureId) {
        name
        sketches(from: $from, to: $to) {
          datasetTimestamp
          lastUploadTimestamp
          totalCount
        }
      }
    }
  }
}
    `;

/**
 * __useGetSegmentedTotalCountQuery__
 *
 * To run a query within a React component, call `useGetSegmentedTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentedTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentedTotalCountQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      featureId: // value for 'featureId'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetSegmentedTotalCountQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentedTotalCountQuery, GetSegmentedTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentedTotalCountQuery, GetSegmentedTotalCountQueryVariables>(GetSegmentedTotalCountDocument, options);
      }
export function useGetSegmentedTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentedTotalCountQuery, GetSegmentedTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentedTotalCountQuery, GetSegmentedTotalCountQueryVariables>(GetSegmentedTotalCountDocument, options);
        }
export type GetSegmentedTotalCountQueryHookResult = ReturnType<typeof useGetSegmentedTotalCountQuery>;
export type GetSegmentedTotalCountLazyQueryHookResult = ReturnType<typeof useGetSegmentedTotalCountLazyQuery>;
export type GetSegmentedTotalCountQueryResult = Apollo.QueryResult<GetSegmentedTotalCountQuery, GetSegmentedTotalCountQueryVariables>;
export const GetSketchesForSingleValuesCardDocument = gql`
    query getSketchesForSingleValuesCard($modelId: String!, $columnName: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float, $isOutput: Boolean = false) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      feature(name: $columnName) @skip(if: $isOutput) {
        name
        sketches(from: $from, to: $to) {
          showAsDiscrete
          datasetTimestamp
          lastUploadTimestamp
          numberSummary {
            ...NumberSummaryFields
          }
        }
      }
      output(name: $columnName) @include(if: $isOutput) {
        name
        sketches(from: $from, to: $to) {
          showAsDiscrete
          datasetTimestamp
          lastUploadTimestamp
          numberSummary {
            ...NumberSummaryFields
          }
        }
      }
    }
  }
}
    ${NumberSummaryFieldsFragmentDoc}`;

/**
 * __useGetSketchesForSingleValuesCardQuery__
 *
 * To run a query within a React component, call `useGetSketchesForSingleValuesCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSketchesForSingleValuesCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSketchesForSingleValuesCardQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      columnName: // value for 'columnName'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      isOutput: // value for 'isOutput'
 *   },
 * });
 */
export function useGetSketchesForSingleValuesCardQuery(baseOptions: Apollo.QueryHookOptions<GetSketchesForSingleValuesCardQuery, GetSketchesForSingleValuesCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSketchesForSingleValuesCardQuery, GetSketchesForSingleValuesCardQueryVariables>(GetSketchesForSingleValuesCardDocument, options);
      }
export function useGetSketchesForSingleValuesCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSketchesForSingleValuesCardQuery, GetSketchesForSingleValuesCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSketchesForSingleValuesCardQuery, GetSketchesForSingleValuesCardQueryVariables>(GetSketchesForSingleValuesCardDocument, options);
        }
export type GetSketchesForSingleValuesCardQueryHookResult = ReturnType<typeof useGetSketchesForSingleValuesCardQuery>;
export type GetSketchesForSingleValuesCardLazyQueryHookResult = ReturnType<typeof useGetSketchesForSingleValuesCardLazyQuery>;
export type GetSketchesForSingleValuesCardQueryResult = Apollo.QueryResult<GetSketchesForSingleValuesCardQuery, GetSketchesForSingleValuesCardQueryVariables>;
export const GetSegmentValuesForFeatureCardsDocument = gql`
    query getSegmentValuesForFeatureCards($modelId: String!, $featureId: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      feature(name: $featureId) {
        name
        sketches(from: $from, to: $to) {
          showAsDiscrete
          datasetTimestamp
          lastUploadTimestamp
          numberSummary {
            ...NumberSummaryFields
          }
        }
      }
    }
  }
}
    ${NumberSummaryFieldsFragmentDoc}`;

/**
 * __useGetSegmentValuesForFeatureCardsQuery__
 *
 * To run a query within a React component, call `useGetSegmentValuesForFeatureCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentValuesForFeatureCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentValuesForFeatureCardsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      featureId: // value for 'featureId'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetSegmentValuesForFeatureCardsQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentValuesForFeatureCardsQuery, GetSegmentValuesForFeatureCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentValuesForFeatureCardsQuery, GetSegmentValuesForFeatureCardsQueryVariables>(GetSegmentValuesForFeatureCardsDocument, options);
      }
export function useGetSegmentValuesForFeatureCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentValuesForFeatureCardsQuery, GetSegmentValuesForFeatureCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentValuesForFeatureCardsQuery, GetSegmentValuesForFeatureCardsQueryVariables>(GetSegmentValuesForFeatureCardsDocument, options);
        }
export type GetSegmentValuesForFeatureCardsQueryHookResult = ReturnType<typeof useGetSegmentValuesForFeatureCardsQuery>;
export type GetSegmentValuesForFeatureCardsLazyQueryHookResult = ReturnType<typeof useGetSegmentValuesForFeatureCardsLazyQuery>;
export type GetSegmentValuesForFeatureCardsQueryResult = Apollo.QueryResult<GetSegmentValuesForFeatureCardsQuery, GetSegmentValuesForFeatureCardsQueryVariables>;
export const GetOutputValuesForFeatureCardDocument = gql`
    query getOutputValuesForFeatureCard($modelId: String!, $outputId: String!, $from: Float!, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    output(name: $outputId) {
      name
      sketches(from: $from, to: $to) {
        showAsDiscrete
        datasetTimestamp
        lastUploadTimestamp
        numberSummary {
          ...NumberSummaryFields
        }
      }
    }
  }
}
    ${NumberSummaryFieldsFragmentDoc}`;

/**
 * __useGetOutputValuesForFeatureCardQuery__
 *
 * To run a query within a React component, call `useGetOutputValuesForFeatureCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputValuesForFeatureCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputValuesForFeatureCardQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      outputId: // value for 'outputId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetOutputValuesForFeatureCardQuery(baseOptions: Apollo.QueryHookOptions<GetOutputValuesForFeatureCardQuery, GetOutputValuesForFeatureCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputValuesForFeatureCardQuery, GetOutputValuesForFeatureCardQueryVariables>(GetOutputValuesForFeatureCardDocument, options);
      }
export function useGetOutputValuesForFeatureCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputValuesForFeatureCardQuery, GetOutputValuesForFeatureCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputValuesForFeatureCardQuery, GetOutputValuesForFeatureCardQueryVariables>(GetOutputValuesForFeatureCardDocument, options);
        }
export type GetOutputValuesForFeatureCardQueryHookResult = ReturnType<typeof useGetOutputValuesForFeatureCardQuery>;
export type GetOutputValuesForFeatureCardLazyQueryHookResult = ReturnType<typeof useGetOutputValuesForFeatureCardLazyQuery>;
export type GetOutputValuesForFeatureCardQueryResult = Apollo.QueryResult<GetOutputValuesForFeatureCardQuery, GetOutputValuesForFeatureCardQueryVariables>;
export const GetOutputValuesForSegmentFeatureCardDocument = gql`
    query getOutputValuesForSegmentFeatureCard($modelId: String!, $outputId: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      output(name: $outputId) {
        name
        sketches(from: $from, to: $to) {
          showAsDiscrete
          datasetTimestamp
          lastUploadTimestamp
          numberSummary {
            ...NumberSummaryFields
          }
        }
      }
    }
  }
}
    ${NumberSummaryFieldsFragmentDoc}`;

/**
 * __useGetOutputValuesForSegmentFeatureCardQuery__
 *
 * To run a query within a React component, call `useGetOutputValuesForSegmentFeatureCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputValuesForSegmentFeatureCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputValuesForSegmentFeatureCardQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      outputId: // value for 'outputId'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetOutputValuesForSegmentFeatureCardQuery(baseOptions: Apollo.QueryHookOptions<GetOutputValuesForSegmentFeatureCardQuery, GetOutputValuesForSegmentFeatureCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputValuesForSegmentFeatureCardQuery, GetOutputValuesForSegmentFeatureCardQueryVariables>(GetOutputValuesForSegmentFeatureCardDocument, options);
      }
export function useGetOutputValuesForSegmentFeatureCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputValuesForSegmentFeatureCardQuery, GetOutputValuesForSegmentFeatureCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputValuesForSegmentFeatureCardQuery, GetOutputValuesForSegmentFeatureCardQueryVariables>(GetOutputValuesForSegmentFeatureCardDocument, options);
        }
export type GetOutputValuesForSegmentFeatureCardQueryHookResult = ReturnType<typeof useGetOutputValuesForSegmentFeatureCardQuery>;
export type GetOutputValuesForSegmentFeatureCardLazyQueryHookResult = ReturnType<typeof useGetOutputValuesForSegmentFeatureCardLazyQuery>;
export type GetOutputValuesForSegmentFeatureCardQueryResult = Apollo.QueryResult<GetOutputValuesForSegmentFeatureCardQuery, GetOutputValuesForSegmentFeatureCardQueryVariables>;
export const SendGraphFeedbackDocument = gql`
    mutation sendGraphFeedback($feedback: Feedback!, $submitAnonymously: Boolean!) {
  submitFeedback(feedback: $feedback, submitAnonymously: $submitAnonymously)
}
    `;
export type SendGraphFeedbackMutationFn = Apollo.MutationFunction<SendGraphFeedbackMutation, SendGraphFeedbackMutationVariables>;

/**
 * __useSendGraphFeedbackMutation__
 *
 * To run a mutation, you first call `useSendGraphFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGraphFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGraphFeedbackMutation, { data, loading, error }] = useSendGraphFeedbackMutation({
 *   variables: {
 *      feedback: // value for 'feedback'
 *      submitAnonymously: // value for 'submitAnonymously'
 *   },
 * });
 */
export function useSendGraphFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SendGraphFeedbackMutation, SendGraphFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGraphFeedbackMutation, SendGraphFeedbackMutationVariables>(SendGraphFeedbackDocument, options);
      }
export type SendGraphFeedbackMutationHookResult = ReturnType<typeof useSendGraphFeedbackMutation>;
export type SendGraphFeedbackMutationResult = Apollo.MutationResult<SendGraphFeedbackMutation>;
export type SendGraphFeedbackMutationOptions = Apollo.BaseMutationOptions<SendGraphFeedbackMutation, SendGraphFeedbackMutationVariables>;
export const GetAllResourcesDocument = gql`
    query getAllResources {
  resources: models {
    ...ResourceBasicInfo
  }
}
    ${ResourceBasicInfoFragmentDoc}`;

/**
 * __useGetAllResourcesQuery__
 *
 * To run a query within a React component, call `useGetAllResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllResourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllResourcesQuery, GetAllResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllResourcesQuery, GetAllResourcesQueryVariables>(GetAllResourcesDocument, options);
      }
export function useGetAllResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllResourcesQuery, GetAllResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllResourcesQuery, GetAllResourcesQueryVariables>(GetAllResourcesDocument, options);
        }
export type GetAllResourcesQueryHookResult = ReturnType<typeof useGetAllResourcesQuery>;
export type GetAllResourcesLazyQueryHookResult = ReturnType<typeof useGetAllResourcesLazyQuery>;
export type GetAllResourcesQueryResult = Apollo.QueryResult<GetAllResourcesQuery, GetAllResourcesQueryVariables>;
export const GetMetricsByTagDocument = gql`
    query getMetricsByTag($resourceId: String!, $tags: [String!]!, $segmentTags: [SegmentTagFilter!], $granularity: TimePeriod!, $from: Float!, $to: Float) {
  resource: model(id: $resourceId) {
    id
    name
    batchFrequency
    segment(tags: $segmentTags) {
      tags {
        key
        value
      }
      customMetrics(tags: $tags, granularity: $granularity) {
        ...MetricData
      }
    }
    customMetrics(tags: $tags, granularity: $granularity) {
      ...MetricData
    }
  }
}
    ${MetricDataFragmentDoc}`;

/**
 * __useGetMetricsByTagQuery__
 *
 * To run a query within a React component, call `useGetMetricsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricsByTagQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      tags: // value for 'tags'
 *      segmentTags: // value for 'segmentTags'
 *      granularity: // value for 'granularity'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMetricsByTagQuery(baseOptions: Apollo.QueryHookOptions<GetMetricsByTagQuery, GetMetricsByTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricsByTagQuery, GetMetricsByTagQueryVariables>(GetMetricsByTagDocument, options);
      }
export function useGetMetricsByTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricsByTagQuery, GetMetricsByTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricsByTagQuery, GetMetricsByTagQueryVariables>(GetMetricsByTagDocument, options);
        }
export type GetMetricsByTagQueryHookResult = ReturnType<typeof useGetMetricsByTagQuery>;
export type GetMetricsByTagLazyQueryHookResult = ReturnType<typeof useGetMetricsByTagLazyQuery>;
export type GetMetricsByTagQueryResult = Apollo.QueryResult<GetMetricsByTagQuery, GetMetricsByTagQueryVariables>;
export const GetProfileTimestampsDocument = gql`
    query getProfileTimestamps($modelId: String, $timestamps: [Float!]!) {
  model(id: $modelId) {
    batchDateRanges(timestamps: $timestamps) {
      fromTimestamp
      toTimestamp
    }
  }
}
    `;

/**
 * __useGetProfileTimestampsQuery__
 *
 * To run a query within a React component, call `useGetProfileTimestampsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileTimestampsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileTimestampsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      timestamps: // value for 'timestamps'
 *   },
 * });
 */
export function useGetProfileTimestampsQuery(baseOptions: Apollo.QueryHookOptions<GetProfileTimestampsQuery, GetProfileTimestampsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileTimestampsQuery, GetProfileTimestampsQueryVariables>(GetProfileTimestampsDocument, options);
      }
export function useGetProfileTimestampsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileTimestampsQuery, GetProfileTimestampsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileTimestampsQuery, GetProfileTimestampsQueryVariables>(GetProfileTimestampsDocument, options);
        }
export type GetProfileTimestampsQueryHookResult = ReturnType<typeof useGetProfileTimestampsQuery>;
export type GetProfileTimestampsLazyQueryHookResult = ReturnType<typeof useGetProfileTimestampsLazyQuery>;
export type GetProfileTimestampsQueryResult = Apollo.QueryResult<GetProfileTimestampsQuery, GetProfileTimestampsQueryVariables>;
export const GetFrequentItemsAnalysisDocument = gql`
    query getFrequentItemsAnalysis($model: String!, $feature: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]) {
  analysisResults(filter: {anomaliesOnly: false, columns: [$feature], datasetId: $model, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, metrics: [FREQUENT_ITEMS]}, offset: 0, limit: 0, sortDirection: ASC) {
    ...LLMFrequentItemsAnalysisData
  }
}
    ${LlmFrequentItemsAnalysisDataFragmentDoc}`;

/**
 * __useGetFrequentItemsAnalysisQuery__
 *
 * To run a query within a React component, call `useGetFrequentItemsAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentItemsAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentItemsAnalysisQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetFrequentItemsAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetFrequentItemsAnalysisQuery, GetFrequentItemsAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFrequentItemsAnalysisQuery, GetFrequentItemsAnalysisQueryVariables>(GetFrequentItemsAnalysisDocument, options);
      }
export function useGetFrequentItemsAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrequentItemsAnalysisQuery, GetFrequentItemsAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFrequentItemsAnalysisQuery, GetFrequentItemsAnalysisQueryVariables>(GetFrequentItemsAnalysisDocument, options);
        }
export type GetFrequentItemsAnalysisQueryHookResult = ReturnType<typeof useGetFrequentItemsAnalysisQuery>;
export type GetFrequentItemsAnalysisLazyQueryHookResult = ReturnType<typeof useGetFrequentItemsAnalysisLazyQuery>;
export type GetFrequentItemsAnalysisQueryResult = Apollo.QueryResult<GetFrequentItemsAnalysisQuery, GetFrequentItemsAnalysisQueryVariables>;
export const GetContinuousMetricsAnalysisDocument = gql`
    query getContinuousMetricsAnalysis($model: String!, $feature: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $metrics: [AnalysisMetric!], $adhocRunId: String) {
  analysisResults(filter: {anomaliesOnly: false, columns: [$feature], metrics: $metrics, datasetId: $model, fromTimestamp: $from, toTimestamp: $to, segmentTags: $tags, adhocRunId: $adhocRunId}, offset: 0, limit: 0, sortDirection: ASC) {
    ...ThresholdAnalysisData
    comparison_expected
    comparison_observed
  }
}
    ${ThresholdAnalysisDataFragmentDoc}`;

/**
 * __useGetContinuousMetricsAnalysisQuery__
 *
 * To run a query within a React component, call `useGetContinuousMetricsAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContinuousMetricsAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContinuousMetricsAnalysisQuery({
 *   variables: {
 *      model: // value for 'model'
 *      feature: // value for 'feature'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      metrics: // value for 'metrics'
 *      adhocRunId: // value for 'adhocRunId'
 *   },
 * });
 */
export function useGetContinuousMetricsAnalysisQuery(baseOptions: Apollo.QueryHookOptions<GetContinuousMetricsAnalysisQuery, GetContinuousMetricsAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContinuousMetricsAnalysisQuery, GetContinuousMetricsAnalysisQueryVariables>(GetContinuousMetricsAnalysisDocument, options);
      }
export function useGetContinuousMetricsAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContinuousMetricsAnalysisQuery, GetContinuousMetricsAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContinuousMetricsAnalysisQuery, GetContinuousMetricsAnalysisQueryVariables>(GetContinuousMetricsAnalysisDocument, options);
        }
export type GetContinuousMetricsAnalysisQueryHookResult = ReturnType<typeof useGetContinuousMetricsAnalysisQuery>;
export type GetContinuousMetricsAnalysisLazyQueryHookResult = ReturnType<typeof useGetContinuousMetricsAnalysisLazyQuery>;
export type GetContinuousMetricsAnalysisQueryResult = Apollo.QueryResult<GetContinuousMetricsAnalysisQuery, GetContinuousMetricsAnalysisQueryVariables>;
export const GetInsightsDocument = gql`
    query getInsights($model: String!, $batchProfile: Float, $refProfileId: String, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    insights(batchProfileTimestamp: $batchProfile, referenceProfileId: $refProfileId, tags: $tags) {
      name
    }
  }
}
    `;

/**
 * __useGetInsightsQuery__
 *
 * To run a query within a React component, call `useGetInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsightsQuery({
 *   variables: {
 *      model: // value for 'model'
 *      batchProfile: // value for 'batchProfile'
 *      refProfileId: // value for 'refProfileId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetInsightsQuery(baseOptions: Apollo.QueryHookOptions<GetInsightsQuery, GetInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsightsQuery, GetInsightsQueryVariables>(GetInsightsDocument, options);
      }
export function useGetInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsightsQuery, GetInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsightsQuery, GetInsightsQueryVariables>(GetInsightsDocument, options);
        }
export type GetInsightsQueryHookResult = ReturnType<typeof useGetInsightsQuery>;
export type GetInsightsLazyQueryHookResult = ReturnType<typeof useGetInsightsLazyQuery>;
export type GetInsightsQueryResult = Apollo.QueryResult<GetInsightsQuery, GetInsightsQueryVariables>;
export const SubmitSecureTrialFormDocument = gql`
    mutation submitSecureTrialForm($form: SecureForm!) {
  submitSecureForm(form: $form)
}
    `;
export type SubmitSecureTrialFormMutationFn = Apollo.MutationFunction<SubmitSecureTrialFormMutation, SubmitSecureTrialFormMutationVariables>;

/**
 * __useSubmitSecureTrialFormMutation__
 *
 * To run a mutation, you first call `useSubmitSecureTrialFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSecureTrialFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSecureTrialFormMutation, { data, loading, error }] = useSubmitSecureTrialFormMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useSubmitSecureTrialFormMutation(baseOptions?: Apollo.MutationHookOptions<SubmitSecureTrialFormMutation, SubmitSecureTrialFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitSecureTrialFormMutation, SubmitSecureTrialFormMutationVariables>(SubmitSecureTrialFormDocument, options);
      }
export type SubmitSecureTrialFormMutationHookResult = ReturnType<typeof useSubmitSecureTrialFormMutation>;
export type SubmitSecureTrialFormMutationResult = Apollo.MutationResult<SubmitSecureTrialFormMutation>;
export type SubmitSecureTrialFormMutationOptions = Apollo.BaseMutationOptions<SubmitSecureTrialFormMutation, SubmitSecureTrialFormMutationVariables>;
export const GetLlmTracesInfoDocument = gql`
    query getLlmTracesInfo($modelId: String!, $from: Float!, $to: Float!) {
  model(id: $modelId) {
    tracesSummary(fromTimestamp: $from, toTimestamp: $to) {
      hasTraces
    }
  }
}
    `;

/**
 * __useGetLlmTracesInfoQuery__
 *
 * To run a query within a React component, call `useGetLlmTracesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLlmTracesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLlmTracesInfoQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetLlmTracesInfoQuery(baseOptions: Apollo.QueryHookOptions<GetLlmTracesInfoQuery, GetLlmTracesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLlmTracesInfoQuery, GetLlmTracesInfoQueryVariables>(GetLlmTracesInfoDocument, options);
      }
export function useGetLlmTracesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLlmTracesInfoQuery, GetLlmTracesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLlmTracesInfoQuery, GetLlmTracesInfoQueryVariables>(GetLlmTracesInfoDocument, options);
        }
export type GetLlmTracesInfoQueryHookResult = ReturnType<typeof useGetLlmTracesInfoQuery>;
export type GetLlmTracesInfoLazyQueryHookResult = ReturnType<typeof useGetLlmTracesInfoLazyQuery>;
export type GetLlmTracesInfoQueryResult = Apollo.QueryResult<GetLlmTracesInfoQuery, GetLlmTracesInfoQueryVariables>;
export const GetMetricDetailPanelDataDocument = gql`
    query getMetricDetailPanelData($datasetId: String!, $metricName: String!, $from: Float!, $to: Float) {
  model(id: $datasetId) {
    id
    name
    datasetMetric(name: $metricName) {
      ...DatasetMetricsFields
    }
  }
}
    ${DatasetMetricsFieldsFragmentDoc}`;

/**
 * __useGetMetricDetailPanelDataQuery__
 *
 * To run a query within a React component, call `useGetMetricDetailPanelDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricDetailPanelDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricDetailPanelDataQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      metricName: // value for 'metricName'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetMetricDetailPanelDataQuery(baseOptions: Apollo.QueryHookOptions<GetMetricDetailPanelDataQuery, GetMetricDetailPanelDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetricDetailPanelDataQuery, GetMetricDetailPanelDataQueryVariables>(GetMetricDetailPanelDataDocument, options);
      }
export function useGetMetricDetailPanelDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetricDetailPanelDataQuery, GetMetricDetailPanelDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetricDetailPanelDataQuery, GetMetricDetailPanelDataQueryVariables>(GetMetricDetailPanelDataDocument, options);
        }
export type GetMetricDetailPanelDataQueryHookResult = ReturnType<typeof useGetMetricDetailPanelDataQuery>;
export type GetMetricDetailPanelDataLazyQueryHookResult = ReturnType<typeof useGetMetricDetailPanelDataLazyQuery>;
export type GetMetricDetailPanelDataQueryResult = Apollo.QueryResult<GetMetricDetailPanelDataQuery, GetMetricDetailPanelDataQueryVariables>;
export const GetOutputFeaturesForSideTableDocument = gql`
    query getOutputFeaturesForSideTable($model: String!, $from: Float!, $to: Float, $adHocRunId: String) {
  model(id: $model) {
    id
    name
    outputs {
      ...FeatureDataSideTable
    }
  }
}
    ${FeatureDataSideTableFragmentDoc}`;

/**
 * __useGetOutputFeaturesForSideTableQuery__
 *
 * To run a query within a React component, call `useGetOutputFeaturesForSideTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputFeaturesForSideTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputFeaturesForSideTableQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      adHocRunId: // value for 'adHocRunId'
 *   },
 * });
 */
export function useGetOutputFeaturesForSideTableQuery(baseOptions: Apollo.QueryHookOptions<GetOutputFeaturesForSideTableQuery, GetOutputFeaturesForSideTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputFeaturesForSideTableQuery, GetOutputFeaturesForSideTableQueryVariables>(GetOutputFeaturesForSideTableDocument, options);
      }
export function useGetOutputFeaturesForSideTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputFeaturesForSideTableQuery, GetOutputFeaturesForSideTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputFeaturesForSideTableQuery, GetOutputFeaturesForSideTableQueryVariables>(GetOutputFeaturesForSideTableDocument, options);
        }
export type GetOutputFeaturesForSideTableQueryHookResult = ReturnType<typeof useGetOutputFeaturesForSideTableQuery>;
export type GetOutputFeaturesForSideTableLazyQueryHookResult = ReturnType<typeof useGetOutputFeaturesForSideTableLazyQuery>;
export type GetOutputFeaturesForSideTableQueryResult = Apollo.QueryResult<GetOutputFeaturesForSideTableQuery, GetOutputFeaturesForSideTableQueryVariables>;
export const GetAllModelsDocument = gql`
    query getAllModels {
  models {
    ...ModelCommonData
    ...LatestAlertOnModel
    ...ModelMetricsName
  }
}
    ${ModelCommonDataFragmentDoc}
${LatestAlertOnModelFragmentDoc}
${ModelMetricsNameFragmentDoc}`;

/**
 * __useGetAllModelsQuery__
 *
 * To run a query within a React component, call `useGetAllModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllModelsQuery, GetAllModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllModelsQuery, GetAllModelsQueryVariables>(GetAllModelsDocument, options);
      }
export function useGetAllModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllModelsQuery, GetAllModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllModelsQuery, GetAllModelsQueryVariables>(GetAllModelsDocument, options);
        }
export type GetAllModelsQueryHookResult = ReturnType<typeof useGetAllModelsQuery>;
export type GetAllModelsLazyQueryHookResult = ReturnType<typeof useGetAllModelsLazyQuery>;
export type GetAllModelsQueryResult = Apollo.QueryResult<GetAllModelsQuery, GetAllModelsQueryVariables>;
export const GetModelPerformanceDocument = gql`
    query getModelPerformance($datasetId: String!, $from: Float!, $to: Float) {
  model(id: $datasetId) {
    id
    name
    modelType
    batchFrequency
    ...ModelPerformance
  }
}
    ${ModelPerformanceFragmentDoc}`;

/**
 * __useGetModelPerformanceQuery__
 *
 * To run a query within a React component, call `useGetModelPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelPerformanceQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetModelPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetModelPerformanceQuery, GetModelPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelPerformanceQuery, GetModelPerformanceQueryVariables>(GetModelPerformanceDocument, options);
      }
export function useGetModelPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelPerformanceQuery, GetModelPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelPerformanceQuery, GetModelPerformanceQueryVariables>(GetModelPerformanceDocument, options);
        }
export type GetModelPerformanceQueryHookResult = ReturnType<typeof useGetModelPerformanceQuery>;
export type GetModelPerformanceLazyQueryHookResult = ReturnType<typeof useGetModelPerformanceLazyQuery>;
export type GetModelPerformanceQueryResult = Apollo.QueryResult<GetModelPerformanceQuery, GetModelPerformanceQueryVariables>;
export const GetModelPerformanceCardDataAvailabilityDocument = gql`
    query getModelPerformanceCardDataAvailability($datasetId: String!, $from: Float!, $to: Float!, $granularity: TimePeriod) {
  model(id: $datasetId) {
    dataAvailability {
      latestTimestamp
    }
    timeseries: datasetMetrics(granularity: $granularity) {
      ...DatasetMetricFields
    }
    modelType
  }
}
    ${DatasetMetricFieldsFragmentDoc}`;

/**
 * __useGetModelPerformanceCardDataAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetModelPerformanceCardDataAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelPerformanceCardDataAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelPerformanceCardDataAvailabilityQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      granularity: // value for 'granularity'
 *   },
 * });
 */
export function useGetModelPerformanceCardDataAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetModelPerformanceCardDataAvailabilityQuery, GetModelPerformanceCardDataAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelPerformanceCardDataAvailabilityQuery, GetModelPerformanceCardDataAvailabilityQueryVariables>(GetModelPerformanceCardDataAvailabilityDocument, options);
      }
export function useGetModelPerformanceCardDataAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelPerformanceCardDataAvailabilityQuery, GetModelPerformanceCardDataAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelPerformanceCardDataAvailabilityQuery, GetModelPerformanceCardDataAvailabilityQueryVariables>(GetModelPerformanceCardDataAvailabilityDocument, options);
        }
export type GetModelPerformanceCardDataAvailabilityQueryHookResult = ReturnType<typeof useGetModelPerformanceCardDataAvailabilityQuery>;
export type GetModelPerformanceCardDataAvailabilityLazyQueryHookResult = ReturnType<typeof useGetModelPerformanceCardDataAvailabilityLazyQuery>;
export type GetModelPerformanceCardDataAvailabilityQueryResult = Apollo.QueryResult<GetModelPerformanceCardDataAvailabilityQuery, GetModelPerformanceCardDataAvailabilityQueryVariables>;
export const GetModelPerformanceRollupDocument = gql`
    query getModelPerformanceRollup($datasetId: String!, $dailyFrom: Float!, $dailyTo: Float!, $weeklyFrom: Float!, $weeklyTo: Float!) {
  model(id: $datasetId) {
    rollup: datasetMetrics(granularity: ALL) {
      name
      oneDay: values(fromTimestamp: $dailyFrom, toTimestamp: $dailyTo) {
        ...MetricValueFields
      }
      oneWeek: values(fromTimestamp: $weeklyFrom, toTimestamp: $weeklyTo) {
        ...MetricValueFields
      }
    }
  }
}
    ${MetricValueFieldsFragmentDoc}`;

/**
 * __useGetModelPerformanceRollupQuery__
 *
 * To run a query within a React component, call `useGetModelPerformanceRollupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelPerformanceRollupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelPerformanceRollupQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      dailyFrom: // value for 'dailyFrom'
 *      dailyTo: // value for 'dailyTo'
 *      weeklyFrom: // value for 'weeklyFrom'
 *      weeklyTo: // value for 'weeklyTo'
 *   },
 * });
 */
export function useGetModelPerformanceRollupQuery(baseOptions: Apollo.QueryHookOptions<GetModelPerformanceRollupQuery, GetModelPerformanceRollupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelPerformanceRollupQuery, GetModelPerformanceRollupQueryVariables>(GetModelPerformanceRollupDocument, options);
      }
export function useGetModelPerformanceRollupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelPerformanceRollupQuery, GetModelPerformanceRollupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelPerformanceRollupQuery, GetModelPerformanceRollupQueryVariables>(GetModelPerformanceRollupDocument, options);
        }
export type GetModelPerformanceRollupQueryHookResult = ReturnType<typeof useGetModelPerformanceRollupQuery>;
export type GetModelPerformanceRollupLazyQueryHookResult = ReturnType<typeof useGetModelPerformanceRollupLazyQuery>;
export type GetModelPerformanceRollupQueryResult = Apollo.QueryResult<GetModelPerformanceRollupQuery, GetModelPerformanceRollupQueryVariables>;
export const OutputTableDocument = gql`
    query outputTable($datasetId: String!, $offset: Int!, $limit: Int!, $from: Float!, $to: Float, $sort: FilteredFeaturesSort, $text: String, $includeDiscrete: Boolean, $includeNonDiscrete: Boolean, $anomalyCategories: [AlertCategory!]) {
  model(id: $datasetId) {
    batchFrequency
    weightMetadata {
      hasWeights
    }
    filteredOutputs(offset: $offset, limit: $limit, sort: $sort, filter: {fromTimestamp: $from, toTimestamp: $to, substring: $text, anomalyCategories: $anomalyCategories, includeDiscrete: $includeDiscrete, includeNonDiscrete: $includeNonDiscrete}) {
      totalCount
      results {
        ...FeatureTableInfo
      }
    }
  }
}
    ${FeatureTableInfoFragmentDoc}`;

/**
 * __useOutputTableQuery__
 *
 * To run a query within a React component, call `useOutputTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutputTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutputTableQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      sort: // value for 'sort'
 *      text: // value for 'text'
 *      includeDiscrete: // value for 'includeDiscrete'
 *      includeNonDiscrete: // value for 'includeNonDiscrete'
 *      anomalyCategories: // value for 'anomalyCategories'
 *   },
 * });
 */
export function useOutputTableQuery(baseOptions: Apollo.QueryHookOptions<OutputTableQuery, OutputTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutputTableQuery, OutputTableQueryVariables>(OutputTableDocument, options);
      }
export function useOutputTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutputTableQuery, OutputTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutputTableQuery, OutputTableQueryVariables>(OutputTableDocument, options);
        }
export type OutputTableQueryHookResult = ReturnType<typeof useOutputTableQuery>;
export type OutputTableLazyQueryHookResult = ReturnType<typeof useOutputTableLazyQuery>;
export type OutputTableQueryResult = Apollo.QueryResult<OutputTableQuery, OutputTableQueryVariables>;
export const GetOutputNamesDocument = gql`
    query getOutputNames($datasetId: String!) {
  model(id: $datasetId) {
    outputs {
      name
    }
  }
}
    `;

/**
 * __useGetOutputNamesQuery__
 *
 * To run a query within a React component, call `useGetOutputNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutputNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutputNamesQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetOutputNamesQuery(baseOptions: Apollo.QueryHookOptions<GetOutputNamesQuery, GetOutputNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutputNamesQuery, GetOutputNamesQueryVariables>(GetOutputNamesDocument, options);
      }
export function useGetOutputNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutputNamesQuery, GetOutputNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutputNamesQuery, GetOutputNamesQueryVariables>(GetOutputNamesDocument, options);
        }
export type GetOutputNamesQueryHookResult = ReturnType<typeof useGetOutputNamesQuery>;
export type GetOutputNamesLazyQueryHookResult = ReturnType<typeof useGetOutputNamesLazyQuery>;
export type GetOutputNamesQueryResult = Apollo.QueryResult<GetOutputNamesQuery, GetOutputNamesQueryVariables>;
export const GetDataExistenceInformationDocument = gql`
    query getDataExistenceInformation($datasetId: String!) {
  model(id: $datasetId) {
    datasetMetrics {
      name
    }
    totalSegments
    entitySchema {
      hasInputs
      hasOutputs
      hasColumns
    }
  }
}
    `;

/**
 * __useGetDataExistenceInformationQuery__
 *
 * To run a query within a React component, call `useGetDataExistenceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataExistenceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataExistenceInformationQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetDataExistenceInformationQuery(baseOptions: Apollo.QueryHookOptions<GetDataExistenceInformationQuery, GetDataExistenceInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataExistenceInformationQuery, GetDataExistenceInformationQueryVariables>(GetDataExistenceInformationDocument, options);
      }
export function useGetDataExistenceInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataExistenceInformationQuery, GetDataExistenceInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataExistenceInformationQuery, GetDataExistenceInformationQueryVariables>(GetDataExistenceInformationDocument, options);
        }
export type GetDataExistenceInformationQueryHookResult = ReturnType<typeof useGetDataExistenceInformationQuery>;
export type GetDataExistenceInformationLazyQueryHookResult = ReturnType<typeof useGetDataExistenceInformationLazyQuery>;
export type GetDataExistenceInformationQueryResult = Apollo.QueryResult<GetDataExistenceInformationQuery, GetDataExistenceInformationQueryVariables>;
export const GetModelAvailablePerformanceMetricsDocument = gql`
    query getModelAvailablePerformanceMetrics($datasetId: String!) {
  model(id: $datasetId) {
    datasetMetrics {
      name
    }
  }
}
    `;

/**
 * __useGetModelAvailablePerformanceMetricsQuery__
 *
 * To run a query within a React component, call `useGetModelAvailablePerformanceMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelAvailablePerformanceMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelAvailablePerformanceMetricsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetModelAvailablePerformanceMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetModelAvailablePerformanceMetricsQuery, GetModelAvailablePerformanceMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelAvailablePerformanceMetricsQuery, GetModelAvailablePerformanceMetricsQueryVariables>(GetModelAvailablePerformanceMetricsDocument, options);
      }
export function useGetModelAvailablePerformanceMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelAvailablePerformanceMetricsQuery, GetModelAvailablePerformanceMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelAvailablePerformanceMetricsQuery, GetModelAvailablePerformanceMetricsQueryVariables>(GetModelAvailablePerformanceMetricsDocument, options);
        }
export type GetModelAvailablePerformanceMetricsQueryHookResult = ReturnType<typeof useGetModelAvailablePerformanceMetricsQuery>;
export type GetModelAvailablePerformanceMetricsLazyQueryHookResult = ReturnType<typeof useGetModelAvailablePerformanceMetricsLazyQuery>;
export type GetModelAvailablePerformanceMetricsQueryResult = Apollo.QueryResult<GetModelAvailablePerformanceMetricsQuery, GetModelAvailablePerformanceMetricsQueryVariables>;
export const FeatureTableDocument = gql`
    query featureTable($datasetId: String!, $offset: Int!, $limit: Int!, $from: Float!, $to: Float, $text: String, $includeDiscrete: Boolean, $includeNonDiscrete: Boolean, $anomalyCategories: [AlertCategory!], $sort: FilteredFeaturesSort) {
  model(id: $datasetId) {
    batchFrequency
    weightMetadata {
      hasWeights
    }
    filteredFeatures(sort: $sort, offset: $offset, limit: $limit, filter: {fromTimestamp: $from, toTimestamp: $to, substring: $text, anomalyCategories: $anomalyCategories, includeDiscrete: $includeDiscrete, includeNonDiscrete: $includeNonDiscrete}) {
      totalCount
      results {
        ...FeatureTableInfo
        weight {
          value
        }
      }
    }
  }
}
    ${FeatureTableInfoFragmentDoc}`;

/**
 * __useFeatureTableQuery__
 *
 * To run a query within a React component, call `useFeatureTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureTableQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      text: // value for 'text'
 *      includeDiscrete: // value for 'includeDiscrete'
 *      includeNonDiscrete: // value for 'includeNonDiscrete'
 *      anomalyCategories: // value for 'anomalyCategories'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFeatureTableQuery(baseOptions: Apollo.QueryHookOptions<FeatureTableQuery, FeatureTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureTableQuery, FeatureTableQueryVariables>(FeatureTableDocument, options);
      }
export function useFeatureTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureTableQuery, FeatureTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureTableQuery, FeatureTableQueryVariables>(FeatureTableDocument, options);
        }
export type FeatureTableQueryHookResult = ReturnType<typeof useFeatureTableQuery>;
export type FeatureTableLazyQueryHookResult = ReturnType<typeof useFeatureTableLazyQuery>;
export type FeatureTableQueryResult = Apollo.QueryResult<FeatureTableQuery, FeatureTableQueryVariables>;
export const GetModelProfilesDocument = gql`
    query getModelProfiles($modelId: String!, $from: Float, $to: Float) {
  model(id: $modelId) {
    batchFrequency
    id
    modelType
    name
    batches(from: $from, to: $to) {
      ...BatchProfile
    }
    referenceProfiles(from: $from, to: $to) {
      ...ReferenceProfile
    }
  }
}
    ${BatchProfileFragmentDoc}
${ReferenceProfileFragmentDoc}`;

/**
 * __useGetModelProfilesQuery__
 *
 * To run a query within a React component, call `useGetModelProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelProfilesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetModelProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetModelProfilesQuery, GetModelProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelProfilesQuery, GetModelProfilesQueryVariables>(GetModelProfilesDocument, options);
      }
export function useGetModelProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelProfilesQuery, GetModelProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelProfilesQuery, GetModelProfilesQueryVariables>(GetModelProfilesDocument, options);
        }
export type GetModelProfilesQueryHookResult = ReturnType<typeof useGetModelProfilesQuery>;
export type GetModelProfilesLazyQueryHookResult = ReturnType<typeof useGetModelProfilesLazyQuery>;
export type GetModelProfilesQueryResult = Apollo.QueryResult<GetModelProfilesQuery, GetModelProfilesQueryVariables>;
export const GetModelProfilesForMonitorConfigDocument = gql`
    query getModelProfilesForMonitorConfig($modelId: String!) {
  model(id: $modelId) {
    batches {
      timestamp
    }
  }
}
    `;

/**
 * __useGetModelProfilesForMonitorConfigQuery__
 *
 * To run a query within a React component, call `useGetModelProfilesForMonitorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelProfilesForMonitorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelProfilesForMonitorConfigQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelProfilesForMonitorConfigQuery(baseOptions: Apollo.QueryHookOptions<GetModelProfilesForMonitorConfigQuery, GetModelProfilesForMonitorConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelProfilesForMonitorConfigQuery, GetModelProfilesForMonitorConfigQueryVariables>(GetModelProfilesForMonitorConfigDocument, options);
      }
export function useGetModelProfilesForMonitorConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelProfilesForMonitorConfigQuery, GetModelProfilesForMonitorConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelProfilesForMonitorConfigQuery, GetModelProfilesForMonitorConfigQueryVariables>(GetModelProfilesForMonitorConfigDocument, options);
        }
export type GetModelProfilesForMonitorConfigQueryHookResult = ReturnType<typeof useGetModelProfilesForMonitorConfigQuery>;
export type GetModelProfilesForMonitorConfigLazyQueryHookResult = ReturnType<typeof useGetModelProfilesForMonitorConfigLazyQuery>;
export type GetModelProfilesForMonitorConfigQueryResult = Apollo.QueryResult<GetModelProfilesForMonitorConfigQuery, GetModelProfilesForMonitorConfigQueryVariables>;
export const GetSegmentMetricDataRollupDocument = gql`
    query getSegmentMetricDataRollup($from: Float!, $to: Float!, $segmentKey: String!, $queries: [MetricQuery!]!) {
  dataQueries {
    getSegmentMetricDataRollup(fromTimestamp: $from, toTimestamp: $to, segmentKey: $segmentKey, queries: $queries) {
      ...MetricRollup
    }
  }
}
    ${MetricRollupFragmentDoc}`;

/**
 * __useGetSegmentMetricDataRollupQuery__
 *
 * To run a query within a React component, call `useGetSegmentMetricDataRollupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentMetricDataRollupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentMetricDataRollupQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      segmentKey: // value for 'segmentKey'
 *      queries: // value for 'queries'
 *   },
 * });
 */
export function useGetSegmentMetricDataRollupQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentMetricDataRollupQuery, GetSegmentMetricDataRollupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentMetricDataRollupQuery, GetSegmentMetricDataRollupQueryVariables>(GetSegmentMetricDataRollupDocument, options);
      }
export function useGetSegmentMetricDataRollupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentMetricDataRollupQuery, GetSegmentMetricDataRollupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentMetricDataRollupQuery, GetSegmentMetricDataRollupQueryVariables>(GetSegmentMetricDataRollupDocument, options);
        }
export type GetSegmentMetricDataRollupQueryHookResult = ReturnType<typeof useGetSegmentMetricDataRollupQuery>;
export type GetSegmentMetricDataRollupLazyQueryHookResult = ReturnType<typeof useGetSegmentMetricDataRollupLazyQuery>;
export type GetSegmentMetricDataRollupQueryResult = Apollo.QueryResult<GetSegmentMetricDataRollupQuery, GetSegmentMetricDataRollupQueryVariables>;
export const SearchSegmentKeysDocument = gql`
    query searchSegmentKeys($datasetId: String!, $tags: [SegmentTagFilter!], $searchString: String, $limitSpec: LimitSpec) {
  searchSegmentKeys(datasetId: $datasetId, tags: $tags, searchString: $searchString, limitSpec: $limitSpec)
}
    `;

/**
 * __useSearchSegmentKeysQuery__
 *
 * To run a query within a React component, call `useSearchSegmentKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSegmentKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSegmentKeysQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      tags: // value for 'tags'
 *      searchString: // value for 'searchString'
 *      limitSpec: // value for 'limitSpec'
 *   },
 * });
 */
export function useSearchSegmentKeysQuery(baseOptions: Apollo.QueryHookOptions<SearchSegmentKeysQuery, SearchSegmentKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSegmentKeysQuery, SearchSegmentKeysQueryVariables>(SearchSegmentKeysDocument, options);
      }
export function useSearchSegmentKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSegmentKeysQuery, SearchSegmentKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSegmentKeysQuery, SearchSegmentKeysQueryVariables>(SearchSegmentKeysDocument, options);
        }
export type SearchSegmentKeysQueryHookResult = ReturnType<typeof useSearchSegmentKeysQuery>;
export type SearchSegmentKeysLazyQueryHookResult = ReturnType<typeof useSearchSegmentKeysLazyQuery>;
export type SearchSegmentKeysQueryResult = Apollo.QueryResult<SearchSegmentKeysQuery, SearchSegmentKeysQueryVariables>;
export const GetModelDataAvailabilityDocument = gql`
    query getModelDataAvailability($modelId: String!) {
  model(id: $modelId) {
    dataAvailability {
      hasData
    }
  }
}
    `;

/**
 * __useGetModelDataAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetModelDataAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelDataAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelDataAvailabilityQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelDataAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetModelDataAvailabilityQuery, GetModelDataAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelDataAvailabilityQuery, GetModelDataAvailabilityQueryVariables>(GetModelDataAvailabilityDocument, options);
      }
export function useGetModelDataAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelDataAvailabilityQuery, GetModelDataAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelDataAvailabilityQuery, GetModelDataAvailabilityQueryVariables>(GetModelDataAvailabilityDocument, options);
        }
export type GetModelDataAvailabilityQueryHookResult = ReturnType<typeof useGetModelDataAvailabilityQuery>;
export type GetModelDataAvailabilityLazyQueryHookResult = ReturnType<typeof useGetModelDataAvailabilityLazyQuery>;
export type GetModelDataAvailabilityQueryResult = Apollo.QueryResult<GetModelDataAvailabilityQuery, GetModelDataAvailabilityQueryVariables>;
export const GetSingleModelProfileDocument = gql`
    query getSingleModelProfile($modelId: String!, $datasetTimestamp: Float!, $offset: Int, $limit: Int, $filter: FeatureSketchFilter!) {
  model(id: $modelId) {
    batch(datasetTimestamp: $datasetTimestamp) {
      sketches(offset: $offset, limit: $limit, filter: $filter) {
        results {
          ...FullSketchFields
        }
      }
    }
  }
}
    ${FullSketchFieldsFragmentDoc}`;

/**
 * __useGetSingleModelProfileQuery__
 *
 * To run a query within a React component, call `useGetSingleModelProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleModelProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleModelProfileQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      datasetTimestamp: // value for 'datasetTimestamp'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSingleModelProfileQuery(baseOptions: Apollo.QueryHookOptions<GetSingleModelProfileQuery, GetSingleModelProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleModelProfileQuery, GetSingleModelProfileQueryVariables>(GetSingleModelProfileDocument, options);
      }
export function useGetSingleModelProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleModelProfileQuery, GetSingleModelProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleModelProfileQuery, GetSingleModelProfileQueryVariables>(GetSingleModelProfileDocument, options);
        }
export type GetSingleModelProfileQueryHookResult = ReturnType<typeof useGetSingleModelProfileQuery>;
export type GetSingleModelProfileLazyQueryHookResult = ReturnType<typeof useGetSingleModelProfileLazyQuery>;
export type GetSingleModelProfileQueryResult = Apollo.QueryResult<GetSingleModelProfileQuery, GetSingleModelProfileQueryVariables>;
export const GetModelSelectedProfilesDocument = gql`
    query getModelSelectedProfiles($modelId: String!, $timestamps: [Float!]!, $staticProfileIds: [String!], $offset: Int, $limit: Int, $filter: FeatureSketchFilter!, $retrievalTokens: [String!]!, $allowIndividualProfiles: Boolean = false) {
  model(id: $modelId) {
    batches(timestamps: $timestamps) {
      timestamp
      sketches(offset: $offset, limit: $limit, filter: $filter) {
        ...ProfilesSketchFields
      }
    }
    referenceProfiles(profileIds: $staticProfileIds) {
      id
      sketches(offset: $offset, limit: $limit, filter: $filter) {
        ...ProfilesSketchFields
      }
    }
    individualProfiles(retrievalTokens: $retrievalTokens) @include(if: $allowIndividualProfiles) {
      retrievalToken
      sketches(offset: $offset, limit: $limit, filter: $filter) {
        ...ProfilesSketchFields
      }
    }
    datasetMetrics {
      name
    }
    outputs {
      name
    }
  }
}
    ${ProfilesSketchFieldsFragmentDoc}`;

/**
 * __useGetModelSelectedProfilesQuery__
 *
 * To run a query within a React component, call `useGetModelSelectedProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelSelectedProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelSelectedProfilesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      timestamps: // value for 'timestamps'
 *      staticProfileIds: // value for 'staticProfileIds'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      retrievalTokens: // value for 'retrievalTokens'
 *      allowIndividualProfiles: // value for 'allowIndividualProfiles'
 *   },
 * });
 */
export function useGetModelSelectedProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetModelSelectedProfilesQuery, GetModelSelectedProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelSelectedProfilesQuery, GetModelSelectedProfilesQueryVariables>(GetModelSelectedProfilesDocument, options);
      }
export function useGetModelSelectedProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelSelectedProfilesQuery, GetModelSelectedProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelSelectedProfilesQuery, GetModelSelectedProfilesQueryVariables>(GetModelSelectedProfilesDocument, options);
        }
export type GetModelSelectedProfilesQueryHookResult = ReturnType<typeof useGetModelSelectedProfilesQuery>;
export type GetModelSelectedProfilesLazyQueryHookResult = ReturnType<typeof useGetModelSelectedProfilesLazyQuery>;
export type GetModelSelectedProfilesQueryResult = Apollo.QueryResult<GetModelSelectedProfilesQuery, GetModelSelectedProfilesQueryVariables>;
export const GetModelColumnsCountsDocument = gql`
    query getModelColumnsCounts($modelId: String!) {
  model(id: $modelId) {
    entitySchema {
      inputCounts {
        discrete
        nonDiscrete
      }
      outputCounts {
        discrete
        nonDiscrete
      }
    }
  }
}
    `;

/**
 * __useGetModelColumnsCountsQuery__
 *
 * To run a query within a React component, call `useGetModelColumnsCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelColumnsCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelColumnsCountsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelColumnsCountsQuery(baseOptions: Apollo.QueryHookOptions<GetModelColumnsCountsQuery, GetModelColumnsCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelColumnsCountsQuery, GetModelColumnsCountsQueryVariables>(GetModelColumnsCountsDocument, options);
      }
export function useGetModelColumnsCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelColumnsCountsQuery, GetModelColumnsCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelColumnsCountsQuery, GetModelColumnsCountsQueryVariables>(GetModelColumnsCountsDocument, options);
        }
export type GetModelColumnsCountsQueryHookResult = ReturnType<typeof useGetModelColumnsCountsQuery>;
export type GetModelColumnsCountsLazyQueryHookResult = ReturnType<typeof useGetModelColumnsCountsLazyQuery>;
export type GetModelColumnsCountsQueryResult = Apollo.QueryResult<GetModelColumnsCountsQuery, GetModelColumnsCountsQueryVariables>;
export const GetFeaturesAndOutputsDocument = gql`
    query getFeaturesAndOutputs($modelId: String!, $from: Float!, $to: Float) {
  model(id: $modelId) {
    outputs {
      sketches(from: $from, to: $to) {
        featureName
        totalCount
      }
    }
    features {
      sketches(from: $from, to: $to) {
        featureName
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetFeaturesAndOutputsQuery__
 *
 * To run a query within a React component, call `useGetFeaturesAndOutputsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesAndOutputsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesAndOutputsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetFeaturesAndOutputsQuery(baseOptions: Apollo.QueryHookOptions<GetFeaturesAndOutputsQuery, GetFeaturesAndOutputsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturesAndOutputsQuery, GetFeaturesAndOutputsQueryVariables>(GetFeaturesAndOutputsDocument, options);
      }
export function useGetFeaturesAndOutputsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesAndOutputsQuery, GetFeaturesAndOutputsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturesAndOutputsQuery, GetFeaturesAndOutputsQueryVariables>(GetFeaturesAndOutputsDocument, options);
        }
export type GetFeaturesAndOutputsQueryHookResult = ReturnType<typeof useGetFeaturesAndOutputsQuery>;
export type GetFeaturesAndOutputsLazyQueryHookResult = ReturnType<typeof useGetFeaturesAndOutputsLazyQuery>;
export type GetFeaturesAndOutputsQueryResult = Apollo.QueryResult<GetFeaturesAndOutputsQuery, GetFeaturesAndOutputsQueryVariables>;
export const GetFilteredFeaturesWithNameDocument = gql`
    query getFilteredFeaturesWithName($modelId: String!, $from: Float!, $to: Float!, $offset: Int!, $limit: Int!, $featureFilter: FeatureFilter!) {
  model(id: $modelId, startTime: $from, endTime: $to) {
    filteredFeatures(offset: $offset, limit: $limit, filter: $featureFilter) {
      results {
        name
      }
    }
    filteredOutputs(offset: $offset, limit: $limit, filter: $featureFilter) {
      results {
        name
      }
    }
  }
}
    `;

/**
 * __useGetFilteredFeaturesWithNameQuery__
 *
 * To run a query within a React component, call `useGetFilteredFeaturesWithNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredFeaturesWithNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredFeaturesWithNameQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      featureFilter: // value for 'featureFilter'
 *   },
 * });
 */
export function useGetFilteredFeaturesWithNameQuery(baseOptions: Apollo.QueryHookOptions<GetFilteredFeaturesWithNameQuery, GetFilteredFeaturesWithNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredFeaturesWithNameQuery, GetFilteredFeaturesWithNameQueryVariables>(GetFilteredFeaturesWithNameDocument, options);
      }
export function useGetFilteredFeaturesWithNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredFeaturesWithNameQuery, GetFilteredFeaturesWithNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredFeaturesWithNameQuery, GetFilteredFeaturesWithNameQueryVariables>(GetFilteredFeaturesWithNameDocument, options);
        }
export type GetFilteredFeaturesWithNameQueryHookResult = ReturnType<typeof useGetFilteredFeaturesWithNameQuery>;
export type GetFilteredFeaturesWithNameLazyQueryHookResult = ReturnType<typeof useGetFilteredFeaturesWithNameLazyQuery>;
export type GetFilteredFeaturesWithNameQueryResult = Apollo.QueryResult<GetFilteredFeaturesWithNameQuery, GetFilteredFeaturesWithNameQueryVariables>;
export const GetAllFilteredFeaturesOutputMetricNamesDocument = gql`
    query getAllFilteredFeaturesOutputMetricNames($modelId: String!, $from: Float!, $to: Float!, $featuresOffset: Int = 0, $outputsOffset: Int = 0, $limit: Int!, $featureFilter: FeatureFilter!) {
  model(id: $modelId, startTime: $from, endTime: $to) {
    filteredFeatures(offset: $featuresOffset, limit: $limit, filter: $featureFilter) {
      results {
        name
      }
    }
    filteredOutputs(offset: $outputsOffset, limit: $limit, filter: $featureFilter) {
      results {
        name
      }
    }
    datasetMetrics {
      name
    }
  }
}
    `;

/**
 * __useGetAllFilteredFeaturesOutputMetricNamesQuery__
 *
 * To run a query within a React component, call `useGetAllFilteredFeaturesOutputMetricNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFilteredFeaturesOutputMetricNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFilteredFeaturesOutputMetricNamesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      featuresOffset: // value for 'featuresOffset'
 *      outputsOffset: // value for 'outputsOffset'
 *      limit: // value for 'limit'
 *      featureFilter: // value for 'featureFilter'
 *   },
 * });
 */
export function useGetAllFilteredFeaturesOutputMetricNamesQuery(baseOptions: Apollo.QueryHookOptions<GetAllFilteredFeaturesOutputMetricNamesQuery, GetAllFilteredFeaturesOutputMetricNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFilteredFeaturesOutputMetricNamesQuery, GetAllFilteredFeaturesOutputMetricNamesQueryVariables>(GetAllFilteredFeaturesOutputMetricNamesDocument, options);
      }
export function useGetAllFilteredFeaturesOutputMetricNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFilteredFeaturesOutputMetricNamesQuery, GetAllFilteredFeaturesOutputMetricNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFilteredFeaturesOutputMetricNamesQuery, GetAllFilteredFeaturesOutputMetricNamesQueryVariables>(GetAllFilteredFeaturesOutputMetricNamesDocument, options);
        }
export type GetAllFilteredFeaturesOutputMetricNamesQueryHookResult = ReturnType<typeof useGetAllFilteredFeaturesOutputMetricNamesQuery>;
export type GetAllFilteredFeaturesOutputMetricNamesLazyQueryHookResult = ReturnType<typeof useGetAllFilteredFeaturesOutputMetricNamesLazyQuery>;
export type GetAllFilteredFeaturesOutputMetricNamesQueryResult = Apollo.QueryResult<GetAllFilteredFeaturesOutputMetricNamesQuery, GetAllFilteredFeaturesOutputMetricNamesQueryVariables>;
export const GetAnomaliesForSpecificProfileDocument = gql`
    query getAnomaliesForSpecificProfile($modelId: String!, $filter: AnalysisFilter!, $sort: SortDirection!) {
  model(id: $modelId) {
    anomalies(filter: $filter, sortDirection: $sort) {
      datasetId
      datasetTimestamp
      metric
      analyzerType
      threshold_absoluteLower
      threshold_absoluteUpper
      threshold_calculatedLower
      threshold_calculatedUpper
      threshold_metricValue
      diff_metricValue
      diff_threshold
      drift_metricValue
      drift_threshold
      column
      tags {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetAnomaliesForSpecificProfileQuery__
 *
 * To run a query within a React component, call `useGetAnomaliesForSpecificProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomaliesForSpecificProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomaliesForSpecificProfileQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAnomaliesForSpecificProfileQuery(baseOptions: Apollo.QueryHookOptions<GetAnomaliesForSpecificProfileQuery, GetAnomaliesForSpecificProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomaliesForSpecificProfileQuery, GetAnomaliesForSpecificProfileQueryVariables>(GetAnomaliesForSpecificProfileDocument, options);
      }
export function useGetAnomaliesForSpecificProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomaliesForSpecificProfileQuery, GetAnomaliesForSpecificProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomaliesForSpecificProfileQuery, GetAnomaliesForSpecificProfileQueryVariables>(GetAnomaliesForSpecificProfileDocument, options);
        }
export type GetAnomaliesForSpecificProfileQueryHookResult = ReturnType<typeof useGetAnomaliesForSpecificProfileQuery>;
export type GetAnomaliesForSpecificProfileLazyQueryHookResult = ReturnType<typeof useGetAnomaliesForSpecificProfileLazyQuery>;
export type GetAnomaliesForSpecificProfileQueryResult = Apollo.QueryResult<GetAnomaliesForSpecificProfileQuery, GetAnomaliesForSpecificProfileQueryVariables>;
export const GetModelsFeatureNamesDocument = gql`
    query getModelsFeatureNames($modelId: String!) {
  model(id: $modelId) {
    name
    features {
      id
      name
    }
  }
}
    `;

/**
 * __useGetModelsFeatureNamesQuery__
 *
 * To run a query within a React component, call `useGetModelsFeatureNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsFeatureNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsFeatureNamesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelsFeatureNamesQuery(baseOptions: Apollo.QueryHookOptions<GetModelsFeatureNamesQuery, GetModelsFeatureNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelsFeatureNamesQuery, GetModelsFeatureNamesQueryVariables>(GetModelsFeatureNamesDocument, options);
      }
export function useGetModelsFeatureNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelsFeatureNamesQuery, GetModelsFeatureNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelsFeatureNamesQuery, GetModelsFeatureNamesQueryVariables>(GetModelsFeatureNamesDocument, options);
        }
export type GetModelsFeatureNamesQueryHookResult = ReturnType<typeof useGetModelsFeatureNamesQuery>;
export type GetModelsFeatureNamesLazyQueryHookResult = ReturnType<typeof useGetModelsFeatureNamesLazyQuery>;
export type GetModelsFeatureNamesQueryResult = Apollo.QueryResult<GetModelsFeatureNamesQuery, GetModelsFeatureNamesQueryVariables>;
export const GetModelReferenceProfilesForMonitorConfigDocument = gql`
    query getModelReferenceProfilesForMonitorConfig($modelId: String!) {
  model(id: $modelId) {
    referenceProfiles {
      uploadTimestamp
      alias
      id
    }
  }
}
    `;

/**
 * __useGetModelReferenceProfilesForMonitorConfigQuery__
 *
 * To run a query within a React component, call `useGetModelReferenceProfilesForMonitorConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelReferenceProfilesForMonitorConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelReferenceProfilesForMonitorConfigQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetModelReferenceProfilesForMonitorConfigQuery(baseOptions: Apollo.QueryHookOptions<GetModelReferenceProfilesForMonitorConfigQuery, GetModelReferenceProfilesForMonitorConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelReferenceProfilesForMonitorConfigQuery, GetModelReferenceProfilesForMonitorConfigQueryVariables>(GetModelReferenceProfilesForMonitorConfigDocument, options);
      }
export function useGetModelReferenceProfilesForMonitorConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelReferenceProfilesForMonitorConfigQuery, GetModelReferenceProfilesForMonitorConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelReferenceProfilesForMonitorConfigQuery, GetModelReferenceProfilesForMonitorConfigQueryVariables>(GetModelReferenceProfilesForMonitorConfigDocument, options);
        }
export type GetModelReferenceProfilesForMonitorConfigQueryHookResult = ReturnType<typeof useGetModelReferenceProfilesForMonitorConfigQuery>;
export type GetModelReferenceProfilesForMonitorConfigLazyQueryHookResult = ReturnType<typeof useGetModelReferenceProfilesForMonitorConfigLazyQuery>;
export type GetModelReferenceProfilesForMonitorConfigQueryResult = Apollo.QueryResult<GetModelReferenceProfilesForMonitorConfigQuery, GetModelReferenceProfilesForMonitorConfigQueryVariables>;
export const GetComparisonSegmentsDocument = gql`
    query getComparisonSegments($datasetId: String!) {
  model(id: $datasetId) {
    segments {
      tags {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetComparisonSegmentsQuery__
 *
 * To run a query within a React component, call `useGetComparisonSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComparisonSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComparisonSegmentsQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *   },
 * });
 */
export function useGetComparisonSegmentsQuery(baseOptions: Apollo.QueryHookOptions<GetComparisonSegmentsQuery, GetComparisonSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComparisonSegmentsQuery, GetComparisonSegmentsQueryVariables>(GetComparisonSegmentsDocument, options);
      }
export function useGetComparisonSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComparisonSegmentsQuery, GetComparisonSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComparisonSegmentsQuery, GetComparisonSegmentsQueryVariables>(GetComparisonSegmentsDocument, options);
        }
export type GetComparisonSegmentsQueryHookResult = ReturnType<typeof useGetComparisonSegmentsQuery>;
export type GetComparisonSegmentsLazyQueryHookResult = ReturnType<typeof useGetComparisonSegmentsLazyQuery>;
export type GetComparisonSegmentsQueryResult = Apollo.QueryResult<GetComparisonSegmentsQuery, GetComparisonSegmentsQueryVariables>;
export const SegmentsListingTableDocument = gql`
    query segmentsListingTable($model: String!, $from: Float!, $to: Float!, $offset: Int, $limit: Int, $sort: SegmentSort, $tags: [SegmentTagFilter!]) {
  model(id: $model) {
    ...ModelCommonData
    totalFilteredSegments(filter: {fromTimestamp: $from, toTimestamp: $to, tags: $tags})
    segments(offset: $offset, limit: $limit, filter: {fromTimestamp: $from, toTimestamp: $to, tags: $tags}, sort: $sort) {
      ...SegmentListingFields
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${SegmentListingFieldsFragmentDoc}`;

/**
 * __useSegmentsListingTableQuery__
 *
 * To run a query within a React component, call `useSegmentsListingTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentsListingTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentsListingTableQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useSegmentsListingTableQuery(baseOptions: Apollo.QueryHookOptions<SegmentsListingTableQuery, SegmentsListingTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentsListingTableQuery, SegmentsListingTableQueryVariables>(SegmentsListingTableDocument, options);
      }
export function useSegmentsListingTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentsListingTableQuery, SegmentsListingTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentsListingTableQuery, SegmentsListingTableQueryVariables>(SegmentsListingTableDocument, options);
        }
export type SegmentsListingTableQueryHookResult = ReturnType<typeof useSegmentsListingTableQuery>;
export type SegmentsListingTableLazyQueryHookResult = ReturnType<typeof useSegmentsListingTableLazyQuery>;
export type SegmentsListingTableQueryResult = Apollo.QueryResult<SegmentsListingTableQuery, SegmentsListingTableQueryVariables>;
export const GetMergedSegmentDocument = gql`
    query getMergedSegment($model: String!, $from: Float!, $to: Float!, $tags: [SegmentTagFilter!]!) {
  model(id: $model) {
    ...ModelCommonData
    segment(tags: $tags) {
      ...SegmentListingFields
    }
  }
}
    ${ModelCommonDataFragmentDoc}
${SegmentListingFieldsFragmentDoc}`;

/**
 * __useGetMergedSegmentQuery__
 *
 * To run a query within a React component, call `useGetMergedSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMergedSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMergedSegmentQuery({
 *   variables: {
 *      model: // value for 'model'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetMergedSegmentQuery(baseOptions: Apollo.QueryHookOptions<GetMergedSegmentQuery, GetMergedSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMergedSegmentQuery, GetMergedSegmentQueryVariables>(GetMergedSegmentDocument, options);
      }
export function useGetMergedSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMergedSegmentQuery, GetMergedSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMergedSegmentQuery, GetMergedSegmentQueryVariables>(GetMergedSegmentDocument, options);
        }
export type GetMergedSegmentQueryHookResult = ReturnType<typeof useGetMergedSegmentQuery>;
export type GetMergedSegmentLazyQueryHookResult = ReturnType<typeof useGetMergedSegmentLazyQuery>;
export type GetMergedSegmentQueryResult = Apollo.QueryResult<GetMergedSegmentQuery, GetMergedSegmentQueryVariables>;
export const ProvisionOrgDocument = gql`
    mutation provisionOrg($orgName: String, $modelName: String) {
  provisionOrganization(orgName: $orgName, modelName: $modelName)
}
    `;
export type ProvisionOrgMutationFn = Apollo.MutationFunction<ProvisionOrgMutation, ProvisionOrgMutationVariables>;

/**
 * __useProvisionOrgMutation__
 *
 * To run a mutation, you first call `useProvisionOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionOrgMutation, { data, loading, error }] = useProvisionOrgMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      modelName: // value for 'modelName'
 *   },
 * });
 */
export function useProvisionOrgMutation(baseOptions?: Apollo.MutationHookOptions<ProvisionOrgMutation, ProvisionOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvisionOrgMutation, ProvisionOrgMutationVariables>(ProvisionOrgDocument, options);
      }
export type ProvisionOrgMutationHookResult = ReturnType<typeof useProvisionOrgMutation>;
export type ProvisionOrgMutationResult = Apollo.MutationResult<ProvisionOrgMutation>;
export type ProvisionOrgMutationOptions = Apollo.BaseMutationOptions<ProvisionOrgMutation, ProvisionOrgMutationVariables>;
export const GetResourceBasicInfoForOrgDocument = gql`
    query getResourceBasicInfoForOrg {
  resources: models {
    id
    assetCategory
    dataAvailability {
      latestTimestamp
      oldestTimestamp
    }
  }
}
    `;

/**
 * __useGetResourceBasicInfoForOrgQuery__
 *
 * To run a query within a React component, call `useGetResourceBasicInfoForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceBasicInfoForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceBasicInfoForOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResourceBasicInfoForOrgQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceBasicInfoForOrgQuery, GetResourceBasicInfoForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceBasicInfoForOrgQuery, GetResourceBasicInfoForOrgQueryVariables>(GetResourceBasicInfoForOrgDocument, options);
      }
export function useGetResourceBasicInfoForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceBasicInfoForOrgQuery, GetResourceBasicInfoForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceBasicInfoForOrgQuery, GetResourceBasicInfoForOrgQueryVariables>(GetResourceBasicInfoForOrgDocument, options);
        }
export type GetResourceBasicInfoForOrgQueryHookResult = ReturnType<typeof useGetResourceBasicInfoForOrgQuery>;
export type GetResourceBasicInfoForOrgLazyQueryHookResult = ReturnType<typeof useGetResourceBasicInfoForOrgLazyQuery>;
export type GetResourceBasicInfoForOrgQueryResult = Apollo.QueryResult<GetResourceBasicInfoForOrgQuery, GetResourceBasicInfoForOrgQueryVariables>;
export const GetResourceBasicCategoryInfoDocument = gql`
    query getResourceBasicCategoryInfo {
  resources: models {
    id
    name
    assetCategory
    modelType
  }
}
    `;

/**
 * __useGetResourceBasicCategoryInfoQuery__
 *
 * To run a query within a React component, call `useGetResourceBasicCategoryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceBasicCategoryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceBasicCategoryInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResourceBasicCategoryInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceBasicCategoryInfoQuery, GetResourceBasicCategoryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceBasicCategoryInfoQuery, GetResourceBasicCategoryInfoQueryVariables>(GetResourceBasicCategoryInfoDocument, options);
      }
export function useGetResourceBasicCategoryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceBasicCategoryInfoQuery, GetResourceBasicCategoryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceBasicCategoryInfoQuery, GetResourceBasicCategoryInfoQueryVariables>(GetResourceBasicCategoryInfoDocument, options);
        }
export type GetResourceBasicCategoryInfoQueryHookResult = ReturnType<typeof useGetResourceBasicCategoryInfoQuery>;
export type GetResourceBasicCategoryInfoLazyQueryHookResult = ReturnType<typeof useGetResourceBasicCategoryInfoLazyQuery>;
export type GetResourceBasicCategoryInfoQueryResult = Apollo.QueryResult<GetResourceBasicCategoryInfoQuery, GetResourceBasicCategoryInfoQueryVariables>;
export const GetResourceCoverageInfoDocument = gql`
    query getResourceCoverageInfo {
  resources: models {
    id
    name
    assetCategory
    modelType
    monitoredCategories
  }
}
    `;

/**
 * __useGetResourceCoverageInfoQuery__
 *
 * To run a query within a React component, call `useGetResourceCoverageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceCoverageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceCoverageInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResourceCoverageInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceCoverageInfoQuery, GetResourceCoverageInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceCoverageInfoQuery, GetResourceCoverageInfoQueryVariables>(GetResourceCoverageInfoDocument, options);
      }
export function useGetResourceCoverageInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceCoverageInfoQuery, GetResourceCoverageInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceCoverageInfoQuery, GetResourceCoverageInfoQueryVariables>(GetResourceCoverageInfoDocument, options);
        }
export type GetResourceCoverageInfoQueryHookResult = ReturnType<typeof useGetResourceCoverageInfoQuery>;
export type GetResourceCoverageInfoLazyQueryHookResult = ReturnType<typeof useGetResourceCoverageInfoLazyQuery>;
export type GetResourceCoverageInfoQueryResult = Apollo.QueryResult<GetResourceCoverageInfoQuery, GetResourceCoverageInfoQueryVariables>;
export const GetResourceBatchesCountDocument = gql`
    query getResourceBatchesCount($from: Float!, $to: Float, $skipInputs: Boolean = false, $skipOutputs: Boolean = false, $skipTimestamps: Boolean = false) {
  resources: models {
    batches(from: $from, to: $to) {
      inputCount @skip(if: $skipInputs)
      outputCount @skip(if: $skipOutputs)
      timestamp @skip(if: $skipTimestamps)
    }
    assetCategory
  }
}
    `;

/**
 * __useGetResourceBatchesCountQuery__
 *
 * To run a query within a React component, call `useGetResourceBatchesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceBatchesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceBatchesCountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      skipInputs: // value for 'skipInputs'
 *      skipOutputs: // value for 'skipOutputs'
 *      skipTimestamps: // value for 'skipTimestamps'
 *   },
 * });
 */
export function useGetResourceBatchesCountQuery(baseOptions: Apollo.QueryHookOptions<GetResourceBatchesCountQuery, GetResourceBatchesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceBatchesCountQuery, GetResourceBatchesCountQueryVariables>(GetResourceBatchesCountDocument, options);
      }
export function useGetResourceBatchesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceBatchesCountQuery, GetResourceBatchesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceBatchesCountQuery, GetResourceBatchesCountQueryVariables>(GetResourceBatchesCountDocument, options);
        }
export type GetResourceBatchesCountQueryHookResult = ReturnType<typeof useGetResourceBatchesCountQuery>;
export type GetResourceBatchesCountLazyQueryHookResult = ReturnType<typeof useGetResourceBatchesCountLazyQuery>;
export type GetResourceBatchesCountQueryResult = Apollo.QueryResult<GetResourceBatchesCountQuery, GetResourceBatchesCountQueryVariables>;
export const GetResourceBasicInfoWithAnomaliesDocument = gql`
    query getResourceBasicInfoWithAnomalies($from: Float!, $to: Float) {
  resources: models {
    id
    name
    assetCategory
    modelType
    allAnomalyCounts(fromTimestamp: $from, toTimestamp: $to, groupBy: CATEGORY) {
      totals {
        category
        count
        metric
      }
    }
  }
}
    `;

/**
 * __useGetResourceBasicInfoWithAnomaliesQuery__
 *
 * To run a query within a React component, call `useGetResourceBasicInfoWithAnomaliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceBasicInfoWithAnomaliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceBasicInfoWithAnomaliesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetResourceBasicInfoWithAnomaliesQuery(baseOptions: Apollo.QueryHookOptions<GetResourceBasicInfoWithAnomaliesQuery, GetResourceBasicInfoWithAnomaliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceBasicInfoWithAnomaliesQuery, GetResourceBasicInfoWithAnomaliesQueryVariables>(GetResourceBasicInfoWithAnomaliesDocument, options);
      }
export function useGetResourceBasicInfoWithAnomaliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceBasicInfoWithAnomaliesQuery, GetResourceBasicInfoWithAnomaliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceBasicInfoWithAnomaliesQuery, GetResourceBasicInfoWithAnomaliesQueryVariables>(GetResourceBasicInfoWithAnomaliesDocument, options);
        }
export type GetResourceBasicInfoWithAnomaliesQueryHookResult = ReturnType<typeof useGetResourceBasicInfoWithAnomaliesQuery>;
export type GetResourceBasicInfoWithAnomaliesLazyQueryHookResult = ReturnType<typeof useGetResourceBasicInfoWithAnomaliesLazyQuery>;
export type GetResourceBasicInfoWithAnomaliesQueryResult = Apollo.QueryResult<GetResourceBasicInfoWithAnomaliesQuery, GetResourceBasicInfoWithAnomaliesQueryVariables>;
export const GetResourceAnomaliesTimeSeriesDataDocument = gql`
    query getResourceAnomaliesTimeSeriesData($from: Float!, $to: Float) {
  resources: models {
    id
    name
    assetCategory
    modelType
    allAnomalyCounts(fromTimestamp: $from, toTimestamp: $to, groupBy: CATEGORY) {
      totals {
        category
        count
        metric
      }
      timeseries {
        timestamp
        counts {
          category
          count
          metric
        }
      }
    }
  }
}
    `;

/**
 * __useGetResourceAnomaliesTimeSeriesDataQuery__
 *
 * To run a query within a React component, call `useGetResourceAnomaliesTimeSeriesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceAnomaliesTimeSeriesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceAnomaliesTimeSeriesDataQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetResourceAnomaliesTimeSeriesDataQuery(baseOptions: Apollo.QueryHookOptions<GetResourceAnomaliesTimeSeriesDataQuery, GetResourceAnomaliesTimeSeriesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceAnomaliesTimeSeriesDataQuery, GetResourceAnomaliesTimeSeriesDataQueryVariables>(GetResourceAnomaliesTimeSeriesDataDocument, options);
      }
export function useGetResourceAnomaliesTimeSeriesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceAnomaliesTimeSeriesDataQuery, GetResourceAnomaliesTimeSeriesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceAnomaliesTimeSeriesDataQuery, GetResourceAnomaliesTimeSeriesDataQueryVariables>(GetResourceAnomaliesTimeSeriesDataDocument, options);
        }
export type GetResourceAnomaliesTimeSeriesDataQueryHookResult = ReturnType<typeof useGetResourceAnomaliesTimeSeriesDataQuery>;
export type GetResourceAnomaliesTimeSeriesDataLazyQueryHookResult = ReturnType<typeof useGetResourceAnomaliesTimeSeriesDataLazyQuery>;
export type GetResourceAnomaliesTimeSeriesDataQueryResult = Apollo.QueryResult<GetResourceAnomaliesTimeSeriesDataQuery, GetResourceAnomaliesTimeSeriesDataQueryVariables>;
export const GetResourcesTableInfoDocument = gql`
    query getResourcesTableInfo($from: Float!, $to: Float) {
  resources: models {
    ...ResourcesExecutiveTableInfo
  }
}
    ${ResourcesExecutiveTableInfoFragmentDoc}`;

/**
 * __useGetResourcesTableInfoQuery__
 *
 * To run a query within a React component, call `useGetResourcesTableInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesTableInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesTableInfoQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetResourcesTableInfoQuery(baseOptions: Apollo.QueryHookOptions<GetResourcesTableInfoQuery, GetResourcesTableInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourcesTableInfoQuery, GetResourcesTableInfoQueryVariables>(GetResourcesTableInfoDocument, options);
      }
export function useGetResourcesTableInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourcesTableInfoQuery, GetResourcesTableInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourcesTableInfoQuery, GetResourcesTableInfoQueryVariables>(GetResourcesTableInfoDocument, options);
        }
export type GetResourcesTableInfoQueryHookResult = ReturnType<typeof useGetResourcesTableInfoQuery>;
export type GetResourcesTableInfoLazyQueryHookResult = ReturnType<typeof useGetResourcesTableInfoLazyQuery>;
export type GetResourcesTableInfoQueryResult = Apollo.QueryResult<GetResourcesTableInfoQuery, GetResourcesTableInfoQueryVariables>;
export const GetSegmentOutputFeaturesDocument = gql`
    query getSegmentOutputFeatures($model: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float, $adHocRunId: String) {
  model(id: $model) {
    segment(tags: $tags) {
      id
      outputs {
        ...FeatureDataSideTable
      }
    }
  }
}
    ${FeatureDataSideTableFragmentDoc}`;

/**
 * __useGetSegmentOutputFeaturesQuery__
 *
 * To run a query within a React component, call `useGetSegmentOutputFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentOutputFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentOutputFeaturesQuery({
 *   variables: {
 *      model: // value for 'model'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      adHocRunId: // value for 'adHocRunId'
 *   },
 * });
 */
export function useGetSegmentOutputFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentOutputFeaturesQuery, GetSegmentOutputFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentOutputFeaturesQuery, GetSegmentOutputFeaturesQueryVariables>(GetSegmentOutputFeaturesDocument, options);
      }
export function useGetSegmentOutputFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentOutputFeaturesQuery, GetSegmentOutputFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentOutputFeaturesQuery, GetSegmentOutputFeaturesQueryVariables>(GetSegmentOutputFeaturesDocument, options);
        }
export type GetSegmentOutputFeaturesQueryHookResult = ReturnType<typeof useGetSegmentOutputFeaturesQuery>;
export type GetSegmentOutputFeaturesLazyQueryHookResult = ReturnType<typeof useGetSegmentOutputFeaturesLazyQuery>;
export type GetSegmentOutputFeaturesQueryResult = Apollo.QueryResult<GetSegmentOutputFeaturesQuery, GetSegmentOutputFeaturesQueryVariables>;
export const GetSegmentCountAndSegmentsForModelDocument = gql`
    query getSegmentCountAndSegmentsForModel($modelId: String!) {
  model(id: $modelId) {
    segments {
      tags {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useGetSegmentCountAndSegmentsForModelQuery__
 *
 * To run a query within a React component, call `useGetSegmentCountAndSegmentsForModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentCountAndSegmentsForModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentCountAndSegmentsForModelQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGetSegmentCountAndSegmentsForModelQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentCountAndSegmentsForModelQuery, GetSegmentCountAndSegmentsForModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentCountAndSegmentsForModelQuery, GetSegmentCountAndSegmentsForModelQueryVariables>(GetSegmentCountAndSegmentsForModelDocument, options);
      }
export function useGetSegmentCountAndSegmentsForModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentCountAndSegmentsForModelQuery, GetSegmentCountAndSegmentsForModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentCountAndSegmentsForModelQuery, GetSegmentCountAndSegmentsForModelQueryVariables>(GetSegmentCountAndSegmentsForModelDocument, options);
        }
export type GetSegmentCountAndSegmentsForModelQueryHookResult = ReturnType<typeof useGetSegmentCountAndSegmentsForModelQuery>;
export type GetSegmentCountAndSegmentsForModelLazyQueryHookResult = ReturnType<typeof useGetSegmentCountAndSegmentsForModelLazyQuery>;
export type GetSegmentCountAndSegmentsForModelQueryResult = Apollo.QueryResult<GetSegmentCountAndSegmentsForModelQuery, GetSegmentCountAndSegmentsForModelQueryVariables>;
export const GetAllSegmentsDocument = gql`
    query getAllSegments($dataset: String!, $from: Float!, $to: Float) {
  model(id: $dataset) {
    segments {
      tags {
        key
        value
      }
      anomalyCounts(fromTimestamp: $from, toTimestamp: $to) {
        totals {
          category
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllSegmentsQuery__
 *
 * To run a query within a React component, call `useGetAllSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSegmentsQuery({
 *   variables: {
 *      dataset: // value for 'dataset'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetAllSegmentsQuery(baseOptions: Apollo.QueryHookOptions<GetAllSegmentsQuery, GetAllSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSegmentsQuery, GetAllSegmentsQueryVariables>(GetAllSegmentsDocument, options);
      }
export function useGetAllSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSegmentsQuery, GetAllSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSegmentsQuery, GetAllSegmentsQueryVariables>(GetAllSegmentsDocument, options);
        }
export type GetAllSegmentsQueryHookResult = ReturnType<typeof useGetAllSegmentsQuery>;
export type GetAllSegmentsLazyQueryHookResult = ReturnType<typeof useGetAllSegmentsLazyQuery>;
export type GetAllSegmentsQueryResult = Apollo.QueryResult<GetAllSegmentsQuery, GetAllSegmentsQueryVariables>;
export const GetSegmentPerformanceDocument = gql`
    query getSegmentPerformance($datasetId: String!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!]) {
  model(id: $datasetId) {
    id
    name
    batchFrequency
    modelType
    segment(tags: $tags) {
      ...SegmentPerformance
    }
  }
}
    ${SegmentPerformanceFragmentDoc}`;

/**
 * __useGetSegmentPerformanceQuery__
 *
 * To run a query within a React component, call `useGetSegmentPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentPerformanceQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetSegmentPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentPerformanceQuery, GetSegmentPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentPerformanceQuery, GetSegmentPerformanceQueryVariables>(GetSegmentPerformanceDocument, options);
      }
export function useGetSegmentPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentPerformanceQuery, GetSegmentPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentPerformanceQuery, GetSegmentPerformanceQueryVariables>(GetSegmentPerformanceDocument, options);
        }
export type GetSegmentPerformanceQueryHookResult = ReturnType<typeof useGetSegmentPerformanceQuery>;
export type GetSegmentPerformanceLazyQueryHookResult = ReturnType<typeof useGetSegmentPerformanceLazyQuery>;
export type GetSegmentPerformanceQueryResult = Apollo.QueryResult<GetSegmentPerformanceQuery, GetSegmentPerformanceQueryVariables>;
export const SegmentFeaturesTableDocument = gql`
    query segmentFeaturesTable($datasetId: String!, $offset: Int!, $limit: Int!, $from: Float!, $to: Float, $text: String, $includeDiscrete: Boolean, $includeNonDiscrete: Boolean, $anomalyCategories: [AlertCategory!], $tags: [SegmentTagFilter!], $sort: FilteredFeaturesSort) {
  model(id: $datasetId) {
    batchFrequency
    weightMetadata {
      hasWeights
    }
    segment(tags: $tags) {
      filteredFeatures(sort: $sort, offset: $offset, limit: $limit, filter: {fromTimestamp: $from, toTimestamp: $to, substring: $text, anomalyCategories: $anomalyCategories, includeDiscrete: $includeDiscrete, includeNonDiscrete: $includeNonDiscrete}) {
        totalCount
        results {
          ...FeatureTableInfo
          weight {
            value
          }
        }
      }
    }
  }
}
    ${FeatureTableInfoFragmentDoc}`;

/**
 * __useSegmentFeaturesTableQuery__
 *
 * To run a query within a React component, call `useSegmentFeaturesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentFeaturesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentFeaturesTableQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      text: // value for 'text'
 *      includeDiscrete: // value for 'includeDiscrete'
 *      includeNonDiscrete: // value for 'includeNonDiscrete'
 *      anomalyCategories: // value for 'anomalyCategories'
 *      tags: // value for 'tags'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSegmentFeaturesTableQuery(baseOptions: Apollo.QueryHookOptions<SegmentFeaturesTableQuery, SegmentFeaturesTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentFeaturesTableQuery, SegmentFeaturesTableQueryVariables>(SegmentFeaturesTableDocument, options);
      }
export function useSegmentFeaturesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentFeaturesTableQuery, SegmentFeaturesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentFeaturesTableQuery, SegmentFeaturesTableQueryVariables>(SegmentFeaturesTableDocument, options);
        }
export type SegmentFeaturesTableQueryHookResult = ReturnType<typeof useSegmentFeaturesTableQuery>;
export type SegmentFeaturesTableLazyQueryHookResult = ReturnType<typeof useSegmentFeaturesTableLazyQuery>;
export type SegmentFeaturesTableQueryResult = Apollo.QueryResult<SegmentFeaturesTableQuery, SegmentFeaturesTableQueryVariables>;
export const SegmentOutputsTableDocument = gql`
    query segmentOutputsTable($datasetId: String!, $offset: Int!, $limit: Int!, $from: Float!, $to: Float, $tags: [SegmentTagFilter!], $sort: FilteredFeaturesSort) {
  model(id: $datasetId) {
    batchFrequency
    segment(tags: $tags) {
      filteredOutputs(sort: $sort, offset: $offset, limit: $limit, filter: {fromTimestamp: $from, toTimestamp: $to}) {
        totalCount
        results {
          ...FeatureTableInfo
        }
      }
    }
  }
}
    ${FeatureTableInfoFragmentDoc}`;

/**
 * __useSegmentOutputsTableQuery__
 *
 * To run a query within a React component, call `useSegmentOutputsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentOutputsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentOutputsTableQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      tags: // value for 'tags'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSegmentOutputsTableQuery(baseOptions: Apollo.QueryHookOptions<SegmentOutputsTableQuery, SegmentOutputsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SegmentOutputsTableQuery, SegmentOutputsTableQueryVariables>(SegmentOutputsTableDocument, options);
      }
export function useSegmentOutputsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SegmentOutputsTableQuery, SegmentOutputsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SegmentOutputsTableQuery, SegmentOutputsTableQueryVariables>(SegmentOutputsTableDocument, options);
        }
export type SegmentOutputsTableQueryHookResult = ReturnType<typeof useSegmentOutputsTableQuery>;
export type SegmentOutputsTableLazyQueryHookResult = ReturnType<typeof useSegmentOutputsTableLazyQuery>;
export type SegmentOutputsTableQueryResult = Apollo.QueryResult<SegmentOutputsTableQuery, SegmentOutputsTableQueryVariables>;
export const GetSegmentProfilesDocument = gql`
    query getSegmentProfiles($modelId: String!, $tags: [SegmentTagFilter!], $from: Float!, $to: Float!) {
  model(id: $modelId) {
    segment(tags: $tags) {
      batches(from: $from, to: $to) {
        ...BatchProfile
      }
      referenceProfiles {
        ...ReferenceProfile
      }
    }
  }
}
    ${BatchProfileFragmentDoc}
${ReferenceProfileFragmentDoc}`;

/**
 * __useGetSegmentProfilesQuery__
 *
 * To run a query within a React component, call `useGetSegmentProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentProfilesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetSegmentProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentProfilesQuery, GetSegmentProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentProfilesQuery, GetSegmentProfilesQueryVariables>(GetSegmentProfilesDocument, options);
      }
export function useGetSegmentProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentProfilesQuery, GetSegmentProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentProfilesQuery, GetSegmentProfilesQueryVariables>(GetSegmentProfilesDocument, options);
        }
export type GetSegmentProfilesQueryHookResult = ReturnType<typeof useGetSegmentProfilesQuery>;
export type GetSegmentProfilesLazyQueryHookResult = ReturnType<typeof useGetSegmentProfilesLazyQuery>;
export type GetSegmentProfilesQueryResult = Apollo.QueryResult<GetSegmentProfilesQuery, GetSegmentProfilesQueryVariables>;
export const GetSegmentSelectedProfilesDocument = gql`
    query getSegmentSelectedProfiles($modelId: String!, $timestamps: [Float!]!, $staticProfileIds: [String!], $offset: Int, $limit: Int, $filter: FeatureSketchFilter, $tags: [SegmentTagFilter!], $retrievalTokens: [String!]!, $allowIndividualProfiles: Boolean = false, $excludeEmpty: Boolean = false) {
  model(id: $modelId) {
    segment(tags: $tags) {
      batches(timestamps: $timestamps) {
        timestamp
        sketches(offset: $offset, limit: $limit, filter: $filter, excludeEmpty: $excludeEmpty) {
          ...ProfilesSketchFields
        }
      }
      referenceProfiles(profileIds: $staticProfileIds) {
        id
        alias
        sketches(offset: $offset, limit: $limit, filter: $filter, excludeEmpty: $excludeEmpty) {
          ...ProfilesSketchFields
        }
      }
      individualProfiles(retrievalTokens: $retrievalTokens) @include(if: $allowIndividualProfiles) {
        retrievalToken
        sketches(offset: $offset, limit: $limit, filter: $filter) {
          ...ProfilesSketchFields
        }
      }
      datasetMetrics {
        name
      }
      outputs {
        name
      }
    }
  }
}
    ${ProfilesSketchFieldsFragmentDoc}`;

/**
 * __useGetSegmentSelectedProfilesQuery__
 *
 * To run a query within a React component, call `useGetSegmentSelectedProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentSelectedProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentSelectedProfilesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      timestamps: // value for 'timestamps'
 *      staticProfileIds: // value for 'staticProfileIds'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      tags: // value for 'tags'
 *      retrievalTokens: // value for 'retrievalTokens'
 *      allowIndividualProfiles: // value for 'allowIndividualProfiles'
 *      excludeEmpty: // value for 'excludeEmpty'
 *   },
 * });
 */
export function useGetSegmentSelectedProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentSelectedProfilesQuery, GetSegmentSelectedProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentSelectedProfilesQuery, GetSegmentSelectedProfilesQueryVariables>(GetSegmentSelectedProfilesDocument, options);
      }
export function useGetSegmentSelectedProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentSelectedProfilesQuery, GetSegmentSelectedProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentSelectedProfilesQuery, GetSegmentSelectedProfilesQueryVariables>(GetSegmentSelectedProfilesDocument, options);
        }
export type GetSegmentSelectedProfilesQueryHookResult = ReturnType<typeof useGetSegmentSelectedProfilesQuery>;
export type GetSegmentSelectedProfilesLazyQueryHookResult = ReturnType<typeof useGetSegmentSelectedProfilesLazyQuery>;
export type GetSegmentSelectedProfilesQueryResult = Apollo.QueryResult<GetSegmentSelectedProfilesQuery, GetSegmentSelectedProfilesQueryVariables>;
export const GetModelSegmentColumnCountsDocument = gql`
    query getModelSegmentColumnCounts($modelId: String!, $tags: [SegmentTagFilter!]) {
  model(id: $modelId) {
    batchFrequency
    segment(tags: $tags) {
      entitySchema {
        inputCounts {
          discrete
          nonDiscrete
        }
        outputCounts {
          discrete
          nonDiscrete
        }
      }
    }
  }
}
    `;

/**
 * __useGetModelSegmentColumnCountsQuery__
 *
 * To run a query within a React component, call `useGetModelSegmentColumnCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelSegmentColumnCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelSegmentColumnCountsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetModelSegmentColumnCountsQuery(baseOptions: Apollo.QueryHookOptions<GetModelSegmentColumnCountsQuery, GetModelSegmentColumnCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelSegmentColumnCountsQuery, GetModelSegmentColumnCountsQueryVariables>(GetModelSegmentColumnCountsDocument, options);
      }
export function useGetModelSegmentColumnCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelSegmentColumnCountsQuery, GetModelSegmentColumnCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelSegmentColumnCountsQuery, GetModelSegmentColumnCountsQueryVariables>(GetModelSegmentColumnCountsDocument, options);
        }
export type GetModelSegmentColumnCountsQueryHookResult = ReturnType<typeof useGetModelSegmentColumnCountsQuery>;
export type GetModelSegmentColumnCountsLazyQueryHookResult = ReturnType<typeof useGetModelSegmentColumnCountsLazyQuery>;
export type GetModelSegmentColumnCountsQueryResult = Apollo.QueryResult<GetModelSegmentColumnCountsQuery, GetModelSegmentColumnCountsQueryVariables>;
export const GetSegmentedFilteredFeaturesDocument = gql`
    query getSegmentedFilteredFeatures($modelId: String!, $tags: [SegmentTagFilter!], $featuresOffset: Int = 0, $outputsOffset: Int = 0, $limit: Int!, $from: Float!, $to: Float!, $featureFilter: FeatureFilter!) {
  model(id: $modelId, startTime: $from, endTime: $to) {
    segment(tags: $tags) {
      filteredFeatures(offset: $featuresOffset, limit: $limit, filter: $featureFilter) {
        results {
          name
        }
      }
      filteredOutputs(offset: $outputsOffset, limit: $limit, filter: $featureFilter) {
        results {
          name
        }
      }
      datasetMetrics {
        name
      }
    }
  }
}
    `;

/**
 * __useGetSegmentedFilteredFeaturesQuery__
 *
 * To run a query within a React component, call `useGetSegmentedFilteredFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentedFilteredFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentedFilteredFeaturesQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      tags: // value for 'tags'
 *      featuresOffset: // value for 'featuresOffset'
 *      outputsOffset: // value for 'outputsOffset'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      featureFilter: // value for 'featureFilter'
 *   },
 * });
 */
export function useGetSegmentedFilteredFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentedFilteredFeaturesQuery, GetSegmentedFilteredFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentedFilteredFeaturesQuery, GetSegmentedFilteredFeaturesQueryVariables>(GetSegmentedFilteredFeaturesDocument, options);
      }
export function useGetSegmentedFilteredFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentedFilteredFeaturesQuery, GetSegmentedFilteredFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentedFilteredFeaturesQuery, GetSegmentedFilteredFeaturesQueryVariables>(GetSegmentedFilteredFeaturesDocument, options);
        }
export type GetSegmentedFilteredFeaturesQueryHookResult = ReturnType<typeof useGetSegmentedFilteredFeaturesQuery>;
export type GetSegmentedFilteredFeaturesLazyQueryHookResult = ReturnType<typeof useGetSegmentedFilteredFeaturesLazyQuery>;
export type GetSegmentedFilteredFeaturesQueryResult = Apollo.QueryResult<GetSegmentedFilteredFeaturesQuery, GetSegmentedFilteredFeaturesQueryVariables>;
export const GetAnomaliesForSpecificSegmentedProfileDocument = gql`
    query getAnomaliesForSpecificSegmentedProfile($modelId: String!, $filter: AnalysisFilter!, $sort: SortDirection!, $tags: [SegmentTagFilter!]) {
  model(id: $modelId) {
    segment(tags: $tags) {
      anomalies(filter: $filter, sortDirection: $sort) {
        datasetId
        datasetTimestamp
        metric
        analyzerType
        threshold_absoluteLower
        threshold_absoluteUpper
        threshold_calculatedLower
        threshold_calculatedUpper
        threshold_metricValue
        diff_metricValue
        diff_threshold
        drift_metricValue
        drift_threshold
        column
        tags {
          key
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetAnomaliesForSpecificSegmentedProfileQuery__
 *
 * To run a query within a React component, call `useGetAnomaliesForSpecificSegmentedProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomaliesForSpecificSegmentedProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomaliesForSpecificSegmentedProfileQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetAnomaliesForSpecificSegmentedProfileQuery(baseOptions: Apollo.QueryHookOptions<GetAnomaliesForSpecificSegmentedProfileQuery, GetAnomaliesForSpecificSegmentedProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnomaliesForSpecificSegmentedProfileQuery, GetAnomaliesForSpecificSegmentedProfileQueryVariables>(GetAnomaliesForSpecificSegmentedProfileDocument, options);
      }
export function useGetAnomaliesForSpecificSegmentedProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnomaliesForSpecificSegmentedProfileQuery, GetAnomaliesForSpecificSegmentedProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnomaliesForSpecificSegmentedProfileQuery, GetAnomaliesForSpecificSegmentedProfileQueryVariables>(GetAnomaliesForSpecificSegmentedProfileDocument, options);
        }
export type GetAnomaliesForSpecificSegmentedProfileQueryHookResult = ReturnType<typeof useGetAnomaliesForSpecificSegmentedProfileQuery>;
export type GetAnomaliesForSpecificSegmentedProfileLazyQueryHookResult = ReturnType<typeof useGetAnomaliesForSpecificSegmentedProfileLazyQuery>;
export type GetAnomaliesForSpecificSegmentedProfileQueryResult = Apollo.QueryResult<GetAnomaliesForSpecificSegmentedProfileQuery, GetAnomaliesForSpecificSegmentedProfileQueryVariables>;
export const GetAllAccessTokensDocument = gql`
    query getAllAccessTokens {
  accessTokens {
    name
    id
    userId
    createdAt
    expiresAt
    scopes
    isRevoked
  }
}
    `;

/**
 * __useGetAllAccessTokensQuery__
 *
 * To run a query within a React component, call `useGetAllAccessTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccessTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccessTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccessTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccessTokensQuery, GetAllAccessTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccessTokensQuery, GetAllAccessTokensQueryVariables>(GetAllAccessTokensDocument, options);
      }
export function useGetAllAccessTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccessTokensQuery, GetAllAccessTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccessTokensQuery, GetAllAccessTokensQueryVariables>(GetAllAccessTokensDocument, options);
        }
export type GetAllAccessTokensQueryHookResult = ReturnType<typeof useGetAllAccessTokensQuery>;
export type GetAllAccessTokensLazyQueryHookResult = ReturnType<typeof useGetAllAccessTokensLazyQuery>;
export type GetAllAccessTokensQueryResult = Apollo.QueryResult<GetAllAccessTokensQuery, GetAllAccessTokensQueryVariables>;
export const GenerateNewAccessTokenDocument = gql`
    mutation generateNewAccessToken($tokenName: String!, $expiresAt: Float, $scopes: [RequestableTokenScope!]) {
  accessToken {
    generate(name: $tokenName, expiresAt: $expiresAt, scopes: $scopes) {
      name
      id
      userId
      createdAt
      expiresAt
      scopes
      secret
    }
  }
}
    `;
export type GenerateNewAccessTokenMutationFn = Apollo.MutationFunction<GenerateNewAccessTokenMutation, GenerateNewAccessTokenMutationVariables>;

/**
 * __useGenerateNewAccessTokenMutation__
 *
 * To run a mutation, you first call `useGenerateNewAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateNewAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateNewAccessTokenMutation, { data, loading, error }] = useGenerateNewAccessTokenMutation({
 *   variables: {
 *      tokenName: // value for 'tokenName'
 *      expiresAt: // value for 'expiresAt'
 *      scopes: // value for 'scopes'
 *   },
 * });
 */
export function useGenerateNewAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateNewAccessTokenMutation, GenerateNewAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateNewAccessTokenMutation, GenerateNewAccessTokenMutationVariables>(GenerateNewAccessTokenDocument, options);
      }
export type GenerateNewAccessTokenMutationHookResult = ReturnType<typeof useGenerateNewAccessTokenMutation>;
export type GenerateNewAccessTokenMutationResult = Apollo.MutationResult<GenerateNewAccessTokenMutation>;
export type GenerateNewAccessTokenMutationOptions = Apollo.BaseMutationOptions<GenerateNewAccessTokenMutation, GenerateNewAccessTokenMutationVariables>;
export const RevokeAccessTokenDocument = gql`
    mutation revokeAccessToken($tokenId: String!, $userId: String!) {
  accessToken {
    revoke(id: $tokenId, userId: $userId) {
      id
    }
  }
}
    `;
export type RevokeAccessTokenMutationFn = Apollo.MutationFunction<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>;

/**
 * __useRevokeAccessTokenMutation__
 *
 * To run a mutation, you first call `useRevokeAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessTokenMutation, { data, loading, error }] = useRevokeAccessTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRevokeAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>(RevokeAccessTokenDocument, options);
      }
export type RevokeAccessTokenMutationHookResult = ReturnType<typeof useRevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationResult = Apollo.MutationResult<RevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationOptions = Apollo.BaseMutationOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>;
export const GetProjectDataForIntegrationsDocument = gql`
    query getProjectDataForIntegrations {
  models {
    id
    name
    dataAvailability {
      hasData
    }
    modelType
  }
}
    `;

/**
 * __useGetProjectDataForIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetProjectDataForIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDataForIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDataForIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectDataForIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectDataForIntegrationsQuery, GetProjectDataForIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectDataForIntegrationsQuery, GetProjectDataForIntegrationsQueryVariables>(GetProjectDataForIntegrationsDocument, options);
      }
export function useGetProjectDataForIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectDataForIntegrationsQuery, GetProjectDataForIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectDataForIntegrationsQuery, GetProjectDataForIntegrationsQueryVariables>(GetProjectDataForIntegrationsDocument, options);
        }
export type GetProjectDataForIntegrationsQueryHookResult = ReturnType<typeof useGetProjectDataForIntegrationsQuery>;
export type GetProjectDataForIntegrationsLazyQueryHookResult = ReturnType<typeof useGetProjectDataForIntegrationsLazyQuery>;
export type GetProjectDataForIntegrationsQueryResult = Apollo.QueryResult<GetProjectDataForIntegrationsQuery, GetProjectDataForIntegrationsQueryVariables>;
export const GetAllModelsForSettingsPageDocument = gql`
    query getAllModelsForSettingsPage {
  models {
    name
    id
    type: modelType
    timePeriod: batchFrequency
  }
}
    `;

/**
 * __useGetAllModelsForSettingsPageQuery__
 *
 * To run a query within a React component, call `useGetAllModelsForSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModelsForSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllModelsForSettingsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllModelsForSettingsPageQuery(baseOptions?: Apollo.QueryHookOptions<GetAllModelsForSettingsPageQuery, GetAllModelsForSettingsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllModelsForSettingsPageQuery, GetAllModelsForSettingsPageQueryVariables>(GetAllModelsForSettingsPageDocument, options);
      }
export function useGetAllModelsForSettingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllModelsForSettingsPageQuery, GetAllModelsForSettingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllModelsForSettingsPageQuery, GetAllModelsForSettingsPageQueryVariables>(GetAllModelsForSettingsPageDocument, options);
        }
export type GetAllModelsForSettingsPageQueryHookResult = ReturnType<typeof useGetAllModelsForSettingsPageQuery>;
export type GetAllModelsForSettingsPageLazyQueryHookResult = ReturnType<typeof useGetAllModelsForSettingsPageLazyQuery>;
export type GetAllModelsForSettingsPageQueryResult = Apollo.QueryResult<GetAllModelsForSettingsPageQuery, GetAllModelsForSettingsPageQueryVariables>;
export const CreateBulkSettingsPageDocument = gql`
    mutation createBulkSettingsPage($modelName: String!, $quantityNum: Int!, $modelType: ModelType!, $timePeriod: TimePeriod) {
  models {
    createBulk(name: $modelName, type: $modelType, quantity: $quantityNum, timePeriod: $timePeriod) {
      id
      name
    }
  }
}
    `;
export type CreateBulkSettingsPageMutationFn = Apollo.MutationFunction<CreateBulkSettingsPageMutation, CreateBulkSettingsPageMutationVariables>;

/**
 * __useCreateBulkSettingsPageMutation__
 *
 * To run a mutation, you first call `useCreateBulkSettingsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkSettingsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkSettingsPageMutation, { data, loading, error }] = useCreateBulkSettingsPageMutation({
 *   variables: {
 *      modelName: // value for 'modelName'
 *      quantityNum: // value for 'quantityNum'
 *      modelType: // value for 'modelType'
 *      timePeriod: // value for 'timePeriod'
 *   },
 * });
 */
export function useCreateBulkSettingsPageMutation(baseOptions?: Apollo.MutationHookOptions<CreateBulkSettingsPageMutation, CreateBulkSettingsPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBulkSettingsPageMutation, CreateBulkSettingsPageMutationVariables>(CreateBulkSettingsPageDocument, options);
      }
export type CreateBulkSettingsPageMutationHookResult = ReturnType<typeof useCreateBulkSettingsPageMutation>;
export type CreateBulkSettingsPageMutationResult = Apollo.MutationResult<CreateBulkSettingsPageMutation>;
export type CreateBulkSettingsPageMutationOptions = Apollo.BaseMutationOptions<CreateBulkSettingsPageMutation, CreateBulkSettingsPageMutationVariables>;
export const DeleteModelForSettingsPageDocument = gql`
    mutation deleteModelForSettingsPage($id: String!) {
  models {
    delete(id: $id) {
      id
      name
    }
  }
}
    `;
export type DeleteModelForSettingsPageMutationFn = Apollo.MutationFunction<DeleteModelForSettingsPageMutation, DeleteModelForSettingsPageMutationVariables>;

/**
 * __useDeleteModelForSettingsPageMutation__
 *
 * To run a mutation, you first call `useDeleteModelForSettingsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelForSettingsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelForSettingsPageMutation, { data, loading, error }] = useDeleteModelForSettingsPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModelForSettingsPageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModelForSettingsPageMutation, DeleteModelForSettingsPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModelForSettingsPageMutation, DeleteModelForSettingsPageMutationVariables>(DeleteModelForSettingsPageDocument, options);
      }
export type DeleteModelForSettingsPageMutationHookResult = ReturnType<typeof useDeleteModelForSettingsPageMutation>;
export type DeleteModelForSettingsPageMutationResult = Apollo.MutationResult<DeleteModelForSettingsPageMutation>;
export type DeleteModelForSettingsPageMutationOptions = Apollo.BaseMutationOptions<DeleteModelForSettingsPageMutation, DeleteModelForSettingsPageMutationVariables>;
export const UpdateModelDocument = gql`
    mutation updateModel($model: ModelUpdateParams!) {
  models {
    update(model: $model) {
      name
    }
  }
}
    `;
export type UpdateModelMutationFn = Apollo.MutationFunction<UpdateModelMutation, UpdateModelMutationVariables>;

/**
 * __useUpdateModelMutation__
 *
 * To run a mutation, you first call `useUpdateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelMutation, { data, loading, error }] = useUpdateModelMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdateModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelMutation, UpdateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelMutation, UpdateModelMutationVariables>(UpdateModelDocument, options);
      }
export type UpdateModelMutationHookResult = ReturnType<typeof useUpdateModelMutation>;
export type UpdateModelMutationResult = Apollo.MutationResult<UpdateModelMutation>;
export type UpdateModelMutationOptions = Apollo.BaseMutationOptions<UpdateModelMutation, UpdateModelMutationVariables>;
export const BulkUpdateModelsDocument = gql`
    mutation bulkUpdateModels($models: [ModelUpdateParams!]!) {
  models {
    bulkUpdate(models: $models) {
      name
    }
  }
}
    `;
export type BulkUpdateModelsMutationFn = Apollo.MutationFunction<BulkUpdateModelsMutation, BulkUpdateModelsMutationVariables>;

/**
 * __useBulkUpdateModelsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateModelsMutation, { data, loading, error }] = useBulkUpdateModelsMutation({
 *   variables: {
 *      models: // value for 'models'
 *   },
 * });
 */
export function useBulkUpdateModelsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateModelsMutation, BulkUpdateModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateModelsMutation, BulkUpdateModelsMutationVariables>(BulkUpdateModelsDocument, options);
      }
export type BulkUpdateModelsMutationHookResult = ReturnType<typeof useBulkUpdateModelsMutation>;
export type BulkUpdateModelsMutationResult = Apollo.MutationResult<BulkUpdateModelsMutation>;
export type BulkUpdateModelsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateModelsMutation, BulkUpdateModelsMutationVariables>;
export const GetGlobalActionIdsDocument = gql`
    query getGlobalActionIds {
  globalActions {
    listGlobalActions {
      id
      enabled
    }
  }
}
    `;

/**
 * __useGetGlobalActionIdsQuery__
 *
 * To run a query within a React component, call `useGetGlobalActionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalActionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalActionIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalActionIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalActionIdsQuery, GetGlobalActionIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalActionIdsQuery, GetGlobalActionIdsQueryVariables>(GetGlobalActionIdsDocument, options);
      }
export function useGetGlobalActionIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalActionIdsQuery, GetGlobalActionIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalActionIdsQuery, GetGlobalActionIdsQueryVariables>(GetGlobalActionIdsDocument, options);
        }
export type GetGlobalActionIdsQueryHookResult = ReturnType<typeof useGetGlobalActionIdsQuery>;
export type GetGlobalActionIdsLazyQueryHookResult = ReturnType<typeof useGetGlobalActionIdsLazyQuery>;
export type GetGlobalActionIdsQueryResult = Apollo.QueryResult<GetGlobalActionIdsQuery, GetGlobalActionIdsQueryVariables>;
export const GetModelsInfoDocument = gql`
    query getModelsInfo {
  models {
    name
    modelType
    id
  }
}
    `;

/**
 * __useGetModelsInfoQuery__
 *
 * To run a query within a React component, call `useGetModelsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelsInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetModelsInfoQuery, GetModelsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelsInfoQuery, GetModelsInfoQueryVariables>(GetModelsInfoDocument, options);
      }
export function useGetModelsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelsInfoQuery, GetModelsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelsInfoQuery, GetModelsInfoQueryVariables>(GetModelsInfoDocument, options);
        }
export type GetModelsInfoQueryHookResult = ReturnType<typeof useGetModelsInfoQuery>;
export type GetModelsInfoLazyQueryHookResult = ReturnType<typeof useGetModelsInfoLazyQuery>;
export type GetModelsInfoQueryResult = Apollo.QueryResult<GetModelsInfoQuery, GetModelsInfoQueryVariables>;
export const GetGlobalActionsDocument = gql`
    query getGlobalActions {
  globalActions {
    listGlobalActions {
      ...ActionDetails
    }
  }
}
    ${ActionDetailsFragmentDoc}`;

/**
 * __useGetGlobalActionsQuery__
 *
 * To run a query within a React component, call `useGetGlobalActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalActionsQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalActionsQuery, GetGlobalActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalActionsQuery, GetGlobalActionsQueryVariables>(GetGlobalActionsDocument, options);
      }
export function useGetGlobalActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalActionsQuery, GetGlobalActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalActionsQuery, GetGlobalActionsQueryVariables>(GetGlobalActionsDocument, options);
        }
export type GetGlobalActionsQueryHookResult = ReturnType<typeof useGetGlobalActionsQuery>;
export type GetGlobalActionsLazyQueryHookResult = ReturnType<typeof useGetGlobalActionsLazyQuery>;
export type GetGlobalActionsQueryResult = Apollo.QueryResult<GetGlobalActionsQuery, GetGlobalActionsQueryVariables>;
export const FindGlobalActionDocument = gql`
    query findGlobalAction($id: String!) {
  globalActions {
    findGlobalAction(id: $id) {
      ...ActionDetails
    }
  }
}
    ${ActionDetailsFragmentDoc}`;

/**
 * __useFindGlobalActionQuery__
 *
 * To run a query within a React component, call `useFindGlobalActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindGlobalActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindGlobalActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindGlobalActionQuery(baseOptions: Apollo.QueryHookOptions<FindGlobalActionQuery, FindGlobalActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindGlobalActionQuery, FindGlobalActionQueryVariables>(FindGlobalActionDocument, options);
      }
export function useFindGlobalActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindGlobalActionQuery, FindGlobalActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindGlobalActionQuery, FindGlobalActionQueryVariables>(FindGlobalActionDocument, options);
        }
export type FindGlobalActionQueryHookResult = ReturnType<typeof useFindGlobalActionQuery>;
export type FindGlobalActionLazyQueryHookResult = ReturnType<typeof useFindGlobalActionLazyQuery>;
export type FindGlobalActionQueryResult = Apollo.QueryResult<FindGlobalActionQuery, FindGlobalActionQueryVariables>;
export const UpdateGlobalActionDocument = gql`
    mutation updateGlobalAction($id: String!, $type: ActionType!, $payload: String!) {
  globalActions {
    updateGlobalAction(id: $id, type: $type, action: $payload)
  }
}
    `;
export type UpdateGlobalActionMutationFn = Apollo.MutationFunction<UpdateGlobalActionMutation, UpdateGlobalActionMutationVariables>;

/**
 * __useUpdateGlobalActionMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalActionMutation, { data, loading, error }] = useUpdateGlobalActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateGlobalActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGlobalActionMutation, UpdateGlobalActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGlobalActionMutation, UpdateGlobalActionMutationVariables>(UpdateGlobalActionDocument, options);
      }
export type UpdateGlobalActionMutationHookResult = ReturnType<typeof useUpdateGlobalActionMutation>;
export type UpdateGlobalActionMutationResult = Apollo.MutationResult<UpdateGlobalActionMutation>;
export type UpdateGlobalActionMutationOptions = Apollo.BaseMutationOptions<UpdateGlobalActionMutation, UpdateGlobalActionMutationVariables>;
export const CreateGlobalActionDocument = gql`
    mutation createGlobalAction($id: String!, $type: ActionType!, $payload: String!) {
  globalActions {
    createGlobalAction(id: $id, type: $type, action: $payload)
  }
}
    `;
export type CreateGlobalActionMutationFn = Apollo.MutationFunction<CreateGlobalActionMutation, CreateGlobalActionMutationVariables>;

/**
 * __useCreateGlobalActionMutation__
 *
 * To run a mutation, you first call `useCreateGlobalActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalActionMutation, { data, loading, error }] = useCreateGlobalActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateGlobalActionMutation(baseOptions?: Apollo.MutationHookOptions<CreateGlobalActionMutation, CreateGlobalActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGlobalActionMutation, CreateGlobalActionMutationVariables>(CreateGlobalActionDocument, options);
      }
export type CreateGlobalActionMutationHookResult = ReturnType<typeof useCreateGlobalActionMutation>;
export type CreateGlobalActionMutationResult = Apollo.MutationResult<CreateGlobalActionMutation>;
export type CreateGlobalActionMutationOptions = Apollo.BaseMutationOptions<CreateGlobalActionMutation, CreateGlobalActionMutationVariables>;
export const DeleteGlobalActionDocument = gql`
    mutation deleteGlobalAction($id: String!) {
  globalActions {
    deleteGlobalAction(id: $id)
  }
}
    `;
export type DeleteGlobalActionMutationFn = Apollo.MutationFunction<DeleteGlobalActionMutation, DeleteGlobalActionMutationVariables>;

/**
 * __useDeleteGlobalActionMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalActionMutation, { data, loading, error }] = useDeleteGlobalActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGlobalActionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGlobalActionMutation, DeleteGlobalActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGlobalActionMutation, DeleteGlobalActionMutationVariables>(DeleteGlobalActionDocument, options);
      }
export type DeleteGlobalActionMutationHookResult = ReturnType<typeof useDeleteGlobalActionMutation>;
export type DeleteGlobalActionMutationResult = Apollo.MutationResult<DeleteGlobalActionMutation>;
export type DeleteGlobalActionMutationOptions = Apollo.BaseMutationOptions<DeleteGlobalActionMutation, DeleteGlobalActionMutationVariables>;
export const DisableGlobalActionDocument = gql`
    mutation disableGlobalAction($id: String!) {
  globalActions {
    disableGlobalAction(id: $id)
  }
}
    `;
export type DisableGlobalActionMutationFn = Apollo.MutationFunction<DisableGlobalActionMutation, DisableGlobalActionMutationVariables>;

/**
 * __useDisableGlobalActionMutation__
 *
 * To run a mutation, you first call `useDisableGlobalActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableGlobalActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableGlobalActionMutation, { data, loading, error }] = useDisableGlobalActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableGlobalActionMutation(baseOptions?: Apollo.MutationHookOptions<DisableGlobalActionMutation, DisableGlobalActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableGlobalActionMutation, DisableGlobalActionMutationVariables>(DisableGlobalActionDocument, options);
      }
export type DisableGlobalActionMutationHookResult = ReturnType<typeof useDisableGlobalActionMutation>;
export type DisableGlobalActionMutationResult = Apollo.MutationResult<DisableGlobalActionMutation>;
export type DisableGlobalActionMutationOptions = Apollo.BaseMutationOptions<DisableGlobalActionMutation, DisableGlobalActionMutationVariables>;
export const EnableGlobalActionDocument = gql`
    mutation enableGlobalAction($id: String!) {
  globalActions {
    enableGlobalAction(id: $id)
  }
}
    `;
export type EnableGlobalActionMutationFn = Apollo.MutationFunction<EnableGlobalActionMutation, EnableGlobalActionMutationVariables>;

/**
 * __useEnableGlobalActionMutation__
 *
 * To run a mutation, you first call `useEnableGlobalActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableGlobalActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableGlobalActionMutation, { data, loading, error }] = useEnableGlobalActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableGlobalActionMutation(baseOptions?: Apollo.MutationHookOptions<EnableGlobalActionMutation, EnableGlobalActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableGlobalActionMutation, EnableGlobalActionMutationVariables>(EnableGlobalActionDocument, options);
      }
export type EnableGlobalActionMutationHookResult = ReturnType<typeof useEnableGlobalActionMutation>;
export type EnableGlobalActionMutationResult = Apollo.MutationResult<EnableGlobalActionMutation>;
export type EnableGlobalActionMutationOptions = Apollo.BaseMutationOptions<EnableGlobalActionMutation, EnableGlobalActionMutationVariables>;
export const TestNotificationActionDocument = gql`
    mutation testNotificationAction($id: String!) {
  globalActions {
    testGlobalAction(id: $id)
  }
}
    `;
export type TestNotificationActionMutationFn = Apollo.MutationFunction<TestNotificationActionMutation, TestNotificationActionMutationVariables>;

/**
 * __useTestNotificationActionMutation__
 *
 * To run a mutation, you first call `useTestNotificationActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestNotificationActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testNotificationActionMutation, { data, loading, error }] = useTestNotificationActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestNotificationActionMutation(baseOptions?: Apollo.MutationHookOptions<TestNotificationActionMutation, TestNotificationActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestNotificationActionMutation, TestNotificationActionMutationVariables>(TestNotificationActionDocument, options);
      }
export type TestNotificationActionMutationHookResult = ReturnType<typeof useTestNotificationActionMutation>;
export type TestNotificationActionMutationResult = Apollo.MutationResult<TestNotificationActionMutation>;
export type TestNotificationActionMutationOptions = Apollo.BaseMutationOptions<TestNotificationActionMutation, TestNotificationActionMutationVariables>;
export const GetUsersOrganizationMembersDocument = gql`
    query getUsersOrganizationMembers {
  user {
    auth0Id
    name
    isAuthenticated
    organization {
      id
      name
      members {
        userId
        role
        email
      }
    }
  }
}
    `;

/**
 * __useGetUsersOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useGetUsersOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersOrganizationMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersOrganizationMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersOrganizationMembersQuery, GetUsersOrganizationMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersOrganizationMembersQuery, GetUsersOrganizationMembersQueryVariables>(GetUsersOrganizationMembersDocument, options);
      }
export function useGetUsersOrganizationMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersOrganizationMembersQuery, GetUsersOrganizationMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersOrganizationMembersQuery, GetUsersOrganizationMembersQueryVariables>(GetUsersOrganizationMembersDocument, options);
        }
export type GetUsersOrganizationMembersQueryHookResult = ReturnType<typeof useGetUsersOrganizationMembersQuery>;
export type GetUsersOrganizationMembersLazyQueryHookResult = ReturnType<typeof useGetUsersOrganizationMembersLazyQuery>;
export type GetUsersOrganizationMembersQueryResult = Apollo.QueryResult<GetUsersOrganizationMembersQuery, GetUsersOrganizationMembersQueryVariables>;
export const GetUserOrganizationsDocument = gql`
    query getUserOrganizations {
  user {
    auth0Id
    name
    isAuthenticated
    joinedOrganizations {
      ...JoinedOrganization
    }
  }
}
    ${JoinedOrganizationFragmentDoc}`;

/**
 * __useGetUserOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>(GetUserOrganizationsDocument, options);
      }
export function useGetUserOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>(GetUserOrganizationsDocument, options);
        }
export type GetUserOrganizationsQueryHookResult = ReturnType<typeof useGetUserOrganizationsQuery>;
export type GetUserOrganizationsLazyQueryHookResult = ReturnType<typeof useGetUserOrganizationsLazyQuery>;
export type GetUserOrganizationsQueryResult = Apollo.QueryResult<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>;
export const SelectDefaultOrganizationDocument = gql`
    mutation selectDefaultOrganization($orgId: String!) {
  memberships {
    setDefaultOrganization(orgId: $orgId)
  }
}
    `;
export type SelectDefaultOrganizationMutationFn = Apollo.MutationFunction<SelectDefaultOrganizationMutation, SelectDefaultOrganizationMutationVariables>;

/**
 * __useSelectDefaultOrganizationMutation__
 *
 * To run a mutation, you first call `useSelectDefaultOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectDefaultOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectDefaultOrganizationMutation, { data, loading, error }] = useSelectDefaultOrganizationMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useSelectDefaultOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SelectDefaultOrganizationMutation, SelectDefaultOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectDefaultOrganizationMutation, SelectDefaultOrganizationMutationVariables>(SelectDefaultOrganizationDocument, options);
      }
export type SelectDefaultOrganizationMutationHookResult = ReturnType<typeof useSelectDefaultOrganizationMutation>;
export type SelectDefaultOrganizationMutationResult = Apollo.MutationResult<SelectDefaultOrganizationMutation>;
export type SelectDefaultOrganizationMutationOptions = Apollo.BaseMutationOptions<SelectDefaultOrganizationMutation, SelectDefaultOrganizationMutationVariables>;
export const AddUserToOrganisationDocument = gql`
    mutation addUserToOrganisation($email: String!, $role: MembershipRole) {
  memberships {
    add(email: $email, role: $role) {
      userId
    }
  }
}
    `;
export type AddUserToOrganisationMutationFn = Apollo.MutationFunction<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>;

/**
 * __useAddUserToOrganisationMutation__
 *
 * To run a mutation, you first call `useAddUserToOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToOrganisationMutation, { data, loading, error }] = useAddUserToOrganisationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddUserToOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>(AddUserToOrganisationDocument, options);
      }
export type AddUserToOrganisationMutationHookResult = ReturnType<typeof useAddUserToOrganisationMutation>;
export type AddUserToOrganisationMutationResult = Apollo.MutationResult<AddUserToOrganisationMutation>;
export type AddUserToOrganisationMutationOptions = Apollo.BaseMutationOptions<AddUserToOrganisationMutation, AddUserToOrganisationMutationVariables>;
export const AddMultipleUsersToOrganisationDocument = gql`
    mutation addMultipleUsersToOrganisation($emails: [String!]!, $role: MembershipRole) {
  memberships {
    bulkAdd(emails: $emails, role: $role) {
      email
    }
  }
}
    `;
export type AddMultipleUsersToOrganisationMutationFn = Apollo.MutationFunction<AddMultipleUsersToOrganisationMutation, AddMultipleUsersToOrganisationMutationVariables>;

/**
 * __useAddMultipleUsersToOrganisationMutation__
 *
 * To run a mutation, you first call `useAddMultipleUsersToOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultipleUsersToOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultipleUsersToOrganisationMutation, { data, loading, error }] = useAddMultipleUsersToOrganisationMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAddMultipleUsersToOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<AddMultipleUsersToOrganisationMutation, AddMultipleUsersToOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMultipleUsersToOrganisationMutation, AddMultipleUsersToOrganisationMutationVariables>(AddMultipleUsersToOrganisationDocument, options);
      }
export type AddMultipleUsersToOrganisationMutationHookResult = ReturnType<typeof useAddMultipleUsersToOrganisationMutation>;
export type AddMultipleUsersToOrganisationMutationResult = Apollo.MutationResult<AddMultipleUsersToOrganisationMutation>;
export type AddMultipleUsersToOrganisationMutationOptions = Apollo.BaseMutationOptions<AddMultipleUsersToOrganisationMutation, AddMultipleUsersToOrganisationMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation updateMember($email: String!, $role: MembershipRole!) {
  memberships {
    update(email: $email, role: $role) {
      userId
    }
  }
}
    `;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const RemoveMemberFromOrgDocument = gql`
    mutation removeMemberFromOrg($email: String!) {
  memberships {
    remove(email: $email) {
      email
    }
  }
}
    `;
export type RemoveMemberFromOrgMutationFn = Apollo.MutationFunction<RemoveMemberFromOrgMutation, RemoveMemberFromOrgMutationVariables>;

/**
 * __useRemoveMemberFromOrgMutation__
 *
 * To run a mutation, you first call `useRemoveMemberFromOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberFromOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberFromOrgMutation, { data, loading, error }] = useRemoveMemberFromOrgMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveMemberFromOrgMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberFromOrgMutation, RemoveMemberFromOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberFromOrgMutation, RemoveMemberFromOrgMutationVariables>(RemoveMemberFromOrgDocument, options);
      }
export type RemoveMemberFromOrgMutationHookResult = ReturnType<typeof useRemoveMemberFromOrgMutation>;
export type RemoveMemberFromOrgMutationResult = Apollo.MutationResult<RemoveMemberFromOrgMutation>;
export type RemoveMemberFromOrgMutationOptions = Apollo.BaseMutationOptions<RemoveMemberFromOrgMutation, RemoveMemberFromOrgMutationVariables>;
export const ClaimSessionDocument = gql`
    mutation claimSession($sessionToken: String!) {
  claimSession(sessionToken: $sessionToken)
}
    `;
export type ClaimSessionMutationFn = Apollo.MutationFunction<ClaimSessionMutation, ClaimSessionMutationVariables>;

/**
 * __useClaimSessionMutation__
 *
 * To run a mutation, you first call `useClaimSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimSessionMutation, { data, loading, error }] = useClaimSessionMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useClaimSessionMutation(baseOptions?: Apollo.MutationHookOptions<ClaimSessionMutation, ClaimSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimSessionMutation, ClaimSessionMutationVariables>(ClaimSessionDocument, options);
      }
export type ClaimSessionMutationHookResult = ReturnType<typeof useClaimSessionMutation>;
export type ClaimSessionMutationResult = Apollo.MutationResult<ClaimSessionMutation>;
export type ClaimSessionMutationOptions = Apollo.BaseMutationOptions<ClaimSessionMutation, ClaimSessionMutationVariables>;